import {
  format,
  isAfter,
  differenceInCalendarDays,
  subDays,
  parseISO,
  parse,
  isBefore,
} from 'date-fns'
import fr from 'date-fns/locale/fr'
import {
  DATE_FORMAT_TIMEZONE,
  TIMESLOT,
  ISO_SHORT,
  DATE_FORMAT_TIME,
  DATE_FORMAT,
} from 'constants/date'

/**
 * dateFormatter
 *
 * format date in french
 *
 * @param {string|Date} date unformated date (ex: 2018-11-16T00:00:00+00:00)
 * @param {string} dateFormat format to transform
 */

export const dateFormatter = (date, dateFormat = DATE_FORMAT_TIMEZONE) => {
  const dateToConv = typeof date === 'string' ? parseISO(date) : date

  return date ? format(dateToConv, dateFormat, { locale: fr }) : null
}

export const getTimeSlotFromDate = date =>
  date !== undefined ? date.substring(11, 16) : ''

export const formatTimeSlotFormValue = timeslot => {
  if (!timeslot) {
    return ''
  }
  const startTimeSlot =
    timeslot.startTime !== undefined
      ? dateFormatter(timeslot.startTime, TIMESLOT)
      : ''
  const stopTimeSlot =
    timeslot.stopTime !== undefined
      ? dateFormatter(timeslot.stopTime, TIMESLOT)
      : ''

  return `${startTimeSlot}-${stopTimeSlot}`
}

export const formatDateTimeSlotFormValue = timeslot => {
  if (!timeslot) {
    return ''
  }
  const startTimeSlot =
    timeslot.startTime !== undefined
      ? dateFormatter(timeslot.startTime, DATE_FORMAT_TIME)
      : ''
  const stopTimeSlot =
    timeslot.stopTime !== undefined
      ? dateFormatter(timeslot.stopTime).split(' ')
      : ''

  return `${startTimeSlot}-${stopTimeSlot[1]}`
}

/**
 *
 * @param {Date|string} date
 * @param {number} delay
 * @returns {boolean}
 */
export const isDateLate = (date, delay = 0) => {
  const parsedDate = parseISO(date)
  const dateToTest = delay
    ? subDays(parsedDate, delay + 1)
    : parseISO(dateFormatter(parsedDate, ISO_SHORT))

  const today = parseISO(dateFormatter(new Date(), ISO_SHORT))

  return (
    isAfter(today, dateToTest) &&
    differenceInCalendarDays(today, dateToTest) > 0
  )
}

/**
 *
 * @param {Date|string} date
 * @returns {number}
 */
export const convertToTimestamp = date => date.getTime() / 1000

/**
 * @param date {string} '05/04/2018'
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {date}
 */
export const createDate = (date, dateFormat = DATE_FORMAT) =>
  parse(date, dateFormat, new Date())

/**
 * @param date {string}
 * @returns {boolean}
 */
export const isBeforeFormattedDate = date =>
  isBefore(parseISO(date), new Date())
