import { compose } from 'redux'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import { getJobEventsForJob } from 'store/jobs/jobActions'

import {
  jobEventsByJobIdIsLoadingSelector,
  currentJobHistorySelector,
  currentJobHistoryParametersSelector,
} from 'store/jobs/jobSelectors'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import JobHistory from './JobHistory'

class JobHistoryContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      job: props.jobId,
      page: props.currentJobHistoryParams.page,
      perPage: props.currentJobHistoryParams.perPage,
    }
  }

  componentDidMount() {
    this.loadJobEvents(this.state)
  }

  loadJobEvents = params => {
    const { getJobEvents } = this.props
    getJobEvents(params)
  }

  handleChangePage = (event, page) => {
    const { currentJobHistoryParams } = this.props
    const params = { ...currentJobHistoryParams, page }

    this.loadJobEvents(params)
  }

  handleChangeRowsPerPage = event => {
    const { currentJobHistoryParams } = this.props
    const params = {
      ...currentJobHistoryParams,
      perPage: event.target.value,
      page: 0,
    }
    this.loadJobEvents(params)
  }

  setLabelDisplayedRows = ({ from, to, count }) => {
    const { translate } = this.props
    return translate('navigation.page_range_info', {
      offsetBegin: from,
      offsetEnd: to,
      total: count,
    })
  }

  render() {
    const {
      currentJobHistory,
      jobId,
      translate,
      isLoading,
      currentJobHistoryParams,
    } = this.props

    if (isLoading) {
      return <CircularProgress size={20} />
    }

    return (
      <JobHistory
        currentJobHistory={currentJobHistory}
        searchParams={currentJobHistoryParams}
        jobId={jobId}
        translate={translate}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        setLabelDisplayedRows={this.setLabelDisplayedRows}
      />
    )
  }
}

JobHistoryContainer.propTypes = {
  currentJobHistory: PropTypes.shape({}),
  currentJobHistoryParams: PropTypes.shape({
    perPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
  jobId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  getJobEvents: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

JobHistoryContainer.defaultProps = {
  currentJobHistory: {
    totalItems: 0,
    events: [],
  },
}

const mapStateToProps = state => ({
  isLoading: jobEventsByJobIdIsLoadingSelector(state),
  currentJobHistoryParams: currentJobHistoryParametersSelector(state),
  currentJobHistory: currentJobHistorySelector(state),
})

const mapDispatchToProps = dispatch => ({
  getJobEvents: searchParams =>
    dispatch(getJobEventsForJob.request({ searchParams })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
)(JobHistoryContainer)
