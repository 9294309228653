import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import Pdf from 'components/shared/Pdf/Pdf'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import { currentSaleInvoiceSelector } from 'store/saleInvoices/saleInvoiceSelectors'
import { getSingleSaleInvoice } from 'store/saleInvoices/saleInvoiceActions'

class SaleInvoicesPreviewContainer extends PureComponent {
  componentDidMount() {
    const { getSaleInvoice } = this.props
    getSaleInvoice()
  }

  render() {
    const { saleInvoice, isLoading, translate } = this.props

    return (
      saleInvoice.content &&
      !isLoading && (
        <Pdf
          file={`data:application/pdf;base64,${saleInvoice.content}`}
          downloadFilename={`${translate(
            'resources.saleInvoices.preview.filename',
          )}.pdf`}
        />
      )
    )
  }
}

SaleInvoicesPreviewContainer.defaultProps = {
  saleInvoice: {},
}

SaleInvoicesPreviewContainer.propTypes = {
  saleInvoice: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  }),
  getSaleInvoice: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  saleInvoice: currentSaleInvoiceSelector(state),
  isLoading: isLoadingSelector(state),
})

const mapDispatchToProps = (dispatch, { saleInvoiceIri }) => ({
  getSaleInvoice: () =>
    dispatch(getSingleSaleInvoice.request({ saleInvoiceIri })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
)(SaleInvoicesPreviewContainer)
