import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { SEARCH_PROS_MANUAL_MATCHING } from 'constants/forms'
import { reduxForm, Field } from 'redux-form'
import { Button, FormControl, Grid, withStyles } from '@material-ui/core'
import RenderTextField from 'components/shared/Form/RenderTextField'
import styles from './ProsSearchStyles'

const ProsSearch = ({
  classes,
  translate,
  handleSearchChange,
  handleReset,
}) => (
  <Grid container justifyContent="flex-start">
    <FormControl className={classes.formControl}>
      <Field
        name="proLastName"
        type="search"
        component={RenderTextField}
        label={translate('resources.firms.fields.last_name')}
        onChange={values => handleSearchChange('proLastName', values)}
      />
    </FormControl>
    <FormControl className={classes.formControl}>
      <Field
        name="proLegacyId"
        type="search"
        component={RenderTextField}
        label={translate('resources.firms.fields.firm_legacy_id')}
        onChange={values => handleSearchChange('proLegacyId', values)}
      />
    </FormControl>
    <FormControl className={classes.formControl}>
      <Button
        onClick={handleReset}
        variant="contained"
        type="reset"
        className={classes.buttonSecondary}
      >
        {translate('app.message.reset')}
      </Button>
    </FormControl>
  </Grid>
)

ProsSearch.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    buttonSecondary: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
}

export default compose(
  i18n,
  reduxForm({
    form: SEARCH_PROS_MANUAL_MATCHING,
  }),
  withStyles(styles),
)(ProsSearch)
