import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  withStyles,
  Card,
  CardContent,
  Button,
  Link as SimpleLink,
  Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import i18n from 'providers/i18n/I18nProvider'
import InlineTextField from 'components/shared/InlineTextField/InlineTextField'
import JobTimeslot from 'components/Jobs/JobTimeslot/JobTimeslot'
import Label from 'components/shared/Label/Label'
import Link from 'components/shared/Link/Link'
import { formatIriToId } from 'helpers/utils/common'
import {
  JOB_PATH_MANUAL_MATCHING_PREFIX,
  PROS_PATH_PREFIX,
} from 'constants/routes'
import {
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  DELIVERY_MODE_BY_CRAFTSMAN,
  PENDING_RESCHEDULING_STATUS,
  PENDING_NEW_DATE_STATUS,
  TO_PENDING_NEW_DATE_STATUS_ENABLED,
  TO_PENDING_NEW_DATE_SUBSTATUS_ENABLED,
  AFTER_SALE_ALL_TYPES,
  MANUAL_RESOLUTION_AFTER_SALE_TYPE,
  PK_TYPE,
  MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE,
} from 'constants/Jobs'
import { DATE_FORMAT_TIME } from 'constants/date'
import { dateFormatter } from 'helpers/date'
import Grid from '@material-ui/core/Grid'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import RescheduleManualMatching from 'components/Jobs/ManualMatching/RescheduleManualMatching/RescheduleManualMatching'
import styles from './JobPlanificationStyles'

const JobPlanification = ({
  classes,
  translate,
  selectedProName,
  interventionDate,
  timeSlotRange,
  jobId,
  proId,
  status,
  subStatus,
  legacyId,
  deliveryMode,
  rematchingJob,
  acceptedProExpiredAtCertificate,
  requiredCertificate,
  timeslots,
  matchings,
  openedIncidentsCount,
  pendingNewDateRequest,
  isPendingNewDateEnabled,
  isManualAfterSaleDiag,
  type,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)
  const isPendingNewDateTransitionDisabled =
    TO_PENDING_NEW_DATE_STATUS_ENABLED.includes(status) ||
    TO_PENDING_NEW_DATE_SUBSTATUS_ENABLED.includes(subStatus)
  const isAfterSale = AFTER_SALE_ALL_TYPES.includes(type)

  const displayPlanificationCard =
    !isAfterSale || (isAfterSale && status !== PENDING_MANUAL_ASSIGNMENT_STATUS)

  const handleResponse = ({ currentTarget: { value } }) => {
    if (value === 'true') {
      pendingNewDateRequest()
    }
    setDialogOpened(!confirmDialogOpened)
  }

  return (
    <Grid container>
      <Card className={classes.card}>
        {status === PENDING_RESCHEDULING_STATUS && <RescheduleManualMatching />}
        {openedIncidentsCount > 0 && (
          <Alert severity="error">
            {translate('job.manual_matching.alert_opened_incidents', {
              smart_count: openedIncidentsCount,
            })}
          </Alert>
        )}
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <InlineTextField
                      label={translate('resources.jobs.fields.proName')}
                      field={
                        proId ? (
                          <SimpleLink
                            href={`${PROS_PATH_PREFIX}/${formatIriToId(
                              proId,
                            )}/show`}
                            target="_blank"
                            className={classes.linkBold}
                          >
                            {selectedProName}
                          </SimpleLink>
                        ) : (
                          '-'
                        )
                      }
                    />
                  </Typography>
                  <InlineTextField
                    label={translate('job.manual_matching.id_legacy')}
                    field={
                      proId ? (
                        <SimpleLink
                          href={`${PROS_PATH_PREFIX}/${formatIriToId(
                            proId,
                          )}/show`}
                          target="_blank"
                          className={classes.linkBold}
                        >
                          {legacyId}
                        </SimpleLink>
                      ) : (
                        '-'
                      )
                    }
                  />
                  {acceptedProExpiredAtCertificate && (
                    <InlineTextField
                      label={translate(
                        'resources.firms.certificate.expiration_date',
                      )}
                      field={dateFormatter(
                        acceptedProExpiredAtCertificate,
                        translate('app.date_format.short'),
                      )}
                      isBold
                      certificate={requiredCertificate}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    <InlineTextField
                      label={translate('job.manual_matching.date_input.label')}
                      field={interventionDate}
                      isBold
                      isStriked={status === PENDING_NEW_DATE_STATUS}
                    />
                  </Typography>
                  <InlineTextField
                    label={translate('job.manual_matching.time_slot')}
                    field={timeSlotRange}
                    isBold
                    isStriked={status === PENDING_NEW_DATE_STATUS}
                  />
                </Grid>
                {/* HISTORY MATCHING */}
                <Grid>
                  <Label
                    label={translate('job.manual_matching.history.label')}
                    className={classes.labels}
                    isBold
                  />
                  <ul className={classes.matchingDate}>
                    {matchings &&
                      matchings.map(matching => (
                        <li key={matching}>
                          {dateFormatter(
                            matching.createdAt,
                            DATE_FORMAT_TIME,
                          ).toString()}
                        </li>
                      ))}
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} direction="row" justifyContent="flex-end">
              {status !== PENDING_NEW_DATE_STATUS &&
                type !== isAfterSale &&
                (type === PK_TYPE ||
                  type === MANUAL_RESOLUTION_AFTER_SALE_TYPE ||
                  (type === MANUAL_DIAGNOSTIC_AFTER_SALE_TYPE &&
                    isManualAfterSaleDiag)) && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonBigger}
                    iri={jobId}
                    component={Link}
                    pathname={`${JOB_PATH_MANUAL_MATCHING_PREFIX}/${encodeURIComponent(
                      jobId,
                    )}`}
                  >
                    {translate('resources.jobs.button.matchedPro')}
                  </Button>
                )}
              {status === PENDING_MANUAL_ASSIGNMENT_STATUS &&
                deliveryMode !== DELIVERY_MODE_BY_CRAFTSMAN &&
                !isAfterSale && (
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonBigger}
                    onClick={() => rematchingJob()}
                  >
                    {translate('resources.jobs.button.rematch_auto')}
                  </Button>
                )}

              {isPendingNewDateEnabled && !isAfterSale && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isPendingNewDateTransitionDisabled}
                    className={classes.buttonBigger}
                    onClick={() => setDialogOpened(true)}
                  >
                    {translate('resources.jobs.button.client_rescheduling')}
                  </Button>
                  {isPendingNewDateTransitionDisabled && !isAfterSale && (
                    <Typography variant="caption">
                      {translate(
                        'resources.jobs.error_message.client_rescheduling',
                      )}
                    </Typography>
                  )}
                  {confirmDialogOpened && (
                    <ConfirmDialog
                      title={translate(
                        'job.pending_new_date.confirm_popin.title',
                      )}
                      onClose={handleResponse}
                    >
                      <Typography component="p">
                        {translate(
                          'job.pending_new_date.confirm_popin.content',
                        )}
                      </Typography>
                    </ConfirmDialog>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        {displayPlanificationCard && (
          <CardContent>
            <Label
              label={translate('resources.jobs.fields.timeslots')}
              className={classes.labels}
              isBold
            />
            <Grid className={classes.tableDate}>
              <JobTimeslot timeslots={timeslots} status={status} />
            </Grid>
          </CardContent>
        )}
      </Card>
    </Grid>
  )
}

JobPlanification.propTypes = {
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    linkBold: PropTypes.string.isRequired,
    labels: PropTypes.string.isRequired,
    tableDate: PropTypes.string.isRequired,
    matchingDate: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  selectedProName: PropTypes.string,
  timeSlotRange: PropTypes.string,
  interventionDate: PropTypes.string,
  jobId: PropTypes.string,
  status: PropTypes.string,
  subStatus: PropTypes.string,
  proId: PropTypes.string,
  legacyId: PropTypes.number,
  deliveryMode: PropTypes.string,
  rematchingJob: PropTypes.func.isRequired,
  acceptedProExpiredAtCertificate: PropTypes.string,
  requiredCertificate: PropTypes.string,
  timeslots: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      stopTime: PropTypes.string,
    }),
  ),
  matchings: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
    }),
  ).isRequired,
  openedIncidentsCount: PropTypes.number.isRequired,
  pendingNewDateRequest: PropTypes.func.isRequired,
  isPendingNewDateEnabled: PropTypes.bool.isRequired,
  isManualAfterSaleDiag: PropTypes.bool.isRequired,
  type: PropTypes.string,
}

JobPlanification.defaultProps = {
  selectedProName: undefined,
  timeSlotRange: undefined,
  interventionDate: undefined,
  proId: undefined,
  jobId: undefined,
  status: undefined,
  subStatus: undefined,
  legacyId: undefined,
  deliveryMode: undefined,
  acceptedProExpiredAtCertificate: null,
  requiredCertificate: null,
  timeslots: undefined,
  type: undefined,
}

export default compose(i18n, memo, withStyles(styles))(JobPlanification)
