import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const styles = () => ({
  wrapper: {
    fontSize: '0.80rem',
  },
  relativeContainer: {
    position: 'relative',
  },
})

const WrapperInline = ({ classes, withIcon, children }) => (
  <div
    className={classNames({
      [classes.wrapper]: true,
      [classes.relativeContainer]: withIcon,
    })}
  >
    {children}
  </div>
)
WrapperInline.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    relativeContainer: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  withIcon: PropTypes.bool,
}

WrapperInline.defaultProps = {
  withIcon: false,
}

export default withStyles(styles)(WrapperInline)
