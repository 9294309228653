import React from 'react'
import PropTypes from 'prop-types'

const FieldEMail = ({ label, email, Component, ...props }) => (
  <Component
    label={label}
    field={`<a href="mailto:${email}">${email}</a>`}
    {...props}
  />
)

FieldEMail.propTypes = {
  label: PropTypes.string,
  email: PropTypes.string,
  Component: PropTypes.func.isRequired,
}

FieldEMail.defaultProps = {
  label: undefined,
  email: undefined,
}

export default FieldEMail
