import { connect } from 'react-redux'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { getReceiptSignature } from 'store/jobs/jobActions'
import { uploadJobBFT } from 'store/upload/uploadActions'
import {
  statusSelector,
  receiptSignatureStatusSelector,
  receiptSignatureRefusalMemberSelector,
  receiptSignatureRefusalReasonSelector,
  receiptSignatureSelector,
} from 'store/jobs/jobSelectors'
import translate from 'providers/i18n/translateService'
import Receipt from './Receipt'

const mapStateToProps = (state, { jobIri }) => ({
  receiptSignatureStatus: receiptSignatureStatusSelector(state),
  receiptSignatureRefusalMember: receiptSignatureRefusalMemberSelector(state),
  receiptSignatureRefusalReason: receiptSignatureRefusalReasonSelector(state),
  receiptSignature: receiptSignatureSelector(state),
  jobStatus: statusSelector(state),
  jobIri,
})

const mapDispatchToProps = (dispatch, { jobIri }) => ({
  dispatchUploadJobBFT: (file, { isContested }) => {
    const contestation =
      isContested === true ? translate('resources.receipt.reserveText') : null
    return dispatch(uploadJobBFT(file, { reserves: contestation }))
  },
  dispatchReceiptSignature: () => {
    dispatch(getReceiptSignature.request({ jobIri }))
  },
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
)(Receipt)
