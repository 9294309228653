import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import {
  matchingProsSelector,
  matchingProsAlgoliaSelector,
  matchingProsAlgoliaRankingSelector,
  matchingProsAlgoliaRankingCountSelector,
  statusSelector,
  reliableListSelector,
  acceptedProSelector,
  isJobPendingAssignSelector,
  timeslotDateSelector,
  timeslotSelector,
  orderNumberSelector,
  timeslotsSelector,
  deliveryModeSelector,
  signedReceiptSelector,
  getFormValuesReschedulingForm,
  reliableCountSelector,
  matchingProsCountSelector,
  matchingProsAlgoliaCountSelector,
  isAcceptedProInTestSelector,
} from 'store/jobs/jobSelectors'
import {
  getSingleJob,
  resetProsSearch,
  getMatchingProsListAlgolia,
  getMatchingProsListAlgoliaRanking,
  getMatchingPros,
  reliableProList,
  jobDeclineTransition,
  jobReschedulingTransition,
  jobRematchingTransition,
} from 'store/jobs/jobActions'
import { getJobIdFromIri } from 'helpers/utils/job/job'
import i18n from 'providers/i18n/I18nProvider'
import { setScrollTop, setBarTitle } from 'store/Application/ApplicationActions'
import ManualMatching from './ManualMatching'

const ManualMatchingContainer = ({
  status,
  matchingPros,
  matchingProsAlgolia,
  matchingProsAlgoliaRanking,
  getJob,
  resetProsSearchForm,
  getMatchingProsList,
  getMatchingProsListFromAlgolia,
  getMatchingProsListFromAlgoliaRanking,
  getReliableProList,
  setScrollTopValue,
  reliableList,
  acceptedPro,
  isJobPendingAssign,
  timeslotDate,
  timeslot,
  declineJob,
  reschedulingJob,
  match,
  orderNumber,
  setTitle,
  translate,
  timeslots,
  deliveryMode,
  rematchingJob,
  signedReceipt,
  searchParams,
  reliableCount,
  matchingProsCount,
  matchingProsAlgoliaCount,
  matchingProsAlgoliaRankingCount,
  isAcceptedProInTest,
}) => {
  const {
    params: { jobId },
  } = match

  const decodedJobId = decodeURIComponent(jobId)
  useEffect(() => {
    getJob({ jobId: decodedJobId })
  }, [decodedJobId, getJob])

  useEffect(() => {
    if (!status) {
      return
    }
    getMatchingProsList({ ...searchParams, jobId: decodedJobId })
    getMatchingProsListFromAlgoliaRanking({ jobId: decodedJobId })
    getReliableProList({ ...searchParams })
    getMatchingProsListFromAlgolia({ jobId: decodedJobId })
  }, [
    decodedJobId,
    status,
    getMatchingProsList,
    getReliableProList,
    getMatchingProsListFromAlgolia,
    getMatchingProsListFromAlgoliaRanking,
    searchParams,
  ])

  useEffect(() => {
    if (orderNumber) {
      setTitle(
        translate('resources.jobs.navbar.title.detail2', {
          orderNumber,
          jobId: getJobIdFromIri(decodedJobId),
        }),
      )
    }
  }, [decodedJobId, orderNumber, setTitle, translate])

  return status ? (
    <ManualMatching
      status={status}
      matchingPros={matchingPros}
      matchingProsAlgolia={matchingProsAlgolia}
      matchingProsAlgoliaRanking={matchingProsAlgoliaRanking}
      jobId={decodedJobId}
      reliableList={reliableList}
      reliableCount={reliableCount}
      matchingProsCount={matchingProsCount}
      matchingProsAlgoliaCount={matchingProsAlgoliaCount}
      matchingProsAlgoliaRankingCount={matchingProsAlgoliaRankingCount}
      acceptedPro={acceptedPro}
      isJobPendingAssign={isJobPendingAssign}
      timeslotDate={timeslotDate}
      timeslot={timeslot}
      declineJob={declineJob}
      reschedulingJob={reschedulingJob}
      setScrollTopValue={setScrollTopValue}
      timeslots={timeslots}
      deliveryMode={deliveryMode}
      rematchingJob={rematchingJob}
      signedReceipt={signedReceipt}
      resetProsSearchForm={resetProsSearchForm}
      getMatchingProsList={getMatchingProsList}
      getMatchingProsListFromAlgolia={getMatchingProsListFromAlgolia}
      getReliableProList={getReliableProList}
      isAcceptedProInTest={isAcceptedProInTest}
    />
  ) : (
    ''
  )
}

const mapDispatchToProps = dispatch => ({
  getJob: searchParams => dispatch(getSingleJob.request(searchParams)),
  resetProsSearchForm: searchParams =>
    dispatch(resetProsSearch.request(searchParams)),
  getMatchingProsList: searchParams =>
    dispatch(getMatchingPros.request(searchParams)),
  getMatchingProsListFromAlgolia: searchParams =>
    dispatch(getMatchingProsListAlgolia.request(searchParams)),
  getMatchingProsListFromAlgoliaRanking: searchParams =>
    dispatch(getMatchingProsListAlgoliaRanking.request(searchParams)),
  getReliableProList: searchParams =>
    dispatch(reliableProList.request(searchParams)),
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
  setScrollTopValue: scroll => dispatch(setScrollTop(scroll)),
  declineJob: (jobIri, selectedProIri) => {
    dispatch(
      jobDeclineTransition.request({
        jobIri,
        proIri: selectedProIri,
      }),
    )
  },
  reschedulingJob: (jobIri, newTimeSlot, selectedProIri) => {
    dispatch(
      jobReschedulingTransition.request({
        jobIri,
        newTimeSlot,
        selectedProIri,
      }),
    )
  },
  rematchingJob: jobIri => {
    dispatch(
      jobRematchingTransition.request({
        jobIri,
      }),
    )
  },
})

const mapStateToProps = (
  state,
  {
    match: {
      params: { jobId },
    },
  },
) => ({
  jobIri: decodeURIComponent(jobId),
  matchingPros: matchingProsSelector(state),
  matchingProsAlgolia: matchingProsAlgoliaSelector(state),
  matchingProsAlgoliaCount: matchingProsAlgoliaCountSelector(state),
  matchingProsAlgoliaRanking: matchingProsAlgoliaRankingSelector(state),
  matchingProsAlgoliaRankingCount: matchingProsAlgoliaRankingCountSelector(
    state,
  ),
  reliableList: reliableListSelector(state),
  reliableCount: reliableCountSelector(state),
  matchingProsCount: matchingProsCountSelector(state),
  status: statusSelector(state),
  acceptedPro: acceptedProSelector(state),
  isJobPendingAssign: isJobPendingAssignSelector(state),
  timeslotDate: timeslotDateSelector(state),
  timeslot: timeslotSelector(state),
  orderNumber: orderNumberSelector(state),
  timeslots: timeslotsSelector(state),
  deliveryMode: deliveryModeSelector(state),
  signedReceipt: signedReceiptSelector(state),
  selectedTimeslot: getFormValuesReschedulingForm(state),
  isAcceptedProInTest: isAcceptedProInTestSelector(state),
})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...ownProps,
  getJob: propsFromDispatch.getJob,
  resetProsSearchForm: propsFromDispatch.resetProsSearchForm,
  getMatchingProsList: propsFromDispatch.getMatchingProsList,
  getMatchingProsListFromAlgolia:
    propsFromDispatch.getMatchingProsListFromAlgolia,
  getMatchingProsListFromAlgoliaRanking:
    propsFromDispatch.getMatchingProsListFromAlgoliaRanking,
  getReliableProList: propsFromDispatch.getReliableProList,
  setScrollTopValue: propsFromDispatch.setScrollTopValue,
  setTitle: propsFromDispatch.setTitle,
  declineJob: () => {
    const selectedProIri = propsFromState.acceptedPro
      ? propsFromState.acceptedPro['@id']
      : null

    propsFromDispatch.declineJob(propsFromState.jobIri, selectedProIri)
  },
  reschedulingJob: () => {
    const newTimeSlot = propsFromState.selectedTimeslot.timeslots
      ? propsFromState.selectedTimeslot.timeslots.split(',')
      : null
    const selectedProIri = propsFromState.acceptedPro
      ? propsFromState.acceptedPro['@id']
      : null

    propsFromDispatch.reschedulingJob(
      propsFromState.jobIri,
      newTimeSlot,
      selectedProIri,
    )
  },
  rematchingJob: () => {
    propsFromDispatch.rematchingJob(propsFromState.jobIri)
  },
})

ManualMatchingContainer.propTypes = {
  matchingPros: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgolia: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgoliaRanking: PropTypes.arrayOf(PropTypes.shape({})),
  getJob: PropTypes.func.isRequired,
  resetProsSearchForm: PropTypes.func.isRequired,
  getMatchingProsList: PropTypes.func.isRequired,
  getMatchingProsListFromAlgolia: PropTypes.func.isRequired,
  getMatchingProsListFromAlgoliaRanking: PropTypes.func.isRequired,
  getReliableProList: PropTypes.func.isRequired,
  reliableCount: PropTypes.number,
  matchingProsCount: PropTypes.number,
  matchingProsAlgoliaCount: PropTypes.number,
  matchingProsAlgoliaRankingCount: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({ jobId: PropTypes.string }),
  }).isRequired,
  status: PropTypes.string,
  reliableList: PropTypes.arrayOf(PropTypes.shape({})),
  acceptedPro: PropTypes.shape({}),
  isJobPendingAssign: PropTypes.bool,
  timeslotDate: PropTypes.string,
  timeslot: PropTypes.string,
  deliveryMode: PropTypes.string,
  signedReceipt: PropTypes.bool,
  selectedTimeslot: PropTypes.shape({}),
  declineJob: PropTypes.func.isRequired,
  reschedulingJob: PropTypes.func.isRequired,
  rematchingJob: PropTypes.func.isRequired,
  setScrollTopValue: PropTypes.func.isRequired,
  orderNumber: PropTypes.string,
  setTitle: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  timeslots: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      stopTime: PropTypes.string,
    }),
  ),
  searchParams: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }),
  isAcceptedProInTest: PropTypes.bool,
}

ManualMatchingContainer.defaultProps = {
  status: undefined,
  matchingPros: [],
  matchingProsAlgolia: [],
  matchingProsAlgoliaRanking: [],
  reliableList: [],
  acceptedPro: undefined,
  isJobPendingAssign: false,
  timeslotDate: undefined,
  timeslot: undefined,
  orderNumber: undefined,
  timeslots: undefined,
  deliveryMode: undefined,
  signedReceipt: undefined,
  selectedTimeslot: undefined,
  searchParams: {
    page: 1,
    rowsPerPage: 25,
  },
  reliableCount: 0,
  matchingProsCount: 0,
  matchingProsAlgoliaCount: 0,
  matchingProsAlgoliaRankingCount: 0,
  isAcceptedProInTest: false,
}

export default compose(
  i18n,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(ManualMatchingContainer)
