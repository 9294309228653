import { n8n } from 'config'
import Client from './client'

const client = new Client({
  baseURL: n8n.url,
  headers: {
    'Content-Type': 'application/json',
  },
}).getInstance()

export default client
