import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { TableCell, TableRow, withStyles } from '@material-ui/core'
import SortableTableCell from 'components/shared/Tables/SortableTableCell'
import styles from './JobsSearchStyles'

const JobsSearchTableHead = ({
  handleChangeSort,
  translate,
  orderBy,
  sort,
  classes,
}) => (
  <TableRow className={classes.smallFont}>
    <TableCell>
      {translate('resources.jobs.fields.search_orderNumber')}
    </TableCell>
    <TableCell>{translate('resources.jobs.fields.search_storeName')}</TableCell>
    <TableCell>{translate('resources.jobs.fields.status')}</TableCell>
    <TableCell>{translate('resources.jobs.fields.incidents_status')}</TableCell>
    <SortableTableCell
      sortField="worksite.order.checkoutCompletedAt"
      label={translate('resources.jobs.fields.checkout_completed_at')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <TableCell>{translate('resources.jobs.fields.ho')}</TableCell>
    <TableCell>{translate('resources.jobs.fields.search_postcode')}</TableCell>
    <TableCell>{translate('resources.jobs.fields.packages')}</TableCell>
    <SortableTableCell
      sortField="minDateHo"
      label={translate('resources.jobs.fields.timeslots')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <TableCell>{translate('resources.jobs.fields.delivery_mode')}</TableCell>
    <TableCell>
      {translate('resources.jobs.fields.accepted_pro.search_name_planned')}
    </TableCell>
    <SortableTableCell
      sortField="minDatePro"
      label={translate('resources.jobs.fields.accepted_pro.timeslot')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <SortableTableCell
      sortField="validatedAt"
      label={translate('resources.jobs.fields.validatedAt')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <SortableTableCell
      sortField="proPaidAt"
      label={translate('resources.jobs.fields.proPaidAt')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
  </TableRow>
)

JobsSearchTableHead.propTypes = {
  handleChangeSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string,
  sort: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    smallFont: PropTypes.string.isRequired,
  }).isRequired,
}

JobsSearchTableHead.defaultProps = {
  orderBy: null,
}

export default compose(i18n, withStyles(styles))(JobsSearchTableHead)
