import { GET_TRADES_LIST } from './tradeActions'
import { initialState } from './tradeSelectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRADES_LIST.SUCCESS:
      return {
        ...state,
        list: action.payload['hydra:member'],
      }
    default: {
      return state
    }
  }
}
