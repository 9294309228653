import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { withStyles, Paper } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import SimpleTable from 'components/shared/Tables/SimpleTable'
import JobsSearchFiltersContainer from 'components/Jobs/JobsSearchFilters/JobsSearchFiltersContainer'
import JobsSearchTableBody from './JobsSearchTableBody'
import JobsSearchTableHead from './JobsSearchTableHead'
import styles from './JobsSearchStyles'

const JobsSearch = ({
  classes,
  rows,
  searchParams,
  total,
  isLoading,
  handleChangePage,
  handleChangePerPage,
  handleSearchChange,
  handleExport,
  handleChangeSort,
}) => (
  <Paper className={classes.root}>
    <JobsSearchFiltersContainer
      handleSearchChange={handleSearchChange}
      handleExport={handleExport}
    />
    <SimpleTable
      rows={rows}
      orderBy={searchParams.orderBy}
      page={searchParams.page}
      sort={searchParams.sort}
      total={total}
      isLoading={isLoading}
      handleChangePage={handleChangePage}
      rowsPerPage={searchParams.rowsPerPage}
      handleChangePerPage={handleChangePerPage}
      handleChangeSort={handleChangeSort}
      TableBodyContent={JobsSearchTableBody}
      TableHeadContent={JobsSearchTableHead}
    />
  </Paper>
)

JobsSearch.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.string,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
  searchParams: PropTypes.shape({
    jobStatus: PropTypes.string.isRequired,
    orderBy: PropTypes.string,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    sort: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangePerPage: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
}

export default compose(i18n, withStyles(styles), withRouter)(JobsSearch)
