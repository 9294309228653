const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  inputTextarea: {
    width: '100%',
    height: '80px',
  },
  inputText: {
    width: '100%',
    height: '65px',
    padding: '20px',
    fontSize: '0.9rem',
  },
  formMargin: {
    margin: '20px 0',
  },
  buttonBigger: theme.buttonBigger,
  buttonReload: theme.buttonReload,
  buttonSecondary: theme.buttonSecondary,
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontSize: 'auto',
    minWidth: '160px',
    width: 'auto',
  },
  margin: {
    margin: '2rem 2rem 0 0',
  },
  button: {
    padding: '0.7rem 3rem',
    borderRadius: '3px',
    textTransform: 'none',
    marginLeft: '2rem',
  },
  container: {
    display: 'flex',
  },
  inputIncidentType: {
    marginLeft: '2rem',
    width: '20vw',
  },
})

export default styles
