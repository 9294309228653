import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import uuidv1 from 'uuid/v1'
import i18n from 'providers/i18n/I18nProvider'

import { isMocks } from 'services/isMocks'
import { dateFormatter } from 'helpers/date'
import { formatIriToId } from 'helpers/utils/common'
import { NO_SCORE } from 'constants/firms'
import { DATE_FORMAT } from 'constants/date'
import { PROS_PATH_PREFIX } from 'constants/routes'
import ViewMoreCell from 'components/shared/Tables/ViewMoreCell'
import AvailableTable from 'components/shared/Tables/AvailableTable'
import FunctionTableCell from 'components/shared/Tables/FunctionTableCell'
import { withStyles, TableCell, TableRow } from '@material-ui/core'
import styles from './FirmsListStyles'

const FirmsListTableBody = ({
  rows,
  classes,
  translate,
  additionalProps: { hasPackages },
}) =>
  rows.map(row => {
    const {
      legacyId,
      name,
      userFirstName,
      userLastName,
      firmProductSubscription,
      firmProductSubscriptionText,
      origin,
      packageStatusV2,
      lastFirmComment,
      contractSignedAt,
      onboardingScore,
      accountingNumber,
      available,
      firmUnavailabilityPeriods,
    } = row

    const lastFirmCommentDate =
      lastFirmComment && lastFirmComment.date ? lastFirmComment.date : null
    const lastFirmCommentContent =
      lastFirmComment && lastFirmComment.content
        ? lastFirmComment.content
        : null
    const packageStatus = packageStatusV2
      ? translate(`firm.packageStatus.${packageStatusV2}`)
      : ''

    const isFirmAvailable = available
    let availabilityDates = null
    let availabilityIcon = 'circle-green'
    if (
      !isFirmAvailable &&
      firmUnavailabilityPeriods &&
      firmUnavailabilityPeriods.length > 0
    ) {
      const firmUnavailabilityPeriodStartAt =
        firmUnavailabilityPeriods[0].startAt
      const firmUnavailabilityPeriodEndAt = firmUnavailabilityPeriods[0].endAt
      availabilityDates = translate('firm.unavailabilityDates', {
        startAt: dateFormatter(firmUnavailabilityPeriodStartAt, DATE_FORMAT),
        endAt: dateFormatter(firmUnavailabilityPeriodEndAt, DATE_FORMAT),
      })
      availabilityIcon = 'circle-red'
    }

    return (
      <TableRow key={uuidv1()} hover className={classes.root}>
        {!isMocks && (
          <TableCell>
            <AvailableTable
              bubbleContent={availabilityDates}
              icon={availabilityIcon}
            />
          </TableCell>
        )}
        <TableCell>
          <Link to={`${PROS_PATH_PREFIX}/${formatIriToId(row['@id'])}/show`}>
            {legacyId}
          </Link>
          <br />({accountingNumber})
        </TableCell>
        <TableCell>
          <Link to={`${PROS_PATH_PREFIX}/${formatIriToId(row['@id'])}/show`}>
            {name}
          </Link>
        </TableCell>
        <TableCell
          className={classes.nameCell}
        >{`${userFirstName} ${userLastName}`}</TableCell>

        {hasPackages && (
          <FunctionTableCell
            render={() =>
              firmProductSubscription &&
              firmProductSubscription.productSubscriptions
                ? firmProductSubscription.productSubscriptions
                    .map(ps => ps.product.name)
                    .join('\n')
                : null
            }
          />
        )}
        <FunctionTableCell
          render={() => (row.trades ? row.trades.join('\n') : null)}
        />
        <TableCell>{packageStatus}</TableCell>
        <TableCell>
          {contractSignedAt &&
            dateFormatter(contractSignedAt, translate('app.date_format.short'))}
        </TableCell>
        <TableCell>
          {origin && translate(`resources.firms.origin.${origin}`)}
        </TableCell>
        <FunctionTableCell render={() => row.postalCode} />
        <TableCell className={classes.firmPackageCell}>
          {firmProductSubscriptionText}
        </TableCell>
        <TableCell>
          {translate(`firm.onboardingscore.${onboardingScore || NO_SCORE}`)}
        </TableCell>
        <TableCell>
          <ViewMoreCell
            cellContent={dateFormatter(
              lastFirmCommentDate,
              translate('app.date_format.short'),
            )}
            bubbleContent={lastFirmCommentContent}
          />
        </TableCell>
      </TableRow>
    )
  })

FirmsListTableBody.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  additionalProps: PropTypes.shape({
    hasPackages: PropTypes.bool.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  translate: PropTypes.func.isRequired,
  featureFlagList: PropTypes.arrayOf(PropTypes.shape({})),
}

export default compose(i18n, withStyles(styles))(FirmsListTableBody)
