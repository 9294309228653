import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import ConfirmDialogActionsContent from 'components/shared/ConfirmDialog/DialogActionsContent'
import SimpleDialogActionsContent from './DialogActionsContent'

class SimpleDialog extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      open: true,
    }
  }

  handleClose = event => {
    const { onClose, disableClose } = this.props
    if (!disableClose) {
      this.setState({ open: false })
    }
    onClose(event)
  }

  enableClose() {
    const { disableClose } = this.props

    return disableClose ? () => {} : this.handleClose
  }

  renderActionsContent() {
    const { type, translate, customActions } = this.props
    if (type === 'confirm') {
      return (
        <ConfirmDialogActionsContent
          translate={translate}
          handleClose={this.handleClose}
        />
      )
    }

    if (type === 'custom') {
      return customActions
    }

    return (
      <>
        {customActions}
        <SimpleDialogActionsContent
          translate={translate}
          handleClose={this.handleClose}
        />
      </>
    )
  }

  render() {
    const { title, children, dialogProps } = this.props
    const { open } = this.state
    return (
      <Dialog
        open={open}
        onClose={this.enableClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        {...dialogProps}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>{this.renderActionsContent()}</DialogActions>
      </Dialog>
    )
  }
}

SimpleDialog.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['simple', 'confirm', 'custom']),
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dialogProps: PropTypes.shape({}),
  disableClose: PropTypes.bool,
  customActions: PropTypes.node,
}

SimpleDialog.defaultProps = {
  type: 'simple',
  dialogProps: {},
  disableClose: false,
  customActions: undefined,
}

export default i18n(SimpleDialog)
