import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { storesListSelector } from 'store/stores/storeSelectors'
import {
  firmIsRemovalSelector,
  firmIsSaturdaySelector,
  firmIsSundaySelector,
  firmIsWeekendSelector,
  firmReliableSelector,
  firmOriginSelector,
  firmStoreCodesSelector,
  firmIsDirectorySelector,
  firmCountJobsDoneSelector,
  firmManualRatingSelector,
  firmRankingRatingSelector,
  currentFirmPackageRatingSelector,
  currentFirmPackageRatingCountSelector,
  currentFirmLeadRatingSelector,
  currentFirmLeadRatingCountSelector,
} from 'store/firms/firmSelectors'
import { updateFirmDetailsInformationsPackage } from 'store/firms/firmActions'
import i18n from 'providers/i18n/I18nProvider'
import FirmInformationsPackage from './FirmInformationsPackage'

const FirmInformationsPackageContainer = ({
  updateCurrentFirm,
  initialValues,
}) => (
  <FirmInformationsPackage
    onSubmit={values => updateCurrentFirm(values)}
    initialValues={initialValues}
  />
)

FirmInformationsPackageContainer.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  updateCurrentFirm: PropTypes.func.isRequired,
}

const getCurrentFirmRating = (ratingSelector, state, translate) => {
  const rating = ratingSelector(state)

  return rating ? `${rating / 100}/5` : translate('resources.firms.fields.na')
}

const mapStateToProps = (state, { translate }) => ({
  initialValues: {
    stores: storesListSelector(state),
    isRemoval: firmIsRemovalSelector(state),
    isSaturday: firmIsSaturdaySelector(state),
    isSunday: firmIsSundaySelector(state),
    isWeekend: firmIsWeekendSelector(state),
    reliable: firmReliableSelector(state),
    origin: firmOriginSelector(state),
    storeCodes: firmStoreCodesSelector(state),
    isDirectory: firmIsDirectorySelector(state),
    nbJobsDone: firmCountJobsDoneSelector(state),
    manualRating: firmManualRatingSelector(state),
    rankingRating: firmRankingRatingSelector(state),
    packageRating: translate('resources.firms.fields.rating', {
      rating: getCurrentFirmRating(
        currentFirmPackageRatingSelector,
        state,
        translate,
      ),
      ratingCount:
        currentFirmPackageRatingCountSelector(state) ||
        translate('resources.firms.fields.na'),
    }),
    leadRating: translate('resources.firms.fields.rating', {
      rating: getCurrentFirmRating(
        currentFirmLeadRatingSelector,
        state,
        translate,
      ),
      ratingCount:
        currentFirmLeadRatingCountSelector(state) ||
        translate('resources.firms.fields.na'),
    }),
  },
})

const mapDispatchToProps = dispatch => ({
  updateCurrentFirm: values =>
    dispatch(updateFirmDetailsInformationsPackage({ payload: values })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
)(FirmInformationsPackageContainer)
