import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import RenderTextField from 'components/shared/Form/RenderTextField'
import GoogleMapsAutoComplete from './GoogleMapsAutoComplete'

class FieldPlace extends Component {
  async componentDidMount() {
    const { id, locale, types, point, radius } = this.props
    this.autocomplete = await new GoogleMapsAutoComplete(
      document.getElementById(id),
      locale,
      types,
      this.onAutocomplete,
      radius,
      point !== null ? point.lat : null,
      point !== null ? point.lng : null,
    )

    this.autocomplete.setMap(
      point !== null ? point.lat : null,
      point !== null ? point.lng : null,
      radius,
    )
  }

  async componentDidUpdate() {
    if (this.autocomplete === undefined) {
      return
    }

    const { point, radius } = this.props
    this.autocomplete.setMap(
      point !== null ? point.lat : null,
      point !== null ? point.lng : null,
      radius,
    )
  }

  onAutocomplete = () => {
    const { onAutoCompleteLocation } = this.props
    const place = this.autocomplete.getPlace()
    if (place && onAutoCompleteLocation) {
      onAutoCompleteLocation(place)
    }
  }

  render() {
    const {
      id,
      name,
      label,
      placeholder,
      icon,
      className,
      onChange,
      fullWidth,
    } = this.props
    return (
      <Field
        id={id}
        name={name}
        component={RenderTextField}
        label={label}
        placeholder={placeholder}
        type="text"
        icon={icon}
        onChange={onChange}
        className={className}
        fullWidth={fullWidth}
      />
    )
  }
}

FieldPlace.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  locale: PropTypes.string,
  fullWidth: PropTypes.bool,
  types: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string.isRequired,
  onAutoCompleteLocation: PropTypes.func.isRequired,
  point: PropTypes.shape({
    lat: PropTypes.string,
    lng: PropTypes.string,
  }),
  radius: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

FieldPlace.defaultProps = {
  id: 'placefield-id',
  name: 'localisation',
  label: 'Adresse',
  types: ['formatted_address', 'geometry'],
  locale: 'fr',
  point: { lat: null, lng: null },
  radius: null,
  icon: null,
  fullWidth: false,
}

export default FieldPlace
