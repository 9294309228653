import { AbstractReducer, initialState } from './abstractReducer'
import { PackageActions } from './packageActions'

class Package extends AbstractReducer {
  static getType() {
    return PackageActions
  }
}

export default (state = initialState, action) => Package.reducer(state, action)
