export const mapCounterByCategories = (categories, entityStatuses) =>
  categories
    .map(categorie => ({
      type: categorie.type,
      counter: Array.isArray(entityStatuses)
        ? entityStatuses
            .filter(entityStatus =>
              categorie.statuses.includes(entityStatus.status),
            )
            .reduce((acc, entityStatus) => acc + entityStatus.count, 0)
        : Object.entries(entityStatuses)
            .filter(entityStatus =>
              categorie.statuses.includes(entityStatus[0]),
            )
            .reduce((acc, entityStatus) => acc + entityStatus[1], 0),
    }))
    .reduce(
      (acc, categorie) => ({ ...acc, [categorie.type]: categorie.counter }),
      {},
    )
