export default theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    width: '500px',
  },
  textField: {
    margin: theme.spacing(1),
    width: '400px',
  },
  button: {
    margin: theme.spacing(2),
    width: '330px',
    '&:hover': {
      color: theme.palette.white.main,
    },
  },
  image: {
    width: '400px',
    marginTop: '-40px',
    marginBottom: theme.spacing(2),
  },
  loaderContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
})
