const styles = () => ({
  table: {
    minWidth: 700,
  },
  statusChange: {
    '&:nth-child(even)': {
      backgroundColor: '#efd5d5',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: '#f4eaea',
    },
  },
  reMatching: {
    '&:nth-child(even)': {
      backgroundColor: '#efd5d5',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: '#f4eaea',
    },
  },
  incident: {
    '&:nth-child(even)': {
      backgroundColor: '#efd5d5',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: '#f4eaea',
    },
  },
  geolocation: {
    '&:nth-child(even)': {
      backgroundColor: '#efd5d5',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: '#f4eaea',
    },
  },
  checkinHo: {
    '&:nth-child(even)': {
      backgroundColor: '#efd5d5',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: '#f4eaea',
    },
  },
  p: {
    fontSize: 'small',
  },
})

export default styles
