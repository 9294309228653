const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  margin: {
    margin: '2rem 2rem 0 0',
  },
  buttonBigger: theme.buttonBigger,
  buttonSecondary: theme.buttonSecondary,
  buttonDelete: theme.buttonDelete,
})

export default styles
