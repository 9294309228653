import {
  generateRequestActions,
  createRequestTypes,
} from 'helpers/store/actionsHelpers'

export const INVOICE_CANCEL_CONTEST_TRANSITION_REQ = createRequestTypes(
  'INVOICE_CANCEL_CONTEST_TRANSITION',
)
export const invoiceCancelContestTransition = generateRequestActions(
  INVOICE_CANCEL_CONTEST_TRANSITION_REQ,
)
export const INVOICE_CONTEST_TRANSITION_REQ = createRequestTypes(
  'INVOICE_CONTEST_TRANSITION',
)
export const invoiceContestTransition = generateRequestActions(
  INVOICE_CONTEST_TRANSITION_REQ,
)
export const INVOICE_REEDITION_TRANSITION_REQ = createRequestTypes(
  'INVOICE_REEDITION_TRANSITION',
)
export const invoiceReeditionTransition = generateRequestActions(
  INVOICE_REEDITION_TRANSITION_REQ,
)
export const INVOICE_PAY_TRANSITION_REQ = createRequestTypes(
  'INVOICE_PAY_TRANSITION',
)
export const invoicePayTransition = generateRequestActions(
  INVOICE_PAY_TRANSITION_REQ,
)
