import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'

const styles = theme => ({
  container: {
    padding: '8px 0px 24px',
  },
  h4: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  },
  jobWorkflowStep: {
    'flex-basis': '100%',
    [theme.breakpoints.up('md')]: {
      'flex-basis': '33%',
    },
  },
  card: {
    width: '100%',
    margin: '5px',
  },
  typography: {
    padding: theme.spacing(2),
  },
  boxShadow: {
    boxShadow: '0 3px 5px #00000055',
  },
  checkbox: {
    marginLeft: 0,
  },
  p: {
    marginLeft: '1rem',
    padding: theme.spacing(2),
  },
  buttonBigger: theme.buttonBigger,
  alignCenter: theme.alignCenter,
  textCenter: {
    textAlign: 'center',
  },
  primary: {
    backgroundColor: green[500],
  },
  error: {
    backgroundColor: red[500],
  },
  uploadBtn: {
    'min-width': '190px',
  },
  contestBtn: {
    justifyContent: 'flex-end',
  },
  expansionPanelDetails: {
    display: 'flex',
    width: '100%',
  },
})

export default styles
