import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { TableCell, TableRow } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'

const SimulatedMatchingFormItem = ({ translate, matchingPro, pro }) => (
  <TableRow key={pro.legacyId}>
    <TableCell component="th" />
    <TableCell>
      <b>{matchingPro.index !== null ? matchingPro.index : '-'}</b>
    </TableCell>
    <TableCell>{pro.legacyId}</TableCell>
    <TableCell>{`${pro.firstName} ${pro.lastName}`}</TableCell>
    <TableCell>{pro.phoneNumber}</TableCell>
    <TableCell>
      {translate(`resources.jobs.proTable.isLM.${pro.isLM ? 'yes' : 'no'}`)}
    </TableCell>
    <TableCell>
      {translate(
        `resources.jobs.proTable.removal.${pro.removal ? 'yes' : 'no'}`,
      )}
    </TableCell>
    <TableCell>{matchingPro.countAcceptedJobs}</TableCell>
    <TableCell>{pro.rankingRating}</TableCell>
  </TableRow>
)

SimulatedMatchingFormItem.propTypes = {
  translate: PropTypes.func.isRequired,
  matchingPro: PropTypes.shape({
    countAcceptedJobs: PropTypes.number,
    index: PropTypes.number,
    notificationsCountForJob: PropTypes.number,
  }),
  pro: PropTypes.shape({
    rankingRating: PropTypes.number,
    phoneNumber: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    legacyId: PropTypes.number,
    isLM: PropTypes.bool.isRequired,
    removal: PropTypes.bool.isRequired,
  }),
}

SimulatedMatchingFormItem.defaultProps = {
  pro: {
    legacyId: null,
  },
  matchingPro: {
    countAcceptedJobs: null,
    index: null,
    notificationsCountForJob: null,
  },
}

export default compose(i18n)(SimulatedMatchingFormItem)
