import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FIRM_COMMENT_PAGE } from 'constants/firms'
import {
  addFirmComment,
  getFirmCommentsList,
} from 'store/firmComments/firmCommentActions'
import {
  firmCommentsSearchParamsSelector,
  firmCommentsSearchResultSelector,
  hasFirmCommentsSelector,
} from 'store/firmComments/firmCommentSelectors'
import { currentFirmIdSelector } from 'store/firms/firmSelectors'
import FirmComments from './FirmComments'

class FirmDetailsContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      firm: props.currentFirmId,
      page: props.firmCommentsSearchParams.page,
      perPage: props.firmCommentsSearchParams.perPage,
    }
  }

  componentDidMount() {
    this.loadComments(this.state)
  }

  loadComments = params => {
    const { getFirmsComments } = this.props

    getFirmsComments(params)
  }

  handleChangePage = (event, page) => {
    const { firmCommentsSearchParams } = this.props
    const params = { ...firmCommentsSearchParams, page }
    this.loadComments(params)
  }

  handleChangeRowsPerPage = event => {
    const { firmCommentsSearchParams } = this.props
    const params = {
      ...firmCommentsSearchParams,
      perPage: event.target.value,
      page: FIRM_COMMENT_PAGE,
    }
    this.loadComments(params)
  }

  setLabelDisplayedRows = ({ from, to, count }) => {
    const { translate } = this.props
    return translate('navigation.page_range_info', {
      offsetBegin: from,
      offsetEnd: to,
      total: count,
    })
  }

  render() {
    const {
      addComment,
      firmCommentsSearchParams,
      firmCommentsSearchResult,
    } = this.props
    return (
      <FirmComments
        onSubmit={values => addComment(values)}
        firmCommentsSearchResult={firmCommentsSearchResult}
        firmCommentsSearchParams={firmCommentsSearchParams}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        setLabelDisplayedRows={this.setLabelDisplayedRows}
      />
    )
  }
}

FirmDetailsContainer.propTypes = {
  addComment: PropTypes.func.isRequired,
  getFirmsComments: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      firmId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  firmCommentsSearchParams: PropTypes.shape({
    perPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
  firmCommentsSearchResult: PropTypes.shape({
    totalItems: PropTypes.number.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        author: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
        '@id': PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  translate: PropTypes.func.isRequired,
  currentFirmId: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  currentFirmId: currentFirmIdSelector(state),
  firmHasComments: hasFirmCommentsSelector(state),
  firmCommentsSearchResult: firmCommentsSearchResultSelector(state),
  firmCommentsSearchParams: firmCommentsSearchParamsSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getFirmsComments: params => dispatch(getFirmCommentsList.request({ params })),
  addComment: values => dispatch(addFirmComment({ payload: values })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  i18n,
)(FirmDetailsContainer)
