export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  card: {
    minHeight: '327px',
  },
  input: {
    width: '80%',
    marginBottom: theme.spacing(1),
  },
  titles: {
    fontSize: '1.2rem',
    color: '#143C58',
  },
  icon: {
    width: '45px',
    backgroundSize: 'contain',
  },
  btnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardIcon: {
    backgroundColor: 'tomato',
  },
})
