import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const CustomLink = React.forwardRef((props, ref) => (
  <Link
    innerRef={ref}
    to={{
      pathname: props.pathname,
    }}
    params={{ iri: props.iri }}
    {...props}
  />
))

CustomLink.propTypes = {
  iri: PropTypes.string.isRequired,
  pathname: PropTypes.string,
}

CustomLink.defaultProps = {
  pathname: undefined,
}

export default CustomLink
