import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  statusSelector,
  acceptedProNameSelector,
  acceptedTimeslotDateSelector,
  acceptedTimeslotRangeSelector,
  acceptedLegacyIdSelector,
  deliveryModeSelector,
  acceptedProIdSelector,
  acceptedProExpiredAtCertificateSelector,
  requiredCertificateSelector,
  timeslotsSelector,
  matchingsSelector,
  substatusSelector,
  currentJobSelector,
} from 'store/jobs/jobSelectors'
import {
  jobRematchingTransition,
  jobPendingNewDateTransition,
} from 'store/jobs/jobActions'
import { isFeatureFlagEnabledSelector } from 'store/Application/ApplicationSelectors'
import {
  MANUAL_AFTER_SALE_DIAGNOSTIC,
  REQUIRED_NEW_DATE,
} from 'constants/features'
import JobPlanification from './JobPlanification'

const mapStateToProps = state => ({
  status: statusSelector(state),
  subStatus: substatusSelector(state),
  selectedProName: acceptedProNameSelector(state),
  interventionDate: acceptedTimeslotDateSelector(state),
  timeSlotRange: acceptedTimeslotRangeSelector(state),
  legacyId: acceptedLegacyIdSelector(state),
  deliveryMode: deliveryModeSelector(state),
  proId: acceptedProIdSelector(state),
  acceptedProExpiredAtCertificate: acceptedProExpiredAtCertificateSelector(
    state,
  ),
  requiredCertificate: requiredCertificateSelector(state),
  timeslots: timeslotsSelector(state),
  matchings: matchingsSelector(state),
  isPendingNewDateEnabled: isFeatureFlagEnabledSelector(
    state,
    REQUIRED_NEW_DATE,
  ),
  type: currentJobSelector(state).type,
  isManualAfterSaleDiag: isFeatureFlagEnabledSelector(
    state,
    MANUAL_AFTER_SALE_DIAGNOSTIC,
  ),
})

const mapDispatchToProps = (dispatch, { jobId }) => ({
  rematchingJob: () => {
    dispatch(
      jobRematchingTransition.request({
        jobIri: decodeURIComponent(jobId),
      }),
    )
  },
  pendingNewDateRequest: () => {
    dispatch(
      jobPendingNewDateTransition.request({
        jobIri: decodeURIComponent(jobId),
      }),
    )
  },
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  JobPlanification,
)
