import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { CardHeader, Typography, withStyles } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import Icon from 'components/shared/Icon/Icon'
import styles from './FirmDetailsProDirStatusStyles'

const getTitle = (directoryActivated, hasDirectoryOption) => {
  if (hasDirectoryOption) {
    if (directoryActivated) {
      return 'statusDirectoryActivate'
    }
    return 'statusDirectoryDisabled'
  }
  return 'statusDirectoryNotActivate'
}

const FirmDetailsProDirectoryStatus = ({
  translate,
  classes,
  directoryActivated,
  hasDirectoryOption,
}) => {
  const iconName =
    directoryActivated && hasDirectoryOption ? 'activate' : 'disabled'
  const fieldTitle = getTitle(directoryActivated, hasDirectoryOption)
  const title = translate(`resources.firms.fields.${fieldTitle}`)

  return (
    <>
      <Typography className={classes.label}>
        {translate('resources.firms.fields.statusDirectory')}:
      </Typography>
      <CardHeader
        avatar={<Icon className={classes.icon} icon={`${iconName}.svg`} />}
        title={title}
        classes={{
          title:
            directoryActivated && hasDirectoryOption
              ? classes.proDirectoryisActivate
              : classes.proDirectoryisDesactivate,
        }}
      />
    </>
  )
}

FirmDetailsProDirectoryStatus.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string,
    label: PropTypes.string,
    proDirectoryisActivate: PropTypes.string,
    proDirectoryisDesactivate: PropTypes.string,
  }).isRequired,
  directoryActivated: PropTypes.bool,
  hasDirectoryOption: PropTypes.bool,
}

FirmDetailsProDirectoryStatus.defaultProps = {
  directoryActivated: false,
  hasDirectoryOption: false,
}

export default compose(i18n, withStyles(styles))(FirmDetailsProDirectoryStatus)
