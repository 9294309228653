import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  reduxForm,
  Field,
  formValueSelector,
  SubmissionError,
} from 'redux-form'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import {
  Grid,
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
} from '@material-ui/core'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import RenderSwitch from 'components/shared/Form/RenderSwitch'
import { GENERATE_REINTERVENTION_AFTERSALE_FORM } from 'constants/forms'
import { PRO_PK_DISABLED_STATUSES } from 'constants/firms'
import { generateReinterventionAfterSale } from 'store/jobs/jobActions'
import { acceptedProPackageStatusSelector } from 'store/jobs/jobSelectors'
import styles from './GenerateReinterventionAfterSaleStyles'

const GenerateReinterventionAfterSaleForm = ({
  classes,
  handleSubmit,
  incidents,
  translate,
  setDialogOpened,
  acceptedProPackageStatus,
  useInitialPro,
}) => {
  const incidentList = incidents.map(incident => ({
    value: incident.id,
    name: `${incident.id} - ${incident.type.code} - ${incident.type.label}`,
  }))

  return (
    <Dialog
      open
      onClose={() => setDialogOpened(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="flex-start">
          <DialogTitle id="alert-dialog-title">
            {translate('afterSale.manual.select_intervention_title')}
          </DialogTitle>
          <DialogContent>
            <Field
              name="incidentId"
              component={RenderSelectField}
              className={classes.selectField}
              choices={incidentList}
              allowEmpty
            />
            <Grid
              container
              direction="row"
              alignItems="center"
              alignContent="center"
            >
              <Field
                name="useInitialPro"
                label={translate('afterSale.manual.use_same_pro')}
                component={RenderSwitch}
              />
            </Grid>
          </DialogContent>
          <DialogContentText>
            {useInitialPro &&
              PRO_PK_DISABLED_STATUSES.includes(acceptedProPackageStatus) && (
                <Typography className={classes.urgentWord}>
                  {translate('afterSale.manual.error.use_same_pro_suspended')}
                </Typography>
              )}
          </DialogContentText>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <DialogActions align="right">
              <Button
                type="submit"
                autoFocus
                className={classes.buttonBigger}
                disabled={
                  useInitialPro &&
                  PRO_PK_DISABLED_STATUSES.includes(acceptedProPackageStatus)
                }
              >
                {translate('afterSale.manual.button_validate_and_continue')}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  )
}

GenerateReinterventionAfterSaleForm.propTypes = {
  incidents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  acceptedProPackageStatus: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    buttonBigger: PropTypes.string.isRequired,
    buttonReload: PropTypes.string.isRequired,
    selectField: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    urgentWord: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  setDialogOpened: PropTypes.func.isRequired,
  useInitialPro: PropTypes.bool.isRequired,
}

export default compose(
  i18n,
  withStyles(styles),
  connect(state => {
    const formSelector = formValueSelector(
      GENERATE_REINTERVENTION_AFTERSALE_FORM,
    )
    return {
      useInitialPro: formSelector(state, 'useInitialPro'),
      acceptedProPackageStatus: acceptedProPackageStatusSelector(state),
    }
  }),
  reduxForm({
    form: GENERATE_REINTERVENTION_AFTERSALE_FORM,
    onSubmit: (
      { incidentId, useInitialPro },
      dispatch,
      { setDialogOpened, translate },
    ) => {
      if (!incidentId) {
        throw new SubmissionError({
          incidentId: translate('afterSale.manual.error.incident_id_empty'),
          _error: translate('afterSale.manual.error.incident_id_empty_code'),
        })
      }
      dispatch(
        generateReinterventionAfterSale.request({
          incidentId,
          useInitialPro,
        }),
      )
      setDialogOpened(false)
    },
  }),
)(GenerateReinterventionAfterSaleForm)
