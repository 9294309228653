import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
  isLoadingSelector,
  isNotifiedSelector,
  notificationMessageSelector,
  messageTypeSelector,
  scrollTopSelector,
  isFeatureFlagEnabledSelector,
} from 'store/Application/ApplicationSelectors'
import { currentJobSelector } from 'store/jobs/jobSelectors'
import { MANUAL_AFTER_SALE_DIAGNOSTIC } from 'constants/features'
import Layout from './Layout'
import styles from './LayoutStyles'

const mapStateToProps = state => ({
  isLoading: isLoadingSelector(state),
  isNotified: isNotifiedSelector(state),
  notificationMessage: notificationMessageSelector(state),
  messageType: messageTypeSelector(state),
  scrollTop: scrollTopSelector(state),
  currentJob: currentJobSelector(state),
  isManualAfterSaleDiag: isFeatureFlagEnabledSelector(
    state,
    MANUAL_AFTER_SALE_DIAGNOSTIC,
  ),
})

export default compose(connect(mapStateToProps), withStyles(styles))(Layout)
