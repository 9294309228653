import jwtUtils from 'jwt-simple'
import { ACCESS_TOKEN } from 'constants/storage'

export const hasPermissions = (permissionsToTest = []) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN)
  const { permissions } = jwtUtils.decode(accessToken, null, true)
  const permIntersection = permissionsToTest.filter(permissionToTest =>
    permissions.includes(permissionToTest),
  )
  return permIntersection.length === permissionsToTest.length
}

export const hasPermission = (permissionToTest = '') =>
  hasPermissions([permissionToTest])
