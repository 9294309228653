import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Paper } from '@material-ui/core'
import SimpleTable from 'components/shared/Tables/SimpleTable'
import AllInvoicesFilters from 'components/Invoices/InvoicesFilters/AllInvoicesFilters'
import Invoice from 'components/Jobs/JobDetails/JobDetailsWorkflow/ValidateBills/Invoice/Invoice'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import AllInvoicesListTableBodyContainer from './AllInvoicesListTableBodyContainer'
import AllInvoicesListTableHead from './AllInvoicesListTableHead'

import styles from './AllInvoicesListStyles'

const AllInvoicesList = ({
  isLoading,
  searchParams,
  handleChangePage,
  handleChangePerPage,
  handleChangeSort,
  handleReset,
  handleSubmitForm,
  handleExport,
  displayedInvoice,
  dispatchResetDisplayedInvoice,
  rows,
  classes,
}) => (
  <Paper className={classes.root}>
    {displayedInvoice && (
      <SimpleDialog title="" onClose={dispatchResetDisplayedInvoice}>
        <Invoice invoiceId={displayedInvoice} />
      </SimpleDialog>
    )}
    <AllInvoicesFilters
      handleReset={handleReset}
      handleSubmitForm={handleSubmitForm}
      handleExport={handleExport}
    />
    <SimpleTable
      rows={rows}
      orderBy={searchParams.orderBy}
      page={searchParams.page}
      rowsPerPage={searchParams.rowsPerPage}
      sort={searchParams.sort}
      total={searchParams.total}
      isLoading={isLoading}
      handleChangePage={handleChangePage}
      hitsPerPage={searchParams.rowsPerPage}
      handleChangePerPage={handleChangePerPage}
      TableBodyContent={AllInvoicesListTableBodyContainer}
      TableHeadContent={AllInvoicesListTableHead}
      handleChangeSort={handleChangeSort}
    />
  </Paper>
)

AllInvoicesList.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangePerPage: PropTypes.func.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  dispatchResetDisplayedInvoice: PropTypes.func.isRequired,
  displayedInvoice: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchParams: PropTypes.shape({
    orderBy: PropTypes.string,
    page: PropTypes.number,
    sort: PropTypes.string,
    total: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }),
}

AllInvoicesList.defaultProps = {
  searchParams: {
    orderBy: PropTypes.string,
    page: 0,
    sort: 'ASC',
    rowsPerPage: 50,
  },
  displayedInvoice: null,
}

export default withStyles(styles)(AllInvoicesList)
