import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  withStyles,
  FormControl,
  FormLabel,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import { CERFA_15497, CERFA_15498, CERFA_BOTH } from 'constants/Jobs'
import styles from './CerfaFormStyles'

const CerfaForm = ({
  translate,
  classes,
  setFormIsValid,
  setAdditionalInfo,
  formOption: { specifiedCerfa, uploadedCerfa },
}) => {
  const [cerfaType, setCerfaType] = useState(null)

  useEffect(() => {
    if ([CERFA_15497, CERFA_15498, CERFA_BOTH].includes(specifiedCerfa)) {
      setCerfaType(specifiedCerfa)
      setFormIsValid(true)
      setAdditionalInfo({ cerfaType })
    }
  }, [
    cerfaType,
    setAdditionalInfo,
    setFormIsValid,
    specifiedCerfa,
    uploadedCerfa,
  ])

  const handleChangeCerfaType = event => {
    setCerfaType(event.target.value)
    setAdditionalInfo({ cerfaType: event.target.value })
    setFormIsValid(true)
  }

  return (
    <Grid item xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.label}>
          {translate('resources.cerfa.subForm.label')}
        </FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={cerfaType}
          row
          onChange={handleChangeCerfaType}
        >
          <FormControlLabel
            value={CERFA_15497}
            control={<Radio color="primary" />}
            disabled={
              !!(
                (specifiedCerfa && specifiedCerfa !== CERFA_15497) ||
                uploadedCerfa.includes(CERFA_15497)
              )
            }
            label={translate('resources.cerfa.subForm.label15497')}
          />
          <FormControlLabel
            value={CERFA_15498}
            control={<Radio color="primary" />}
            disabled={
              !!(
                (specifiedCerfa && specifiedCerfa !== CERFA_15498) ||
                uploadedCerfa.includes(CERFA_15498)
              )
            }
            label={translate('resources.cerfa.subForm.label15498')}
          />
          <FormControlLabel
            value={CERFA_BOTH}
            control={<Radio color="primary" />}
            disabled={
              !!(
                (specifiedCerfa && specifiedCerfa !== CERFA_BOTH) ||
                uploadedCerfa.includes(CERFA_BOTH)
              )
            }
            label={translate('resources.cerfa.subForm.labelBoth')}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  )
}

CerfaForm.propTypes = {
  translate: PropTypes.func.isRequired,
  setFormIsValid: PropTypes.func.isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  formOption: PropTypes.shape({
    specifiedCerfa: PropTypes.oneOf([CERFA_15497, CERFA_15498, CERFA_BOTH]),
    uploadedCerfa: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  classes: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
}

CerfaForm.defaultProps = {
  formOption: {
    specifiedCerfa: null,
    uploadedCerfa: [],
  },
}

export default compose(withStyles(styles))(CerfaForm)
