import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { Button, Grid, withStyles } from '@material-ui/core'
import RenderDepartmentsMultiSelect from 'components/shared/Form/RenderDepartmentsMultiSelect'
import styles from './FirmsFiltersStyles'
import FirmsFilter from './FirmsFilter'

const FirmsFilters = ({
  classes,
  translate,
  contractOptions,
  trades,
  departments,
  packages,
  origins,
  onboardingScores,
  verifiedDocuments,
  packagesSubscription,
  packagesSubscriptionName,
  handleSearchChange,
  handleFiltersChange,
  handleReset,
  showAllFilters,
  mandatoryDocumentsStatus,
  firmUserStatusSuspendedReasons,
}) => {
  const filters = useMemo(
    () => [
      {
        autocomplete: true,
        name: 'contractOptions',
        handleFiltersChange,
        label: 'resources.firms.fields.contract_options',
        choices: contractOptions,
      },
      {
        autocomplete: true,
        name: 'trades',
        handleFiltersChange,
        label: 'firm.trades.label',
        choices: trades,
        optionValue: 'name',
      },
      {
        name: 'postalCode',
        type: 'search',
        label: 'resources.firms.accordions.departmentFilter',
        onChange: handleSearchChange,
      },
      {
        autocomplete: true,
        name: 'departments',
        handleFiltersChange,
        label: 'resources.firms.accordions.interventionArea',
        departments,
        component: RenderDepartmentsMultiSelect,
      },
      {
        autocomplete: true,
        name: 'origins',
        handleFiltersChange,
        label: 'firm.origin.label',
        choices: origins,
      },
      {
        autocomplete: true,
        name: packagesSubscriptionName,
        handleFiltersChange,
        label: 'firm.packages_subscription.label',
        choices: packagesSubscription,
      },
      {
        autocomplete: true,
        name: 'packages',
        handleFiltersChange,
        label: 'resources.firms.accordions.packages',
        choices: packages,
      },
      {
        autocomplete: true,
        name: 'onboardingScore',
        handleFiltersChange,
        label: 'firm.onboardingscore.label',
        choices: onboardingScores,
      },
      {
        autocomplete: true,
        name: 'verifiedDocuments',
        handleFiltersChange,
        label: 'firm.onboardingscore.verifiedDocuments',
        choices: verifiedDocuments,
      },
      {
        autocomplete: true,
        name: 'mandatoryDocumentsStatus',
        handleFiltersChange,
        label: 'firm.mandatory_document.label',
        choices: mandatoryDocumentsStatus,
      },
      {
        autocomplete: true,
        name: 'suspensionReason',
        handleFiltersChange,
        label: 'firm.suspensionReason.filter.label',
        choices: firmUserStatusSuspendedReasons,
      },
    ],
    [
      contractOptions,
      departments,
      handleFiltersChange,
      handleSearchChange,
      mandatoryDocumentsStatus,
      onboardingScores,
      verifiedDocuments,
      origins,
      packages,
      packagesSubscription,
      packagesSubscriptionName,
      trades,
      firmUserStatusSuspendedReasons,
    ],
  )
  return (
    <Grid container className={classes.searchBar}>
      <FirmsFilter
        name="query"
        type="search"
        label="firm.filter.search.label"
        onChange={handleSearchChange}
      />
      {showAllFilters && (
        <>
          {filters.map(filter => {
            if (filter.featureEnabled === false) return false
            return (
              <FirmsFilter
                className={classes.selectEmpty}
                key={filter.name}
                {...filter}
              />
            )
          })}
        </>
      )}
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Button
            onClick={handleReset}
            variant="contained"
            color="default"
            type="reset"
            className={classes.buttonReload}
          >
            {translate('app.message.reset')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

FirmsFilters.propTypes = {
  classes: PropTypes.shape({
    searchBar: PropTypes.string.isRequired,
    selectEmpty: PropTypes.string.isRequired,
    formControl: PropTypes.string.isRequired,
    buttonReload: PropTypes.string.isRequired,
    inputSizing: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  contractOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  departments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  origins: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onboardingScores: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  verifiedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  mandatoryDocumentsStatus: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  packagesSubscription: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  firmUserStatusSuspendedReasons: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  trades: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  showAllFilters: PropTypes.bool.isRequired,
  packagesSubscriptionName: PropTypes.string.isRequired,
}

export default compose(i18n, withStyles(styles))(FirmsFilters)
