import { createSelector } from 'reselect'
import { get } from 'lodash'

export const initialState = {
  contractOptions: [],
  isLoading: false,
}

export const firmContractOptionsSelector = state =>
  get(state, 'firmContractOptions')

export const getContractOptions = createSelector(
  firmContractOptionsSelector,
  state => get(state, 'contractOptions'),
)

export const getContractOptionByName = contractOptionName =>
  createSelector(getContractOptions, state =>
    state.find(item => item.name === contractOptionName),
  )

export const getfirmContractOptionsLoadingSelector = createSelector(
  firmContractOptionsSelector,
  state => get(state, 'isLoading'),
)
