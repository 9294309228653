import { all, call, takeLatest } from 'redux-saga/effects'
import { handleRequest } from 'helpers/store/sagasHelpers'

import client from 'services/httpClient/syliusClient'
import { GET_STORES_LIST, storeList } from './storeActions'

export function* handleReadStoreListRequest({ partner }) {
  try {
    yield* handleRequest({
      requestActions: storeList,
      promise: call(
        client.get,
        `/shop-api/stores?sorting[name]=ASC&criteria[enabled]=true&criteria[channel_code]=${partner}`,
      ),
      actionParams: {
        triggerModalLoader: true,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default function*() {
  yield all([takeLatest(GET_STORES_LIST.REQUEST, handleReadStoreListRequest)])
}
