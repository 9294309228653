import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import {
  invoiceCartDeleteItem,
  invoiceCartUpdateItem,
} from 'store/invoices/invoiceActions'
import {
  jobIdSelector,
  currentItemSubtotalSelector,
  cartFirmSubjectToVatSelector,
  currentItemIsOverchargeSelector,
} from 'store/invoices/invoiceSelectors'
import {
  FIELD_INVOICE_ITEM_UNIT_PRICE,
  FIELD_INVOICE_ITEM_QUANTITY,
  FIELD_INVOICE_ITEM_NAME,
} from 'constants/invoices'
import { convertEurosToCents } from 'helpers/utils/common'
import InvoiceItem from './InvoiceItem'

class InvoiceItemContainer extends PureComponent {
  handleChangeName = event => {
    const { index, updateCart } = this.props
    updateCart(index, {
      [FIELD_INVOICE_ITEM_NAME]: event.target.value,
    })
  }

  handleChangeQuantity = event => {
    const { index, updateCart } = this.props
    updateCart(index, {
      [FIELD_INVOICE_ITEM_QUANTITY]: parseInt(event.target.value, 10),
    })
  }

  handleChangeUnitPrice = event => {
    const { index, updateCart } = this.props
    updateCart(index, {
      [FIELD_INVOICE_ITEM_UNIT_PRICE]: convertEurosToCents(event.target.value),
    })
  }

  handleRemoveItem = () => {
    const { removeItem, index } = this.props
    removeItem(index)
  }

  render() {
    const { ...props } = this.props

    return (
      <InvoiceItem
        {...props}
        handleChangeName={this.handleChangeName}
        handleChangeQuantity={this.handleChangeQuantity}
        handleChangeUnitPrice={this.handleChangeUnitPrice}
        handleClickOnDeleteRow={this.handleRemoveItem}
      />
    )
  }
}

InvoiceItemContainer.propTypes = {
  updateCart: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  currentItemSubtotal: PropTypes.number.isRequired,
  isFirmSubjectToVat: PropTypes.bool.isRequired,
  currentItemIsOvercharge: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, { item }) => ({
  jobId: jobIdSelector(state),
  currentItemSubtotal: currentItemSubtotalSelector(state, item),
  currentItemIsOvercharge: currentItemIsOverchargeSelector(state, item),
  isFirmSubjectToVat: cartFirmSubjectToVatSelector(state),
})

const mapDispatchToProps = dispatch => ({
  removeItem: index => dispatch(invoiceCartDeleteItem({ index })),
  updateCart: (index, item) => dispatch(invoiceCartUpdateItem({ index, item })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
)(InvoiceItemContainer)
