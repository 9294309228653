import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'

const JobTimeslotSelect = ({ translate, input: { value, onChange } }) => (
  <FormControl>
    <InputLabel htmlFor="time-slot-id" style={{ left: '30px' }}>
      {translate('job.manual_matching.time_slot')}
    </InputLabel>
    <Select
      value={value}
      onChange={onChange}
      inputProps={{ name: 'time_slot', id: 'time-slot-id' }}
      style={{ left: '30px', width: '150px' }}
    >
      <MenuItem value="08:00:00.000-13:30:00.000">
        {translate('job.manual_matching.time_slot_range.morning')}
      </MenuItem>
      <MenuItem value="13:30:00.000-18:00:00.000">
        {translate('job.manual_matching.time_slot_range.afternoon')}
      </MenuItem>
    </Select>
  </FormControl>
)

JobTimeslotSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
}
export default compose(i18n)(JobTimeslotSelect)
