const styles = theme => ({
  textContainer: {
    margin: '6px 0px',
  },
  textarea: {
    height: '80px',
  },
  counter: {
    textAlign: 'right',
  },
  buttonBigger: theme.buttonBigger,
  buttonReload: theme.buttonReload,
})

export default styles
