import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { toDoneTransition } from 'store/jobs/jobActions'
import {
  toCancelTransitionIsLoadingSelector,
  jobIdSelector,
  statusOrSubstatusSelector,
} from 'store/jobs/jobSelectors'
import { showStatusJobDetailsSelector } from 'store/Application/ApplicationSelectors'
import PropTypes from 'prop-types'
import ToDoneLink from './ToDoneLink'

const ToDoneLinkContainer = ({
  jobIri,
  status,
  showStatus,
  isLoading,
  updateJobStatusAction,
}) =>
  jobIri &&
  status &&
  showStatus && (
    <ToDoneLink
      jobIri={jobIri}
      status={status}
      showStatus={showStatus}
      isLoading={isLoading}
      updateJobStatusAction={updateJobStatusAction}
    />
  )

ToDoneLinkContainer.propTypes = {
  jobIri: PropTypes.string,
  status: PropTypes.string,
  showStatus: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  updateJobStatusAction: PropTypes.func.isRequired,
}

ToDoneLinkContainer.defaultProps = {
  jobIri: false,
  status: false,
  isLoading: false,
}

const mapStateToProps = state => ({
  jobIri: jobIdSelector(state),
  status: statusOrSubstatusSelector(state),
  showStatus: showStatusJobDetailsSelector(state),
  isLoading: toCancelTransitionIsLoadingSelector(state),
})

const mapDispatchToProps = dispatch => ({
  updateJobStatusAction: jobIri =>
    dispatch(toDoneTransition.request({ jobIri })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ToDoneLinkContainer)
