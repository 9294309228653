export default () => ({
  error: {
    textAlign: 'center',
    fontFamily: 'montserrat',
  },
  message: {
    margin: '15rem 0 3rem',
  },
  buttonBigger: {
    marginTop: '2rem',
    color: '#232323',
    textDecoration: 'none',
    marginBottom: '1rem',
    padding: '0.8rem 3rem',
    fontSize: '0.9rem',
    boxShadow: 'none',
    fontFamily: 'montserrat',
    fontWeight: 500,
    borderRadius: '3px',
    textTransform: 'none',
    backgroundColor: '#FFC258',
  },
})
