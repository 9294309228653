import { format, startOfDay, subDays } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { ISO_SHORT } from 'constants/date'
import {
  INCIDENT_SOLVED_STATUS,
  INCIDENT_TYPE_CODES_PROPOSING_RESCHEDULING_ON_SOLVE,
  NB_DAYS_BEFORE_JOB_SHOULD_RESCHEDULING_ON_SOLVE_MALFUNCTIONING_PRODUCT_LIKE,
} from 'constants/incidents'
import i18n from 'providers/i18n/I18nProvider'
import GenericConfirmDialog from 'components/Jobs/JobDetails/JobDetailsIncidents/StatusChangeConfirmDialog/GenericConfirmDialog/GenericConfirmDialog'
import RescheduleFormContainer from 'components/Jobs/JobDetails/JobDetailsIncidents/StatusChangeConfirmDialog/RescheduleForm/RescheduleFormContainer'
import {
  PENDING_NEW_DATE_STATUS,
  CHECK_BOX_FIELD_NAME,
  TIMESLOT_DATE_FIELD_NAME,
  TIMESLOT_FIELD_NAME,
} from 'constants/Jobs'

const StatusChangeConfirmDialog = ({
  translate,
  confirmDialogActionGenerator,
  hideStatusChangeConfirmDialog,
  incidentTypeCode,
  newStatus,
  plannedDtStr,
  proPlannedDtStr,
  initialTimeslotStr,
  status,
}) => {
  const proposeReschedulingOnStatusChange =
    newStatus === INCIDENT_SOLVED_STATUS &&
    INCIDENT_TYPE_CODES_PROPOSING_RESCHEDULING_ON_SOLVE.includes(
      incidentTypeCode,
    )

  if (!proposeReschedulingOnStatusChange) {
    const confirmDialogAction = confirmDialogActionGenerator()

    return (
      <GenericConfirmDialog
        translate={translate}
        hideStatusChangeConfirmDialog={hideStatusChangeConfirmDialog}
        confirmDialogAction={confirmDialogAction}
      />
    )
  }

  const plannedDt = new Date(plannedDtStr)
  const deadlineDate = subDays(
    startOfDay(plannedDt),
    NB_DAYS_BEFORE_JOB_SHOULD_RESCHEDULING_ON_SOLVE_MALFUNCTIONING_PRODUCT_LIKE,
  )

  const nowDate = new Date()
  const deadlineReached =
    status === PENDING_NEW_DATE_STATUS || nowDate >= deadlineDate

  return (
    <RescheduleFormContainer
      translate={translate}
      hideStatusChangeConfirmDialog={hideStatusChangeConfirmDialog}
      confirmDialogActionGenerator={confirmDialogActionGenerator}
      deadlineReached={deadlineReached}
      proPlannedDtStr={proPlannedDtStr}
      initialValues={{
        [TIMESLOT_DATE_FIELD_NAME]: format(plannedDt, ISO_SHORT),
        [CHECK_BOX_FIELD_NAME]: deadlineReached,
        [TIMESLOT_FIELD_NAME]: initialTimeslotStr,
      }}
    />
  )
}

StatusChangeConfirmDialog.defaultProps = {
  proPlannedDtStr: null,
  initialTimeslotStr: null,
}

StatusChangeConfirmDialog.propTypes = {
  translate: PropTypes.func.isRequired,
  confirmDialogActionGenerator: PropTypes.func.isRequired,
  hideStatusChangeConfirmDialog: PropTypes.func.isRequired,
  incidentTypeCode: PropTypes.string.isRequired,
  newStatus: PropTypes.string.isRequired,
  plannedDtStr: PropTypes.string.isRequired,
  proPlannedDtStr: PropTypes.string,
  initialTimeslotStr: PropTypes.string,
  status: PropTypes.string.isRequired,
}

export default i18n(StatusChangeConfirmDialog)
