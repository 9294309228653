import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import {
  getInvoicesExport,
  getInvoicesList,
  resetFilterInvoice,
  resetInvoicesList,
} from 'store/invoices/invoiceActions'
import {
  invoicesListSelector,
  searchParamsSelector,
} from 'store/invoices/invoiceSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import InvoicesList from './InvoicesList'

class InvoicesListContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.handleChangePerPage = this.handleChangePerPage.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeSort = this.handleChangeSort.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleFiltersChange = this.handleFiltersChange.bind(this)
    this.handleExportClick = this.handleExportClick.bind(this)
    this.delaySearchInvoices = debounce(
      this.delaySearchInvoices.bind(this),
      600,
    )
    this.loadInvoices = this.loadInvoices.bind(this)
  }

  componentDidMount() {
    const { resetFiltersThenDoSearch } = this.props
    resetFiltersThenDoSearch()
  }

  componentWillUnmount() {
    const { dispatchResetInvoicesList } = this.props
    dispatchResetInvoicesList()
  }

  handleChangePerPage(event) {
    const { searchParams } = this.props
    this.loadInvoices({
      ...searchParams,
      rowsPerPage: event.target.value,
    })
  }

  handleChangeSort(orderBy) {
    const { searchParams } = this.props
    let sort
    if (!searchParams.orderBy || searchParams.orderBy !== orderBy) {
      sort = 'desc'
    } else {
      sort = searchParams.sort === 'desc' ? 'asc' : 'desc'
    }

    return this.loadInvoices({
      ...searchParams,
      orderBy,
      sort,
    })
  }

  handleChangePage(_, page) {
    const { searchParams } = this.props
    this.loadInvoices({
      ...searchParams,
      page,
    })
  }

  handleSearchChange(event) {
    this.delaySearchInvoices(event.target.value)
  }

  handleFiltersChange(fieldName, values) {
    const { searchParams } = this.props

    this.loadInvoices({
      ...searchParams,
      [fieldName]: values ? values.map(element => element.value) : [],
      page: 0,
    })
  }

  handleExportClick() {
    const { searchParams, exportInvoices } = this.props
    exportInvoices({
      ...searchParams,
      export: true,
    })
  }

  delaySearchInvoices(query) {
    const { searchParams } = this.props

    this.loadInvoices({
      ...searchParams,
      query,
      page: 0,
    })
  }

  loadInvoices(params) {
    const { getInvoices } = this.props
    getInvoices({ ...params })
  }

  render() {
    const { invoices, searchParams, isLoading } = this.props
    return (
      <InvoicesList
        rows={invoices}
        searchParams={searchParams}
        isLoading={isLoading}
        handleChangePage={this.handleChangePage}
        handleChangePerPage={this.handleChangePerPage}
        handleChangeSort={this.handleChangeSort}
        handleSearchChange={this.handleSearchChange}
        handleFiltersChange={this.handleFiltersChange}
        handleExportClick={this.handleExportClick}
      />
    )
  }
}

InvoicesListContainer.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatchResetInvoicesList: PropTypes.func.isRequired,
  resetFiltersThenDoSearch: PropTypes.func.isRequired,
  getInvoices: PropTypes.func.isRequired,
  exportInvoices: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchParams: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired,
  }).isRequired,
}

const mapStateToProps = state => ({
  invoices: invoicesListSelector(state),
  searchParams: searchParamsSelector(state),
  isLoading: isLoadingSelector(state),
})

const mapDispatchToProps = dispatch => ({
  resetFiltersThenDoSearch: () => dispatch(resetFilterInvoice()),
  getInvoices: searchParams => dispatch(getInvoicesList.request(searchParams)),
  exportInvoices: searchParams =>
    dispatch(getInvoicesExport.request(searchParams)),
  dispatchResetInvoicesList: () => dispatch(resetInvoicesList()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoicesListContainer)
