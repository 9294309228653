import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import PropTypes from 'prop-types'
import React from 'react'
import DialogTwoButtonsAction from 'components/shared/SimpleDialog/DialogTwoButtonsAction'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'

const GenericConfirmDialog = ({
  translate,
  title,
  hideStatusChangeConfirmDialog,
  confirmDialogAction,
  validateLabel,
  justifyContainerBtns,
  disableValidate,
  dialogContentText,
  formContent,
}) => (
  <SimpleDialog
    title={translate(title)}
    onClose={hideStatusChangeConfirmDialog}
    type="custom"
    customActions={
      <DialogTwoButtonsAction
        onValidateClick={confirmDialogAction}
        onCancelClick={hideStatusChangeConfirmDialog}
        validateLabel={validateLabel}
        justifyContainer={justifyContainerBtns}
        disableValidate={disableValidate}
      />
    }
  >
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {translate(dialogContentText)}
      </DialogContentText>
      {formContent}
    </DialogContent>
  </SimpleDialog>
)

GenericConfirmDialog.defaultProps = {
  title: 'resources.incidents.update.status.confirm_popin.title',
  validateLabel: 'app.action.confirm',
  justifyContainerBtns: undefined,
  disableValidate: false,
  dialogContentText: 'resources.incidents.update.status.confirm_popin.content',
  formContent: null,
}

GenericConfirmDialog.propTypes = {
  translate: PropTypes.func.isRequired,
  title: PropTypes.string,
  hideStatusChangeConfirmDialog: PropTypes.func.isRequired,
  confirmDialogAction: PropTypes.func.isRequired,
  validateLabel: PropTypes.string,
  justifyContainerBtns: PropTypes.string,
  disableValidate: PropTypes.bool,
  dialogContentText: PropTypes.string,
  formContent: PropTypes.node,
}

export default GenericConfirmDialog
