import { loadGoogleMapsAPI } from 'services/googleMaps/googleMaps'

class GoogleMapsAutoComplete {
  constructor(input, locale, types, onAutoComplete, radius, lat, lng) {
    this.initialize(input, locale, types, onAutoComplete, radius, lat, lng)
  }

  async initialize(input, locale, types, onAutoComplete, radius, lat, lng) {
    await loadGoogleMapsAPI()
    this.autocomplete = new window.google.maps.places.Autocomplete(input, {
      types,
      componentRestrictions: {
        country: 'FR',
      },
    })
    this.autocomplete.addListener('place_changed', onAutoComplete)
    window.google.maps.event.addDomListener(input, 'keydown', event => {
      const autoCompleteDOMNode = document.querySelector('.pac-item-selected')

      if (
        event.keyCode === 13 &&
        autoCompleteDOMNode &&
        autoCompleteDOMNode.offsetWidth > 0 &&
        autoCompleteDOMNode.offsetHeight > 0
      ) {
        event.preventDefault()
      }
    })

    this.map = new window.google.maps.Map(document.getElementById('basicMap'), {
      center: { lat: 48.8566, lng: 2.3522 },
      zoom: radius ? 7 : 4,
    })
    this.setMap(lat, lng, radius)
  }

  async setMap(latValue, lngValue, radius) {
    if (!radius || !latValue || !lngValue) {
      return
    }
    await loadGoogleMapsAPI()
    const lat = Number(latValue)
    const lng = Number(lngValue)
    if (this.marker !== undefined) {
      this.marker.setMap(null)
    }
    this.marker = new window.google.maps.Marker({
      position: { lat, lng },
      map: this.map,
    })
    if (this.circle !== undefined) {
      this.circle.setMap(null)
    }
    this.circle = new window.google.maps.Circle({
      strokeColor: '#006400',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#006400',
      fillOpacity: 0.35,
      map: this.map,
      center: { lat, lng },
      radius,
    })
  }

  getPlace() {
    return this.autocomplete.getPlace()
  }
}

export default GoogleMapsAutoComplete
