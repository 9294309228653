import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { withStyles, Grid, Button } from '@material-ui/core'
import styles from './IncidentFormStyles'

const IncidentFormDialogButtons = ({
  classes,
  translate,
  handleClose,
  handleConfirm,
}) => (
  <Grid container justifyContent="flex-end">
    <Grid item className={classes.margin}>
      <Button
        onClick={() => {
          handleConfirm()
          handleClose()
        }}
        variant="contained"
        color="secondary"
        className={classes.buttonBigger}
      >
        {translate('app.action.confirm')}
      </Button>
    </Grid>
    <Grid item className={classes.margin}>
      <Button onClick={handleClose} className={classes.buttonReload}>
        {translate('app.action.cancel')}
      </Button>
    </Grid>
  </Grid>
)

IncidentFormDialogButtons.propTypes = {
  classes: PropTypes.shape({
    buttonReload: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  translate: PropTypes.func,
}

IncidentFormDialogButtons.defaultProps = {
  translate: null,
}

export default compose(i18n, withStyles(styles))(IncidentFormDialogButtons)
