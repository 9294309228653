import { delay, put, select } from 'redux-saga/effects'
import { selectIdTokenExpAt } from 'store/Application/ApplicationSelectors'
import { ERROR } from 'constants/variant'
import translate from 'providers/i18n/translateService'
import {
  authLogout,
  loadingData,
  showNotification,
} from 'store/Application/ApplicationActions'
import { isMocks } from 'services/isMocks'

const defaultResponseFormatter = response => response.data

const algoliaDefaultResponseFormatter = ({
  hits,
  hitsPerPage,
  nbHits,
  nbPages,
  page,
}) => ({ hits, hitsPerPage, nbHits, nbPages, page })

const algoliaFacetsResponseFormatter = ({
  facetHits,
  exhaustiveFacetsCount,
}) => ({ facetHits, exhaustiveFacetsCount })

function* baseHandleRequest(
  requestActions,
  promise,
  actionParams = {
    triggerModalLoader: false,
    composeTriggerModalLoader: false,
  },
  responseFormatter = defaultResponseFormatter,
  checkTokens,
) {
  const {
    triggerModalLoader,
    composeTriggerModalLoader,
    ...otherActionParams
  } = actionParams
  const trigger = composeTriggerModalLoader
    ? requestActions.key.prefix
    : 'default'
  const useLoader = triggerModalLoader || composeTriggerModalLoader
  if (useLoader) {
    yield put(loadingData(true, trigger))
  }
  try {
    if (checkTokens && !isMocks) {
      const idTokenExpAt = yield select(selectIdTokenExpAt)
      const now = new Date().getTime() / 1000

      if (!idTokenExpAt || now >= idTokenExpAt) {
        yield put(
          showNotification({
            payload: {
              messageType: ERROR,
              message: translate('user.sign_in.token_expired'),
            },
          }),
        )
        yield put(loadingData(true, trigger))
        yield delay(2000)
        yield put(authLogout())
        throw new Error('Token expired before call api begin')
      }
    }

    const response = yield promise
    yield put(
      requestActions.success(responseFormatter(response), otherActionParams),
    )

    return response
  } catch (e) {
    if (
      checkTokens &&
      e?.response?.data?.detail === 'Expired token' &&
      !isMocks
    ) {
      yield put(
        showNotification({
          payload: {
            messageType: ERROR,
            message: translate('user.sign_in.token_expired'),
          },
        }),
      )
      yield put(loadingData(true, trigger))
      yield delay(2000)
      yield put(authLogout())
    }

    yield put(requestActions.failure(e, otherActionParams))

    throw e
  } finally {
    if (useLoader) {
      yield put(loadingData(false, trigger))
    }
  }
}

export function* handleRequest({
  requestActions,
  promise,
  actionParams = { triggerModalLoader: false },
  checkTokens = true,
}) {
  return yield baseHandleRequest(
    requestActions,
    promise,
    actionParams,
    defaultResponseFormatter,
    checkTokens,
  )
}

export function* handleAlgoliaRequest(
  requestActions,
  promise,
  actionParams = { triggerModalLoader: false },
  isFacet = false,
) {
  const formatter = isFacet
    ? algoliaFacetsResponseFormatter
    : algoliaDefaultResponseFormatter

  yield baseHandleRequest(
    requestActions,
    promise,
    actionParams,
    formatter,
    false,
  )
}

export function* handleAlgoliaFacetsRequest(
  requestActions,
  promise,
  actionParams = { triggerModalLoader: false },
) {
  yield baseHandleRequest(
    requestActions,
    promise,
    actionParams,
    algoliaFacetsResponseFormatter,
    false,
  )
}
