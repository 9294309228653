import {
  generateRequestActions,
  createRequestTypes,
} from 'helpers/store/actionsHelpers'

export const FIRM_GET_SINGLE_FILE_CONTENT = createRequestTypes(
  'FIRM_GET_SINGLE_FILE_CONTENT',
)

export const firmGetSingleFileContent = generateRequestActions(
  FIRM_GET_SINGLE_FILE_CONTENT,
)
