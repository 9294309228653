import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { dateFormatter } from 'helpers/date'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

import uuidv1 from 'uuid/v1'

const TableProducts = ({ products, translate }) => {
  const dateFormat = translate('app.date_format.short')

  if (products.length === 0) {
    return ''
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row" key="quantityLabel">
            {translate('resources.jobs.fields.quantity')}
          </TableCell>
          <TableCell component="th" scope="row" key="designationLabel">
            {translate('resources.jobs.fields.designation')}
          </TableCell>
          <TableCell component="th" scope="row" key="designationLabel">
            {translate('resources.jobs.fields.ref')}
          </TableCell>
          <TableCell component="th" scope="row" key="dispoDateLabel">
            {translate('resources.jobs.fields.dispo_date')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map(
          ({ quantity, name, productUrl, code, availabilityDate }) => (
            <TableRow key={uuidv1()}>
              <TableCell component="td" scope="row">
                {quantity}
              </TableCell>
              <TableCell component="td" scope="row">
                {name}
              </TableCell>
              <TableCell component="td" scope="row">
                {productUrl && code ? (
                  <a href={productUrl} target="_blank" rel="noreferrer">
                    {code}
                  </a>
                ) : (
                  code || '-'
                )}
              </TableCell>
              <TableCell component="td" scope="row">
                {availabilityDate
                  ? dateFormatter(availabilityDate, dateFormat, true)
                  : '-'}
              </TableCell>
            </TableRow>
          ),
        )}
      </TableBody>
    </Table>
  )
}

TableProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      productUrl: PropTypes.string.isRequired,
      availabilityDate: PropTypes.string.isRequired,
    }),
  ),
  translate: PropTypes.func.isRequired,
}

TableProducts.defaultProps = {
  products: [],
}

export default i18n(TableProducts)
