import {
  createRequestTypes,
  generateRequestActions,
  generateSimpleAction,
} from 'helpers/store/actionsHelpers'

export const UPDATE_INCIDENT_STATUS = createRequestTypes(
  'UPDATE_INCIDENT_STATUS',
)
export const updateIncidentStatus = generateRequestActions(
  UPDATE_INCIDENT_STATUS,
)

export const SET_INCIDENT_RESPONSIBLE = createRequestTypes(
  'SET_INCIDENT_RESPONSIBLE',
)
export const setIncidentResponsible = generateRequestActions(
  SET_INCIDENT_RESPONSIBLE,
)

export const FETCH_INCIDENT = createRequestTypes('FETCH_INCIDENT')
export const fetchIncident = generateRequestActions(FETCH_INCIDENT)

export const FETCH_INCIDENT_STATUSES = createRequestTypes(
  'FETCH_INCIDENT_STATUSES',
)
export const fetchIncidentStatuses = generateRequestActions(
  FETCH_INCIDENT_STATUSES,
)

export const FETCH_INCIDENT_TYPES = createRequestTypes('FETCH_INCIDENT_TYPES')
export const fetchIncidentTypes = generateRequestActions(FETCH_INCIDENT_TYPES)

export const POST_INCIDENT_REQ = createRequestTypes('POST_INCIDENT')
export const postIncident = generateRequestActions(POST_INCIDENT_REQ)

export const RESET_INCIDENT_LIST = 'RESET_INCIDENT_LIST'
export const resetIncidentList = generateSimpleAction(RESET_INCIDENT_LIST)

export const GET_COUNT_INCIDENTS_BY_STATUSES = createRequestTypes(
  'GET_COUNT_INCIDENTS_BY_STATUSES',
)
export const getCountIncidentsByStatuses = generateRequestActions(
  GET_COUNT_INCIDENTS_BY_STATUSES,
)

export const FETCH_INCIDENT_WAITING_FOR_LIST = createRequestTypes(
  'FETCH_INCIDENT_WAITING_FOR_LIST',
)
export const fetchIncidentWaitingForList = generateRequestActions(
  FETCH_INCIDENT_WAITING_FOR_LIST,
)

export const UPDATE_INCIDENT_WAITING_FOR = createRequestTypes(
  'UPDATE_INCIDENT_WAITING_FOR',
)

export const updateIncidentWaitingFor = generateRequestActions(
  UPDATE_INCIDENT_WAITING_FOR,
)

export const RESP_SELECT_DIALOG_DISPLAYED = 'RESP_SELECT_DIALOG_DISPLAYED'
export const doDisplayRespSelectDialog = generateSimpleAction(
  RESP_SELECT_DIALOG_DISPLAYED,
)
export const STATUS_CHANGE_CONFIRM_DIALOG_DISPLAYED =
  'STATUS_CHANGE_CONFIRM_DIALOG_DISPLAYED'
export const doDisplayStatusChangeConfirmDialog = generateSimpleAction(
  STATUS_CHANGE_CONFIRM_DIALOG_DISPLAYED,
)

export const STATUS_CHANGED = 'STATUS_CHANGED'
export const doChangeStatus = generateSimpleAction(STATUS_CHANGED)
