import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import get from 'lodash/get'
import {
  firmNameSelector,
  firmProGenderSelector,
  firmProFisrtNameSelector,
  firmProLastNameSelector,
  firmProEmailSelector,
  firmMobilePhoneNumberSelector,
  firmFixedPhoneNumberSelector,
  firmContractOptionsLabelSelector,
  firmIsDirectorySelector,
  displayedInProDirectorySelector,
  firmLegacyIdSelector,
  firmFilesCompletedAtFormattedSelector,
  firmEmailSelector,
  firmPackageStatusSelector,
  firmPackageStatusV2Selector,
  firmPackageStatusCandidateOriginLabelSelector,
  firmAccountingNumberSelector,
  firmAvailablePackageStatusSelector,
  firmOnboardingScoreSelector,
  firmVerifiedDocumentsSelector,
  firmUserSuspensionReason,
} from 'store/firms/firmSelectors'
import {
  updateFirmDetailsPro,
  desactivateFirmDirectory,
} from 'store/firms/firmActions'
import {
  DOCUMENTS_COMPLETE,
  FIRM_USER_STATUS_SUSPENDED_REASONS,
} from 'constants/firms'
import FirmDetailsPro from './FirmDetailsPro'

const FirmDetailsProContainer = ({
  firm,
  updateCurrentFirm,
  initialValues,
  availablePackageStatus,
  displayDesactiveBtn,
  desactivateFirmDirectoryAction,
  hasDirectoryOption,
}) => (
  <FirmDetailsPro
    onSubmit={values => {
      updateCurrentFirm(values)
    }}
    initialValues={initialValues}
    isVerifiedDocumentDisabled={
      firm.mandatoryDocumentsStatus !== DOCUMENTS_COMPLETE
    }
    availablePackageStatus={availablePackageStatus}
    displayDesactiveBtn={displayDesactiveBtn}
    desactivateFirmDirectoryAction={desactivateFirmDirectoryAction}
    hasDirectoryOption={hasDirectoryOption}
  />
)

FirmDetailsProContainer.propTypes = {
  updateCurrentFirm: PropTypes.func.isRequired,
  firm: PropTypes.shape({
    mandatoryDocumentsStatus: PropTypes.string.isRequired,
  }).isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  availablePackageStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayDesactiveBtn: PropTypes.bool.isRequired,
  hasDirectoryOption: PropTypes.bool.isRequired,
  desactivateFirmDirectoryAction: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const reasonCode = firmUserSuspensionReason(state)
  const suspendedReasonLabel = get(
    FIRM_USER_STATUS_SUSPENDED_REASONS.find(
      reason => reason.code === reasonCode,
    ),
    'name',
  )

  return {
    initialValues: {
      name: firmNameSelector(state),
      email: firmEmailSelector(state),
      proGender: firmProGenderSelector(state),
      proFirstName: firmProFisrtNameSelector(state),
      proLastName: firmProLastNameSelector(state),
      proEmail: firmProEmailSelector(state),
      mobilePhoneNumber: firmMobilePhoneNumberSelector(state),
      fixedPhoneNumber: firmFixedPhoneNumberSelector(state),
      legacyId: firmLegacyIdSelector(state),
      contractOptionsLabel: firmContractOptionsLabelSelector(state),
      packageStatus: firmPackageStatusSelector(state),
      packageStatusV2: firmPackageStatusV2Selector(state),
      packageStatusCandidateOriginLabel: firmPackageStatusCandidateOriginLabelSelector(
        state,
      ),
      accountingNumber: firmAccountingNumberSelector(state),
      filesCompletedAtFormatted: firmFilesCompletedAtFormattedSelector(state),
      onboardingScore: firmOnboardingScoreSelector(state),
      verifiedDocuments: firmVerifiedDocumentsSelector(state),
      suspensionReason: suspendedReasonLabel,
    },
    availablePackageStatus: firmAvailablePackageStatusSelector(state),
    displayDesactiveBtn: displayedInProDirectorySelector(state),
    hasDirectoryOption: firmIsDirectorySelector(state),
  }
}

const mapDispatchToProps = dispatch => ({
  updateCurrentFirm: values =>
    dispatch(updateFirmDetailsPro({ payload: values })),
  desactivateFirmDirectoryAction: () =>
    dispatch(desactivateFirmDirectory.request()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirmDetailsProContainer)
