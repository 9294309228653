import {
  generateRequestActions,
  createRequestTypes,
  generateSimpleAction,
} from 'helpers/store/actionsHelpers'

export const RESET_JOBS_LIST = 'RESET_JOBS_LIST'
export const RESET_JOBS_SEARCH = 'RESET_JOBS_SEARCH'
export const RESET_JOBS_DASHBOARD_PARAMS = 'RESET_JOBS_DASHBOARD_PARAMS'
export const UPLOAD_JOB_BFT = 'UPLOAD_JOB_BFT'

export const uploadJobBFT = file => ({
  type: UPLOAD_JOB_BFT,
  payload: { file },
})

export const UNBLOCK_JOB_RECEIPT = 'UNBLOCK_JOB_RECEIPT'
export const unblockReceipt = (jobId, reserves) => ({
  type: UNBLOCK_JOB_RECEIPT,
  payload: { jobId, reserves },
})

export const SET_SELECTED_TABBED_JOBS_INDEX = 'SET_SELECTED_TABBED_JOBS_INDEX'
export const GET_JOBS_LIST = createRequestTypes('GET_JOBS_LIST')
export const EXPORT_JOBS_LIST = createRequestTypes('EXPORT_JOBS_LIST')
export const GET_SINGLE_JOB = createRequestTypes('GET_SINGLE_JOB')
export const REFRESH_JOB = createRequestTypes('REFRESH_JOB')
export const GET_MATCHING_PROS = createRequestTypes('GET_MATCHING_PROS')
export const GET_MATCHING_PROS_LIST = createRequestTypes(
  'GET_MATCHING_PROS_LIST',
)
export const GET_MATCHING_PROS_LIST_RANKING = createRequestTypes(
  'GET_MATCHING_PROS_LIST_RANKING',
)
export const RESET_PROS_SEARCH = createRequestTypes('RESET_PROS_SEARCH')
export const GET_RECEIPT_JOB = createRequestTypes('GET_RECEIPT_JOB')
export const GET_CERFA_JOB = createRequestTypes('GET_CERFA_JOB')
export const GET_INVOICE_JOB = createRequestTypes('GET_INVOICE_JOB')
export const GET_COUNT_JOBS_BY_STATUSES = createRequestTypes(
  'GET_COUNT_JOBS_BY_STATUSES',
)
export const getJobsList = generateRequestActions(GET_JOBS_LIST)
export const exportJobsList = generateRequestActions(EXPORT_JOBS_LIST)
export const resetJobsList = generateSimpleAction(RESET_JOBS_LIST)
export const resetJobsSearch = generateSimpleAction(RESET_JOBS_SEARCH)
export const resetJobsDashboardParams = generateSimpleAction(
  RESET_JOBS_DASHBOARD_PARAMS,
)
export const setSelectedTabbedJobsIndex = generateSimpleAction(
  SET_SELECTED_TABBED_JOBS_INDEX,
)
export const getSingleJob = generateRequestActions(GET_SINGLE_JOB)
export const refreshJob = generateRequestActions(REFRESH_JOB)
export const getMatchingPros = generateRequestActions(GET_MATCHING_PROS)
export const resetProsSearch = generateRequestActions(RESET_PROS_SEARCH)
export const getMatchingProsListAlgolia = generateRequestActions(
  GET_MATCHING_PROS_LIST,
)
export const getMatchingProsListAlgoliaRanking = generateRequestActions(
  GET_MATCHING_PROS_LIST_RANKING,
)
export const getReceiptJob = generateRequestActions(GET_RECEIPT_JOB)
export const getCerfaJob = generateRequestActions(GET_CERFA_JOB)
export const getInvoiceJob = generateRequestActions(GET_INVOICE_JOB)

export const getCountJobsByStatuses = generateRequestActions(
  GET_COUNT_JOBS_BY_STATUSES,
)

export const UPDATE_JOB_REQ = createRequestTypes('UPDATE_JOB')
export const updateJob = generateRequestActions(UPDATE_JOB_REQ)

export const UPDATE_JOB_HO_DATE_REQ = createRequestTypes(
  'UPDATE_JOB_HO_DATE_REQ',
)
export const updateJobHoDate = generateRequestActions(UPDATE_JOB_HO_DATE_REQ)

export const TO_PENDING_PAYMENT_TRANSITION_REQ = createRequestTypes(
  'TO_PENDING_PAYMENT_TRANSITION',
)
export const toPendingPaymentTransition = generateRequestActions(
  TO_PENDING_PAYMENT_TRANSITION_REQ,
)

export const GET_JOB_EVENTS_FOR_JOB_REQ = createRequestTypes(
  'GET_JOB_EVENTS_FOR_JOB',
)
export const getJobEventsForJob = generateRequestActions(
  GET_JOB_EVENTS_FOR_JOB_REQ,
)

export const JOB_ADD_COMMENT_REQ = createRequestTypes('JOB_ADD_COMMENT')
export const jobAddComment = generateRequestActions(JOB_ADD_COMMENT_REQ)

export const RELIABLE_PRO_LIST = createRequestTypes('RELIABLE_PRO_LIST')
export const reliableProList = generateRequestActions(RELIABLE_PRO_LIST)

export const JOB_ACCEPT_TRANSITION_REQ = createRequestTypes(
  'JOB_ACCEPT_TRANSITION',
)
export const jobAcceptTransition = generateRequestActions(
  JOB_ACCEPT_TRANSITION_REQ,
)

export const JOB_DECLINE_TRANSITION_REQ = createRequestTypes(
  'JOB_DECLINE_TRANSITION',
)
export const jobDeclineTransition = generateRequestActions(
  JOB_DECLINE_TRANSITION_REQ,
)

export const JOB_RESCHEDULING_TRANSITION_REQ = createRequestTypes(
  'JOB_RESCHEDULING_TRANSITION',
)
export const jobReschedulingTransition = generateRequestActions(
  JOB_RESCHEDULING_TRANSITION_REQ,
)

export const proMatchingJobList = generateSimpleAction('PRO_MATCHING_JOB_LIST')

export const JOB_REMATCHING_TRANSITION_REQ = createRequestTypes(
  'JOB_REMATCHING_TRANSITION',
)
export const jobRematchingTransition = generateRequestActions(
  JOB_REMATCHING_TRANSITION_REQ,
)

export const JOB_PENDING_NEW_DATE_TRANSITION_REQ = createRequestTypes(
  'JOB_PENDING_NEW_DATE_TRANSITION',
)
export const jobPendingNewDateTransition = generateRequestActions(
  JOB_PENDING_NEW_DATE_TRANSITION_REQ,
)

export const GET_RECEIPT_SIGNATURE = createRequestTypes('GET_RECEIPT_SIGNATURE')
export const getReceiptSignature = generateRequestActions(GET_RECEIPT_SIGNATURE)

export const GET_CERFA_SIGNATURE = createRequestTypes('GET_CERFA_SIGNATURE')
export const getCerfaSignature = generateRequestActions(GET_CERFA_SIGNATURE)

export const UPDATE_SEARCH_JOB_PARAMS = 'UPDATE_SEARCH_JOB_PARAMS'
export const updateSearchJobParams = generateSimpleAction(
  UPDATE_SEARCH_JOB_PARAMS,
)

export const PROCESS_JOB_START_TRANSITION_REQ = createRequestTypes(
  'PROCESS_JOB_START_TRANSITION',
)
export const processJobStartTransition = generateRequestActions(
  PROCESS_JOB_START_TRANSITION_REQ,
)

export const TO_PENDING_CANCELATION_TRANSITION_REQ = createRequestTypes(
  'TO_PENDING_CANCELATION_TRANSITION',
)
export const toPendingCancelationTransition = generateRequestActions(
  TO_PENDING_CANCELATION_TRANSITION_REQ,
)

export const GENERATE_SUPPORTING_DOCUMENTS_REQ = createRequestTypes(
  'GENERATE_SUPPORTING_DOCUMENTS',
)
export const generateSupportingDocuments = generateRequestActions(
  GENERATE_SUPPORTING_DOCUMENTS_REQ,
)

export const GENERATE_DISPUTE_DOCUMENTS_REQ = createRequestTypes(
  'GENERATE_DISPUTE_DOCUMENTS',
)
export const generateDisputeDocuments = generateRequestActions(
  GENERATE_DISPUTE_DOCUMENTS_REQ,
)

export const GENERATE_REINTERVENTION_AFTERSALE_REQ = createRequestTypes(
  'GENERATE_REINTERVENTION_AFTERSALE',
)
export const generateReinterventionAfterSale = generateRequestActions(
  GENERATE_REINTERVENTION_AFTERSALE_REQ,
)

export const GET_MATCHINGS = createRequestTypes('GET_MATCHINGS')
export const getMatchings = generateRequestActions(GET_MATCHINGS)

export const POST_CLIENT_DATA_REQ = createRequestTypes('POST_CLIENT_DATA')
export const postClientData = generateRequestActions(POST_CLIENT_DATA_REQ)

export const TO_CANCEL_TRANSITION_REQ = createRequestTypes(
  'TO_CANCEL_TRANSITION',
)
export const toCancelTransition = generateRequestActions(
  TO_CANCEL_TRANSITION_REQ,
)

export const TO_DONE_TRANSITION_REQ = createRequestTypes('TO_DONE_TRANSITION')
export const toDoneTransition = generateRequestActions(TO_DONE_TRANSITION_REQ)
