import { connect } from 'react-redux'
import { statusSelector } from 'store/jobs/jobSelectors'
import { processJobStartTransition } from 'store/jobs/jobActions'
import JobInProgressUtilities from './JobInProgressUtilities'

const mapStateToProps = state => ({
  status: statusSelector(state),
})

const mapDispatchToProps = (dispatch, { jobId }) => ({
  dispatchStartJob: () => {
    dispatch(
      processJobStartTransition.request({
        jobIri: decodeURIComponent(jobId),
      }),
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobInProgressUtilities)
