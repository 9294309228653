export default theme => ({
  icon: {
    width: '15px',
    height: '15px',
    marginLeft: '5px',
  },
  customTooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    padding: '1rem',
  },
  customArrow: {
    color: theme.palette.background.default,
  },
})
