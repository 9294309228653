import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'
import styles from './LabelStyle'

const Label = ({ label, classes }) =>
  label && (
    <Typography
      variant="body2"
      className={classes.label}
      component="label"
    >{`${label}: `}</Typography>
  )

Label.propTypes = {
  label: PropTypes.string,
  classes: PropTypes.shape().isRequired,
}

Label.defaultProps = {
  label: undefined,
}

export default withStyles(styles)(Label)
