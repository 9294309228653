import { createSelector } from 'reselect'
import get from 'lodash/get'
import { SALE_INVOICE_ITEM_NB_LINES } from 'constants/invoices'
import {
  getProductItemsFromInvoice,
  getProductsFromSaleInvoice,
} from 'helpers/utils/invoice/invoice'

export const initialState = {
  list: [],
  searchParams: {
    query: '',
    state: [],
    page: 0,
    rowsPerPage: 50,
    total: 0,
    sort: 'asc',
    orderBy: 'issuedAt',
  },
  currentSaleInvoice: {},
}

const saleInvoicesSelector = state => get(state, 'saleInvoices')

export const saleInvoicesListSelector = createSelector(
  saleInvoicesSelector,
  state => get(state, 'list', []),
)

export const currentSaleInvoiceSelector = createSelector(
  saleInvoicesSelector,
  state => get(state, 'currentSaleInvoice', {}),
)

export const formatedSaleInvoicesListSelector = createSelector(
  saleInvoicesListSelector,
  saleInvoiceList =>
    saleInvoiceList.map(saleInvoice => {
      const errorResponse = {
        ...saleInvoice,
        products: [],
        customer: null,
        timeslot: null,
        pro: null,
      }

      const products = getProductsFromSaleInvoice(saleInvoice)
      if (!products.length) {
        return errorResponse
      }

      const parts = getProductItemsFromInvoice(saleInvoice)[0].name.split('\n')

      if (parts.length !== SALE_INVOICE_ITEM_NB_LINES) {
        return errorResponse
      }

      const [, customerAndTimeslot, company] = parts
      const customerLength = customerAndTimeslot.length

      // Returns customer's fullname without gender
      const customer = customerAndTimeslot
        .substring(0, customerLength - 14)
        .split(' ')
        .splice(1)
        .join(' ')
      const timeslot = customerAndTimeslot.substring(
        customerLength - 10,
        customerLength,
      )
      const pro = company
        .split(' ')
        .splice(2)
        .join(' ')

      return { ...saleInvoice, products, customer, timeslot, pro }
    }),
)

export const searchParamsSelector = createSelector(
  saleInvoicesSelector,
  state => get(state, 'searchParams'),
)
