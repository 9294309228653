export default theme => ({
  menuLink: {
    textDecoration: 'none',
    display: 'block',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    paddingLeft: '0',
    paddingRight: '0',
    marginLeft: '1rem',
    marginRight: '1rem',
    borderBottom: '1px solid #ffffff25',

    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  isActive: {
    backgroundColor: theme.palette.menu,
    opacity: 1,
  },
  imgIconLink: {
    backgroundSize: 'contain',
    height: '42px',
    width: '42px',
    margin: '0.2rem auto 0.8rem',
    textAlign: 'center',
  },
})
