import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles, Button } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import { styles } from './FirmSaveButtonStyle'

const FirmSaveButton = ({
  classes,
  handleClick,
  translate,
  variant,
  color,
  text,
  bigger,
  hide,
}) => (
  <Button
    onClick={handleClick}
    variant={variant}
    color={color}
    className={bigger ? classes.buttonBigger : null}
    disabled={hide}
  >
    {translate(text)}
  </Button>
)

FirmSaveButton.propTypes = {
  classes: PropTypes.shape({
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  handleClick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  bigger: PropTypes.bool,
  hide: PropTypes.bool,
}

FirmSaveButton.defaultProps = {
  bigger: false,
  hide: false,
}

export default compose(i18n, withStyles(styles))(FirmSaveButton)
