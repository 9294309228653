import { connect } from 'react-redux'
import {
  orderProductsSelector,
  deliveryModeSelector,
} from 'store/jobs/jobSelectors'
import JobDetailsProduct from './JobDetailsProduct'

const mapStateToProps = state => ({
  products: orderProductsSelector(state),
  deliveryMode: deliveryModeSelector(state),
})

export default connect(mapStateToProps)(JobDetailsProduct)
