import { all, call, put, takeLatest } from 'redux-saga/effects'
import documentsApiClient from 'services/httpClient/documentsApiClient'
import { handleRequest } from 'helpers/store/sagasHelpers'
import { showNotification } from 'store/Application/ApplicationActions'
import { ERROR } from 'constants/variant'
import translate from 'providers/i18n/translateService'

import {
  FIRM_GET_SINGLE_FILE_CONTENT,
  firmGetSingleFileContent,
} from './firmCertificateFileActions'

function* documentApi(requestActions, promise, actionParams = {}) {
  try {
    yield* handleRequest({
      requestActions,
      promise,
      actionParams,
    })
  } catch (e) {
    yield put(
      showNotification({
        payload: {
          messageType: ERROR,
          message: translate('firm.certificate_file.req_failure'),
        },
      }),
    )
  }
}

export function* handleGetSingleCertificateFile({ certificateId }) {
  const options = { triggerModalLoader: true }
  const p = certificateId
    ? call(documentsApiClient.get, `${certificateId}/download`)
    : null
  yield* documentApi(firmGetSingleFileContent, p, options)
}

export default function*() {
  yield all([
    takeLatest(
      FIRM_GET_SINGLE_FILE_CONTENT.REQUEST,
      handleGetSingleCertificateFile,
    ),
  ])
}
