import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import jwtDecode from 'jwt-decode'
import { withStyles, Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import i18n from 'providers/i18n/I18nProvider'
import { ID_TOKEN } from 'constants/storage'
import { jobAddComment } from 'store/jobs/jobActions'
import { COMMENT_FORM } from 'constants/Jobs'
import styles from './CommentFormStyles'

const CommentForm = ({ classes, error, handleSubmit, translate }) => (
  <div className={classes.myMargin}>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            name="comment"
            type="textarea"
            component="input"
            className={classes.inputText}
            placeholder={translate('resources.jobs.comment.placeholder')}
            rows={2}
          />
          {error && <strong>{error}</strong>}
        </Grid>
        <Grid item>
          <Button
            className={classes.buttonBigger}
            type="submit"
            variant="contained"
            color="secondary"
          >
            {translate('resources.jobs.comment.button')}
          </Button>
        </Grid>
      </Grid>
    </form>
  </div>
)

CommentForm.propTypes = {
  classes: PropTypes.shape({
    myMargin: PropTypes.string.isRequired,
    inputText: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    perPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
}

CommentForm.defaultProps = {
  error: undefined,
}

export default compose(
  reduxForm({
    form: COMMENT_FORM,
    destroyOnUnmount: false,
    enableReinitialize: true,
    fields: ['comment', 'notes'],
    onSubmit: ({ comment }, dispatch, { jobId, searchParams }) => {
      const emailRC = jwtDecode(localStorage.getItem(ID_TOKEN)).email
      dispatch(
        jobAddComment.request({
          data: {
            type: 'COMMENT',
            actor: emailRC,
            comment,
            job: jobId,
          },
          searchParams: { ...searchParams, job: jobId },
        }),
      )
    },
  }),
  withStyles(styles),
  i18n,
)(CommentForm)
