import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector, reduxForm, touch } from 'redux-form'
import {
  CHECK_BOX_FIELD_NAME,
  TIMESLOT_DATE_FIELD_NAME,
  TIMESLOT_FIELD_NAME,
} from 'constants/Jobs'

import RescheduleForm from './RescheduleForm'

const FORM_NAME = 'INCIDENT_STATUS_CHANGE_CONFIRM_DIALOG_WITH_RESCHEDULING_FORM'

const mapStateToProps = (state, { form, invalid }) => {
  const selectFormValue = formValueSelector(form)

  const {
    [CHECK_BOX_FIELD_NAME]: checkBoxValue,
    [TIMESLOT_DATE_FIELD_NAME]: timeslotDate,
    [TIMESLOT_FIELD_NAME]: timeslot,
  } = selectFormValue(
    state,
    CHECK_BOX_FIELD_NAME,
    TIMESLOT_DATE_FIELD_NAME,
    TIMESLOT_FIELD_NAME,
  )

  return {
    disableValidate: invalid,
    timeslotDate,
    timeslot,
    checkBoxValue,
  }
}

const mapDispatchToProps = (dispatch, { form }) => ({
  triggerValidation: () =>
    dispatch(touch(form, TIMESLOT_DATE_FIELD_NAME, TIMESLOT_FIELD_NAME)),
})

const mergeProps = (
  { timeslotDate, timeslot, checkBoxValue, ...otherStateProp },
  dispatchProps,
  ownProps,
) => {
  let timeslots = null

  if (checkBoxValue !== false && timeslotDate && timeslot) {
    const timeslotArr = timeslot.split('-')
    timeslots = [
      {
        startTime: new Date(`${timeslotDate} ${timeslotArr[0]}`)
          .toISOString()
          .replace('.000Z', 'Z'),
        stopTime: new Date(`${timeslotDate} ${timeslotArr[1]}`)
          .toISOString()
          .replace('.000Z', 'Z'),
      },
    ]
  }

  return {
    ...ownProps,
    ...otherStateProp,
    ...dispatchProps,
    confirmDialogAction: ownProps.confirmDialogActionGenerator(timeslots),
    checkBoxValue,
  }
}

export default compose(
  reduxForm({ form: FORM_NAME }),
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(RescheduleForm)
