const styles = theme => ({
  table: {
    display: 'flex',
  },
  cellWrapper: {
    display: 'flex',
  },
  icon: {
    height: '24px',
    width: '24px',
    backgroundSize: '18px',
    display: 'inline-block',
    marginRight: '15px',
  },
  chip: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  buttonSmaller: {
    ...theme.button,
    textTransform: 'none',
  },
  buttonBigger: {
    ...theme.buttonBigger,
    padding: '0.3rem 3rem',
    margin: '0!important',
    textTransform: 'none',
    width: '100%',
  },
})

export default styles
