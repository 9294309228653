import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles, Grid, Button } from '@material-ui/core'
import { styles } from './DialogActionsContentStyles'

const DialogActionsContent = ({ classes, translate, handleClose }) => (
  <Grid container justifyContent="flex-end">
    <Grid item>
      <Button
        onClick={handleClose}
        value="true"
        variant="contained"
        color="secondary"
        className={classes.buttonBigger}
      >
        {translate('app.action.confirm')}
      </Button>
    </Grid>
    <Grid item>
      <Button
        onClick={handleClose}
        value="false"
        variant="outlined"
        color="primary"
        className={classes.button}
      >
        {translate('app.action.cancel')}
      </Button>
    </Grid>
  </Grid>
)

DialogActionsContent.propTypes = {
  classes: PropTypes.shape({
    buttonBigger: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  translate: PropTypes.func,
}

DialogActionsContent.defaultProps = {
  translate: null,
}

export default compose(withStyles(styles))(DialogActionsContent)
