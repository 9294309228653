import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { selectIncidentListOrderByCreatedAtDesc } from 'store/jobs/jobSelectors'
import PropTypes from 'prop-types'
import GenerateReinterventionAfterSale from './GenerateReinterventionAfterSale'

const GenerateReinterventionAfterSaleContainer = ({ incidents }) => (
  <GenerateReinterventionAfterSale incidents={incidents} />
)

GenerateReinterventionAfterSaleContainer.propTypes = {
  incidents: PropTypes.arrayOf(PropTypes.shape({})),
}

GenerateReinterventionAfterSaleContainer.defaultProps = {
  incidents: undefined,
}

const mapStateToProps = state => ({
  incidents: selectIncidentListOrderByCreatedAtDesc(state),
})

export default compose(
  i18n,
  connect(mapStateToProps),
)(GenerateReinterventionAfterSaleContainer)
