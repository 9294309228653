import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { generateSupportingDocuments as generateSupportingDocumentsAction } from 'store/jobs/jobActions'
import { jobIdSelector } from 'store/jobs/jobSelectors'
import PropTypes from 'prop-types'
import GenerateSupportingDocuments from './GenerateSupportingDocuments'

const GenerateSupportingDocumentsContainer = ({
  jobIri,
  generateSupportingDocuments,
}) => (
  <GenerateSupportingDocuments
    generateSupportingDocuments={generateSupportingDocuments}
    jobIri={jobIri}
  />
)

GenerateSupportingDocumentsContainer.propTypes = {
  jobIri: PropTypes.string,
  generateSupportingDocuments: PropTypes.func.isRequired,
}

GenerateSupportingDocumentsContainer.defaultProps = {
  jobIri: undefined,
}

const mapStateToProps = state => ({
  jobIri: jobIdSelector(state),
})

const mapDispatchToProps = dispatch => ({
  generateSupportingDocuments: jobIri =>
    dispatch(generateSupportingDocumentsAction.request({ jobIri })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(GenerateSupportingDocumentsContainer)
