import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  ADDITIONAL_INVOICES_FORM_AFTER_SALE,
  ADDITIONAL_INVOICES_TRAVEL_COMPENSATION,
} from 'constants/features'
import { isFeatureFlagEnabledSelector } from 'store/Application/ApplicationSelectors'
import {
  doDisplayTravelCompensationPurchaseInvoiceCreationDialog,
  doDisplayPurchaseInvoiceCreationDialog,
  getJobInvoicesList,
  setDisplayedInvoice,
} from 'store/invoices/invoiceActions'
import {
  displayedInvoiceSelector,
  selectCreateTravelCompensationPurchaseInvoiceDialogDisplayed,
  selectCreatePurchaseInvoiceDialogDisplayed,
} from 'store/invoices/invoiceSelectors'
import { invoicesSelector } from 'store/jobs/jobSelectors'
import i18n from 'providers/i18n/I18nProvider'
import { CircularProgress } from '@material-ui/core'

import JobDetailsInvoices from './JobDetailsInvoices'

const JobDetailsInvoicesContainer = ({
  invoices,
  translate,
  dispatchSetDisplayedInvoice,
  dispatchResetDisplayedInvoice,
  displayedInvoice,
  getInvoices,
  displayTravelCompensationPurchaseInvoiceCreationDialog,
  createTravelCompensationPurchaseInvoiceDialogDisplayed,
  displayPurchaseInvoiceCreationDialog,
  createPurchaseInvoiceDialogDisplayed,
  isAdditionalInvoiceFormEnabled,
  isAdditionalInvoiceTravelCompensationFeatureEnabled,
}) => {
  useEffect(() => {
    if (!invoices.listRequested && !invoices.listRequesting) {
      getInvoices()
    }
  }, [getInvoices, invoices.listRequested, invoices.listRequesting])

  if (invoices.listRequesting) {
    return <CircularProgress size={20} />
  }

  return (
    <JobDetailsInvoices
      translate={translate}
      invoices={invoices}
      dispatchSetDisplayedInvoice={dispatchSetDisplayedInvoice}
      dispatchResetDisplayedInvoice={dispatchResetDisplayedInvoice}
      displayedInvoice={displayedInvoice}
      displayTravelCompensationPurchaseInvoiceCreationDialog={
        displayTravelCompensationPurchaseInvoiceCreationDialog
      }
      createTravelCompensationPurchaseInvoiceDialogDisplayed={
        createTravelCompensationPurchaseInvoiceDialogDisplayed
      }
      travelCompensationPurchaseInvoiceFeatureEnabled={
        isAdditionalInvoiceTravelCompensationFeatureEnabled
      }
      displayPurchaseInvoiceCreationDialog={
        displayPurchaseInvoiceCreationDialog
      }
      createPurchaseInvoiceDialogDisplayed={
        createPurchaseInvoiceDialogDisplayed
      }
      isAdditionalInvoiceFormEnabled={isAdditionalInvoiceFormEnabled}
    />
  )
}

JobDetailsInvoicesContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  getInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    totalItems: PropTypes.number,
    loading: PropTypes.bool,
    listRequesting: PropTypes.bool,
    listRequested: PropTypes.bool,
  }),
  dispatchSetDisplayedInvoice: PropTypes.func.isRequired,
  dispatchResetDisplayedInvoice: PropTypes.func.isRequired,
  displayedInvoice: PropTypes.number,
  displayTravelCompensationPurchaseInvoiceCreationDialog:
    PropTypes.func.isRequired,
  createTravelCompensationPurchaseInvoiceDialogDisplayed:
    PropTypes.bool.isRequired,
  displayPurchaseInvoiceCreationDialog: PropTypes.func.isRequired,
  createPurchaseInvoiceDialogDisplayed: PropTypes.bool.isRequired,
  isAdditionalInvoiceFormEnabled: PropTypes.bool.isRequired,
  isAdditionalInvoiceTravelCompensationFeatureEnabled:
    PropTypes.bool.isRequired,
}

JobDetailsInvoicesContainer.defaultProps = {
  invoices: {
    list: [],
    totalItems: 0,
    loading: false,
    listRequesting: false,
    listRequested: false,
  },
  displayedInvoice: null,
}

const mapStateToProps = state => ({
  invoices: invoicesSelector(state),
  displayedInvoice: displayedInvoiceSelector(state),
  createTravelCompensationPurchaseInvoiceDialogDisplayed: selectCreateTravelCompensationPurchaseInvoiceDialogDisplayed(
    state,
  ),
  isAdditionalInvoiceTravelCompensationFeatureEnabled: isFeatureFlagEnabledSelector(
    state,
    ADDITIONAL_INVOICES_TRAVEL_COMPENSATION,
  ),
  createPurchaseInvoiceDialogDisplayed: selectCreatePurchaseInvoiceDialogDisplayed(
    state,
  ),
  isAdditionalInvoiceFormEnabled: isFeatureFlagEnabledSelector(
    state,
    ADDITIONAL_INVOICES_FORM_AFTER_SALE,
  ),
})

const mapDispatchToProps = dispatch => ({
  getInvoices: () => dispatch(getJobInvoicesList.request()),
  dispatchSetDisplayedInvoice: invoiceId =>
    dispatch(setDisplayedInvoice(invoiceId)),
  dispatchResetDisplayedInvoice: () => dispatch(setDisplayedInvoice(null)),
  displayTravelCompensationPurchaseInvoiceCreationDialog: () =>
    dispatch(
      doDisplayTravelCompensationPurchaseInvoiceCreationDialog({
        payload: { displayed: true },
      }),
    ),
  displayPurchaseInvoiceCreationDialog: () =>
    dispatch(
      doDisplayPurchaseInvoiceCreationDialog({
        payload: { displayed: true },
      }),
    ),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
)(JobDetailsInvoicesContainer)
