import { google } from 'config'
import ExternalLibrary from '../externalLibrary'

export const loadGoogleMapsAPI = async () => {
  const lang = 'fr'
  const country = 'FR'
  const params = [
    `key=${google.mapsKey}`,
    `language=${lang}`,
    `region=${country}`,
    'libraries=places',
  ]
  return ExternalLibrary(
    `https://maps.googleapis.com/maps/api/js?${params.join('&')}`,
  )
}
