import { GET_FIRM_COMMENTS_LIST } from './firmCommentActions'
import { initialState } from './firmCommentSelectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FIRM_COMMENTS_LIST.REQUEST:
      return {
        ...state,
        searchParams: action.params,
      }
    case GET_FIRM_COMMENTS_LIST.SUCCESS:
      return {
        ...state,
        searchResult: {
          list: action.payload['hydra:member'],
          totalItems: action.payload['hydra:totalItems'],
        },
      }
    default: {
      return state
    }
  }
}
