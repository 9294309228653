const styles = () => ({
  table: {
    margin: '1rem 0 0',
  },
  redText: {
    color: '#C6414D',
    fontWeight: '500',
  },
  newIncident: {
    '&:nth-child(even)': {
      backgroundColor: '#b53f3f38',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: '#f4eaea',
    },
  },
  solved: {
    '&:nth-child(even)': {
      backgroundColor: '#b1e3d0',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: '#c3f4e1',
    },
  },
  link: {
    cursor: 'pointer',
  },
  incidents: {
    display: 'block',
    padding: '12px',
    width: '100%',
  },
  icon: {
    height: '20px',
    width: '20px',
    display: 'inline-block',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
  },
})

export default styles
