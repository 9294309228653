import lodashPick from 'lodash/pick'
import { GET_SINGLE_WORKSITE } from './worksiteActions'
import { initialState } from './worksiteSelectors'
import {
  GET_SINGLE_JOB,
  JOB_PENDING_NEW_DATE_TRANSITION_REQ,
  JOB_REMATCHING_TRANSITION_REQ,
  PROCESS_JOB_START_TRANSITION_REQ,
  REFRESH_JOB,
  TO_CANCEL_TRANSITION_REQ,
  TO_DONE_TRANSITION_REQ,
  TO_PENDING_CANCELATION_TRANSITION_REQ,
  UPDATE_JOB_REQ,
} from '../jobs/jobActions'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_WORKSITE.REQUEST:
      return {
        ...state,
        currentWorksite: {
          worksiteId: action.worksiteId,
        },
      }
    case GET_SINGLE_WORKSITE.SUCCESS:
      return {
        ...state,
        currentWorksite: {
          ...state.currentWorksite,
          partnerWorksiteId: action.payload.partnerWorksiteId,
          status: action.payload.status,
          address: action.payload.address,
          order: action.payload.order,
          jobs: action.payload.jobs,
          partner: action.payload.partner,
        },
      }
    case UPDATE_JOB_REQ.SUCCESS:
    case GET_SINGLE_JOB.SUCCESS:
    case REFRESH_JOB.SUCCESS:
    case JOB_REMATCHING_TRANSITION_REQ.SUCCESS:
    case JOB_PENDING_NEW_DATE_TRANSITION_REQ.SUCCESS:
    case PROCESS_JOB_START_TRANSITION_REQ.SUCCESS:
    case TO_PENDING_CANCELATION_TRANSITION_REQ.SUCCESS:
    case TO_CANCEL_TRANSITION_REQ.SUCCESS:
    case TO_DONE_TRANSITION_REQ.SUCCESS:
      return {
        ...state,
        currentWorksite: {
          ...state.currentWorksite,
          ...lodashPick(
            action.payload.worksite,
            Object.keys(state.currentWorksite),
          ),
          jobs: state.currentWorksite.jobs?.map(j => {
            if (j['@id'] === action.payload['@id']) {
              return {
                ...j,
                ...lodashPick(action.payload, Object.keys(j)),
              }
            }
            return j
          }),
        },
      }
    default: {
      return state
    }
  }
}
