import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'

/**
 * TODO: move this component into shared folder after Tarek has merged his PR
 *
 * This component should be used instead of the FunctionField used in BO v1
 */
const FunctionTableCell = ({ render, ...props }) => (
  <TableCell {...props}>{render()}</TableCell>
)

FunctionTableCell.propTypes = {
  render: PropTypes.func.isRequired,
}

export default FunctionTableCell
