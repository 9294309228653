import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { TableCell, TableRow } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import SortableTableCell from 'components/shared/Tables/SortableTableCell'

const SaleInvoicesListTableHead = ({
  handleChangeSort,
  translate,
  orderBy,
  sort,
}) => (
  <TableRow>
    <TableCell>{translate('saleInvoice.order_number.label')}</TableCell>
    <TableCell>{translate('saleInvoice.number.label')}</TableCell>
    <SortableTableCell
      sortField="issuedAt"
      label={translate('saleInvoice.issued_at.label')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <TableCell>{translate('saleInvoice.store.label')}</TableCell>
    <TableCell>{translate('saleInvoice.pro.label')}</TableCell>
    <TableCell>{translate('saleInvoice.customer.label')}</TableCell>
    <TableCell>{translate('saleInvoice.package.label')}</TableCell>
    <TableCell>{translate('saleInvoice.time_slot.label')}</TableCell>
    <TableCell>{translate('saleInvoice.status.label')}</TableCell>
    <TableCell>{translate('saleInvoice.state_updated_at.label')}</TableCell>
    <TableCell>{translate('saleInvoice.subtotal.label')}</TableCell>
  </TableRow>
)

SaleInvoicesListTableHead.propTypes = {
  handleChangeSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
}

export default compose(i18n)(SaleInvoicesListTableHead)
