import { createSelector } from 'reselect'
import get from 'lodash/get'
import { CONSUMER_BACKOFFICE } from 'constants/features'
import { isFeatureFlagEnabled } from 'helpers/features'
import { formValueSelector } from 'redux-form'
import {
  DASHBOARD_SELECTED_ZONE,
  GEOZONES_SELECTOR_FORM,
} from 'constants/dashboard'
import { initialState } from 'store/Application/ApplicationReducer'
import { ACCESS_TOKEN, ID_TOKEN } from 'constants/storage'
import jwtDecode from 'jwt-decode'

const applicationSelector = state => get(state, 'application')

const featureFlagsSelector = state => get(state, 'featureFlags')

export const isReadySelector = createSelector(
  applicationSelector,
  application => get(application, 'isReady', false),
)

export const authLinkEmailSelector = createSelector(
  applicationSelector,
  application => get(application, 'authLinkEmail', false),
)

export const authLoginLoadingSelector = createSelector(
  applicationSelector,
  application => get(application, 'authLoginLoading', false),
)

export const isLoggedInSelector = createSelector(
  applicationSelector,
  application => get(application, 'loggedIn', false),
)

export const isLoadingSelector = createSelector(
  applicationSelector,
  application => Object.keys(get(application, 'isLoading', {})).length !== 0,
)

export const isNotifiedSelector = createSelector(
  applicationSelector,
  application => get(application, 'notification.isNotified'),
)

export const notificationMessageSelector = createSelector(
  applicationSelector,
  application => get(application, 'notification.message'),
)

export const messageTypeSelector = createSelector(
  applicationSelector,
  application => get(application, 'notification.messageType'),
)

export const getBarTitleSelector = createSelector(
  applicationSelector,
  application => get(application, 'barTitle'),
)

export const showStatusJobDetailsSelector = createSelector(
  applicationSelector,
  application => get(application, 'showJobDetailsStatus') || false,
)

export const scrollTopSelector = createSelector(
  applicationSelector,
  application => get(application, 'scrollTop'),
)

export const showGeozoneSelectorSelector = createSelector(
  applicationSelector,
  application => get(application, 'showGeozoneSelector'),
)

export const formValueGeozoneSelector = state =>
  formValueSelector(GEOZONES_SELECTOR_FORM)(state, 'geozones_list')

export const featureFlagSelector = createSelector(
  featureFlagsSelector,
  featureFlag => get(featureFlag, CONSUMER_BACKOFFICE),
)

export const isFeatureFlagEnabledSelector = createSelector(
  featureFlagSelector,
  (_, props) => props,
  (featureFlags, featureFlag) =>
    isFeatureFlagEnabled(featureFlags, featureFlag),
)

export const selectedGeozoneSelector = state => {
  const geozones =
    formValueGeozoneSelector(state) ||
    localStorage.getItem(DASHBOARD_SELECTED_ZONE) ||
    'all'

  return geozones
}

const selectIdToken = (state = initialState) =>
  applicationSelector(state)[ID_TOKEN]
const selectAccessToken = (state = initialState) =>
  applicationSelector(state)[ACCESS_TOKEN]

export const selectIdTokenDecoded = createSelector(selectIdToken, idToken =>
  idToken ? jwtDecode(idToken) : null,
)
export const selectAccessTokenDecoded = createSelector(
  selectAccessToken,
  accessToken => (accessToken ? jwtDecode(accessToken) : null),
)

export const selectIdTokenExpAt = createSelector(
  selectIdTokenDecoded,
  idTokenDecoded => idTokenDecoded?.exp,
)
