export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  buttonSecondary: theme.buttonSecondary,
})
