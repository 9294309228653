import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  Typography,
  withStyles,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core'
import Chip from 'components/shared/Chip/Chip'
import { RECEIPT_SIGNATURE_STATE_MANUAL, CERFA_BOTH } from 'constants/Jobs'
import UploaderBtn from 'components/shared/Uploader/UploaderBtn'
import CerfaFormContainer from './CerfaForm/CerfaFormContainer'
import CerfaList from './CerfaList/CerfaList'
import ReceiptInformations from '../ItemList/Receipt/ReceiptInformations'
import styles from './CerfaStyles'

const Cerfa = ({
  translate,
  classes,
  jobIri,
  dispatchCerfaSignature,
  cerfaSignature,
  cerfaSignatureStatus,
  isInProgressOrFinishedCerfa,
  isDocumentCreated,
  dispatchUploadJobCerfa,
  chipType,
  totalMember,
  membersWhoSigned,
  cerfaManualSignStatus,
}) => {
  useEffect(() => {
    if (!cerfaSignature && isInProgressOrFinishedCerfa) {
      // Checking if the cerfaSignature is generated (means that we have initiate YouSign procedure)
      dispatchCerfaSignature()
    }
  }, [cerfaSignature, dispatchCerfaSignature, isInProgressOrFinishedCerfa])

  const showCerfaAddBtn =
    (cerfaManualSignStatus.length === 1 &&
      cerfaManualSignStatus[0].type !== CERFA_BOTH) ||
    cerfaManualSignStatus.length < 1

  return (
    <Card>
      <CardContent>
        <Typography component="p" paragraph>
          {translate('resources.jobs.validateBills.cerfa.helpText')}
        </Typography>
        <Grid item xs={12}>
          {isDocumentCreated &&
            cerfaSignatureStatus !== RECEIPT_SIGNATURE_STATE_MANUAL && (
              <>
                <Typography className={classes.title}>
                  {translate('resources.jobs.fields.cerfa')}
                </Typography>
                <Chip
                  type={chipType}
                  label={translate(
                    `resources.jobs.validateBills.receipt.${cerfaSignatureStatus}`,
                  )}
                />
              </>
            )}
        </Grid>
        {cerfaSignatureStatus === RECEIPT_SIGNATURE_STATE_MANUAL && (
          <Grid item xs={12}>
            <CerfaList
              jobIri={jobIri}
              cerfaManualSignStatus={cerfaManualSignStatus}
              dispatchUploadJobCerfa={dispatchUploadJobCerfa}
            />
          </Grid>
        )}
        {isDocumentCreated && isInProgressOrFinishedCerfa && (
          <Grid item xs={12}>
            {/* TODO: refacto: move ReceiptInformations => components/Document/Informations */}
            <ReceiptInformations
              totalMember={totalMember}
              memberWhoSigned={membersWhoSigned}
              translate={translate}
              classes={classes}
              receiptSignature={cerfaSignature}
            />
          </Grid>
        )}
        {showCerfaAddBtn && (
          <Grid item xs={12} className={classes.btnContainer}>
            <UploaderBtn
              className={classes.buttonBigger}
              label={
                !cerfaManualSignStatus.length
                  ? translate('resources.jobs.validateBills.cerfa.upload')
                  : translate('resources.cerfa.againTitle')
              }
              title={translate('resources.cerfa.title')}
              onSuccess={() => null} // TODO
              onValidation={dispatchUploadJobCerfa}
              formOption={{
                specifiedCerfa: null,
                uploadedCerfa: cerfaManualSignStatus.map(cerfa => cerfa.type),
              }}
              FormComponent={CerfaFormContainer}
            />
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

Cerfa.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    btnContainer: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  jobIri: PropTypes.string.isRequired,
  cerfaManualSignStatus: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ).isRequired,
  dispatchCerfaSignature: PropTypes.func.isRequired,
  cerfaSignature: PropTypes.shape({}),
  cerfaSignatureStatus: PropTypes.string.isRequired,
  isInProgressOrFinishedCerfa: PropTypes.bool.isRequired,
  isDocumentCreated: PropTypes.bool.isRequired,
  dispatchUploadJobCerfa: PropTypes.func.isRequired,
  chipType: PropTypes.string.isRequired,
  totalMember: PropTypes.number.isRequired,
  membersWhoSigned: PropTypes.number.isRequired,
}

Cerfa.defaultProps = {
  cerfaSignature: null,
}

export default compose(withStyles(styles))(Cerfa)
