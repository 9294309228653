import { pick } from 'lodash'
import {
  FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE,
  FIELD_INVOICE_ITEM_NAME,
  FIELD_INVOICE_ITEM_QUANTITY,
  FIELD_INVOICE_ITEM_SUBTOTAL,
  FIELD_INVOICE_ITEM_UNIT_PRICE,
  FIELD_INVOICE_ITEM_VAT_RATE,
  FIELD_INVOICE_ITEMS,
  FIELD_INVOICE_ITEMS_FLAG,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER,
} from 'constants/invoices'
import {
  isVat,
  maxLength,
  required,
  requiredArray,
  isNumber,
  minValue,
  isInteger,
} from 'helpers/form/validationHelpers'
import { applyValidationRules } from 'helpers/utils/validate'

export const validate = (values, props) => {
  const { legalStatus } = values

  // Define here the rules which must be applied for this form
  const fieldsWithRules = {
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY]: [required],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS]: [required],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER]: [required],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET]: [required, maxLength(200)],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY]: [required, maxLength(50)],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE]: [required, maxLength(10)],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET]: [
      required,
      maxLength(200),
    ],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY]: [
      required,
      maxLength(50),
    ],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE]: [
      required,
      maxLength(10),
    ],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER]: [
      required,
      isVat,
      maxLength(50),
    ],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME]: [required, maxLength(50)],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME]: [required, maxLength(50)],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY]: [
      required,
      maxLength(50),
    ],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT]: [required],
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT]: [required],
    [FIELD_INVOICE_ITEMS]: [requiredArray],
  }

  const itemsFieldsWithRules = {
    [FIELD_INVOICE_ITEM_NAME]: [required],
    [FIELD_INVOICE_ITEM_QUANTITY]: [required, isNumber, minValue(1), isInteger],
    [FIELD_INVOICE_ITEM_UNIT_PRICE]: [required, isNumber, minValue(0)],
    [FIELD_INVOICE_ITEM_SUBTOTAL]: [required, isNumber, minValue(0)],
    [FIELD_INVOICE_ITEM_VAT_RATE]: [required, isNumber, minValue(0)],
  }

  // Apply these rules for all fields
  const errors = applyValidationRules(fieldsWithRules, values, props)

  let fieldsToVerify

  // Keep only usefull rules
  switch (legalStatus) {
    case undefined:
    case null:
    case '':
      fieldsToVerify = [FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS]
      break
    case 'EI':
      fieldsToVerify = [
        FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME,
      ]
      break
    default:
      fieldsToVerify = [
        FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE,
        FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER,
      ]
      break
  }

  // Add common rules
  fieldsToVerify.push(
    FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT,
    FIELD_INVOICE_ITEMS,
    FIELD_INVOICE_ITEMS_FLAG,
  )

  // Manage items validation
  if (errors[FIELD_INVOICE_ITEMS]) {
    errors[FIELD_INVOICE_ITEMS_FLAG] = props.translate(
      'resources.invoices.validation.items',
    )
    errors[FIELD_INVOICE_ITEMS] = undefined
  } else {
    errors[FIELD_INVOICE_ITEMS] = []
    values[FIELD_INVOICE_ITEMS].forEach((item, index) => {
      errors[FIELD_INVOICE_ITEMS][index] = applyValidationRules(
        itemsFieldsWithRules,
        item,
        props,
      )
    })
  }

  return pick(errors, fieldsToVerify)
}
