import { memo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { change, reduxForm, reset, submit, getFormValues } from 'redux-form'
import { withStyles } from '@material-ui/core'

import { postClientData } from 'store/jobs/jobActions'
import {
  currentJobSelector,
  formattedJobIdSelector,
} from 'store/jobs/jobSelectors'
import { EDIT_CLIENT_INTERVENTION_DATA_FORM } from 'constants/forms'
import i18n from 'providers/i18n/I18nProvider'
import styles from './JobDetailsClientFormStyles'
import JobDetailsClientForm from './JobDetailsClientForm'

const mapStateToProps = (
  state,
  {
    additionalInformation,
    street,
    postCode,
    city,
    elevator,
    type,
    parking,
    phoneNumber,
  },
) => ({
  formValues: getFormValues(EDIT_CLIENT_INTERVENTION_DATA_FORM)(state),
  currentJob: currentJobSelector(state),
  jobId: formattedJobIdSelector(state),
  initialValues: {
    address:
      street && postCode && city ? `${street} ${postCode} ${city}` : null,
    street,
    postCode,
    city,
    additionalInformation,
    elevator,
    type,
    parking,
    phoneNumber,
  },
})

const mapDispatchToProps = dispatch => ({
  submitFormDispatch: () =>
    dispatch(submit(EDIT_CLIENT_INTERVENTION_DATA_FORM)),
  changeFieldValue: (field, value) =>
    dispatch(change(EDIT_CLIENT_INTERVENTION_DATA_FORM, field, value)),
})

export default compose(
  i18n,
  withStyles(styles),
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: EDIT_CLIENT_INTERVENTION_DATA_FORM,
    enableReinitialize: true,
    asyncBlurFields: ['address'],
    onSubmit: (data, dispatch, ownProps) => {
      dispatch(
        postClientData.request({
          data,
          orderId: ownProps.jobId,
          initialValues: ownProps.initialValues,
        }),
      )
    },
    onSubmitSuccess: (result, dispatch) => {
      dispatch(reset(EDIT_CLIENT_INTERVENTION_DATA_FORM))
    },
  }),
)(JobDetailsClientForm)
