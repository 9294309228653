import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  Fade,
  Paper,
  Popper as BasePopper,
  withStyles,
} from '@material-ui/core'
import styles from './PopperStyles'

const Popper = ({ classes, open, anchorEl, children }) => (
  <BasePopper open={open} anchorEl={anchorEl} transition>
    {({ TransitionProps }) => (
      <Fade {...TransitionProps} timeout={350}>
        <Paper className={classes.boxShadow}>
          <div className={classes.content}>{children}</div>
        </Paper>
      </Fade>
    )}
  </BasePopper>
)

Popper.propTypes = {
  classes: PropTypes.shape({
    boxShadow: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
}

Popper.defaultProps = {
  anchorEl: null,
}

export default compose(withStyles(styles))(Popper)
