export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
  },
  selectEmpty: {
    fontSize: 'auto',
    minWidth: '180px',
    width: 'auto',
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
})
