import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import Pdf from 'components/shared/Pdf/Pdf'
import { getCerfaJob } from 'store/jobs/jobActions'
import { cerfaJobSelector } from 'store/jobs/jobSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'

const CerfaViewer = ({ cerfa, isLoading, getCerfa, translate }) => {
  useEffect(() => {
    getCerfa()
  }, [getCerfa])

  return (
    cerfa.content &&
    !isLoading && (
      <Pdf
        file={`data:application/pdf;base64,${cerfa.content}`}
        pageNumber={1}
        downloadFilename={`${translate('resources.jobs.fields.cerfa')}.pdf`}
        loading={isLoading}
      />
    )
  )
}

CerfaViewer.defaultProps = {
  cerfa: {
    name: null,
    content: null,
  },
}

CerfaViewer.propTypes = {
  cerfa: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  }),
  getCerfa: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  cerfa: cerfaJobSelector(state),
  isLoading: isLoadingSelector(state),
})

const mapDispatchToProps = (dispatch, { jobIri, cerfaType }) => ({
  getCerfa: () => dispatch(getCerfaJob.request({ jobIri, cerfaType })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
)(CerfaViewer)
