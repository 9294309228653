import React, { useState, useMemo } from 'react'
import { compose } from 'redux'
import uuidv1 from 'uuid/v1'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'

const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50]

/**
 * @param {array} columns Columns of table
 * @param {array} data Data record array to be displayed
 * @param {Int} rowsPerPage number of rows per page
 * @param {Int} dataLength total number of rows
 * @param {func} handleChangePage Callback executed when page changes
 * @param {func} handleChangeRowsPerPage Callback executed when number of rows per page changes
 * @param {func} applyRowClassName function which takes a record as param and returns a className
 * @param {bool} pagination Pagination config, hide it by setting it to false
 */
const DataTable = ({
  columns,
  data,
  rowsPerPage,
  dataLength,
  handleChangePage,
  handleChangeRowsPerPage,
  applyRowClassName,
  tableWrapperClassName,
  pagination,
  translate,
}) => {
  const [page, setPage] = useState(0)
  const [nbRowsPerPage, setNbRowsPerPage] = useState(rowsPerPage)

  const dataInPage = useMemo(
    () =>
      pagination && data.length > nbRowsPerPage
        ? data.slice(page * nbRowsPerPage, (page + 1) * nbRowsPerPage)
        : data,
    [data, page, nbRowsPerPage, pagination],
  )

  const onChangePage = (event, pageValue) => {
    setPage(pageValue)
    handleChangePage(event, pageValue)
  }

  const onChangeRowsPerPage = event => {
    setNbRowsPerPage(event.target.value)
    handleChangeRowsPerPage(event)
  }

  return (
    <div className={tableWrapperClassName}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ key, title }) => (
              <TableCell component="th" scope="row" key={key}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataInPage.map(item => (
            <TableRow className={applyRowClassName(item)} hover key={uuidv1()}>
              {columns.map(({ render }) => (
                <TableCell key={uuidv1()} component="th" scope="row">
                  {render(item)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          classes={{
            root: 'paginationRoot',
            menuItem: 'paginationMenuItem',
            selectRoot: 'paginationSelectRoot',
            actions: 'paginationActions',
          }}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={dataLength || data.length}
          rowsPerPage={nbRowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={translate('table.navigation.page_rows_per_page')}
          labelDisplayedRows={({ from, to, count }) =>
            translate('navigation.page_range_info', {
              offsetBegin: from,
              offsetEnd: to,
              total: count,
            })
          }
        />
      )}
    </div>
  )
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowsPerPage: PropTypes.number,
  dataLength: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  applyRowClassName: PropTypes.func,
  tableWrapperClassName: PropTypes.string,
  translate: PropTypes.func.isRequired,
  pagination: PropTypes.bool,
}

DataTable.defaultProps = {
  rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
  dataLength: null,
  pagination: true,
  tableWrapperClassName: '',
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {},
  applyRowClassName: () => {},
}

export default compose(i18n)(DataTable)
