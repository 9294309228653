import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  Typography,
  Button,
  CircularProgress,
  withStyles,
} from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import { dateFormatter } from 'helpers/date'
import { formatIriToId } from 'helpers/utils/common'
import DataTable from 'components/shared/Tables/DataTable'
import styles from './JobMessagingStyles'
import JobMessageDialogContainer from './JobMessageDialog/JobMessageDialogContainer'

class JobMessaging extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentConversationId: null,
      dialogConversationOpened: false,
    }
  }

  componentDidMount() {
    const { getConversations, jobId } = this.props
    getConversations(jobId)
  }

  render() {
    const {
      classes,
      translate,
      conversationsLoading,
      conversationList,
    } = this.props

    if (conversationsLoading) {
      return <CircularProgress size={20} />
    }

    if (!conversationList.length) {
      return (
        <Typography>
          {translate('app.message.no_result_conversations')}
        </Typography>
      )
    }

    const toggleConversationDialog = () => {
      this.setState(prevState => ({
        dialogConversationOpened: !prevState.dialogConversationOpened,
      }))
    }

    const handleClickForConversation = convIri => {
      this.setState({ currentConversationId: formatIriToId(convIri) })
      toggleConversationDialog()
    }

    const columns = [
      {
        title: translate('job.job_conversations.col_headers.status'),
        render: ({ endedAt }) =>
          endedAt
            ? translate('job.job_conversations.status.ended')
            : translate('job.job_conversations.status.in_progress'),
        key: 'status',
      },
      {
        title: translate('job.job_conversations.col_headers.pro_name'),
        render: ({ proFirstName, proLastName }) =>
          `${proLastName.toUpperCase()} ${proFirstName}`,
        key: 'name',
      },
      {
        title: translate('job.job_conversations.col_headers.starting_date'),
        render: ({ startedAt }) =>
          dateFormatter(startedAt, translate('app.date_format.long'), true),
        key: 'startedAt',
      },
      {
        title: '',
        render: conversation => (
          <Button
            className={classes.button}
            color="primary"
            onClick={() => handleClickForConversation(conversation['@id'])}
          >
            {translate('job.job_conversations.cta.view_conv')}
          </Button>
        ),
        key: 'viewConv',
      },
    ]

    const { dialogConversationOpened, currentConversationId } = this.state

    return (
      <>
        <DataTable
          tableWrapperClassName={classes.table}
          columns={columns}
          data={conversationList.sort((convA, convB) =>
            convA.startedAt > convB.startedAt ? 1 : 0,
          )}
        />
        {dialogConversationOpened && (
          <JobMessageDialogContainer
            conversationId={currentConversationId}
            onClose={toggleConversationDialog}
          />
        )}
      </>
    )
  }
}

JobMessaging.propTypes = {
  translate: PropTypes.func.isRequired,
  getConversations: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    table: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  conversationList: PropTypes.arrayOf(PropTypes.shape({})),
  conversationsLoading: PropTypes.bool.isRequired,
  jobId: PropTypes.string.isRequired,
}

JobMessaging.defaultProps = {
  conversationList: [],
}

export default compose(i18n, withStyles(styles))(JobMessaging)
