import { all, call, select, takeLatest } from 'redux-saga/effects'

import { handleRequest } from 'helpers/store/sagasHelpers'
import client from 'services/httpClient/engineClient'

import { GET_RESERVE_TYPES_REQ, getReserveTypesReq } from './reservesActions'
import { hasReserveTypesSelector } from './reservesSelectors'

export function* handleGetReserveTypeRequest() {
  const hasRecords = yield select(hasReserveTypesSelector)

  if (!hasRecords) {
    try {
      yield* handleRequest({
        requestActions: getReserveTypesReq,
        promise: call(client.get, 'jobs/receipt/reserves-types'),
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export default function*() {
  yield all([
    takeLatest(GET_RESERVE_TYPES_REQ.REQUEST, handleGetReserveTypeRequest),
  ])
}
