import { all, call, select, takeLatest } from 'redux-saga/effects'

import { handleRequest } from 'helpers/store/sagasHelpers'
import client from 'services/httpClient/syliusClient'

import { GET_DEPARTMENTS_LIST, getDepartmentsList } from './departmentActions'
import { hasDepartmentsSelector } from './departmentSelectors'

export function* handleReadDepartmentListRequest() {
  const hasRecords = yield select(hasDepartmentsSelector)

  if (!hasRecords) {
    try {
      yield* handleRequest({
        requestActions: getDepartmentsList,
        promise: call(
          client.get,
          '/shop-api/departments?sorting[code]=asc&criteria[enabled]=true',
        ),
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export default function*() {
  yield all([
    takeLatest(GET_DEPARTMENTS_LIST.REQUEST, handleReadDepartmentListRequest),
  ])
}
