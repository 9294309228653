const styles = theme => ({
  jobInProgressUtilities: {
    padding: '16px',
  },
  jobInProgressUtilitiesHeader: {
    color: '#143C58',
    fontSize: '1.2rem',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    lineHeight: 1.5,
    marginBottom: '16px',
  },
  buttonSecondary: theme.buttonSecondary,
  buttonBigger: theme.buttonBigger,
})

export default styles
