import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { FILTER_JOBS_LIST_FORM } from 'constants/forms'
import { PERM_JOB_EXPORT } from 'constants/permissions'
import { hasPermission } from 'services/permissions'
import { reduxForm, Field } from 'redux-form'
import { Button, FormControl, Grid, withStyles } from '@material-ui/core'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import SelectFieldWithIcon from 'components/shared/Form/SelectFieldWithIcon'
import { maxLength, isNumber } from 'helpers/form/validationHelpers'
import { SEARCH } from 'constants/Jobs'
import RenderDepartmentsMultiSelect from 'components/shared/Form/RenderDepartmentsMultiSelect'
import RenderCheckbox from 'components/shared/Form/RenderCheckbox'
import RenderSelectMultipleAutocompleteField from 'components/shared/Form/RenderSelectMultipleAutocompleteField'
import Icon from 'components/shared/Icon/Icon'
import styles from './JobsSearchFiltersStyles'

const JobsSearchFilters = ({
  classes,
  translate,
  handleSubmit,
  handleReset,
  handleExport,
  storeCodes,
  packageList,
  partnerList,
  getStoreList,
  orderStatus,
  incidentStatuses,
  incidentWaitingForList,
  initialValues,
  dashboardSearchParams,
  departments,
  reserveTypes,
  isClientReservesEnabled,
}) => {
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    dashboardSearchParams.forceSubmit ? handleSubmit() : null
  }, [dashboardSearchParams, handleSubmit, initialValues])

  const packagesOptions = useMemo(
    () =>
      Object.keys(packageList).map(id => ({
        label: `${packageList[id].categories[0]} - ${packageList[id].name}`,
        value: packageList[id].code,
        icon: packageList[id].required_certificate,
      })),
    [packageList],
  )

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Grid container>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              name="job"
              type="search"
              component={RenderTextField}
              label={translate('resources.jobs.filter.job')}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              name="pro"
              type="search"
              component={RenderTextField}
              label={translate('resources.jobs.filter.pro')}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              name="lastName"
              type="search"
              component={RenderTextField}
              label={translate('resources.jobs.filter.lastName')}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              name="phoneNumber"
              type="search"
              component={RenderTextField}
              label={translate('resources.jobs.filter.ho_phone_number')}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              name="postCode"
              type="search"
              validate={[maxLength(5), isNumber]}
              component={RenderTextField}
              label={translate('resources.jobs.filter.postal_code')}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              className={classes.selectEmpty}
              name="jobStatus"
              id="jobStatusSelect"
              choices={orderStatus}
              component={RenderSelectField}
              label={translate('resources.jobs.filter.order_status')}
              optionValue="key"
              optionText={({ key }) =>
                translate(`resources.jobs.search_status.${key}`)
              }
              allowEmpty
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              name="minDateHo"
              type="date"
              component={RenderTextField}
              label={translate('resources.jobs.filter.date_ho')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              name="minDatePro"
              type="date"
              component={RenderTextField}
              label={translate('resources.jobs.filter.scheduling_date')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              className={classes.selectEmpty}
              name="partner"
              id="partnerSelect"
              choices={partnerList}
              component={RenderSelectField}
              onChange={e => {
                getStoreList(e.target.value)
              }}
              label={translate('resources.jobs.filter.partner')}
              optionValue="value"
              optionText="label"
              allowEmpty
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              className={classes.selectEmpty}
              name="storeCode"
              id="storeCodeSelect"
              choices={storeCodes}
              component={RenderSelectField}
              label={translate('resources.jobs.filter.stores')}
              optionValue="code"
              optionText="name"
              allowEmpty
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              name="postCodes"
              id="departmentsSelector"
              label={translate('resources.jobs.filter.interventionArea')}
              component={RenderDepartmentsMultiSelect}
              translate={translate}
              departments={departments}
              initialValues={initialValues.postCodes}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              className={classes.selectEmpty}
              name="packageType"
              id="packageTypeSelect"
              choices={packagesOptions}
              component={SelectFieldWithIcon}
              inputLabel={translate('resources.jobs.filter.package_type')}
              allowEmpty
            />
          </FormControl>
        </Grid>
        {incidentStatuses.length > 0 && (
          <Grid item className={classes.inputSizing}>
            <FormControl className={classes.formControl}>
              <Field
                className={classes.selectEmpty}
                name="incidentStatus"
                id="incidentStatusSelect"
                component={RenderSelectField}
                label={translate('resources.jobs.filter.incident_status')}
                choices={incidentStatuses}
                optionValue="key"
                optionText="value"
                allowEmpty
              />
            </FormControl>
          </Grid>
        )}
        {incidentWaitingForList.length > 0 && (
          <Grid item className={classes.inputSizing}>
            <FormControl className={classes.formControl}>
              <Field
                className={classes.selectEmpty}
                name="incidentWaitingFor"
                id="waitingForSelect"
                component={RenderSelectField}
                label={translate('resources.jobs.filter.incident_waiting_for')}
                choices={incidentWaitingForList}
                optionValue="key"
                optionText="value"
                allowEmpty
              />
            </FormControl>
          </Grid>
        )}
        <Grid item className={classes.inputSizing}>
          <FormControl className={classes.formControl}>
            <Field
              className={classes.fullWidth}
              name={
                isClientReservesEnabled
                  ? 'reserveType'
                  : 'withReceiptWithReserves'
              }
              id={
                isClientReservesEnabled
                  ? 'reserveType'
                  : 'withReceiptWithReserves'
              }
              component={
                isClientReservesEnabled
                  ? RenderSelectMultipleAutocompleteField
                  : RenderCheckbox
              }
              label={translate(
                'resources.jobs.filter.with_receipt_with_reserves',
              )}
              choices={reserveTypes}
              optionValue="code"
              optionText="label"
              allowEmpty
            />
          </FormControl>
        </Grid>
        <Grid container justifyContent="flex-start">
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.buttonSearch}
            >
              {translate('pos.search')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => handleReset(FILTER_JOBS_LIST_FORM)}
              variant="contained"
              color="default"
              type="reset"
              className={classes.buttonReload}
            >
              {translate('app.message.reset')}
            </Button>
          </Grid>
          <Grid item className={classes.blocButtonExport}>
            <Button
              onClick={() => handleExport(FILTER_JOBS_LIST_FORM)}
              variant="contained"
              color="default"
              className={classes.buttonExport}
              disabled={!hasPermission(PERM_JOB_EXPORT)}
            >
              <Icon className={classes.imgDownload} icon="download.svg" />
              {translate('app.message.export')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

JobsSearchFilters.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    inputSizing: PropTypes.string.isRequired,
    formControl: PropTypes.string.isRequired,
    selectEmpty: PropTypes.string.isRequired,
    selectDepartement: PropTypes.string.isRequired,
    fullWidth: PropTypes.string.isRequired,
    buttonSearch: PropTypes.string.isRequired,
    buttonReload: PropTypes.string.isRequired,
    blocButtonExport: PropTypes.string.isRequired,
    buttonExport: PropTypes.string.isRequired,
    imgDownload: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  getStoreList: PropTypes.func.isRequired,
  storeCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  packageList: PropTypes.shape({}).isRequired,
  partnerList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  incidentStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  incidentWaitingForList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialValues: PropTypes.shape({
    postCodes: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  dashboardSearchParams: PropTypes.shape({
    jobStatus: PropTypes.string,
    reserveType: PropTypes.string,
    withReceiptWithReserves: PropTypes.bool,
    forceSubmit: PropTypes.bool,
    incidentStatus: PropTypes.string,
    incidentWaitingFor: PropTypes.string,
    postCodes: PropTypes.arrayOf(PropTypes.string),
    pro: PropTypes.string,
  }).isRequired,
  departments: PropTypes.shape({}).isRequired,
  reserveTypes: PropTypes.shape({}).isRequired,
  isClientReservesEnabled: PropTypes.string.isRequired,
}

const formConfig = {
  form: FILTER_JOBS_LIST_FORM,
  enableReinitialize: true,
  destroyOnUnmount: false,
  onSubmit: (values, dispatch, { handleSearchChange }) => {
    handleSearchChange({
      ...values,
      jobStatus: values.jobStatus ? values.jobStatus : SEARCH,
    })
  },
}

export default compose(
  i18n,
  reduxForm(formConfig),
  withStyles(styles),
)(JobsSearchFilters)
