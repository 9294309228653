import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field, reset } from 'redux-form'
import i18n from 'providers/i18n/I18nProvider'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { ADD_FIRM_COMMENT_FORM } from 'constants/forms'
import { FIRM_COMMENT_TYPE } from 'constants/firms'
import { dateFormatter } from 'helpers/date'
import styles from './FirmCommentsStyles'

const FirmComments = ({
  classes,
  translate,
  firmCommentsSearchParams,
  firmCommentsSearchResult,
  handleChangePage,
  handleChangeRowsPerPage,
  setLabelDisplayedRows,
  handleSubmit,
}) => (
  <div className={classes.history}>
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            name="firmComment"
            component="input"
            type="textarea"
            placeholder={translate('resources.jobs.comment.placeholder')}
            value=""
            className={classes.inputText}
          />
        </Grid>
      </Grid>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              {translate('resources.firms.fields.comments_date')}
            </TableCell>
            <TableCell>
              {translate('resources.firms.fields.comments_author')}
            </TableCell>
            <TableCell align="center">
              {translate('resources.firms.fields.comments_comment')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {firmCommentsSearchResult.list.map(row => {
            const { createdAt, author, comment, type } = row
            return (
              <TableRow
                key={row['@id']}
                className={classNames({
                  [classes.statusChange]:
                    type === FIRM_COMMENT_TYPE.STATUS_CHANGE,
                })}
              >
                <TableCell align="center" className={classes.tdDate}>
                  {dateFormatter(createdAt, translate('app.date_format.long'))}
                </TableCell>
                <TableCell>{author}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.multiLine}
                  align="left"
                >
                  {comment}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={firmCommentsSearchResult.totalItems}
        rowsPerPage={firmCommentsSearchParams.perPage}
        page={firmCommentsSearchParams.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={translate('navigation.page_rows_per_page')}
        labelDisplayedRows={setLabelDisplayedRows}
      />
    </form>
  </div>
)

FirmComments.propTypes = {
  classes: PropTypes.shape({
    multiLine: PropTypes.string.isRequired,
    history: PropTypes.string.isRequired,
    tdDate: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    inputText: PropTypes.string.isRequired,
    statusChange: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  firmCommentsSearchParams: PropTypes.shape({
    perPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
  }).isRequired,
  firmCommentsSearchResult: PropTypes.shape({
    totalItems: PropTypes.number.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        author: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
        '@id': PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  setLabelDisplayedRows: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
}

const afterSubmit = (result, dispatch) => dispatch(reset(ADD_FIRM_COMMENT_FORM))

export default compose(
  reduxForm({
    form: ADD_FIRM_COMMENT_FORM,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmitSuccess: afterSubmit,
  }),
  withStyles(styles),
  i18n,
)(FirmComments)
