import { Typography } from '@material-ui/core'
import React from 'react'
import { PRO_DOCUMENT_UPLOAD_SIZE_LIMIT } from 'constants/documents'
import { toString } from 'helpers/Uploader/acceptedFiles'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import Style from './UploaderRequirementsStyle'

const UploaderRequirements = ({ acceptedFiles, translate = () => {} }) => (
  <>
    <Typography paragraph style={Style.title}>
      {translate('resources.receipt.file.requirements.title')}
    </Typography>
    <ul style={Style.ul}>
      <li>
        {translate('resources.receipt.file.requirements.one', {
          size: PRO_DOCUMENT_UPLOAD_SIZE_LIMIT / 1000000,
        })}
      </li>
      <li>
        {translate('resources.receipt.file.requirements.two', {
          filetypes: toString(acceptedFiles),
        })}
      </li>
    </ul>
  </>
)

UploaderRequirements.propTypes = {
  acceptedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ).isRequired,
  translate: PropTypes.func.isRequired,
}

export default compose(i18n)(UploaderRequirements)
