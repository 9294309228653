import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'
import { compose } from 'redux'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import NoResult from 'components/NoResult/NoResult'
import i18n from 'providers/i18n/I18nProvider'
import styles from './SimpleTableStyles'

const SimpleTable = ({
  additionalProps,
  classes,
  translate,
  rows,
  onClick,
  orderBy,
  page,
  sort,
  total,
  isLoading,
  handleChangePage,
  rowsPerPage,
  handleChangePerPage,
  handleChangeSort,
  TableHeadContent,
  TableBodyContent,
}) => {
  if (rows.length === 0 && !isLoading) {
    return <NoResult />
  }

  return (
    <>
      <Typography
        component="h1"
        variant="h6"
        color="primary"
        className={classes.results}
      >
        {total === 0 && translate('app.message.noResult')}
        {total > 0 && translate('app.message.nbResult', { total })}
      </Typography>

      <Table className={classes.table}>
        <TableHead>
          <TableHeadContent
            handleChangeSort={handleChangeSort}
            orderBy={orderBy}
            sort={sort}
            additionalProps={additionalProps}
          />
        </TableHead>
        <TableBody>
          <TableBodyContent
            rows={rows}
            onClick={onClick}
            additionalProps={additionalProps}
          />
        </TableBody>
      </Table>
      <TablePagination
        classes={{
          root: 'paginationRoot',
          menuItem: 'paginationMenuItem',
          selectRoot: 'paginationSelectRoot',
          actions: 'paginationActions',
        }}
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangePerPage}
        labelRowsPerPage={translate('table.navigation.page_rows_per_page')}
        labelDisplayedRows={({ from, to, count }) =>
          translate('navigation.page_range_info', {
            offsetBegin: from,
            offsetEnd: to,
            total: count,
          })
        }
      />
    </>
  )
}

SimpleTable.propTypes = {
  additionalProps: PropTypes.shape({}),
  classes: PropTypes.shape({
    results: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderBy: PropTypes.string,
  page: PropTypes.number.isRequired,
  sort: PropTypes.string,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  handleChangePage: PropTypes.func.isRequired,
  handleChangePerPage: PropTypes.func.isRequired,
  handleChangeSort: PropTypes.func,
  rowsPerPage: PropTypes.number.isRequired,
  TableHeadContent: PropTypes.func.isRequired,
  TableBodyContent: PropTypes.func.isRequired,
}

SimpleTable.defaultProps = {
  additionalProps: {},
  handleChangeSort: () => {},
  onClick: () => {},
  orderBy: null,
  sort: 'asc',
  isLoading: false,
}

export default compose(i18n, withStyles(styles))(SimpleTable)
