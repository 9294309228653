import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import uuidv1 from 'uuid/v1'
import { dateFormatter } from 'helpers/date'
import { DATE_FORMAT } from 'constants/date'
import { convertCentsToEuros } from 'helpers/utils/common'
import {
  getDisplayedState,
  getInvoiceIdFromIri,
} from 'helpers/utils/invoice/invoice'
import { buildJobIri, getPartnerIconUrl } from 'helpers/utils/job/job'
import { TableCell, TableRow, CardMedia, withStyles } from '@material-ui/core'
import UiLink from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import { JOB_PATH_PREFIX } from 'constants/routes'
import i18n from 'providers/i18n/I18nProvider'
import styles from './AllInvoicesListStyles'

const AllInvoicesListTableBody = ({
  rows,
  translate,
  classes,
  dispatchSetDisplayedInvoice,
}) =>
  rows.map(row => {
    const {
      number,
      partnerOrderNumber,
      state,
      stateUpdatedAt,
      jobId,
      store,
      issuedAt,
      proName,
      homeOwnerName,
      amountExcludingTaxes,
      partner,
    } = row

    const link = `${JOB_PATH_PREFIX}/${encodeURIComponent(
      buildJobIri(jobId),
    )}/show`

    const partnerUrl = getPartnerIconUrl(partner ? partner.id : null)

    return (
      <TableRow key={uuidv1()}>
        <TableCell>
          <div className={classes.jobOrderIdContainer}>
            <div>
              {partnerUrl ? (
                <CardMedia className={classes.imgPartner} image={partnerUrl} />
              ) : (
                <div className={classes.partnerLabel}>
                  {partner && partner.label.substring(0, 3)}
                </div>
              )}
            </div>
            <div>
              <UiLink
                href="#"
                onClick={event => {
                  event.preventDefault()
                  dispatchSetDisplayedInvoice(getInvoiceIdFromIri(row['@id']))
                }}
                className={classes.menuLink}
              >
                {number || translate('invoice.proforma.label')}
              </UiLink>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <Link to={link} className={classes.menuLink}>
            {partnerOrderNumber}
          </Link>
        </TableCell>
        <TableCell>{jobId}</TableCell>
        <TableCell>{`${store.name} (${store.codeAccount})`}</TableCell>
        <TableCell>{proName}</TableCell>
        <TableCell>{homeOwnerName}</TableCell>
        <TableCell>{convertCentsToEuros(amountExcludingTaxes)} €</TableCell>
        <TableCell>{dateFormatter(issuedAt, DATE_FORMAT)}</TableCell>
        <TableCell>
          {translate(
            `invoice.state.${getDisplayedState(state, stateUpdatedAt)}`,
          )}
        </TableCell>
        <TableCell>{dateFormatter(stateUpdatedAt, DATE_FORMAT)}</TableCell>
      </TableRow>
    )
  })

AllInvoicesListTableBody.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatchSetDisplayedInvoice: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string,
      proName: PropTypes.string.isRequired,
      homeOwnerName: PropTypes.string.isRequired,
      orderNumber: PropTypes.string.isRequired,
      amountExcludingTaxes: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
      stateUpdatedAt: PropTypes.string.isRequired,
      jobId: PropTypes.string.isRequired,
      store: PropTypes.shape({
        name: PropTypes.string.isRequired,
        codeAccount: PropTypes.string.isRequired,
      }).isRequired,
      issuedAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default compose(i18n, withStyles(styles))(AllInvoicesListTableBody)
