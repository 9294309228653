import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import { dateFormatter } from 'helpers/date'
import uuidv1 from 'uuid/v1'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import { HOUR_FORMAT } from 'constants/date'

const styles = () => ({
  strikeTimeslots: {
    textDecorationLine: 'line-through',
  },
})

const JobTimeslotTable = ({ classes, timeslots, translate }) => {
  const dateFormat = translate('app.date_format.textual')

  return timeslots.length > 0 ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row" key="timeslotDateLabel">
            {translate('resources.jobs.fields.timeslot_date')}
          </TableCell>
          <TableCell component="th" scope="row" key="timeslotRangeLabel">
            {translate('resources.jobs.fields.timeslot_range')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {timeslots.map(timeslot => (
          <TableRow
            key={uuidv1()}
            className={timeslot.enabled ? null : classes.strikeTimeslots}
          >
            <TableCell component="td" scope="row">
              {dateFormatter(timeslot.startTime, dateFormat)}
            </TableCell>
            <TableCell component="td" scope="row">
              {`${translate('resources.jobs.fields.between')} ${dateFormatter(
                timeslot.startTime,
                HOUR_FORMAT,
              )} ${translate('resources.jobs.fields.and')} ${dateFormatter(
                timeslot.stopTime,
                HOUR_FORMAT,
              )}`}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  ) : (
    <Typography variant="body2" gutterBottom>
      {translate('resources.customers.values.noInfo')}
    </Typography>
  )
}

JobTimeslotTable.propTypes = {
  classes: PropTypes.shape({
    strikeTimeslots: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  timeslots: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      stopTime: PropTypes.string,
    }),
  ),
}

JobTimeslotTable.defaultProps = {
  timeslots: [],
}

export default compose(i18n, withStyles(styles))(JobTimeslotTable)
