import PropTypes from 'prop-types'
import React from 'react'
import JobDetailsIncidentsPopin from 'components/Jobs/JobDetails/JobDetailsIncidents/JobDetailsIncidentsPopin'
import StatusChangeConfirmDialogContainer from 'components/Jobs/JobDetails/JobDetailsIncidents/StatusChangeConfirmDialog/ConfirmDialog/StatusChangeConfirmDialogContainer'

const Dialogs = ({ displayedDialogs, hideRespSelectDialog }) => (
  <>
    <JobDetailsIncidentsPopin
      displayed={displayedDialogs.respSelect}
      handleClose={hideRespSelectDialog}
    />
    {displayedDialogs.statusChangeConfirm && (
      <StatusChangeConfirmDialogContainer />
    )}
  </>
)

Dialogs.propTypes = {
  displayedDialogs: PropTypes.shape({
    respSelect: PropTypes.bool.isRequired,
    statusChangeConfirm: PropTypes.bool.isRequired,
  }).isRequired,
  hideRespSelectDialog: PropTypes.func.isRequired,
}

export default Dialogs
