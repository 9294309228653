import React, { useState } from 'react'
import { Button, Link, Typography, withStyles } from '@material-ui/core'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import styles from './GenerateSupportingDocumentsStyles'

const GenerateSupportingDocuments = ({
  jobIri,
  translate,
  classes,
  generateSupportingDocuments,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)

  const handleResponse = ({ currentTarget: { value } }) => {
    if (value === 'true') {
      generateSupportingDocuments(jobIri)
    }
    setDialogOpened(!confirmDialogOpened)
  }

  return (
    <>
      <Button
        name="generateSupportingDocuments"
        className={classes.buttonBigger}
        variant="contained"
        color="secondary"
        component={Link}
        onClick={() => setDialogOpened(true)}
      >
        {translate('job.generate_supporting_documents.button')}
      </Button>
      {confirmDialogOpened && (
        <ConfirmDialog
          title={translate(
            'job.generate_supporting_documents.confirm_popin.title',
          )}
          onClose={handleResponse}
        >
          <Typography component="p">
            {translate(
              'job.generate_supporting_documents.confirm_popin.content',
            )}
          </Typography>
        </ConfirmDialog>
      )}
    </>
  )
}

GenerateSupportingDocuments.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  jobIri: PropTypes.string.isRequired,
  generateSupportingDocuments: PropTypes.func.isRequired,
}

export default compose(i18n, withStyles(styles))(GenerateSupportingDocuments)
