import { createSelector } from 'reselect'
import { convertArrayToObject } from 'helpers/utils/common'
import get from 'lodash/get'

const LIST = 'list'
const CATEGORY_LIST = 'categoryList'

export class AbstractSelector {
  static getType() {
    return ''
  }

  static typeListSelector() {
    return createSelector(
      state => get(state, this.getType()),
      state => get(state, 'list'),
    )
  }

  static typeListIndexedByCodeSelector() {
    return createSelector(
      state => get(state, this.getType()),
      state => convertArrayToObject(get(state, LIST, [])),
    )
  }

  static typeCategoryListSelector() {
    return createSelector(
      state => get(state, this.getType()),
      state => get(state, CATEGORY_LIST),
    )
  }

  static hasSelector(type) {
    return createSelector(
      state => get(state, this.getType()),
      state => get(state, type, []).length > 0,
    )
  }

  static hasTypeSelector() {
    return this.hasSelector(LIST)
  }

  static hasTypeCategoriesSelector() {
    return this.hasSelector(CATEGORY_LIST)
  }

  static filterTypeByCategorySelector() {
    return createSelector(this.typeListSelector(), data =>
      (data || []).reduce(
        (result, value) => ({
          ...result,
          [value.categories[0]]: [
            ...(result[value.categories[0]] || []),
            value,
          ],
        }),
        {},
      ),
    )
  }
}
