import {
  GET_FIRMS_LIST,
  GET_SINGLE_FIRM,
  GET_COUNT_JOBS_DONE,
  UPDATE_FIRM_STATE,
  RESET_FIRM_STATE,
  RESET_FILTER_FIRM,
  UPDATE_FIRM_DETAILS,
  FIRM_GET_SINGLE_FILE,
  REMOVE_REGISTER_FILE,
  FIRM_SET_FIRM_FILES,
  GET_COUNT_FIRMS_BY_STATUSES,
  GET_COUNT_FIRMS_BY_DOC_STATUSES,
  GET_COUNT_FIRMS_BY_DOC_VERIFIED_STATUSES,
  UPDATE_FIRM_DETAILS_TRANSITION,
  DESACTIVATE_FIRM_DIRECTORY,
  SET_FIRMS_LIST_SEARCH_PARAMS,
  GET_FIRM_CERTIFICATES,
  SET_ORGANISME_CERTIFICAT,
  SET_CATEGORIES_CERTIFICAT_RGE,
  SET_EXPIRATION_DATE_CERTIFICAT,
  SET_CERTIFICATE_REFERENCE,
  UPDATE_FIRM_COLLABORATORS,
  UPDATE_USER_SUSPENSION_REASONS,
} from './firmActions'
import { initialState, initialDataToSubmit } from './firmSelectors'

export default (state = initialState, action) => {
  let updatedUsers

  switch (action.type) {
    case GET_FIRMS_LIST.REQUEST:
    case SET_FIRMS_LIST_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.searchParams,
        },
      }
    case GET_FIRMS_LIST.SUCCESS:
      return {
        ...state,
        list: action.payload.hits,
        searchParams: {
          ...state.searchParams,
          page: action.payload.page,
          rowsPerPage: action.payload.hitsPerPage,
          nbPages: action.payload.nbPages,
          total: action.payload.nbHits,
        },
      }
    case UPDATE_FIRM_COLLABORATORS.SUCCESS:
      updatedUsers = state.currentFirm.details.users.map(user => {
        if (user['@id'] === action.payload['@id']) {
          return action.payload
        }

        return user
      })

      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          details: {
            ...state.currentFirm.details,
            users: updatedUsers,
          },
        },
      }
    case GET_SINGLE_FIRM.REQUEST:
      return {
        ...state,
        currentFirm: {
          firmId: action.firmId,
        },
      }
    case GET_FIRM_CERTIFICATES.REQUEST:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          certificate: {
            ...state.currentFirm.certificate,
            loading: true,
          },
        },
      }
    case GET_FIRM_CERTIFICATES.FAILURE:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          certificate: {
            ...state.currentFirm.certificate,
            loading: false,
          },
        },
      }
    case GET_FIRM_CERTIFICATES.SUCCESS: {
      const categoriesByTypeIri = {}
      const types = action.payload['hydra:member'].map(
        ({ certificateCategories, ...otherFields }) => {
          const typeIri = otherFields['@id']
          categoriesByTypeIri[typeIri] = []
          certificateCategories.forEach(certificateCategory => {
            categoriesByTypeIri[typeIri].push(certificateCategory)
          })

          return otherFields
        },
      )

      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          certificate: {
            ...state.currentFirm.certificate,
            types,
            categoriesByTypeIri,
            loading: false,
          },
        },
      }
    }
    case SET_ORGANISME_CERTIFICAT:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          certificate: {
            ...state.currentFirm.certificate,
            selected: {
              ...state.currentFirm.certificate.selected,
              organisme: action.payload,
            },
          },
        },
      }
    case SET_CATEGORIES_CERTIFICAT_RGE:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          certificate: {
            ...state.currentFirm.certificate,
            selected: {
              ...state.currentFirm.certificate.selected,
              categories: action.payload,
            },
          },
        },
      }
    case SET_EXPIRATION_DATE_CERTIFICAT:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          certificate: {
            ...state.currentFirm.certificate,
            selected: {
              ...state.currentFirm.certificate.selected,
              expirationDate: action.payload,
            },
          },
        },
      }
    case SET_CERTIFICATE_REFERENCE:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          certificate: {
            ...state.currentFirm.certificate,
            selected: {
              ...state.currentFirm.certificate.selected,
              reference: action.payload,
            },
          },
        },
      }
    case DESACTIVATE_FIRM_DIRECTORY.SUCCESS:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          details: {
            ...state.currentFirm.details,
            displayedInProDirectory: false,
          },
        },
      }
    case GET_SINGLE_FIRM.SUCCESS:
    case UPDATE_FIRM_DETAILS.SUCCESS:
    case UPDATE_FIRM_DETAILS_TRANSITION.SUCCESS:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          details: action.payload,
        },
      }
    case GET_COUNT_JOBS_DONE.REQUEST:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          nbJobsDone: undefined,
        },
      }
    case GET_COUNT_JOBS_DONE.SUCCESS:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          nbJobsDone: action.payload,
        },
      }
    case RESET_FIRM_STATE:
      return {
        ...state,
        dataToSubmit: initialDataToSubmit,
      }
    case RESET_FILTER_FIRM:
      return {
        ...state,
        searchParams: initialState.searchParams,
      }
    case UPDATE_FIRM_STATE:
      return {
        ...state,
        dataToSubmit: {
          ...state.dataToSubmit,
          isFormReady: {
            ...state.dataToSubmit.isFormReady,
            [action.form]: true,
          },
          payload: {
            ...state.dataToSubmit.payload,
            ...action.payload,
          },
        },
      }
    case UPDATE_USER_SUSPENSION_REASONS.REQUEST:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          details: {
            ...state.currentFirm.details,
            suspensionReason: null,
          },
        },
      }
    case UPDATE_USER_SUSPENSION_REASONS.SUCCESS:
      return {
        ...state,
        currentFirm: {
          ...state.currentFirm,
          details: {
            ...state.currentFirm.details,
            suspensionReason: action.payload.suspensionReason,
            packageStatusV2: action.payload.packageStatusV2,
          },
        },
      }
    case FIRM_GET_SINGLE_FILE.SUCCESS:
      return {
        ...state,
        firmFiles: {
          ...state.firmFiles,
          [action.payload['@id']]: action.payload,
        },
      }
    case FIRM_SET_FIRM_FILES:
      return {
        ...state,
        firmFiles: {
          ...state.firmFiles,
          [action.payload.id]: {
            ...state.firmFiles[action.payload.id],
            type: action.payload.type,
            name: action.payload.name,
          },
        },
      }
    case REMOVE_REGISTER_FILE.SUCCESS: {
      const toDelete = `/api/firm_files/${action.actionParams.idFile}`
      const { [toDelete]: deletedFile, ...newFirmFiles } = state.firmFiles

      return {
        ...state,
        firmFiles: newFirmFiles,
      }
    }
    case GET_COUNT_FIRMS_BY_STATUSES.SUCCESS:
      return {
        ...state,
        firmsCount: action.payload.facetHits,
      }
    case GET_COUNT_FIRMS_BY_DOC_STATUSES.SUCCESS:
      return {
        ...state,
        firmsDocumentCount: action.payload.facetHits,
      }
    case GET_COUNT_FIRMS_BY_DOC_VERIFIED_STATUSES.SUCCESS:
      return {
        ...state,
        firmsDocVerifiedCount: action.payload.facetHits,
      }

    default: {
      return state
    }
  }
}
