import { drawerWidth } from 'constants/layout'
import { BLUE_QUOTATIS, WHITE_QUOTATIS } from '../../../theme/constants'

const styles = () => ({
  listUnlisted: {
    'padding-left': 0,
    'list-style': 'none',
    margin: 1,
  },
  item: {
    display: 'inline-block',
    margin: '0 20px',
  },
  tabs: {
    position: 'fixed',
    display: 'flex',
    width: `calc(100% - ${drawerWidth}px - 6rem)`,
    marginTop: '4.5rem',
    marginLeft: '3rem',
    padding: '1rem 0 0',
    boxSizing: 'border-box',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    background: '#dae1e6',
    zIndex: '10',

    '&::after': {
      content: "''",
      position: 'absolute',
      width: '99%',
      height: '30px',
      bottom: 0,
      left: '0.5%',
      borderRadius: '200px / 5px',
      boxShadow: '0 10px 10px #00000015',
      zIndex: '-1',
    },
  },
  tab: {
    display: 'inline-block',
    flex: '3',
    textAlign: 'center',
    background: WHITE_QUOTATIS,
    borderRight: '2px solid #dae1e6',

    '&:first-child': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderRight: '2px solid #dae1e6',
    },

    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      borderRight: 'none',
    },
    color: BLUE_QUOTATIS,
  },
  link: {
    display: 'block',
    alignItem: 'center',
    padding: '12px',
    fontSize: '0.875rem',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    textDecoration: 'none',
    color: 'rgb(138, 158, 171)',
    '&:hover': {
      color: BLUE_QUOTATIS,
    },
    width: '100%',
  },
  activeLink: {
    color: BLUE_QUOTATIS,
  },
  icon: {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    margin: '5px 8px 0 0',
    backgroundSize: 'contain',
  },
})

export default styles
