import { all, call, select, takeLatest } from 'redux-saga/effects'
import { handleRequest } from 'helpers/store/sagasHelpers'

import client from 'services/httpClient/proApiClient'
import { GET_TRADES_LIST, getTradesList } from './tradeActions'
import { hasTradesSelector } from './tradeSelectors'

export function* handleReadTradeListRequest() {
  const hasRecords = yield select(hasTradesSelector)

  if (!hasRecords) {
    yield* handleRequest({
      requestActions: getTradesList,
      promise: call(client.get, '/api/trades'),
    })
  }
}

export default function*() {
  yield all([takeLatest(GET_TRADES_LIST.REQUEST, handleReadTradeListRequest)])
}
