import {
  all,
  put,
  delay,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'
import { handleRequest } from 'helpers/store/sagasHelpers'
import auth from 'services/auth'
import { isMocks } from 'services/isMocks'
import {
  HOME_PATH,
  DASHBOARD_PATH,
  ERROR_PATH,
  LOGIN_PATH,
} from 'constants/routes'
import { ACCESS_TOKEN, ID_TOKEN } from 'constants/storage'
import { ERROR } from 'constants/variant'
import { scrollTopSelector } from 'store/Application/ApplicationSelectors'
import { push } from 'react-router-redux'
import {
  authLoginEnterprise,
  setLoggedIn,
  setScrollTop,
  APPLICATION_IS_READY,
  AUTH_LOGOUT,
  SET_SCROLL_TOP,
  SHOW_NOTIFICATION,
  hideNotification,
  showNotification,
  setShowJobStatus,
  setShowGeozoneSelector,
  doSetAuthTokens,
  SET_AUTH_TOKENS,
  AUTH_LOGIN_ENTERPRISE,
} from './ApplicationActions'
import { RESET_JOBS_LIST } from '../jobs/jobActions'

export function* closeNotification() {
  yield delay(3000)
  yield put(hideNotification())
}

const saveTokens = function*({ accessToken, idToken }) {
  yield put(
    doSetAuthTokens({
      payload: {
        idToken,
        accessToken,
      },
    }),
  )
}

const removeTokens = () => {
  localStorage.removeItem(ID_TOKEN)
  localStorage.removeItem(ACCESS_TOKEN)
}

export function* handleLoginRequest({ email }) {
  try {
    yield* handleRequest({
      requestActions: authLoginEnterprise,
      promise: auth.passwordlessStart({
        email,
      }),
      actionParams: {
        email,
      },
      checkTokens: false,
    })
  } catch (e) {
    console.info({ e })

    yield put(
      showNotification({
        payload: {
          message: e.description,
          messageType: ERROR,
        },
      }),
    )
  }
}

export function* handleAuthentication({ payload }) {
  if (ERROR_PATH === payload.location.pathname) {
    return
  }

  if (localStorage.getItem(ID_TOKEN)) {
    if ([HOME_PATH, LOGIN_PATH].includes(payload.location.pathname)) {
      yield put(push(DASHBOARD_PATH))
    }

    return
  }

  if (payload.location.hash) {
    try {
      const hash = payload.location?.hash.split('&')

      const accessToken =
        hash?.[0]?.replace('#access_token=', '') ||
        localStorage.getItem(ACCESS_TOKEN)

      const idToken =
        hash?.[5]?.replace('id_token=', '') || localStorage.getItem(ID_TOKEN)

      yield saveTokens({
        [ACCESS_TOKEN]: accessToken,
        [ID_TOKEN]: idToken,
      })
      yield put(setLoggedIn(true))
      yield put(push(DASHBOARD_PATH))
      document.location.reload(true)
    } catch (e) {
      removeTokens()
      yield put(push(ERROR_PATH))
    }

    return
  }
  if (!isMocks && payload.location.pathname !== LOGIN_PATH) {
    yield put(push(LOGIN_PATH))
  }
  // TODO: Remove this part when OAuth will be mocked
  if (isMocks) {
    document.location.reload(true)
  }
}

export function handleAuthLogout() {
  auth.logout()
  removeTokens()
  document.location.reload(true)
}

const bootstrapAuthFromLs = function*() {
  const idToken = localStorage.getItem(ID_TOKEN)
  const accessToken = localStorage.getItem(ACCESS_TOKEN)

  yield put(
    doSetAuthTokens({
      payload: {
        idToken,
        accessToken,
      },
    }),
  )

  return !!idToken && !!accessToken
}

function* handleClientInitiated() {
  const isLoggedIn = yield bootstrapAuthFromLs()
  yield put(setLoggedIn(isLoggedIn))
}

function* initScroll() {
  const scrollTop = yield select(scrollTopSelector)

  if (scrollTop === true) {
    yield delay(1000)
    yield put(setScrollTop(false))
  }
}

function* resetShowJobDetailsStatus() {
  yield put(setShowJobStatus({ showJobDetailsStatus: false }))
}
function* resetShowGeozoneSelector({ payload }) {
  if (payload.location.pathname !== DASHBOARD_PATH) {
    yield put(setShowGeozoneSelector({ showSelector: false }))
  }
}

function handleSetAuthTokens({ payload: { idToken, accessToken } }) {
  if (idToken && accessToken) {
    localStorage.setItem(ID_TOKEN, idToken)
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  }
}

export default function*() {
  yield all([
    takeLatest(APPLICATION_IS_READY, handleClientInitiated),
    takeLatest(SET_SCROLL_TOP, initScroll),
    takeLatest('@@router/LOCATION_CHANGE', handleAuthentication),
    takeLatest(AUTH_LOGIN_ENTERPRISE.REQUEST, handleLoginRequest),
    takeLatest('@@router/LOCATION_CHANGE', resetShowGeozoneSelector),
    takeLatest(AUTH_LOGOUT, handleAuthLogout),
    takeEvery(SHOW_NOTIFICATION, closeNotification),
    takeEvery(RESET_JOBS_LIST, resetShowJobDetailsStatus),
    takeLatest(SET_AUTH_TOKENS, handleSetAuthTokens),
  ])
}
