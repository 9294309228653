export const CNI = 'cni'
export const KBIS = 'kbis'
export const RIB = 'rib'
export const RDM = 'rdm'
export const RGE = 'rge'
export const CERFA = 'cerfa'
export const CERTIFICATE = 'certificate'
export const ASSURANCE_RC = 'assurance_rc'
export const ATTESTATION_VIGILANCE = 'attestation_vigilance'
export const ASSURANCE_DECENNALE = 'assurance_decennale'
export const PRO_DOCUMENT_UPLOAD_SIZE_LIMIT = 5000000
export const DOCUMENT_TYPES = [
  CNI,
  RIB,
  RDM,
  ASSURANCE_RC,
  ATTESTATION_VIGILANCE,
  ASSURANCE_DECENNALE,
]

export const UPLOAD_TYPE_BASIC = 'basic'
export const UPLOAD_TYPE_EXPIRING = 'expiring'
export const FILES_TYPES = [
  CNI,
  KBIS,
  CERFA,
  RGE,
  ASSURANCE_RC,
  ATTESTATION_VIGILANCE,
  ASSURANCE_DECENNALE,
]

export const FILE_UPLOAD_LIMIT = 10

export const PRO_DOCUMENTS = [
  {
    name: CNI,
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: false,
    },
    mandatory: true,
  },
  {
    name: KBIS,
    meta: {
      expirationDate: false,
      issueDate: true,
      reference: false,
      category: false,
    },
    mandatory: true,
  },
  {
    name: CERTIFICATE,
    certificateType: CERFA,
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: false,
      isCertificate: true,
    },
    mandatory: false,
  },
  {
    name: CERTIFICATE,
    certificateType: RGE,
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: true,
      isCertificate: true,
    },
    mandatory: false,
  },
  {
    name: ASSURANCE_RC,
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: false,
    },
    mandatory: true,
  },
  {
    name: ASSURANCE_DECENNALE,
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: false,
    },
    mandatory: true,
  },
  {
    name: ATTESTATION_VIGILANCE,
    meta: {
      expirationDate: false,
      issueDate: true,
      reference: true,
      category: false,
    },
    mandatory: true,
  },
]
