import { BLUE_QUOTATIS } from 'theme/constants'

const styles = () => ({
  root: {},
  geozoneSelector: {
    fontSize: '1.25rem',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    color: BLUE_QUOTATIS,
    lineHeight: '1.6',
    marginTop: '0 !important',
  },
})

export default styles
