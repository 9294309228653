import { createSelector } from 'reselect'
import { get } from 'lodash'

export const initialState = {
  currentWorksite: {},
}

export const worksiteSelector = state => get(state, 'worksites')

export const currentWorksiteSelector = createSelector(worksiteSelector, state =>
  get(state, 'currentWorksite'),
)

export const worksiteJobsSelector = createSelector(
  currentWorksiteSelector,
  state => get(state, `jobs`),
)

export const partnerLabelSelector = createSelector(
  currentWorksiteSelector,
  state => get(state, 'partner.label'),
)
