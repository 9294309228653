export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  card: {
    minHeight: '310px',
  },
  input: {
    width: '80%',
    minWidth: '160px',
    marginBottom: theme.spacing(1),
  },
  labels: {
    lineHeight: '2rem',
    fontWeight: 500,
  },
  fullWidth: {
    minWidth: '180px',
    position: 'initial !important',
  },
  titles: {
    fontSize: '1.2rem',
    color: '#143C58',
  },
  icon: {
    width: '45px',
    height: '45px',
    backgroundSize: 'contain',
  },
})
