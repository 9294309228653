import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import {
  getSaleInvoicesExport,
  getSaleInvoicesList,
  resetFilterSaleInvoice,
} from 'store/saleInvoices/saleInvoiceActions'
import {
  formatedSaleInvoicesListSelector,
  searchParamsSelector,
} from 'store/saleInvoices/saleInvoiceSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import SaleInvoicesList from './SaleInvoicesList'

const SaleInvoicesListContainer = ({
  resetFiltersThenDoSearch,
  getInvoices,
  searchParams,
  exportInvoices,
  invoices,
  isLoading,
}) => {
  useEffect(() => {
    resetFiltersThenDoSearch()
  }, [resetFiltersThenDoSearch])

  const handleChangePerPage = event => {
    getInvoices({
      ...searchParams,
      rowsPerPage: event.target.value,
    })
  }

  const handleChangeSort = orderBy => {
    let sort
    if (!searchParams.orderBy || searchParams.orderBy !== orderBy) {
      sort = 'desc'
    } else {
      sort = searchParams.sort === 'desc' ? 'asc' : 'desc'
    }

    return getInvoices({
      ...searchParams,
      orderBy,
      sort,
    })
  }

  const handleChangePage = (_, page) => {
    getInvoices({
      ...searchParams,
      page,
    })
  }

  const delaySearchInvoices = query => {
    getInvoices({
      ...searchParams,
      query,
      page: 0,
    })
  }

  const handleSearchChange = ({ target }) => {
    debounce(delaySearchInvoices(target.value), 600)
  }

  const handleFiltersChange = (fieldName, selectedValues) => {
    getInvoices({
      ...searchParams,
      [fieldName]: selectedValues
        ? selectedValues.map(selectedValue => selectedValue.value)
        : [],
      page: 0,
    })
  }

  const handleExportClick = () => {
    exportInvoices({
      ...searchParams,
      export: true,
    })
  }

  return (
    <SaleInvoicesList
      rows={invoices}
      searchParams={searchParams}
      isLoading={isLoading}
      handleChangePage={handleChangePage}
      handleChangePerPage={handleChangePerPage}
      handleChangeSort={handleChangeSort}
      handleSearchChange={handleSearchChange}
      handleFiltersChange={handleFiltersChange}
      handleExportClick={handleExportClick}
    />
  )
}

SaleInvoicesListContainer.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resetFiltersThenDoSearch: PropTypes.func.isRequired,
  getInvoices: PropTypes.func.isRequired,
  exportInvoices: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchParams: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired,
  }).isRequired,
}

const mapStateToProps = state => ({
  invoices: formatedSaleInvoicesListSelector(state),
  searchParams: searchParamsSelector(state),
  isLoading: isLoadingSelector(state),
})

const mapDispatchToProps = dispatch => ({
  resetFiltersThenDoSearch: () => dispatch(resetFilterSaleInvoice()),
  getInvoices: searchParams =>
    dispatch(getSaleInvoicesList.request(searchParams)),
  exportInvoices: searchParams =>
    dispatch(getSaleInvoicesExport.request(searchParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SaleInvoicesListContainer)
