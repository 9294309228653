import translate from 'providers/i18n/translateService'
import {
  PACKAGES_SUBSCRIPTION_WORDING__TRANSITIONS,
  SUBJECT_TO_VAT,
} from 'constants/firms'

export const validateManualRating = (value, allValues, props) =>
  value && !/^(5|([0-4]([.,][0-9]{1,2})?))$/gm.test(value)
    ? props.translate('resources.firms.validation.range_manual_rating')
    : undefined

export const formatRankingRating = rankingRating => {
  if (rankingRating === null || rankingRating < 0) return 0
  if (rankingRating > 50) return 5

  return rankingRating / 10
}

export const getPackageStatusLabel = packageStatus => {
  switch (packageStatus) {
    case 1:
      return translate('firm.packageStatus.candidate')
    case 2:
      return translate('firm.packageStatus.validate')
    case 3:
      return translate('firm.packageStatus.suspended')
    default:
      return ''
  }
}

export const isSubjectToVat = vat_category => vat_category === SUBJECT_TO_VAT

export const selectTransition = (oldStatus, newStatus) => {
  let transitionToApply = null

  Object.keys(PACKAGES_SUBSCRIPTION_WORDING__TRANSITIONS).forEach(
    transition => {
      if (
        PACKAGES_SUBSCRIPTION_WORDING__TRANSITIONS[transition].from.includes(
          oldStatus,
        ) &&
        PACKAGES_SUBSCRIPTION_WORDING__TRANSITIONS[transition].to === newStatus
      ) {
        transitionToApply = transition
      }
    },
  )

  return transitionToApply
}
