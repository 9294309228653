import React, { useState } from 'react'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import { Button, withStyles } from '@material-ui/core'
import Chip from 'components/shared/Chip/Chip'
import { CERFA_15497, CERFA_BOTH, CERFA_LABEL } from 'constants/Jobs'
import UploaderBtn from 'components/shared/Uploader/UploaderBtn'
import DataTable from 'components/shared/Tables/DataTable'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import Icon from 'components/shared/Icon/Icon'
import CerfaFormContainer from '../CerfaForm/CerfaFormContainer'
import styles from './CerfaListStyles'
import CerfaViewer from '../CerfaViewer/CerfaViewer'

function CerfaList({
  translate,
  classes,
  jobIri,
  cerfaManualSignStatus,
  dispatchUploadJobCerfa,
}) {
  const [dialogOpened, setDialogOpened] = useState(false)
  const [cerfaType, setCerfaType] = useState(null)

  const handleDialog = type => {
    setCerfaType(type === CERFA_BOTH ? CERFA_15497 : type)
    setDialogOpened(prevState => !prevState)
  }

  const columns = [
    {
      title: translate('resources.cerfa.table.file'),
      render: () => (
        <div className={classes.cellWrapper}>
          <Icon className={classes.icon} icon="file.svg" />
          <Chip
            className={classes.chip}
            type="smallSuccess"
            label={translate(`resources.jobs.validateBills.receipt.manual`)}
          />
        </div>
      ),
      key: 'file',
    },
    {
      title: translate('resources.cerfa.table.type'),
      render: ({ type }) => translate(CERFA_LABEL[type]),
      key: 'type',
    },
    {
      title: translate('resources.cerfa.table.uploadDate'),
      render: ({ date }) => date,
      key: 'date',
    },
    {
      title: '',
      // eslint-disable-next-line
      render: ({ type }) => (
        <Button
          className={classes.buttonSmaller}
          color="primary"
          onClick={() => handleDialog(type)}
        >
          {translate('resources.cerfa.table.cta.see')}
        </Button>
      ),
      key: 'viewCerfa',
    },
    {
      title: '',
      // eslint-disable-next-line
      render: ({ type }) => (
        <UploaderBtn
          className={classes.buttonBigger}
          label={translate('resources.cerfa.table.cta.import')}
          title={translate('resources.cerfa.title')}
          onSuccess={() => null} // TODO
          formOption={{ specifiedCerfa: type, uploadedCerfa: [] }}
          onValidation={dispatchUploadJobCerfa}
          FormComponent={CerfaFormContainer}
        />
      ),
      key: 'editCerfa',
    },
  ]

  return (
    <>
      <DataTable
        tableWrapperClassName={classes.table}
        columns={columns}
        data={cerfaManualSignStatus}
        pagination={false}
      />
      {dialogOpened && (
        <SimpleDialog title="" onClose={handleDialog}>
          <CerfaViewer jobIri={jobIri} cerfaType={cerfaType} />
        </SimpleDialog>
      )}
    </>
  )
}

CerfaList.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    table: PropTypes.string.isRequired,
    cellWrapper: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    chip: PropTypes.string.isRequired,
    buttonSmaller: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  jobIri: PropTypes.string.isRequired,
  dispatchUploadJobCerfa: PropTypes.func.isRequired,
  cerfaManualSignStatus: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      fileId: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default compose(i18n, withStyles(styles))(CerfaList)
