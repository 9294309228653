export const formatIriToId = iri => iri.split('/').pop()
export const getJobStatus = path => path.split('/').pop()

/**
 * Convert an array like: [
 *    { code: 'QUOTATIS', name: 'quotatis'},
 *    { code: 'LEROY_MERLIN', name: 'Leroy merlin'},
 *    ]
 * into an object like:
 *    {
 *      QUOTATIS: {code: 'QUOTATIS', name: 'quotatis'},
 *      LEROY_MERLIN: { code: 'LEROY_MERLIN', name: 'Leroy merlin'},
 *    }
 * @param  {Array} list
 * @param  {String} [uniqueKey='code'] This is the key in your array that should be unique
 * @return {Object}
 */
export const convertArrayToObject = (list, uniqueKey = 'code') =>
  list.reduce(
    (element, current) => ({ ...element, [current[uniqueKey]]: current }),
    {},
  )

/**
 * convertCentsToEuros
 *
 * convert a price in centimes into a price in euros
 *
 * @param {Int} priceInCents
 * @return {Int} price in euros
 */
export const convertCentsToEuros = priceInCents =>
  (priceInCents / 100).toFixed(2)

/**
 * convertEurosToCents
 *
 * convert a price in euros into a price in centimes
 *
 * @param {Int} priceInEuros
 * @return {Int} price in cents
 */
export const convertEurosToCents = priceInEuros =>
  parseFloat(priceInEuros) * 100

export const deleteIndexFromArray = (index, list) =>
  list.filter((_, i) => i !== index)

export const removeSpaces = text => text.replace(/ /g, '')

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const removeEmptyAttribute = obj =>
  Object.keys(obj)
    .filter(k => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {})

export const removeUnchangedAttribute = (obj, values) =>
  Object.keys(values)
    .filter(k => values[k] !== obj[k])
    .reduce((a, k) => ({ ...a, [k]: values[k] }), {})
