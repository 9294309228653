export const styles = theme => ({
  ntm: {},
  inputTextInterventionZone: {
    minWidth: '300px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
    minWidth: '200px',
  },
  fullWidth: {
    width: 'auto',
    minWidth: '200px',
  },
  map: {
    marginTop: '20px',
    height: '300px',
  },
  checkboxInterventionZone: {
    flexDirection: 'row',
  },
  checkboxArea: {
    flexDirection: 'row',
    marginTop: '10px',
    marginLeft: '20px',
  },
})
