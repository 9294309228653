import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import uuidv1 from 'uuid/v1'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const RenderMultiselect = ({
  id,
  label,
  options,
  className,
  meta: { touched, error },
  input,
  onSelectChange,
  disabled,
}) => {
  const [option, setOption] = React.useState([])

  const handleChange = event => {
    onSelectChange(event)
    setOption(event.target.value)
    input.onChange(event.target.value)
  }

  if (input.value.length === 0 && option.length !== 0) {
    setOption([])
  }

  // Allow us to display and choose with a label and return a value (like an id)
  const mapper = options.reduce(
    (obj, { valueOption, labelOption }) => ({
      ...obj,
      [valueOption]: labelOption || valueOption,
    }),
    {},
  )

  return (
    <FormControl className={className} error={touched && error !== undefined}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        {...input}
        name={input.name}
        id={id}
        multiple
        value={input.value || []}
        onChange={handleChange}
        input={<Input />}
        renderValue={selected =>
          selected.map(select => mapper[select]).join(', ')
        }
        MenuProps={MENU_PROPS}
        disabled={disabled}
      >
        {options.map(({ labelOption, valueOption }) => (
          <MenuItem
            key={uuidv1()}
            value={valueOption}
            style={{ textOverflow: 'ellipsis', fontSize: '0.875rem' }}
          >
            <Checkbox checked={option.indexOf(valueOption) > -1} />
            {labelOption}
          </MenuItem>
        ))}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

RenderMultiselect.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    touch: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      labelOption: PropTypes.string,
      valueOption: PropTypes.string,
    }),
  ).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
}

RenderMultiselect.defaultProps = {
  meta: {},
  onSelectChange: () => {},
  disabled: false,
}

export default RenderMultiselect
