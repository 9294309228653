export default () => ({
  root: {
    background: 'none',
  },
  container: {
    position: 'absolute',
    left: '-115px',
    top: '-140px',
    pointerEvents: 'none',
  },
  content: {
    boxShadow: '1px 1px 1px 1px #dddedf',
    height: '90px',
    textOverflow: 'ellipsis',
    padding: '6px',
    margin: '40px',
    position: 'relative',
    width: '200px',
    backgroundColor: '#e6eaed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  round: {
    borderRadius: '20px',
  },
  arrow: {
    '&:after': {
      top: 'auto',
      left: '140px',
      right: 'auto',
      bottom: '-30px',
      border: '18px solid',
      content: '""',
      position: 'absolute',
      borderColor: '#e6eaed  transparent transparent transparent',
      width: 0,
      height: 0,
      borderLeft: '20px solid transparent',
      borderRight: '20px solid transparent',
      borderTop: '20px solid #e6eaed',
    },
  },
  text: {
    lineHeight: '12px',
    fontSize: '12px',
    padding: '8px 2px',
    textAlign: 'center',
    overflow: 'hidden',
    maxWidth: '180px',
    height: '70px',
  },
})
