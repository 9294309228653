import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { getJobsList, exportJobsList } from 'store/jobs/jobActions'
import {
  jobsListSelector,
  searchParamsSelector,
  totalSelector,
} from 'store/jobs/jobSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import JobsSearch from './JobsSearch'

class JobsSearchContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      orderNumber: '',
      lastName: '',
      proLastName: '',
    }

    this.handleChangePerPage = this.handleChangePerPage.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeSort = this.handleChangeSort.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.delaySearchJobs = debounce(this.delaySearchJobs.bind(this), 600)
    this.loadJobs = this.loadJobs.bind(this)
  }

  handleChangePerPage(event) {
    const { searchParams } = this.props
    this.loadJobs({
      ...this.state,
      ...searchParams,
      page: 0,
      rowsPerPage: event.target.value,
    })
  }

  handleChangeSort(orderBy) {
    const { searchParams } = this.props
    const sort = searchParams.sort === 'desc' ? 'asc' : 'desc'

    return this.loadJobs({
      ...this.state,
      ...searchParams,
      ...this.state,
      orderBy,
      sort,
      page: 0,
    })
  }

  handleChangePage(event, page) {
    const { searchParams } = this.props
    this.loadJobs({
      ...this.state,
      ...searchParams,
      page,
    })
  }

  handleExport() {
    const { searchParams, exportJobs } = this.props

    exportJobs({
      ...searchParams,
      ...this.state,
    })
  }

  handleSearchChange(values) {
    this.setState({
      ...values,
    })
    this.delaySearchJobs(values)
  }

  delaySearchJobs(values) {
    const { searchParams } = this.props
    this.loadJobs({
      ...searchParams,
      ...values,
      page: 0,
    })
  }

  loadJobs(params) {
    const { getJobs } = this.props
    getJobs({ ...params })
  }

  render() {
    const { jobs, searchParams, isLoading, total } = this.props
    return (
      <JobsSearch
        rows={jobs}
        searchParams={searchParams}
        total={total}
        isLoading={isLoading}
        handleChangePage={this.handleChangePage}
        handleChangePerPage={this.handleChangePerPage}
        handleChangeSort={this.handleChangeSort}
        handleSearchChange={this.handleSearchChange}
        handleExport={this.handleExport}
      />
    )
  }
}

JobsSearchContainer.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  getJobs: PropTypes.func.isRequired,
  exportJobs: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  total: PropTypes.number.isRequired,
  searchParams: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
    sort: PropTypes.string.isRequired,
  }).isRequired,
}

const mapStateToProps = state => ({
  jobs: jobsListSelector(state),
  searchParams: searchParamsSelector(state),
  isLoading: isLoadingSelector(state),
  total: totalSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getJobs: searchParams => dispatch(getJobsList.request(searchParams)),
  exportJobs: searchParams => dispatch(exportJobsList.request(searchParams)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(JobsSearchContainer)
