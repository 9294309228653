import React from 'react'
import jwtUtils from 'jwt-simple'
import { ACCESS_TOKEN } from 'constants/storage'

export const PermissionsContainer = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN)
  const { permissions } = jwtUtils.decode(accessToken, null, true)
  return (
    <div>
      <h2>Permissions</h2>
      {permissions.length !== 0 && (
        <ul>
          {permissions.map(permission => (
            <li>{permission}</li>
          ))}
        </ul>
      )}
    </div>
  )
}
