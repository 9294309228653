import {
  generateRequestActions,
  createRequestTypes,
} from 'helpers/store/actionsHelpers'

export class AbstractActions {
  static getType() {
    return ''
  }

  static GET_TYPE_LIST() {
    return createRequestTypes(`GET_${this.getType()}_LIST`)
  }

  static GET_TYPE_CATEGORIES_LIST() {
    return createRequestTypes(`GET_${this.getType()}_CATEGORIES_LIST`)
  }

  static getTypeList() {
    return generateRequestActions(this.GET_TYPE_LIST())
  }

  static getTypeCategoriesList() {
    return generateRequestActions(this.GET_TYPE_CATEGORIES_LIST())
  }
}
