import { initialState } from './firmContractOptionsSelectors'
import { GET_FIRM_CONTRACT_OPTIONS } from './firmContractOptionsActions'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FIRM_CONTRACT_OPTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_FIRM_CONTRACT_OPTIONS.SUCCESS:
      return {
        ...state,
        contractOptions: action.payload['hydra:member'],
        isLoading: false,
      }
    case GET_FIRM_CONTRACT_OPTIONS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
