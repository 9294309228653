export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  card: {},
  input: {
    width: '80%',
    minWidth: '160px',
    marginBottom: theme.spacing(1),
  },
  labels: {
    lineHeight: '2rem',
    fontWeight: 500,
  },
  fullWidth: {
    minWidth: '180px',
    position: 'initial !important',
  },
})
