import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

const DialogActionsContent = ({ translate, handleClose }) => (
  <Button onClick={handleClose} color="grey" autoFocus>
    {translate('app.action.close')}
  </Button>
)

DialogActionsContent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

export default memo(DialogActionsContent)
