import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { touch, change } from 'redux-form'
import uuidv1 from 'uuid/v1'
import { departmentsListSelector } from 'store/departments/departmentSelectors'
import {
  currentFirmPostcodes,
  currentFirmMatchingOption,
  currentFirmMatchingRadius,
  currentFirmMatchingAddress,
  currentFirmMatchingPoint,
  isFirmValidatedForPackageSelector,
} from 'store/firms/firmSelectors'
import { getDepartmentsList } from 'store/departments/departmentActions'
import { updateFirmDetailsInterventionZone } from 'store/firms/firmActions'
import { EDIT_FIRM_INTERVENTION_ZONE_FORM } from 'constants/forms'
import FirmInterventionZone from './FirmInterventionZone'

const FirmInterventionZoneContainer = ({
  updateCurrentFirm,
  departments,
  isFirmValidatedForPackage,
  initialValues,
  forceValidation,
  getDepartments,
}) => {
  useEffect(() => {
    getDepartments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FirmInterventionZone
      onSubmit={values => updateCurrentFirm(values)}
      departments={departments}
      isFirmValidatedForPackage={isFirmValidatedForPackage}
      initialValues={initialValues}
      forceValidation={forceValidation}
      change={change}
    />
  )
}

FirmInterventionZoneContainer.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFirmValidatedForPackage: PropTypes.bool.isRequired,
  getDepartments: PropTypes.func.isRequired,
  updateCurrentFirm: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  forceValidation: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  isFirmValidatedForPackage: isFirmValidatedForPackageSelector(state),
  initialValues: {
    postcodes: currentFirmPostcodes(state),
    matchingOption: currentFirmMatchingOption(state),
    matchingRadius: currentFirmMatchingRadius(state),
    matchingAddress: currentFirmMatchingAddress(state),
    matchingPoint: currentFirmMatchingPoint(state),
  },
  departments: departmentsListSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getDepartments: () => dispatch(getDepartmentsList.request()),
  updateCurrentFirm: values =>
    dispatch(updateFirmDetailsInterventionZone({ payload: values })),
  forceValidation: () => {
    // usefull to force the redux-form validation & the error message displaying
    dispatch(
      touch(
        EDIT_FIRM_INTERVENTION_ZONE_FORM,
        'matchingOption',
        'postcodes',
        'matchingRadius',
        'matchingAddress',
      ),
    )

    // Force change action on a field to trigger validation
    dispatch(change(EDIT_FIRM_INTERVENTION_ZONE_FORM, 'checkBox', uuidv1()))
  },
  change: (name, value) =>
    dispatch(change(EDIT_FIRM_INTERVENTION_ZONE_FORM, name, value)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirmInterventionZoneContainer)
