import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import PropTypes from 'prop-types'
import React from 'react'

const TIMESLOT_CHOICES = {
  MORNING: {
    value: '08:00:00.000-13:30:00.000',
    text: 'job.manual_matching.time_slot_range.morning',
  },
  AFTERNOON: {
    value: '13:30:00.000-18:00:00.000',
    text: 'job.manual_matching.time_slot_range.afternoon',
  },
}

export const TIMESLOT_VALUES = Object.keys(TIMESLOT_CHOICES).reduce(
  (acc, currKey) => [...acc, TIMESLOT_CHOICES[currKey].value],
  [],
)

const RenderTimeslotSelect = ({
  input: { value, onChange },
  isTimeslotDateDisabled,
  translate,
  meta: { error, touched },
}) => {
  const touchedWithErr = touched && !!error
  return (
    <FormControl disabled={isTimeslotDateDisabled} error={touchedWithErr}>
      <InputLabel htmlFor="time-slot-id">
        {translate('job.manual_matching.time_slot')}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        inputProps={{
          name: 'time_slot',
          id: 'time-slot-id',
        }}
        style={{
          width: '150px',
        }}
      >
        {Object.keys(TIMESLOT_CHOICES).map(timeslotKey => {
          const timeslot = TIMESLOT_CHOICES[timeslotKey]
          const timeslotValue = timeslot.value

          return (
            <MenuItem key={timeslotValue} value={timeslotValue}>
              {translate(timeslot.text)}
            </MenuItem>
          )
        })}
      </Select>
      {touchedWithErr && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

RenderTimeslotSelect.defaultProps = {
  isTimeslotDateDisabled: false,
  meta: {},
}

RenderTimeslotSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  isTimeslotDateDisabled: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
}

export default RenderTimeslotSelect
