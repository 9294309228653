import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import FormDialogContainer from 'components/shared/FormDialog/FormDialogContainer'
import { required } from 'helpers/form/validationHelpers'
import { PRO_FIELD_NAME } from 'constants/Jobs'

const CreateTravelCompensationPurchaseInvoiceForm = ({
  form,
  handleTravelCompensationPurchaseInvoiceCreationFormSubmit,
  hideTravelCompensationPurchaseInvoiceCreationDialog,
  translate,
  prosHavingBeenPlannedOnJob,
}) => (
  <FormDialogContainer
    form={form}
    onSubmit={handleTravelCompensationPurchaseInvoiceCreationFormSubmit}
    handleClose={hideTravelCompensationPurchaseInvoiceCreationDialog}
    title="resources.invoice.dialog.create_displacement_invoice.title"
    text="resources.invoice.dialog.create_displacement_invoice.text"
    translate={translate}
  >
    <Field
      id={`${form}_${PRO_FIELD_NAME}`}
      name={PRO_FIELD_NAME}
      required
      allowEmpty
      optionText="label"
      component={RenderSelectField}
      label={translate(
        'resources.invoice.dialog.create_displacement_invoice.field.pro.label',
      )}
      choices={prosHavingBeenPlannedOnJob}
      validate={[required]}
    />
  </FormDialogContainer>
)

CreateTravelCompensationPurchaseInvoiceForm.propTypes = {
  form: PropTypes.string.isRequired,
  handleTravelCompensationPurchaseInvoiceCreationFormSubmit:
    PropTypes.func.isRequired,
  hideTravelCompensationPurchaseInvoiceCreationDialog:
    PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  prosHavingBeenPlannedOnJob: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default CreateTravelCompensationPurchaseInvoiceForm
