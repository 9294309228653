import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import { authLoginEnterprise } from 'store/Application/ApplicationActions'
import {
  authLinkEmailSelector,
  authLoginLoadingSelector,
} from 'store/Application/ApplicationSelectors'
import { LOGIN_FORM } from 'constants/forms'

import i18n from 'providers/i18n/I18nProvider'
import Login from './Login'

const mapStateToProps = state => ({
  authLinkEmail: authLinkEmailSelector(state),
  isAuthLoginLoading: authLoginLoadingSelector(state),
})

export default compose(
  i18n,
  connect(mapStateToProps),
  reduxForm({
    form: LOGIN_FORM,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmit: ({ userEmail }, dispatch) => {
      dispatch(authLoginEnterprise.request({ email: userEmail }))
    },
    onSubmitSuccess: (result, dispatch) => {
      dispatch(reset(LOGIN_FORM))
    },
  }),
)(Login)
