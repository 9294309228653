import {
  generateRequestActions,
  createRequestTypes,
  generateSimpleAction,
} from 'helpers/store/actionsHelpers'

export const GET_SALE_INVOICES_LIST = createRequestTypes(
  'GET_SALE_INVOICES_LIST',
)
export const GET_SALE_INVOICES_EXPORT = createRequestTypes(
  'GET_SALE_INVOICES_EXPORT',
)
export const RESET_FILTER_SALE_INVOICE = 'RESET_FILTER_SALE_INVOICE'
export const GET_SINGLE_SALE_INVOICE = createRequestTypes(
  'GET_SINGLE_SALE_INVOICE',
)

export const getSaleInvoicesList = generateRequestActions(
  GET_SALE_INVOICES_LIST,
)
export const getSaleInvoicesExport = generateRequestActions(
  GET_SALE_INVOICES_EXPORT,
)
export const resetFilterSaleInvoice = generateSimpleAction(
  RESET_FILTER_SALE_INVOICE,
)
export const getSingleSaleInvoice = generateRequestActions(
  GET_SINGLE_SALE_INVOICE,
)
