const styles = () => ({
  table: {
    display: 'inline-flex',
    flexFlow: 'row wrap',
    flexDirection: 'row-reverse',
    flexGrow: '1',
  },
  button: {
    textTransform: 'none',
  },
})

export default styles
