const styles = theme => ({
  title: {
    fontWeight: 600,
    display: 'inline-block',
    marginRight: '1rem',
    marginBottom: '2rem',
  },
  buttonSecondary: theme.buttonSecondary,
  buttonBigger: {
    ...theme.buttonBigger,
    marginLeft: '1rem',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
  receiptInfo: {
    maxWidth: 'max-content',
    minWidth: '50%',
    fontSize: '12px',
    fontFamily: 'Montserrat',
    backgroundColor: '#E6EAED',
    padding: '10px 8px',
    marginTop: '-10px',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blockList: {
    display: 'flex',
    width: '100%',
  },
  borderRight: {
    '&:nth-child(1)': {
      borderRight: '1px solid',
    },
  },
  progressBarContainer: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    width: '94%',
  },
  line: {
    padding: '2px 10px',
    minWidth: '50%',
    lineHeight: '0.8rem',
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  info: {
    paddingLeft: '4px',
  },
})

export default styles
