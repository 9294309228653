import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Field, reduxForm } from 'redux-form'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import { dateFormatter } from 'helpers/date'
import { ISO_SHORT } from 'constants/date'
import RenderTextField from 'components/shared/Form/RenderTextField'
import DateInput from 'components/shared/DateInput/DateInput'
import { validate } from './FileUploaderValidation'

const FileUploader = ({
  translate,
  setFormIsValid,
  valid,
  addAdditionalInfo,
  editionMode,
  previousFormValues,
  formOption: { document, documentIri },
}) => {
  useEffect(() => {
    addAdditionalInfo({
      documentName: document.name,
      documentIri,
      editionMode,
      previousFormValues,
    })
    setFormIsValid(valid)
  }, [
    valid,
    setFormIsValid,
    addAdditionalInfo,
    document.name,
    editionMode,
    previousFormValues,
    documentIri,
  ])

  return (
    <form noValidate>
      {document.meta.issueDate && (
        <Field
          component={DateInput}
          name="issueDate"
          type="date"
          label={translate('pro.account.pro-documents.issueDateLabel')}
          onChange={(event, newValue, previousValue, name) =>
            addAdditionalInfo({ [name]: newValue })
          }
        />
      )}
      {document.meta.expirationDate && (
        <Field
          component={DateInput}
          name="expirationDate"
          type="date"
          label={translate('pro.account.pro-documents.expirationDateLabel')}
          onChange={(event, newValue, previousValue, name) =>
            addAdditionalInfo({ [name]: newValue })
          }
        />
      )}
      {document.meta.reference && (
        <Field
          component={RenderTextField}
          name="reference"
          type="text"
          placeholder={translate('pro.account.pro-documents.referenceLabel')}
          onChange={(event, newValue, previousValue, name) =>
            addAdditionalInfo({ [name]: newValue })
          }
        />
      )}
    </form>
  )
}

FileUploader.propTypes = {
  addAdditionalInfo: PropTypes.func.isRequired,
  setFormIsValid: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  formOption: PropTypes.shape({
    documentIri: PropTypes.string.isRequired,
    document: PropTypes.shape({
      name: PropTypes.string.isRequired,
      meta: PropTypes.shape({
        issueDate: PropTypes.bool.isRequired,
        expirationDate: PropTypes.bool.isRequired,
        reference: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  editionMode: PropTypes.bool.isRequired,
  previousFormValues: PropTypes.shape({}).isRequired,
}

const mapStateToProps = (state, ownProps) => {
  const {
    initialValues: { expirationDate, issueDate, reference },
    formName,
    formId,
    document,
  } = ownProps.formOption
  const form = formName + (formId || '')

  return {
    form,
    initialValues: {
      documentName: document.name,
      expirationDate: dateFormatter(expirationDate, ISO_SHORT),
      issueDate: dateFormatter(issueDate, ISO_SHORT),
      reference,
    },
    editionMode: !!formId,
    previousFormValues: { expirationDate, issueDate, reference },
  }
}

export default compose(
  connect(mapStateToProps),
  i18n,
  reduxForm({
    validate,
  }),
)(FileUploader)
