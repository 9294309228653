export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchBar: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '3rem 3rem 0',
    padding: '2rem 3rem 3rem',
    backgroundColor: '#fff',
    borderRadius: '5px',
    width: 'auto',
  },
  formControl: {
    width: '90%',
  },
  inputSizing: {
    height: 'fit-content',
    width: '18%',
    marginRight: '2%',
    boxSizing: 'border-box',
    padding: '0 0 1rem',
  },
  selectEmpty: {
    fontSize: 'auto',
    minWidth: '13vw',
    width: 'auto',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  buttonReload: {
    padding: '0.7rem 2.9rem',
    borderRadius: '3px',
    border: '2px solid #143C58',
    textTransform: 'none',
    background: 'none',
    color: '#143C58',
    marginTop: '1.5rem',
  },
})
