export default theme => ({
  container: {
    padding: '2rem',
  },
  button: {
    margin: theme.spacing(1),
  },
  titleDashboardPart: {
    margin: '1rem 0 1rem 1rem',
    color: '#143C58',
  },
  background: {
    backgroundSize: 'contain',
    width: '300px',
    height: '180px',
    position: 'fixed',
    bottom: 0,
    right: '20px',
  },
})
