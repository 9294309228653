import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, Field, reset } from 'redux-form'
import {
  Grid,
  withStyles,
  Card,
  CardContent,
  Button,
  Typography,
  FormControl,
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import WrapperInline from 'components/shared/WrapperInline/WrapperInline'
import RenderSelectTimeslotsField from 'components/shared/Form/RenderSelectTimeslotsField'
import { RESCHEDULING_JOB_FORM } from 'constants/forms'
import { required } from 'helpers/form/validationHelpers'
import { formatDateTimeSlotFormValue } from 'helpers/date'
import styles from '../ManualMatchingStyles'

class RescheduleManualMatching extends PureComponent {
  render() {
    const {
      classes,
      handleSubmit,
      translate,
      reschedulingJob,
      declineJob,
      timeslots,
    } = this.props
    const enabledTimeslots =
      timeslots && timeslots.filter(timeslot => timeslot.enabled === true)
    return (
      <form onSubmit={handleSubmit}>
        <Card className={classes.card}>
          <CardContent>
            <Grid container className={classes.bgPink} spacing={3}>
              <Grid item xs={1}>
                <ErrorOutlineIcon className={classes.ErrorOutlineIcon} />
              </Grid>
              <Grid item xs={6}>
                <Typography gutterBottom>
                  {translate('job.manual_matching.alert_message_intro')}
                </Typography>
                <WrapperInline>
                  <Typography variant="subtitle2" component="span" gutterBottom>
                    {translate('job.manual_matching.recontact_pro')}
                  </Typography>
                  {!reschedulingJob && !declineJob && (
                    <Typography component="span">
                      {translate(
                        'job.manual_matching.alert_message_indication',
                      )}
                    </Typography>
                  )}
                  {timeslots && (
                    <Typography component="span">
                      {translate(
                        'job.manual_matching.alert_message_indication_2',
                      )}
                    </Typography>
                  )}
                </WrapperInline>
                {timeslots && (
                  <FormControl className={classes.customTop}>
                    <Field
                      name="timeslots"
                      validate={[required]}
                      component={RenderSelectTimeslotsField}
                      choices={enabledTimeslots}
                      label={translate('job.manual_matching.new_date_asked')}
                      optionValue="name"
                      optionText={element =>
                        formatDateTimeSlotFormValue(element)
                      }
                      allowEmpty={enabledTimeslots.length > 1}
                    />
                  </FormControl>
                )}
              </Grid>
              {reschedulingJob && declineJob && (
                <Grid item xs={5}>
                  <Typography className={classes.conditionsLabel}>
                    {translate(
                      'job.manual_matching.modifications_accepted_by_pro',
                    )}
                  </Typography>
                  <Button
                    className={classes.buttonConditions}
                    variant="contained"
                    type="submit"
                  >
                    {translate(
                      'job.manual_matching.modifications_accepted_button',
                    )}
                  </Button>
                  <Typography className={classes.conditionsLabel}>
                    {translate(
                      'job.manual_matching.modifications_refused_by_pro',
                    )}
                  </Typography>
                  <Button
                    className={classes.buttonConditions}
                    variant="contained"
                    onClick={() => declineJob()}
                  >
                    {translate(
                      'job.manual_matching.modifications_refused_button',
                    )}
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </form>
    )
  }
}

RescheduleManualMatching.propTypes = {
  classes: PropTypes.shape({
    card: PropTypes.string.isRequired,
    bgPink: PropTypes.string.isRequired,
    ErrorOutlineIcon: PropTypes.string.isRequired,
    listening: PropTypes.string.isRequired,
    conditionsLabel: PropTypes.string.isRequired,
    conditions: PropTypes.string.isRequired,
    buttonConditions: PropTypes.string.isRequired,
    customTop: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  translate: PropTypes.func.isRequired,
  reschedulingJob: PropTypes.func,
  declineJob: PropTypes.func,
  timeslots: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      stopTime: PropTypes.string,
    }),
  ),
}

RescheduleManualMatching.defaultProps = {
  history: { goBack: () => {} },
  timeslots: undefined,
  reschedulingJob: undefined,
  declineJob: undefined,
}

const mapStateToProps = (state, ownProps) => {
  const enabledTimeslots =
    ownProps.timeslots &&
    ownProps.timeslots.filter(timeslot => timeslot.enabled === true)
  return {
    state,
    initialValues: {
      timeslots:
        enabledTimeslots && enabledTimeslots.length === 1
          ? `${enabledTimeslots[0].startTime},${enabledTimeslots[0].stopTime}`
          : null,
    },
  }
}

const afterSubmit = (result, dispatch) => dispatch(reset(RESCHEDULING_JOB_FORM))

export default compose(
  withStyles(styles),
  i18n,
  connect(mapStateToProps),
  reduxForm({
    form: RESCHEDULING_JOB_FORM,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmit: (values, dispatch, props) => {
      const { reschedulingJob } = props
      reschedulingJob()
    },
    onSubmitSuccess: afterSubmit,
  }),
  withRouter,
)(RescheduleManualMatching)
