import { all, call, select, takeLatest } from 'redux-saga/effects'
import { handleRequest } from 'helpers/store/sagasHelpers'
import client from 'services/httpClient/proApiClient'
import { firmCommentsSearchParamsSelector } from 'store/firmComments/firmCommentSelectors'
import {
  UPDATE_FIRM_DETAILS_TRANSITION,
  UPDATE_FIRM_DETAILS,
} from 'store/firms/firmActions'
import {
  GET_FIRM_COMMENTS_LIST,
  SAVE_FIRM_COMMENT,
  getFirmCommentsList,
  saveFirmComment,
} from './firmCommentActions'

export function* handleSaveFirmComment({ firmIri, firmComment }) {
  try {
    yield handleRequest({
      requestActions: saveFirmComment,
      promise: call(client.post, '/api/firm_comments', {
        firm: firmIri,
        comment: firmComment,
      }),
    })
  } catch (e) {
    console.error(e)
  }
}

function* handleReadFirmCommentListRequest(searchParams) {
  const firmCommentsSearchParams = yield select(
    firmCommentsSearchParamsSelector,
  )

  const searchParameters = {
    params: searchParams.params || firmCommentsSearchParams,
  }

  try {
    yield handleRequest({
      requestActions: getFirmCommentsList,
      promise: call(client.get, `/api/firm_comments`, {
        ...searchParameters,
        params: {
          ...searchParameters.params,
          page: searchParameters.params.page + 1,
        },
      }),
      actionParams: {
        triggerModalLoader: true,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export default function*() {
  yield all([
    takeLatest(
      [GET_FIRM_COMMENTS_LIST.REQUEST, SAVE_FIRM_COMMENT.SUCCESS],
      handleReadFirmCommentListRequest,
    ),
    takeLatest(
      [UPDATE_FIRM_DETAILS_TRANSITION.SUCCESS, UPDATE_FIRM_DETAILS.SUCCESS],
      handleReadFirmCommentListRequest,
    ),
  ])
}
