import { connect } from 'react-redux'
import { compose } from 'redux'
import { FIRM_CERTIFICATE_FORM } from 'constants/forms'
import {
  firmCertificateLoadingSelector,
  firmCertificateTypesToChoicesSelector,
  firmCertificateFormCategoriesToChoicesSelector,
  certificateTypeSelectedSelector,
} from 'store/firms/firmSelectors'
import { change } from 'redux-form'
import FirmCertificateSelect from './FirmCertificateSelect'

const mapStateToProps = (state, ownProps) => {
  const { initialValues } = ownProps.formOption

  return {
    certificateType: certificateTypeSelectedSelector(state),
    firmCertificateLoading: firmCertificateLoadingSelector(state),
    firmCertificateTypes: firmCertificateTypesToChoicesSelector(state),
    firmCertificateTypeCategories: firmCertificateFormCategoriesToChoicesSelector(
      state,
    ),
    initialValues,
    ...ownProps,
  }
}

const mapDispatchToProps = dispatch => ({
  resetCategories: () =>
    dispatch(
      change(
        FIRM_CERTIFICATE_FORM.NAME,
        FIRM_CERTIFICATE_FORM.FIELDS.CATEGORIES,
        null,
      ),
    ),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FirmCertificateSelect,
)
