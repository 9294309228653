import { connect } from 'react-redux'
import { isLoggedInSelector } from 'store/Application/ApplicationSelectors'

import PrivateRoute from './PrivateRoute'

const mapStateToProps = state => {
  const isLoggedIn = isLoggedInSelector(state)

  return { isLoggedIn }
}

export default connect(mapStateToProps)(PrivateRoute)
