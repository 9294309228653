import { PHONE_CALLING_CODE } from 'constants/locales'

export const phoneNumberNormalizationForSearch = values => {
  const { phoneNumber } = values
  if (phoneNumber && phoneNumber[0] === '0') {
    return {
      ...values,
      phoneNumber: PHONE_CALLING_CODE + parseInt(phoneNumber, 10),
    }
  }

  return values
}
