import React from 'react'
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import PropTypes from 'prop-types'

const Feature = ({ name, enabled }) => {
  const divStyle = {
    position: 'relative',
    'padding-left': '50px',
  }

  const imgStyle = {
    width: '50px',
    height: 'auto',
    display: 'inline-flex',
    position: 'absolute',
    left: 0,
  }

  return (
    <div style={divStyle}>
      <FormGroup>
        <FormControlLabel
          htmlFor={name}
          name={name}
          control={<Switch name={name} size="small" checked={enabled} />}
          label={name}
        />
        {enabled && (
          <img
            src={require(`assets/feature.gif`)}
            alt="enabled"
            style={imgStyle}
          />
        )}
      </FormGroup>
    </div>
  )
}

Feature.propTypes = {
  name: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
}

export default Feature
