const base = {
  fontSize: 12,
  margin: 0,
}

export default {
  title: {
    ...base,
  },
  ul: {
    ...base,
    marginBottom: 10,
  },
}
