import React from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  withStyles,
} from '@material-ui/core'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { getPartnerIconUrl } from 'helpers/utils/job/job'
import JobDetailsOrderContainer from './JobDetailsOrder/JobDetailsOrderContainer'
import JobDetailsPackagesContainer from './JobDetailsPackages/JobDetailsPackagesContainer'
import JobDetailsClientContainer from './JobDetailsClient/JobDetailsClientContainer'
import JobDetailsProductContainer from './JobDetailsProduct/JobDetailsProductContainer'
import styles from './JobDetailsStyles'

const JobDetailsClientOrder = ({ translate, classes, jobId, partnerId }) => {
  const partnerUrl = getPartnerIconUrl(partnerId)

  return (
    <Card className={classes.firstCard}>
      <div className={classes.orderTitle}>
        <CardHeader
          classes={{
            title: classes.subtitlesOrder,
          }}
          title={translate('resources.jobs.subtitles.order')}
        />
        <CardMedia className={classes.imgPartner} image={partnerUrl} />
      </div>
      <CardContent>
        <JobDetailsOrderContainer jobId={jobId} />
      </CardContent>
      <CardHeader
        classes={{
          title: classes.subtitlesPackages,
        }}
        title={translate('resources.jobs.subtitles.packages')}
      />
      <CardContent>
        <JobDetailsPackagesContainer />
      </CardContent>
      <CardHeader
        classes={{
          title: classes.subtitlesClient,
        }}
        title={translate('resources.jobs.subtitles.client')}
      />
      <CardContent>
        <JobDetailsClientContainer jobId={jobId} />
      </CardContent>
      <CardHeader
        classes={{
          title: classes.subtitlesClient,
        }}
        title={translate('resources.jobs.fields.products')}
      />
      <CardContent>
        <JobDetailsProductContainer jobId={jobId} />
      </CardContent>
    </Card>
  )
}

JobDetailsClientOrder.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    firstCard: PropTypes.string.isRequired,
    iconOrder: PropTypes.string.isRequired,
    subtitlesOrder: PropTypes.string.isRequired,
    secondCard: PropTypes.string.isRequired,
    iconProfile: PropTypes.string.isRequired,
    subtitlesClient: PropTypes.string.isRequired,
    imgPartner: PropTypes.string.isRequired,
    orderTitle: PropTypes.string.isRequired,
    subtitlesPackages: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  jobId: PropTypes.string,
  partnerId: PropTypes.string,
}

JobDetailsClientOrder.defaultProps = {
  jobId: '',
  partnerId: undefined,
}

export default compose(
  i18n,
  withRouter,
  withStyles(styles),
)(JobDetailsClientOrder)
