export default {
  root: {
    width: '100%',
    overflowX: 'auto',
    backgroundColor: '#DAE1E7',
  },
  menuLink: {
    textDecoration: 'none',
  },
}
