import { createSelector } from 'reselect'
import get from 'lodash/get'
import { GEOZONES_DEPARTMENTS } from 'constants/dashboard'
import { selectedGeozoneSelector } from 'store/Application/ApplicationSelectors'
import {
  dashboardSearchParamsSelector,
  searchParamsSelector,
} from 'store/jobs/jobSelectors'
import lodashIntersection from 'lodash/intersection'
import lodashConcat from 'lodash/concat'

export const initialState = {
  status: null,
  list: [],
}

const departmentsSelector = state => get(state, 'departments')

export const departmentsListSelector = createSelector(
  departmentsSelector,
  state => {
    const list = get(state, 'list', [])

    return list.reduce(
      (acc, currentValue) => [
        ...acc,
        {
          ...currentValue,
          name: `${currentValue.name} (${currentValue.code})`,
        },
      ],
      [],
    )
  },
)

export const departmentsListByZoneSelector = createSelector(
  departmentsSelector,
  state => {
    const list = get(state, 'list', [])

    const departments = {}
    Object.keys(GEOZONES_DEPARTMENTS).forEach(zone => {
      list.forEach(dep => {
        if (GEOZONES_DEPARTMENTS[zone].includes(dep.code)) {
          if (departments[zone] === undefined) {
            departments[zone] = []
          }
          departments[zone].push(dep)
        }
      })
    })

    return departments
  },
)

export const dbDepsZoneSelectedSelector = createSelector(
  departmentsListByZoneSelector,
  dashboardSearchParamsSelector,
  searchParamsSelector,
  selectedGeozoneSelector,
  (departments, dashboardSearchParams, searchParams, selectedZone) => {
    const dbsCodes =
      searchParams.postCodes || dashboardSearchParams.postCodes || []

    if (selectedZone === 'all' || dbsCodes.length === 0) {
      return []
    }

    let response = []
    Object.keys(departments).forEach(geozone => {
      if (departments[geozone]) {
        response = lodashConcat(
          response,
          lodashIntersection(
            departments[geozone].map(dep => dep.code),
            dbsCodes,
          ),
        )
      }
    })

    return response
  },
)

export const hasDepartmentsSelector = createSelector(
  departmentsSelector,
  state => get(state, 'list', []).length > 0,
)
