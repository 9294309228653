export default theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    backgroundColor: '#d9e1e8',
  },
  menuLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    display: 'inline-flex',
  },
  dateStatus: {
    display: 'block',
    marginBottom: '5px',
  },
  icon: {
    height: '12px',
    width: '12px',
    color: theme.palette.primary.main,
    margin: '4px 6px',
  },
  buttonBigger: theme.buttonBigger,
  buttons: {
    display: 'flex',
    gap: '10px',
  },
})
