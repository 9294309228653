import { initialState } from './saleInvoiceSelectors'
import {
  GET_SALE_INVOICES_LIST,
  GET_SINGLE_SALE_INVOICE,
  RESET_FILTER_SALE_INVOICE,
} from './saleInvoiceActions'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SALE_INVOICES_LIST.REQUEST:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          query: action.query,
          state: action.state,
          page: action.page,
          rowsPerPage: action.rowsPerPage,
          orderBy: action.orderBy || null,
          sort: action.sort || initialState.searchParams.sort,
        },
        currentSaleInvoice: initialState.currentSaleInvoice,
      }
    case GET_SALE_INVOICES_LIST.SUCCESS:
      return {
        ...state,
        list: action.payload['hydra:member'],
        searchParams: {
          ...state.searchParams,
          total: action.payload['hydra:totalItems'],
        },
      }
    case RESET_FILTER_SALE_INVOICE:
      return {
        ...state,
        searchParams: initialState.searchParams,
      }
    case GET_SINGLE_SALE_INVOICE.SUCCESS:
      return {
        ...state,
        currentSaleInvoice: action.payload,
      }
    default: {
      return state
    }
  }
}
