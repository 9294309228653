import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { touch } from 'redux-form'
import {
  getInvoiceDetails,
  invoiceCartAddItem,
  setSubjectToVat,
  updateInvoice as updateInvoiceActionRequest,
} from 'store/invoices/invoiceActions'
import {
  currentInvoiceSelector,
  companySelector,
  issuedAtSelector,
  orderNumberSelector,
  citySelector,
  postcodeSelector,
  streetSelector,
  capitalAmountSelector,
  firmSubjectToVatSelector,
  headquartersStreetSelector,
  headquartersCitySelector,
  headquartersPostcodeSelector,
  firstNameSelector,
  lastNameSelector,
  legalStatusSelector,
  registrationCitySelector,
  registrationNumberSelector,
  vatNumberSelector,
  cartSubtotalSelector,
  cartTaxTotalSelector,
  cartTotalSelector,
  cartItemsSelector,
} from 'store/invoices/invoiceSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import {
  FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY,
  FIELD_INVOICE_ORDER_NUMBER,
  FIELD_INVOICE_ISSUED_AT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER,
  FIELD_INVOICE_ITEMS,
  FIELD_INVOICE_ITEMS_FLAG,
  FIELD_INVOICE_SUBTOTAL,
  FIELD_INVOICE_TAX_TOTAL,
  FIELD_INVOICE_TOTAL,
  INVOICE_ACTION_UPDATE_THEN_VALIDATE,
} from 'constants/invoices'
import { EDIT_INVOICE_FORM } from 'constants/forms'
import { isSubjectToVat } from 'helpers/utils/firm/firm'
import UpdateInvoice from './UpdateInvoice'

class UpdateInvoiceContainer extends PureComponent {
  componentDidMount() {
    const { getInvoice } = this.props
    getInvoice()
  }

  render() {
    const { invoice, isLoading, ...props } = this.props

    return invoice && !isLoading && <UpdateInvoice {...props} />
  }
}

UpdateInvoiceContainer.defaultProps = {
  invoice: undefined,
}

UpdateInvoiceContainer.propTypes = {
  invoice: PropTypes.shape({}),
  getInvoice: PropTypes.func.isRequired,
  addNewItem: PropTypes.func.isRequired,
  handleChangeSubjectToVat: PropTypes.func.isRequired,
  forceValidation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
}

const mapStateToProps = state => ({
  invoice: currentInvoiceSelector(state),
  isLoading: isLoadingSelector(state),
  initialValues: {
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY]: companySelector(state),
    [FIELD_INVOICE_ISSUED_AT]: issuedAtSelector(state),
    [FIELD_INVOICE_ORDER_NUMBER]: orderNumberSelector(state),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS]: legalStatusSelector(
      state,
    ),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT]: capitalAmountSelector(
      state,
    ),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER]: registrationNumberSelector(
      state,
    ),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET]: headquartersStreetSelector(
      state,
    ),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY]: headquartersCitySelector(
      state,
    ),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE]: headquartersPostcodeSelector(
      state,
    ),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET]: streetSelector(state),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY]: citySelector(state),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE]: postcodeSelector(state),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY]: registrationCitySelector(
      state,
    ),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER]: vatNumberSelector(state),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME]: firstNameSelector(state),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME]: lastNameSelector(state),
    [FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT]: firmSubjectToVatSelector(
      state,
    ),
    [FIELD_INVOICE_ITEMS]: cartItemsSelector(state),
    [FIELD_INVOICE_SUBTOTAL]: cartSubtotalSelector(state),
    [FIELD_INVOICE_TAX_TOTAL]: cartTaxTotalSelector(state),
    [FIELD_INVOICE_TOTAL]: cartTotalSelector(state),
  },
})

const mapDispatchToProps = (dispatch, { invoiceId }) => ({
  getInvoice: () => dispatch(getInvoiceDetails.request({ invoiceId })),
  addNewItem: () => dispatch(invoiceCartAddItem()),
  handleChangeSubjectToVat: event =>
    dispatch(
      setSubjectToVat({
        isSubjectToVat: isSubjectToVat(event.target.value),
      }),
    ),
  forceValidation: () => {
    // usefull to force the redux-form validation & the error message displaying
    dispatch(touch(EDIT_INVOICE_FORM, FIELD_INVOICE_ITEMS_FLAG))
  },
  updateInvoice: values =>
    dispatch(
      updateInvoiceActionRequest.request({
        validate: values.action === INVOICE_ACTION_UPDATE_THEN_VALIDATE,
      }),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateInvoiceContainer)
