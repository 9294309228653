import { all, takeLatest, put, call } from 'redux-saga/effects'
import translate from 'providers/i18n/translateService'
import {
  INVOICE_TRANSITION_CONTEST,
  INVOICE_TRANSITION_CANCEL_CONTEST,
  INVOICE_TRANSITION_REEDITION,
  INVOICE_TRANSITION_PAY,
} from 'constants/invoices'
import {
  invoiceContestTransition,
  invoiceCancelContestTransition,
  invoiceReeditionTransition,
  invoicePayTransition,
  INVOICE_CONTEST_TRANSITION_REQ,
  INVOICE_CANCEL_CONTEST_TRANSITION_REQ,
  INVOICE_REEDITION_TRANSITION_REQ,
  INVOICE_PAY_TRANSITION_REQ,
} from 'store/invoices/transitions/invoiceTransitionActions'
import { getInvoiceJob, GET_INVOICE_JOB } from 'store/jobs/jobActions'
import { showNotification } from 'store/Application/ApplicationActions'
import { ERROR, INFO } from 'constants/variant'
import billingClient from 'services/httpClient/billingClient'
import { handleRequest } from 'helpers/store/sagasHelpers'
import { billingAPI } from '../invoiceSagas'

function* handleGetInvoiceJob({ invoiceId }) {
  const promise = call(billingClient.get, `invoices/${invoiceId}/content`)
  const options = { triggerModalLoader: true }
  yield* billingAPI(getInvoiceJob, promise, options, 'invoice')
}

const getActionFromTransition = transition => {
  switch (transition) {
    case INVOICE_TRANSITION_CANCEL_CONTEST:
      return invoiceCancelContestTransition
    case INVOICE_TRANSITION_CONTEST:
      return invoiceContestTransition
    case INVOICE_TRANSITION_REEDITION:
      return invoiceReeditionTransition
    case INVOICE_TRANSITION_PAY:
      return invoicePayTransition
    default:
      return ''
  }
}

function* applyTransitionToInvoice(invoiceId, transition, data = {}) {
  try {
    const action = getActionFromTransition(transition)
    const url = `/purchase-invoices/${invoiceId}/transition/${transition}`
    const options = { triggerModalLoader: true }

    yield* handleRequest({
      requestActions: action,
      promise: call(billingClient.post, url, data),
      actionParams: options,
    })
    yield put(
      showNotification({
        payload: {
          message: translate(`invoice.transition.${transition}.success`, {
            transition,
          }),
          messageType: INFO,
        },
      }),
    )
  } catch (e) {
    console.error(`Error while applying transition ${transition}`)
    yield put(
      showNotification({
        payload: {
          messageType: ERROR,
          message: translate(`invoice.transition.${transition}.error`, {
            transition,
          }),
        },
      }),
    )
  }
}

function* handleInvoiceCancelContestTransitionRequest({ invoiceId }) {
  yield applyTransitionToInvoice(invoiceId, INVOICE_TRANSITION_CANCEL_CONTEST)
}

function* handleInvoiceContestTransitionRequest({ invoiceId, contestation }) {
  const data = { reasonDispute: contestation }
  yield applyTransitionToInvoice(invoiceId, INVOICE_TRANSITION_CONTEST, data)
}

function* handleInvoiceReeditionTransitionRequest({ invoiceId }) {
  yield applyTransitionToInvoice(invoiceId, INVOICE_TRANSITION_REEDITION)
}

function* handleInvoicePayTransitionRequest({ invoiceId }) {
  yield applyTransitionToInvoice(invoiceId, INVOICE_TRANSITION_PAY)
}

export default function*() {
  yield all([
    takeLatest(
      INVOICE_CANCEL_CONTEST_TRANSITION_REQ.REQUEST,
      handleInvoiceCancelContestTransitionRequest,
    ),
    takeLatest(
      INVOICE_CONTEST_TRANSITION_REQ.REQUEST,
      handleInvoiceContestTransitionRequest,
    ),
    takeLatest(
      INVOICE_REEDITION_TRANSITION_REQ.REQUEST,
      handleInvoiceReeditionTransitionRequest,
    ),
    takeLatest(
      INVOICE_PAY_TRANSITION_REQ.REQUEST,
      handleInvoicePayTransitionRequest,
    ),
    takeLatest(GET_INVOICE_JOB.REQUEST, handleGetInvoiceJob),
  ])
}
