import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { saveAs } from 'file-saver'
import { handleRequest } from 'helpers/store/sagasHelpers'
import billingClient from 'services/httpClient/billingClient'
import translate from 'providers/i18n/translateService'
import { showNotification } from 'store/Application/ApplicationActions'
import { ERROR } from 'constants/variant'
import { FILTER_SALE_INVOICES_LIST_FORM } from 'constants/forms'
import {
  getSaleInvoicesList,
  getSaleInvoicesExport,
  GET_SALE_INVOICES_LIST,
  GET_SALE_INVOICES_EXPORT,
  RESET_FILTER_SALE_INVOICE,
  GET_SINGLE_SALE_INVOICE,
  getSingleSaleInvoice,
} from './saleInvoiceActions'
import { searchParamsSelector } from './saleInvoiceSelectors'

const buildInvoiceParams = searchParams => {
  const urlParams = {}

  if (searchParams.query) {
    urlParams.query = searchParams.query
  }

  if (searchParams.state) {
    urlParams.state = searchParams.state
  }

  if (!searchParams.export && searchParams.rowsPerPage) {
    urlParams.page = searchParams.page + 1
    urlParams.perPage = searchParams.rowsPerPage
  }

  if (searchParams.orderBy && searchParams.sort) {
    urlParams[`order[${searchParams.orderBy}]`] = searchParams.sort
  }

  return urlParams
}

function* handleGetSaleInvoicesList(searchParams) {
  try {
    yield* handleRequest({
      requestActions: getSaleInvoicesList,
      promise: call(billingClient.get, 'sale-invoices', {
        params: buildInvoiceParams(searchParams),
      }),
      actionParams: {
        triggerModalLoader: true,
      },
    })
  } catch (e) {
    console.error(e)
    yield put(
      showNotification({
        payload: {
          messageType: ERROR,
          message: translate('saleInvoice.saleInvoice_list.req_failure'),
        },
      }),
    )
  }
}

function* handleGetSaleInvoicesExport(searchParams) {
  try {
    const promise = call(
      billingClient.get,
      'sale-invoices.csv?pagination=false',
      {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
        params: buildInvoiceParams(searchParams),
      },
    )

    const response = yield handleRequest({
      requestActions: getSaleInvoicesExport,
      promise,
      actionParams: {
        triggerModalLoader: true,
      },
    })

    // invoke 'Save As' dialog
    saveAs(response.data, 'partner-invoices_export.csv')
  } catch (e) {
    console.error(e)
    yield put(
      showNotification({
        payload: {
          messageType: ERROR,
          message: translate('saleInvoice.saleInvoice_export.req_failure'),
        },
      }),
    )
  }
}

export function* handleResetFilterSaleInvoice() {
  // reset redux form
  yield put(reset(FILTER_SALE_INVOICES_LIST_FORM))

  // force search reloading with default params
  const searchParams = yield select(searchParamsSelector)
  yield put(getSaleInvoicesList.request(searchParams))
}

function* handleGetSingleSaleInvoice({ saleInvoiceIri }) {
  try {
    yield* handleRequest({
      requestActions: getSingleSaleInvoice,
      promise: call(billingClient.get, `${saleInvoiceIri}/content`),
      actionParams: {
        triggerModalLoader: true,
      },
    })
  } catch (e) {
    yield put(
      showNotification({
        payload: {
          message: translate('resources.saleInvoice.preview.error'),
          messageType: ERROR,
        },
      }),
    )
  }
}

export default function*() {
  yield all([
    takeLatest(GET_SALE_INVOICES_LIST.REQUEST, handleGetSaleInvoicesList),
    takeLatest(GET_SALE_INVOICES_EXPORT.REQUEST, handleGetSaleInvoicesExport),
    takeLatest(RESET_FILTER_SALE_INVOICE, handleResetFilterSaleInvoice),
    takeLatest(GET_SINGLE_SALE_INVOICE.REQUEST, handleGetSingleSaleInvoice),
  ])
}
