import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { Button, FormControl, Grid, withStyles } from '@material-ui/core'
import { ImportExport } from '@material-ui/icons'
import i18n from 'providers/i18n/I18nProvider'
import { FILTER_SALE_INVOICES_LIST_FORM } from 'constants/forms'
import { SALE_INVOICES_STATES } from 'constants/saleInvoices'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectMultipleAutocompleteField from 'components/shared/Form/RenderSelectMultipleAutocompleteField'
import styles from './SaleInvoicesFiltersStyles'

const SaleInvoicesFilters = ({
  classes,
  translate,
  handleSearchChange,
  handleFiltersChange,
  handleExportClick,
}) => (
  <Grid container justifyContent="flex-start">
    <FormControl className={classes.formControl}>
      <Field
        name="query"
        type="search"
        component={RenderTextField}
        label={translate('saleInvoice.filter.search.label')}
        onChange={handleSearchChange}
      />
    </FormControl>
    <FormControl className={classes.formControl}>
      <Field
        className={classes.selectEmpty}
        name="state"
        id="stateAutocompleteSelect"
        component={RenderSelectMultipleAutocompleteField}
        onChange={values => handleFiltersChange('state', values)}
        label={translate('saleInvoice.status.label')}
        choices={SALE_INVOICES_STATES}
        optionValue="code"
      />
    </FormControl>
    <Button
      onClick={handleExportClick}
      variant="contained"
      color="default"
      className={classes.button}
    >
      {translate('saleInvoice.buttons.export')}
      <ImportExport className={classes.rightIcon} />
    </Button>
  </Grid>
)

SaleInvoicesFilters.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    selectEmpty: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    rightIcon: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  handleExportClick: PropTypes.func.isRequired,
}

export default compose(
  i18n,
  reduxForm({
    form: FILTER_SALE_INVOICES_LIST_FORM,
  }),
  withStyles(styles),
)(SaleInvoicesFilters)
