import {
  generateSimpleAction,
  generateRequestActions,
  createRequestTypes,
} from 'helpers/store/actionsHelpers'

export const APPLICATION_IS_READY = 'APPLICATION_IS_READY'
export const AUTH_SET_LOGGED_IN = 'AUTH_SET_LOGGED_IN'
export const APPLICATION_IS_LOADING = 'APPLICATION_IS_LOADING'
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_BAR_TITLE = 'SET_BAR_TITLE'
export const SET_SHOW_JOB_STATUS = 'SET_SHOW_JOB_STATUS'
export const SET_SHOW_GEOZONE_SELECTOR = 'SET_SHOW_GEOZONE_SELECTOR'
export const SET_SCROLL_TOP = 'SET_SCROLL_TOP'
export const AUTH_LOGIN_ENTERPRISE = createRequestTypes('AUTH_LOGIN_ENTERPRISE')
export const authLoginEnterprise = generateRequestActions(AUTH_LOGIN_ENTERPRISE)
export const setLoggedIn = generateSimpleAction(AUTH_SET_LOGGED_IN)
export const authLogout = generateSimpleAction(AUTH_LOGOUT)
export const showNotification = generateSimpleAction(SHOW_NOTIFICATION)
export const hideNotification = generateSimpleAction(HIDE_NOTIFICATION)
export const setBarTitle = generateSimpleAction(SET_BAR_TITLE)
export const setShowJobStatus = generateSimpleAction(SET_SHOW_JOB_STATUS)
export const setShowGeozoneSelector = generateSimpleAction(
  SET_SHOW_GEOZONE_SELECTOR,
)
export const setScrollTop = generateSimpleAction(SET_SCROLL_TOP)

export const loadApplication = () => ({
  type: APPLICATION_IS_READY,
  isReady: true,
})

export const loadingData = (isLoading, trigger) => ({
  type: APPLICATION_IS_LOADING,
  isLoading,
  trigger,
})

export const SET_AUTH_TOKENS = 'SET_AUTH_TOKENS'
export const doSetAuthTokens = generateSimpleAction(SET_AUTH_TOKENS)
