import { connect } from 'react-redux'

import { invoicePayTransition } from 'store/invoices/transitions/invoiceTransitionActions'
import {
  invoiceIdSelector,
  invoiceStatusSelector,
} from 'store/jobs/jobSelectors'

import PaymentStep from './PaymentStep'

const mapStateToProps = state => ({
  invoiceId: invoiceIdSelector(state),
  invoiceStatus: invoiceStatusSelector(state),
})

const mapDispatchToProps = dispatch => ({
  dispatchInvoicePayTransition: invoiceId =>
    dispatch(invoicePayTransition.request({ invoiceId })),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStep)
