import { withStyles, Tab, Tabs } from '@material-ui/core'

export const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  form: {
    width: '100%',
  },
  checked: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
})

export const VerticalTabs = withStyles(() => ({
  flexContainer: {
    flexDirection: 'column',
  },
  indicator: {
    display: 'none',
  },
}))(Tabs)

export const MyTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: '400',

    '&:hover': {
      backgroundColor: `${theme.palette.blueLight.main}`,
      color: `${theme.palette.black.main}`,
    },
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main}`,
    color: `${theme.palette.white.main}`,
  },
}))(Tab)
