const styles = theme => ({
  informationsSubtitle: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h2.fontWeight,
    marginTop: 0,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  history: {
    width: '100%',
    clear: 'both',
    paddingTop: '1rem',
  },
  p: {
    fontSize: 'small',
  },
  tdDate: {
    minWidth: '150px',
  },
  multiLine: {
    whiteSpace: 'pre-line',
  },
  statusChange: {
    '&:nth-child(even)': {
      backgroundColor: '#fdeffe',
    },
    '&:nth-child(2n+1)': {
      backgroundColor: '#f0e1f4',
    },
  },
  newComment: {
    marginBottom: '2rem',
  },
  inputText: {
    width: '100%',
    height: '65px',
    padding: '20px',
    fontSize: '0.9rem',
    marginBottom: '1rem',
  },
  buttonBigger: theme.buttonBigger,
})

export default styles
