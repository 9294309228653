import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import {
  List,
  withStyles,
  Menu as MenuMaterial,
  MenuItem as MenuItemMaterial,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import {
  Dashboard,
  Search,
  List as ListPros,
  AccountBalance,
} from '@material-ui/icons'
import MenuItem from 'components/shared/MenuItem/MenuItem'
import {
  ALL_INVOICES_LIST_PATH,
  DASHBOARD_PATH,
  JOBS_SEARCH_PATH,
  PROS_LIST_PATH,
} from 'constants/routes'
import { resetIncidentList } from 'store/incidents/incidentActions'
import { resetJobsSearch } from 'store/jobs/jobActions'
import { resetFilterFirm } from 'store/firms/firmActions'
import { ID_TOKEN } from 'constants/storage'
import { SEARCH } from 'constants/Jobs'
import { authLogout } from 'store/Application/ApplicationActions'
import Icon from 'components/shared/Icon/Icon'
import styles from './MenuStyles'

class Menu extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
    }
  }

  isActive = (item, location) => {
    const loc = location.pathname.split('/')
    return loc[2] === item
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }

  handleMenuLogout = () => {
    const { logout } = this.props
    logout()
  }

  render() {
    const {
      classes,
      handleJobReset,
      handleFirmReset,
      location,
      translate,
    } = this.props

    const { anchorEl } = this.state
    const isMenuOpen = Boolean(anchorEl)
    const token = localStorage.getItem(ID_TOKEN)
    const userBackoffice = jwtDecode(token).name

    const renderMenu = (
      <MenuMaterial
        className={classes.popOver}
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        <MenuItemMaterial onClick={this.handleMenuLogout}>
          {translate('app.menu.logout')}
        </MenuItemMaterial>
      </MenuMaterial>
    )

    return (
      <>
        {/* It has to be a Link component to use ListItem, CardMedia and
          have the appropriate CSS on the menu */}
        <Link to={() => false} className={classes.adminLink}>
          {renderMenu}
          <ListItem
            button
            className={classes.isActive}
            aria-owns={isMenuOpen}
            aria-haspopup="true"
            onClick={this.handleProfileMenuOpen}
          >
            <Icon className={classes.imgIconLink} icon="admin.svg" />
            <ListItemText className={classes.adminName}>
              {userBackoffice}
            </ListItemText>
          </ListItem>
        </Link>
        <List component="nav" className={classes.root}>
          <MenuItem
            translateId="sidebar.dashboard.title"
            path={DASHBOARD_PATH}
            icon={<Dashboard />}
            imageType="menu_link_dashboard"
            active={this.isActive('dashboard', location)}
            onClick={handleJobReset}
          />
          <MenuItem
            translateId="sidebar.search_job.title"
            path={JOBS_SEARCH_PATH}
            icon={<Search />}
            imageType="menu_link_intervention"
            jobStatus={SEARCH}
            active={this.isActive('jobs', location)}
            onClick={handleJobReset}
          />
          <MenuItem
            translateId="sidebar.firms.menu.firm_all"
            path={PROS_LIST_PATH}
            icon={<ListPros />}
            imageType="menu_link_pro"
            active={this.isActive('pros', location)}
            onClick={handleFirmReset}
          />
          <MenuItem
            translateId="sidebar.allInvoices.title"
            path={ALL_INVOICES_LIST_PATH}
            icon={<AccountBalance />}
            imageType="menu_link_bill"
            active={this.isActive('all-invoices', location)}
          />
        </List>
      </>
    )
  }
}

Menu.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    adminLink: PropTypes.string.isRequired,
    adminName: PropTypes.string.isRequired,
    isActive: PropTypes.string.isRequired,
    imgIconLink: PropTypes.string.isRequired,
    popOver: PropTypes.string.isRequired,
    arrow: PropTypes.string.isRequired,
  }).isRequired,
  handleFirmReset: PropTypes.func.isRequired,
  handleJobReset: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  handleJobReset: () => {
    dispatch(resetJobsSearch())
    dispatch(resetIncidentList())
  },
  handleFirmReset: () => dispatch(resetFilterFirm()),
  logout: () => dispatch(authLogout()),
})

export default compose(
  withRouter,
  i18n,
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(Menu)
