import translate from 'providers/i18n/translateService'
import { PRO_DOCUMENTS } from 'constants/documents'

export const validate = values => {
  // Translate errors
  const errors = {}

  if (!values.documentName) {
    return errors
  }

  if (
    PRO_DOCUMENTS.find(fileType => fileType.name === values.documentName).meta
      .issueDate &&
    !values.issueDate
  ) {
    errors.issueDate = translate(
      'resources.firms.fields.document.issueDateError',
    )
  }

  if (
    PRO_DOCUMENTS.find(fileType => fileType.name === values.documentName).meta
      .expirationDate &&
    !values.expirationDate
  ) {
    errors.expirationDate = translate(
      'resources.firms.fields.document.expirationDateError',
    )
  }

  if (
    PRO_DOCUMENTS.find(fileType => fileType.name === values.documentName).meta
      .reference &&
    !values.reference
  ) {
    errors.reference = translate(
      'resources.firms.fields.document.referenceError',
    )
  }

  return errors
}
