import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './JobPicturesCarousel.css'
import { Carousel } from 'react-responsive-carousel'
import PropTypes from 'prop-types'
import { Cloudinary } from 'cloudinary-core'
import { Link } from '@material-ui/core'
import { formatDate } from 'helpers/utils/job/job'
import { FILE_ICONS, FILE_IMAGE } from 'constants/files'

const JobPicturesCarousel = ({ pictures }) => {
  const cloudinaryCore = new Cloudinary({ cloud_name: 'quotatis' })
  const getSrc = picture => {
    if (!picture.contentType || picture.contentType.includes(FILE_IMAGE)) {
      return picture.url || cloudinaryCore.url(picture.path)
    }

    return cloudinaryCore.url(FILE_ICONS[picture.contentType])
  }

  return (
    <Carousel autoPlay={false} showStatus={false} showIndicators={false}>
      {pictures.map(picture => (
        <div className="carousel-container" key={picture.id}>
          <img
            className="carousel-image"
            src={getSrc(picture)}
            alt={picture.origin}
          />
          <div className="legend">
            <h3>{picture.origin}</h3>
            <h5>{formatDate(picture.createdAt)}</h5>
            <h3>{picture.comment}</h3>
          </div>
          <Link
            className="carousel-link"
            href={picture.url || cloudinaryCore.url(picture.path)}
            target="_blank"
          />
        </div>
      ))}
    </Carousel>
  )
}

JobPicturesCarousel.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default JobPicturesCarousel
