import { createSelector } from 'reselect'
import get from 'lodash/get'
import { getCurrentCountry } from 'constants/countries'

export const initialState = {
  status: null,
  list: [],
}

const tradesSelector = state => get(state, 'trades')

export const allTradesListSelector = createSelector(tradesSelector, state =>
  get(state, 'list'),
)

export const tradesListSelector = createSelector(allTradesListSelector, data =>
  data.filter(option => option.countryCode === getCurrentCountry()),
)

export const hasTradesSelector = createSelector(
  tradesSelector,
  state => get(state, 'list', []).length > 0,
)
