import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, Field, reset } from 'redux-form'
import i18n from 'providers/i18n/I18nProvider'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Grid, Dialog, Button } from '@material-ui/core'
import { updateFirmCollaborators } from 'store/firms/firmActions'

import { UPDATE_COLLABORATOR_FORM } from 'constants/forms'
import RenderTextField from 'components/shared/Form/RenderTextField'
import { validatePhone, required } from 'helpers/form/validationHelpers'

const FirmCollaboratorsPopin = ({
  open,
  handleClose,
  handleSubmit,
  translate,
}) => (
  <Dialog open={open} onClose={handleClose}>
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item md={12}>
          <DialogTitle id="alert-dialog-title">
            {translate('collaborators.popin.title')}
          </DialogTitle>
        </Grid>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Field
                id="gender"
                name="gender"
                type="text"
                component={RenderTextField}
                label={translate('collaborators.popin.field.gender')}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Field
                id="name"
                name="name"
                type="text"
                component={RenderTextField}
                label={translate('collaborators.popin.field.name')}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Field
                id="status"
                name="status"
                type="text"
                component={RenderTextField}
                label={translate('collaborators.popin.field.status')}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Field
                id="email"
                name="email"
                type="text"
                component={RenderTextField}
                label={translate('collaborators.popin.field.email')}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item md={12}>
              <Field
                id="fixedPhone"
                name="fixedPhone"
                type="text"
                component={RenderTextField}
                label={translate('collaborators.popin.field.fixedPhone')}
                validate={[validatePhone]}
                fullWidth
              />
            </Grid>
            <Grid item md={12}>
              <Field
                id="mobilePhone"
                name="mobilePhone"
                type="text"
                component={RenderTextField}
                label={translate('collaborators.popin.field.mobilePhone')}
                validate={[validatePhone, required]}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <DialogActions align="right">
          <Button type="submit" variant="contained" color="secondary">
            {translate('app.action.validate')}
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            {translate('app.action.cancel')}
          </Button>
        </DialogActions>
      </Grid>
    </form>
  </Dialog>
)

FirmCollaboratorsPopin.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

const mapStateToProps = (state, { user }) => ({
  initialValues: {
    name: `${user.firstName} ${user.lastName}`,
    gender: user.gender,
    email: user.email,
    status: user.status,
    fixedPhone: user.fixedPhone,
    mobilePhone: user.mobilePhone,
  },
})

export default compose(
  i18n,
  connect(mapStateToProps),
  reduxForm({
    form: UPDATE_COLLABORATOR_FORM,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmit: ({ fixedPhone, mobilePhone }, dispatch, ownProps) => {
      dispatch(
        updateFirmCollaborators.request({
          data: {
            userId: ownProps.user['@id'],
            fixedPhone,
            mobilePhone,
          },
        }),
      )
    },
    onSubmitSuccess: (result, dispatch, { handleClose }) => {
      dispatch(reset(UPDATE_COLLABORATOR_FORM))
      handleClose()
    },
  }),
)(FirmCollaboratorsPopin)
