export default () => ({
  icon: {
    width: '18px',
    height: '15px',
    marginLeft: '10px',
    flex: 'none',
  },
  contentContainer: {
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  contentContainerFlex: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
})
