import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import {
  Button,
  withStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import { JOB_CANCELABLE_STATUSES } from 'constants/Jobs'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import styles from '../ManualMatchingStyles'

class DeclinedManualMatching extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      dialogOpened: false,
    }
  }

  openDialog = () => {
    this.setState({ dialogOpened: true })
  }

  handleDialogResponse = event => {
    const { declineJob } = this.props
    const { value } = event.currentTarget
    if (value === 'true') {
      declineJob()
    }
    this.setState({ dialogOpened: false })
  }

  render() {
    const { translate, status, signedReceipt, classes } = this.props
    const { dialogOpened } = this.state

    return (
      <>
        {dialogOpened && (
          <ConfirmDialog
            title={translate(
              'job.manual_matching.cancel_plan.confirm_dialog.title',
            )}
            onClose={this.handleDialogResponse}
          >
            <Typography component="p">
              {translate('job.manual_matching.cancel_plan.confirm_dialog.text')}
            </Typography>
            {signedReceipt && (
              <SnackbarContent
                className={classes.warning}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.message}>
                    <WarningIcon className={classes.icon} />
                    {translate(
                      'job.manual_matching.cancel_plan.delete_receipt_invoice_warning',
                    )}
                  </span>
                }
              />
            )}
          </ConfirmDialog>
        )}
        {JOB_CANCELABLE_STATUSES.includes(status) && (
          <Card>
            <CardContent>
              <Typography component="p">
                {translate('job.manual_matching.cancel_plan.help_text')}
              </Typography>
              {signedReceipt && (
                <SnackbarContent
                  className={classes.warning}
                  aria-describedby="client-snackbar"
                  message={
                    <span id="client-snackbar" className={classes.message}>
                      <WarningIcon className={classes.icon} />
                      {translate(
                        'job.manual_matching.cancel_plan.delete_receipt_invoice_warning',
                      )}
                    </span>
                  }
                />
              )}
              <Button
                variant="contained"
                onClick={this.openDialog}
                className={classes.buttonBigger}
              >
                {translate('job.manual_matching.cancel_plan.btn_label')}
              </Button>
            </CardContent>
          </Card>
        )}
      </>
    )
  }
}

DeclinedManualMatching.propTypes = {
  translate: PropTypes.func.isRequired,
  declineJob: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    warning: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  status: PropTypes.string,
  signedReceipt: PropTypes.bool,
}

DeclinedManualMatching.defaultProps = {
  status: undefined,
  signedReceipt: undefined,
}

export default compose(withStyles(styles), i18n)(DeclinedManualMatching)
