import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { FILTER_SALE_ALL_INVOICES_LIST_FORM } from 'constants/forms'
import {
  getInvoicesList,
  resetInvoicesList,
  exportInvoicesList,
  setDisplayedInvoice,
} from 'store/invoices/invoiceActions'
import {
  invoicesListSelector,
  searchParamsSelector,
  displayedInvoiceSelector,
} from 'store/invoices/invoiceSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import AllInvoicesList from './AllInvoicesList'

class AllInvoicesListContainer extends Component {
  constructor(props) {
    super(props)

    this.loadInvoices = this.loadInvoices.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangePerPage = this.handleChangePerPage.bind(this)
    this.handleChangeSort = this.handleChangeSort.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleReset = this.handleReset.bind(this)
  }

  handleChangePage(_, page) {
    const { searchParams } = this.props

    const { total, ...params } = searchParams

    this.loadInvoices({
      searchParams: {
        ...params,
        page,
      },
    })
  }

  handleChangePerPage(event) {
    const { searchParams } = this.props
    const { total, rowsPerPage, page, ...params } = searchParams

    this.loadInvoices({
      searchParams: {
        ...params,
        page: 0,
        rowsPerPage: event.target.value,
      },
    })
  }

  handleChangeSort(orderBy) {
    const { searchParams } = this.props
    let sort = 'desc'
    if (searchParams.orderBy && searchParams.orderBy === orderBy) {
      sort = searchParams.sort === 'desc' ? 'asc' : 'desc'
    }

    const { total, page, ...params } = searchParams

    this.loadInvoices({
      searchParams: {
        ...params,
        orderBy,
        sort,
        page: 0,
      },
    })
  }

  handleSubmit(data) {
    const { searchParams } = this.props
    const {
      total,
      page,
      jobId,
      number,
      orderNumber,
      proName,
      homeOwnerName,
      states,
      issuedAt,
      types,
      ...params
    } = searchParams

    this.loadInvoices({
      searchParams: {
        ...params,
        ...data,
        page: 0,
      },
    })
  }

  handleReset() {
    const { dispatchResetInvoicesFilter } = this.props
    dispatchResetInvoicesFilter()
  }

  loadInvoices({ searchParams }) {
    const { getInvoices } = this.props

    getInvoices(searchParams)
  }

  render() {
    const {
      invoices,
      searchParams,
      isLoading,
      dispatchExportInvoicesList,
      dispatchResetDisplayedInvoice,
      displayedInvoice,
    } = this.props

    return (
      <AllInvoicesList
        rows={invoices}
        searchParams={searchParams}
        isLoading={isLoading}
        handleSearchChange={this.handleSearchChange}
        handleChangePage={this.handleChangePage}
        handleChangePerPage={this.handleChangePerPage}
        handleChangeSort={this.handleChangeSort}
        handleFilterByType={this.handleFilterByType}
        handleFilterByCreationDate={this.handleFilterByCreationDate}
        handleSubmitForm={this.handleSubmit}
        handleReset={this.handleReset}
        handleExport={dispatchExportInvoicesList}
        dispatchResetDisplayedInvoice={dispatchResetDisplayedInvoice}
        displayedInvoice={displayedInvoice}
      />
    )
  }
}

AllInvoicesListContainer.propTypes = {
  getInvoices: PropTypes.func.isRequired,
  dispatchResetInvoicesList: PropTypes.func.isRequired,
  dispatchResetInvoicesFilter: PropTypes.func.isRequired,
  dispatchExportInvoicesList: PropTypes.func.isRequired,
  dispatchResetDisplayedInvoice: PropTypes.func.isRequired,
  invoices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  displayedInvoice: PropTypes.number,
  searchParams: PropTypes.shape({
    jobId: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    proName: PropTypes.string.isRequired,
    homeOwnerName: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    states: PropTypes.shape([]).isRequired,
    types: PropTypes.shape([]).isRequired,
    issuedAt: PropTypes.shape([]).isRequired,
    orderBy: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired,
  }).isRequired,
}

AllInvoicesListContainer.defaultProps = {
  displayedInvoice: null,
}

const mapStateToProps = state => ({
  invoices: invoicesListSelector(state),
  searchParams: searchParamsSelector(state),
  isLoading: isLoadingSelector(state),
  displayedInvoice: displayedInvoiceSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getInvoices: searchParams => dispatch(getInvoicesList.request(searchParams)),
  dispatchResetInvoicesList: () => dispatch(resetInvoicesList()),
  dispatchResetInvoicesFilter: () =>
    dispatch(reset(FILTER_SALE_ALL_INVOICES_LIST_FORM)),
  dispatchExportInvoicesList: () => dispatch(exportInvoicesList()),
  dispatchResetDisplayedInvoice: () => dispatch(setDisplayedInvoice(null)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllInvoicesListContainer)
