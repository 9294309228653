import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { submit } from 'redux-form'
import { withRouter } from 'react-router-dom'
import { uploadDocument, fileLoadSuccess } from 'store/upload/uploadActions'
import i18n from 'providers/i18n/I18nProvider'
import { setBarTitle } from 'store/Application/ApplicationActions'
import {
  currentFirmSelector,
  firmLegacyIdSelector,
  firmNameSelector,
  firmCertificateTypesSelector,
  contractSignatureIdSelector,
  contractSignatureStatusSelector,
  contractSignedAtSelector,
  organismeAndCertificateSelector,
} from 'store/firms/firmSelectors'
import FirmDetails from 'components/Firms/FirmDetails/FirmDetails'
import {
  getSingleFirm,
  getCountJobsDone,
  resetFirmState,
  getFirmContract,
  getFirmCertificates,
} from 'store/firms/firmActions'
import { storeList } from 'store/stores/storeActions'
import {
  EDIT_FIRM_DETAILS_PRO_FORM,
  EDIT_FIRM_DETAILS_PACKAGES_FORM,
  EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
  EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM,
  EDIT_FIRM_INTERVENTION_ZONE_FORM,
  ADD_FIRM_COMMENT_FORM,
} from 'constants/forms'
import { getFirmContractOptions } from 'store/firmContractOptions/firmContractOptionsActions'

const FirmDetailsContainer = ({
  getFirm,
  match,
  firm,
  legacyId,
  name,
  contractSignatureId,
  contractSignatureStatus,
  contractSignedAt,
  getFirmCertificateTypes,
  countJobsDone,
  setTitle,
  translate,
  getStoreList,
  resetFormFirmState,
  getFirmContractData,
  dispatch,
  certificates,
  getContractOptions,
  onValidationDocumentUploaded,
  onSuccessFileLoad,
}) => {
  const {
    params: { firmId },
  } = match

  const handleSubmit = () => {
    resetFormFirmState()
    dispatch(submit(EDIT_FIRM_DETAILS_PRO_FORM))
    dispatch(submit(EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM))
    dispatch(submit(EDIT_FIRM_DETAILS_PACKAGES_FORM))
    dispatch(submit(EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM))
    dispatch(submit(EDIT_FIRM_INTERVENTION_ZONE_FORM))
    dispatch(submit(ADD_FIRM_COMMENT_FORM))
  }

  useEffect(() => {
    getStoreList()
  }, [getStoreList])

  useEffect(() => {
    getFirm({ firmId: decodeURIComponent(firmId) })
  }, [firmId, getFirm])

  useEffect(() => {
    countJobsDone({ firmId: decodeURIComponent(firmId) })
  }, [countJobsDone, firmId])

  useEffect(() => {
    getContractOptions()
  }, [getContractOptions])

  useEffect(() => {
    if (name) {
      setTitle(
        translate('resources.firms.navbar.title.detail', {
          name,
          legacyId: legacyId || 'Identifiant non renseigné',
        }),
      )
    }
  }, [name, legacyId, setTitle, translate])

  return firm ? (
    <FirmDetails
      firm={firm}
      contractSignatureId={contractSignatureId}
      contractSignatureStatus={contractSignatureStatus}
      contractSignedAt={contractSignedAt}
      getFirmCertificates={getFirmCertificateTypes}
      handleSubmit={handleSubmit}
      getFirmContractData={getFirmContractData}
      certificates={certificates}
      onValidationDocumentUploaded={onValidationDocumentUploaded}
      onSuccessFileLoad={onSuccessFileLoad}
    />
  ) : null
}

FirmDetailsContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  firm: PropTypes.shape({}),
  legacyId: PropTypes.number,
  certificates: PropTypes.shape({}),
  name: PropTypes.string,
  getFirm: PropTypes.func.isRequired,
  resetFormFirmState: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  getFirmCertificateTypes: PropTypes.func.isRequired,
  onValidationDocumentUploaded: PropTypes.func.isRequired,
  onSuccessFileLoad: PropTypes.func.isRequired,
  countJobsDone: PropTypes.func.isRequired,
  getStoreList: PropTypes.func.isRequired,
  contractSignatureId: PropTypes.string,
  contractSignatureStatus: PropTypes.string,
  contractSignedAt: PropTypes.string,
  getFirmContractData: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      firmId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  featureFlagList: PropTypes.shape({}),
  getContractOptions: PropTypes.func.isRequired,
}

FirmDetailsContainer.defaultProps = {
  firm: undefined,
  legacyId: undefined,
  name: undefined,
  contractSignatureId: undefined,
  contractSignatureStatus: undefined,
  contractSignedAt: undefined,
  featureFlagList: {},
  certificates: {},
}

const mapStateToProps = state => ({
  firm: currentFirmSelector(state),
  legacyId: firmLegacyIdSelector(state),
  name: firmNameSelector(state),
  contractSignatureId: contractSignatureIdSelector(state),
  contractSignatureStatus: contractSignatureStatusSelector(state),
  contractSignedAt: contractSignedAtSelector(state),
  firmCertificateTypes: firmCertificateTypesSelector(state),
  certificates: organismeAndCertificateSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getFirm: params => dispatch(getSingleFirm.request(params)),
  getFirmCertificateTypes: () => dispatch(getFirmCertificates.request()),
  resetFormFirmState: () => dispatch(resetFirmState()),
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
  countJobsDone: param => dispatch(getCountJobsDone.request(param)),
  getStoreList: () => dispatch(storeList.request()),
  getFirmContractData: firmId => dispatch(getFirmContract.request(firmId)),
  getContractOptions: () => dispatch(getFirmContractOptions.request()),
  onSuccessFileLoad: fileName => dispatch(fileLoadSuccess(fileName)),
  onValidationDocumentUploaded: (files, additionalInfo) =>
    dispatch(uploadDocument(files, additionalInfo)),
  dispatch,
})

export default compose(
  i18n,
  memo,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(FirmDetailsContainer)
