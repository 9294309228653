import React from 'react'
import PropTypes from 'prop-types'
import { convertCentsToEuros } from 'helpers/utils/common'
import InlineTextField from 'components/shared/InlineTextField/InlineTextField'

const FieldPrice = ({ label, field, currency, isBold }) => (
  <InlineTextField
    label={label}
    isBold={isBold}
    field={field ? `${convertCentsToEuros(field)} ${currency}` : '-'}
  />
)

FieldPrice.propTypes = {
  label: PropTypes.string,
  field: PropTypes.number,
  currency: PropTypes.string,
  isBold: PropTypes.bool,
}

FieldPrice.defaultProps = {
  label: undefined,
  field: undefined,
  currency: 'EUR',
  isBold: false,
}

export default FieldPrice
