import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { touch, change } from 'redux-form'
import uuidv1 from 'uuid/v1'
import { PackageActions } from 'store/products/packageActions'
import { PackageSelector } from 'store/products/packageSelector'
import {
  currentFirmPackagesCodeListSelector,
  currentFirmPackageLabelListSelector,
  isFirmValidatedForPackageSelector,
  selectedDepartmentsSelector,
} from 'store/firms/firmSelectors'
import { updateFirmDetailsPackages } from 'store/firms/firmActions'
import { EDIT_FIRM_DETAILS_PACKAGES_FORM } from 'constants/forms'
import FirmDetailsPackage from './FirmDetailsPackage'

const FirmDetailsPackageContainer = ({
  updateCurrentFirm,
  packageDetails,
  getPackageList,
  packageList,
  selectedDepartments,
  firmLabelList,
  initialValues,
  isFirmValidatedForPackage,
  forceValidation,
}) => {
  useEffect(() => {
    getPackageList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FirmDetailsPackage
      onSubmit={values => updateCurrentFirm(values)}
      packageDetails={packageDetails}
      initialValues={initialValues}
      firmLabelList={firmLabelList}
      packageList={packageList}
      isFirmValidatedForPackage={isFirmValidatedForPackage}
      selectedDepartments={selectedDepartments}
      forceValidation={forceValidation}
    />
  )
}

FirmDetailsPackageContainer.propTypes = {
  updateCurrentFirm: PropTypes.func.isRequired,
  packageDetails: PropTypes.shape({}).isRequired,
  firmLabelList: PropTypes.shape({}).isRequired,
  // packages are indexed by code
  packageList: PropTypes.shape({}).isRequired,
  getPackageList: PropTypes.func.isRequired,
  selectedDepartments: PropTypes.arrayOf(PropTypes.string),
  isFirmValidatedForPackage: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  forceValidation: PropTypes.func.isRequired,
}

FirmDetailsPackageContainer.defaultProps = {
  selectedDepartments: [],
}

const mapStateToProps = state => ({
  packageDetails: PackageSelector.filterTypeByCategorySelector()(state),
  firmLabelList: currentFirmPackageLabelListSelector(state),
  packageList: PackageSelector.typeListIndexedByCodeSelector()(state),
  selectedDepartments: selectedDepartmentsSelector(state),
  isFirmValidatedForPackage: isFirmValidatedForPackageSelector(state),
  initialValues: {
    ...currentFirmPackagesCodeListSelector(state),
  },
})

const mapDispatchToProps = dispatch => ({
  getPackageList: () => dispatch(PackageActions.getTypeList().request()),
  updateCurrentFirm: values =>
    dispatch(updateFirmDetailsPackages({ payload: values })),
  forceValidation: () => {
    // usefull to force the redux-form validation & the error message displaying
    dispatch(touch(EDIT_FIRM_DETAILS_PACKAGES_FORM, 'checkBox'))

    // Force change action on a field to trigger validation
    dispatch(change(EDIT_FIRM_DETAILS_PACKAGES_FORM, 'checkBox', uuidv1()))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirmDetailsPackageContainer)
