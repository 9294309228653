import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles, Typography } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import styles from './NoResultStyles'

const NoResult = ({ classes, translate }) => (
  <div className={classes.root}>
    <Typography
      component="h1"
      variant="h6"
      color="primary"
      className={classes.noResult}
    >
      {translate('app.message.noResult')}
    </Typography>
  </div>
)

NoResult.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    noResult: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
}

export default compose(i18n, withStyles(styles))(NoResult)
