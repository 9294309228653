import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { Grid, withStyles, FormControl, Button } from '@material-ui/core'
import { FILTER_SALE_ALL_INVOICES_LIST_FORM } from 'constants/forms'
import RenderTextField from 'components/shared/Form/RenderTextField'
import i18n from 'providers/i18n/I18nProvider'
import RenderDateRangePicker from 'components/shared/Form/RenderDateRangePicker/RenderDateRangePicker'
import RenderMultiSelect from 'components/shared/Form/RenderMultiSelect'
import Icon from 'components/shared/Icon/Icon'
import {
  OPTIONS_TYPE,
  OPTIONS_NATURE,
  OPTIONS_STATUS,
} from 'constants/invoices'

import { hasPermission } from 'services/permissions'
import { PERM_INVOICE_EXPORT } from 'constants/permissions'
import styles from './InvoicesFiltersStyles'

const AllInvoicesFilters = ({
  handleReset,
  handleSubmit,
  handleExport,
  handleSubmitForm,
  translate,
  classes,
}) => (
  <form onSubmit={handleSubmit(handleSubmitForm)} className={classes.root}>
    <Grid container>
      <Grid item className={classes.inputSizing}>
        <FormControl className={classes.formControl}>
          <Field
            name="number"
            type="search"
            component={RenderTextField}
            label={translate('invoice.filterAllInvoices.number.label')}
          />
        </FormControl>
      </Grid>
      <Grid item className={classes.inputSizing}>
        <FormControl className={classes.formControl}>
          <Field
            name="jobId"
            type="search"
            component={RenderTextField}
            label={translate('invoice.filterAllInvoices.jobId.label')}
          />
        </FormControl>
      </Grid>
      <Grid item className={classes.inputSizing}>
        <FormControl className={classes.formControl}>
          <Field
            name="orderNumber"
            type="search"
            component={RenderTextField}
            label={translate('invoice.filterAllInvoices.orderNumber.label')}
          />
        </FormControl>
      </Grid>
      <Grid item className={classes.inputSizing}>
        <FormControl className={classes.formControl}>
          <Field
            name="proName"
            type="search"
            component={RenderTextField}
            label={translate('invoice.filterAllInvoices.proName.label')}
          />
        </FormControl>
      </Grid>
      <Grid item className={classes.inputSizing}>
        <FormControl className={classes.formControl}>
          <Field
            name="homeOwnerName"
            type="search"
            component={RenderTextField}
            label={translate('invoice.filterAllInvoices.homeOwnerName.label')}
          />
        </FormControl>
      </Grid>
      <Grid className={classes.fullWidth}>
        <Field
          id="partner-creation-id"
          name="issuedAt"
          label={translate('resources.allInvoices.partnerCreation.label')}
          component={RenderDateRangePicker}
        />
        <Field
          id="document-type-id"
          name="types"
          label={translate('resources.allInvoices.documentType.label')}
          options={OPTIONS_TYPE}
          component={RenderMultiSelect}
          className={classes.multiselect}
        />
        <Field
          id="nature"
          name="natures"
          label={translate('invoice.filterAllInvoices.nature.label')}
          options={OPTIONS_NATURE}
          component={RenderMultiSelect}
          className={classes.multiselect}
        />
        <Field
          id="document-status-id"
          name="states"
          label={translate('invoice.filterAllInvoices.status.label')}
          options={OPTIONS_STATUS}
          component={RenderMultiSelect}
          className={classes.proField}
        />
      </Grid>
      <Grid container justifyContent="flex-start">
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.buttonSearch}
          >
            {translate('pos.search')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => handleReset()}
            variant="contained"
            color="default"
            type="reset"
            className={classes.buttonReload}
          >
            {translate('app.message.reset')}
          </Button>
        </Grid>
        <Grid item className={classes.blocButtonExport}>
          <Button
            onClick={handleExport}
            variant="contained"
            color="default"
            className={classes.buttonExport}
            disabled={!hasPermission(PERM_INVOICE_EXPORT)}
          >
            <Icon className={classes.imgDownload} icon="download.svg" />
            {translate('invoice.buttons.export')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </form>
)

AllInvoicesFilters.propTypes = {
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    proField: PropTypes.string.isRequired,
    fullWidth: PropTypes.string.isRequired,
    formControl: PropTypes.string.isRequired,
    multiselect: PropTypes.string.isRequired,
    inputSizing: PropTypes.string.isRequired,
    buttonSearch: PropTypes.string.isRequired,
    buttonReload: PropTypes.string.isRequired,
    blocButtonExport: PropTypes.string.isRequired,
    buttonExport: PropTypes.string.isRequired,
    imgDownload: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(
  i18n,
  reduxForm({
    initialValues: {
      ['document-type']: [],
      ['document-status']: [],
    },
    form: FILTER_SALE_ALL_INVOICES_LIST_FORM,
    destroyOnUnmount: false,
  }),
  withStyles(styles),
)(AllInvoicesFilters)
