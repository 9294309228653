import React from 'react'
import PropTypes from 'prop-types'
import { dateFormatter } from 'helpers/date'
import { List } from '@material-ui/core'
import { RECEIPT_SIGNATURE_STATE_DONE } from 'constants/Jobs'
import classnames from 'classnames'

import ReceiptProgressBar from './ReceiptProgressBar'

const signedAt = (isReady, finishedAt, translate) => {
  if (!isReady) {
    return <span>{translate('resources.jobs.validateBills.waiting')}</span>
  }

  return (
    <span>
      <strong>{translate('resources.jobs.validateBills.signedAt')}</strong>
      &nbsp;{dateFormatter(finishedAt, translate('app.date_format.long'))}
    </span>
  )
}

const ReceiptInformations = ({
  receiptSignature,
  memberWhoSigned,
  totalMember,
  translate,
  classes,
}) => {
  if (!receiptSignature) {
    return null
  }

  const percentValue = (memberWhoSigned / totalMember) * 100
  return (
    <div className={classes.receiptInfo}>
      <ReceiptProgressBar
        createdAt={receiptSignature.createdAt}
        translate={translate}
        classes={classes}
        value={percentValue}
      />
      <List className={classes.blockList}>
        {receiptSignature.members.map(
          ({
            status,
            id,
            lastname,
            firstname,
            email,
            phone,
            finishedAt,
            position,
          }) => {
            const isMemberReceiptDone = status === RECEIPT_SIGNATURE_STATE_DONE
            return (
              <li
                key={id}
                className={classnames(classes.borderRight, classes.line)}
              >
                <h4 className={classes.h4}>
                  {position === 1 ? 'HOME OWNER' : 'PRO'}
                </h4>
                <div className={classes.info}>
                  <p className={classes.label}>{`${firstname} ${lastname}`}</p>
                  <p className={classes.label} title={email}>
                    {email}
                  </p>
                  <p className={classes.label}>{phone}</p>
                  <p>{signedAt(isMemberReceiptDone, finishedAt, translate)}</p>
                </div>
              </li>
            )
          },
        )}
      </List>
    </div>
  )
}

ReceiptInformations.propTypes = {
  classes: PropTypes.shape({
    textCenter: PropTypes.string,
    primary: PropTypes.string,
    error: PropTypes.string,
    receiptInfo: PropTypes.string,
    blockList: PropTypes.string,
    borderRight: PropTypes.string,
    label: PropTypes.string,
    line: PropTypes.string,
    h4: PropTypes.string,
    info: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  receiptSignature: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  anchorElReceipt: PropTypes.shape({}),
  memberWhoSigned: PropTypes.number.isRequired,
  totalMember: PropTypes.number.isRequired,
}

ReceiptInformations.defaultProps = {
  anchorElReceipt: null,
  receiptSignature: null,
}
export default ReceiptInformations
