import React from 'react'
import PropTypes from 'prop-types'
import { Fab, InputAdornment, TableCell, TableRow } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { Field } from 'redux-form'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import {
  FIELD_INVOICE_ITEM_NAME,
  FIELD_INVOICE_ITEM_QUANTITY,
  FIELD_INVOICE_ITEM_SUBTOTAL,
  FIELD_INVOICE_ITEM_UNIT_PRICE,
  FIELD_INVOICE_ITEM_VAT_RATE,
  VAT_RATES,
} from 'constants/invoices'
import { convertCentsToEuros } from 'helpers/utils/common'

class InvoiceItem extends React.PureComponent {
  generateVatChoices() {
    const { translate, currentItemSubtotal } = this.props

    return VAT_RATES.map(vatRate => {
      const vatEstimation = convertCentsToEuros(
        (vatRate * currentItemSubtotal) / 100,
      )
      return {
        name: translate('resources.invoices.details.rows.vat_rate', {
          vatRate,
          vatEstimation,
        }),
        value: vatEstimation,
      }
    })
  }

  render() {
    const {
      handleClickOnDeleteRow,
      handleChangeName,
      handleChangeQuantity,
      handleChangeUnitPrice,
      item,
      jobId,
      isFirmSubjectToVat,
      currentItemIsOvercharge,
    } = this.props

    return (
      <TableRow>
        <TableCell>{jobId}</TableCell>
        <TableCell>
          <Field
            name={`${item}.${FIELD_INVOICE_ITEM_NAME}`}
            multiline
            rows={3}
            component={RenderTextField}
            onChange={handleChangeName}
            disabled={!currentItemIsOvercharge}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.${FIELD_INVOICE_ITEM_QUANTITY}`}
            type="number"
            InputProps={{ inputProps: { min: 1 } }}
            component={RenderTextField}
            onChange={handleChangeQuantity}
            disabled={!currentItemIsOvercharge}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.${FIELD_INVOICE_ITEM_UNIT_PRICE}`}
            type="number"
            component={RenderTextField}
            InputProps={{
              endAdornment: <InputAdornment position="start">€</InputAdornment>,
              inputProps: { min: 0 },
            }}
            onChange={handleChangeUnitPrice}
            disabled={!currentItemIsOvercharge}
          />
        </TableCell>
        <TableCell>
          <Field
            name={`${item}.${FIELD_INVOICE_ITEM_SUBTOTAL}`}
            type="number"
            component={RenderTextField}
            InputProps={{
              endAdornment: <InputAdornment position="start">€</InputAdornment>,
            }}
            disabled
          />
        </TableCell>
        <TableCell>
          {isFirmSubjectToVat && (
            <Field
              name={`${item}.${FIELD_INVOICE_ITEM_VAT_RATE}`}
              id={`${item}.${FIELD_INVOICE_ITEM_VAT_RATE}Select`}
              component={RenderSelectField}
              choices={this.generateVatChoices()}
              disabled
            />
          )}
        </TableCell>
        <TableCell>
          {currentItemIsOvercharge && (
            <Fab
              color="default"
              aria-label="delete"
              onClick={handleClickOnDeleteRow}
            >
              <Delete />
            </Fab>
          )}
        </TableCell>
      </TableRow>
    )
  }
}

InvoiceItem.propTypes = {
  translate: PropTypes.func.isRequired,
  handleClickOnDeleteRow: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeQuantity: PropTypes.func.isRequired,
  handleChangeUnitPrice: PropTypes.func.isRequired,
  item: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  currentItemSubtotal: PropTypes.number.isRequired,
  isFirmSubjectToVat: PropTypes.bool.isRequired,
  currentItemIsOvercharge: PropTypes.bool.isRequired,
}

export default InvoiceItem
