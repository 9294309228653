import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import {
  jobAnchorNavigationSelector,
  jobEventsByJobIdIsLoadingSelector,
  incidentsLoadingSelector,
  invoicesLoadingSelector,
} from 'store/jobs/jobSelectors'
import {
  INFORMATIONS,
  JOB_DETAILS_TAB_OFFSET,
  JOB_DETAILS_OFFSET_ACTIVE_ANCHOR,
  INCIDENTS,
} from 'constants/Jobs'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'
import JobDetailsNavigation from './JobDetailsNavigation'

const JobDetailsNavigationContainer = ({
  anchorNavigation,
  isLoading,
  openedIncidentsCount,
}) => {
  const [activeId, setActiveId] = useState(INFORMATIONS)
  const [isMoved, setIsMoved] = useState(false)

  const scroll = id => {
    const element = document.getElementById(id)

    setActiveId(id)

    if (element) {
      const position = element.offsetTop || null

      if (position !== null) {
        window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: position - JOB_DETAILS_TAB_OFFSET,
        })
      }
    }
  }

  const scrollNavigation = _.throttle(
    useCallback(() => {
      /** check position */
      const currentlyActive = anchorNavigation
        .map(item => {
          const element = document.getElementById(item.key)

          return element
            ? {
                key: item.key,
                position: element.offsetTop,
                height: element.offsetHeight,
                test: element.getBoundingClientRect(),
              }
            : {}
        })
        .filter(
          item =>
            item.position <
            window.pageYOffset + JOB_DETAILS_OFFSET_ACTIVE_ANCHOR,
        )
        .pop()

      if (currentlyActive && currentlyActive.key !== activeId) {
        setActiveId(currentlyActive.key)
      }
    }, [activeId, anchorNavigation]),
    250,
  )

  const nbItems = { [INCIDENTS]: openedIncidentsCount }

  useEffect(() => {
    if (!isLoading && !isMoved) {
      setIsMoved(true)
      const hrefWithAnchor = window.location.href
      const id = hrefWithAnchor.split('#')[1] || undefined

      if (id) {
        scroll(id)
      }
    }

    window.addEventListener('scroll', scrollNavigation)

    return () => {
      window.removeEventListener('scroll', scrollNavigation)
    }
  }, [anchorNavigation, isMoved, scrollNavigation, setIsMoved, isLoading])

  return (
    <JobDetailsNavigation
      scroll={scroll}
      anchorNavigation={anchorNavigation}
      nbItems={nbItems}
    />
  )
}

JobDetailsNavigationContainer.propTypes = {
  anchorNavigation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  openedIncidentsCount: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  anchorNavigation: jobAnchorNavigationSelector(state),
  isLoading:
    isLoadingSelector(state) ||
    jobEventsByJobIdIsLoadingSelector(state) ||
    incidentsLoadingSelector(state) ||
    invoicesLoadingSelector(state),
})

export default compose(
  i18n,
  connect(mapStateToProps),
  withRouter,
)(JobDetailsNavigationContainer)
