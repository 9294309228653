import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import uuidv1 from 'uuid/v1'
import { dateFormatter } from 'helpers/date'
import { DATE_FORMAT } from 'constants/date'
import { convertCentsToEuros } from 'helpers/utils/common'
import { Button, TableCell, TableRow, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { JOB_PATH_PREFIX } from 'constants/routes'
import i18n from 'providers/i18n/I18nProvider'
import { buildJobIri } from 'helpers/utils/job/job'
import { getDisplayedState } from 'helpers/utils/invoice/invoice'
import styles from './SaleInvoicesListStyles'

const SaleInvoicesListTableBody = ({
  rows,
  translate,
  classes,
  additionalProps: { handleClickForPreview },
}) =>
  rows.map(row => {
    const {
      '@id': iri,
      orderNumber,
      number,
      state,
      jobId,
      store,
      issuedAt,
      subtotal,
      pro,
      customer,
      products,
      timeslot,
      stateUpdatedAt,
    } = row

    const link = `${JOB_PATH_PREFIX}/${encodeURIComponent(
      buildJobIri(jobId),
    )}/show`

    return (
      <TableRow key={uuidv1()}>
        <TableCell>
          <Link to={link} className={classes.menuLink}>
            {orderNumber}
          </Link>
        </TableCell>
        <TableCell>
          <Button color="primary" onClick={() => handleClickForPreview(iri)}>
            {number}
          </Button>
        </TableCell>
        <TableCell>{dateFormatter(issuedAt, DATE_FORMAT)}</TableCell>
        <TableCell>{`${store.name} (${store.codeAccount})`}</TableCell>
        <TableCell>{pro}</TableCell>
        <TableCell>{customer}</TableCell>
        <TableCell>
          {products.map(product => (
            <div>{product}</div>
          ))}
        </TableCell>
        <TableCell>{timeslot}</TableCell>
        <TableCell>
          {translate(
            `invoice.state.${getDisplayedState(state, stateUpdatedAt)}`,
          )}
        </TableCell>
        <TableCell>{dateFormatter(stateUpdatedAt, DATE_FORMAT)}</TableCell>
        <TableCell>{convertCentsToEuros(subtotal)} €</TableCell>
      </TableRow>
    )
  })

SaleInvoicesListTableBody.propTypes = {
  translate: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      number: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      stateUpdatedAt: PropTypes.string.isRequired,
      jobId: PropTypes.string.isRequired,
      store: PropTypes.shape({
        name: PropTypes.string.isRequired,
        codeAccount: PropTypes.string.isRequired,
      }).isRequired,
      issuedAt: PropTypes.string.isRequired,
      subtotal: PropTypes.number.isRequired,
      pro: PropTypes.string.isRequired,
      customer: PropTypes.string.isRequired,
      products: PropTypes.arrayOf(PropTypes.string).isRequired,
      timeslot: PropTypes.string.isRequired,
    }),
  ).isRequired,
  additionalProps: PropTypes.shape({
    handleClickForPreview: PropTypes.func.isRequired,
  }).isRequired,
}

export default compose(i18n, withStyles(styles))(SaleInvoicesListTableBody)
