import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import { dateFormatter } from 'helpers/date'
import i18n from 'providers/i18n/I18nProvider'
import uuidv1 from 'uuid/v1'
import styles from './JobMessageListStyles'

const JobMessageList = ({ conversation, translate, classes }) => (
  <>
    <div className={classes.messageModal}>
      <span className={classes.label}>
        {translate('job.job_conversations.col_headers.pro_name')} :
      </span>
      <span className={classes.value}>{conversation.proName}</span>
    </div>
    <div className={classes.conversationWrapper}>
      {conversation.messages.reverse().map(message => (
        <div className={classes.message} key={message.id}>
          <div className={classes.author}>{`${message.author} (${dateFormatter(
            message.createdAt,
            translate('app.date_format.long'),
            true,
          )}) :`}</div>
          <div className={classes.content}>
            {message.content.split('\n').map(line => (
              <p key={uuidv1()}>{line}</p>
            ))}
          </div>
        </div>
      ))}
    </div>
  </>
)

JobMessageList.propTypes = {
  classes: PropTypes.shape({
    messageModal: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    conversationWrapper: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  conversation: PropTypes.shape({
    proName: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

export default compose(i18n, memo, withStyles(styles))(JobMessageList)
