import {
  JOB_ACCEPTED_STATUS,
  JOB_CANCELED_STATUS,
  PENDING_ASSIGNMENT_STATUS,
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  PENDING_CANCELATION_STATUS,
  PENDING_PAYMENT_STATUS,
  JOB_DONE_STATUS,
  WAITING_FOR_INVOICE,
  WITH_CONTESTED_INVOICE,
  REFUSED_SIGNATURE,
  BLOCKED_RECEIPT,
  WITH_RECEIPT_WITH_RESERVES,
} from 'constants/Jobs'
import translate from 'providers/i18n/translateService'
import config from 'config'
import Moment from 'moment'

export const mapJobsCount = jobsCount =>
  jobsCount.map(jobCount => ({
    [jobCount.status]: jobCount.count,
  }))

export const formatIriForTransition = iri => iri.replace('/engine', '')
export const getJobIdFromIri = iri => iri.replace('/engine/jobs/', '')
export const getJobIriFromId = id => `/engine/jobs/${id}`

export const getAcceptedMatchingPro = matchingPros =>
  matchingPros &&
  matchingPros.find(matchingPro =>
    [JOB_ACCEPTED_STATUS, JOB_CANCELED_STATUS].includes(matchingPro.status),
  )

export const isJobPendingAssign = jobStatus =>
  [PENDING_ASSIGNMENT_STATUS, PENDING_MANUAL_ASSIGNMENT_STATUS].includes(
    jobStatus,
  )

export const isJobCancelable = jobStatus =>
  ![
    PENDING_CANCELATION_STATUS,
    JOB_CANCELED_STATUS,
    PENDING_PAYMENT_STATUS,
    JOB_DONE_STATUS,
    WAITING_FOR_INVOICE,
    WITH_CONTESTED_INVOICE,
    REFUSED_SIGNATURE,
    BLOCKED_RECEIPT,
    WITH_RECEIPT_WITH_RESERVES,
  ].includes(jobStatus)

export const existOrBoolJobValue = field => {
  if (field === true) {
    return translate('resources.customers.values.info_exist')
  }
  if (field === false) {
    return translate('resources.customers.values.info_not_exist')
  }
  if (field) {
    return field
  }
  return translate('resources.customers.values.noInfo')
}

export const buildJobIri = jobId => `/engine/jobs/${jobId}`

export const formatDate = date =>
  Moment(date).format(translate('resources.jobs.carousel.date_format'))

export const getPartnerIconUrl = partnerId => {
  if (!partnerId) {
    return null
  }
  return `${config.cloudinary.url}/vitrine/partners/pastille-${partnerId}.svg`
}
