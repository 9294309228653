import { connect } from 'react-redux'
import {
  currentConversationSelector,
  currentConversationMessagesLoadingSelector,
} from 'store/conversations/conversationsSelectors'
import { getConversationMessages } from 'store/conversations/conversationsActions'
import JobMessageDialog from './JobMessageDialog'

const mapStateToProps = state => ({
  conversation: currentConversationSelector(state),
  conversationMessagesLoading: currentConversationMessagesLoadingSelector(
    state,
  ),
})

const mapDispatchToProps = (dispatch, { conversationId }) => ({
  getCurrentConversation: () =>
    dispatch(getConversationMessages.request({ conversationId })),
})

export default connect(mapStateToProps, mapDispatchToProps)(JobMessageDialog)
