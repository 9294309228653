import { partners } from 'config'
import Client from './client'

const client = new Client({
  baseURL: partners.url,
  headers: {
    'Content-Type': 'application/ld+json',
  },
}).getInstance()

export default client
