import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@material-ui/icons'
import styles from './NotificationStyles'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const Notification = ({ open, message, messageType, className, classes }) => {
  const Icon = variantIcon[messageType]

  if (!Icon || !message) {
    return null
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <SnackbarContent
        className={classNames(classes[messageType], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
      />
    </Snackbar>
  )
}

Notification.propTypes = {
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
  open: PropTypes.bool,
  classes: PropTypes.shape({
    message: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconVariant: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  messageType: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
}

Notification.defaultProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  open: false,
  className: '',
  message: null,
  messageType: 'info',
}

export default withStyles(styles)(Notification)
