import { generateSimpleAction } from 'helpers/store/actionsHelpers'

export const SET_LEGACYID = 'SET_LEGACYID'
export const SET_LS_LEGACYID = 'SET_LS_LEGACYID'
export const GET_LS_LEGACYID = 'GET_LS_LEGACYID'
export const setLSProLegacyId = generateSimpleAction(SET_LS_LEGACYID)
export const getLSProLegacyId = generateSimpleAction(GET_LS_LEGACYID)
export const setLegacyId = legacyId => ({
  type: SET_LEGACYID,
  payload: legacyId,
})
