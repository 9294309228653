import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import { submit, isValid, getFormValues } from 'redux-form'
import {
  Button,
  withStyles,
  Card,
  Typography,
  CardActions,
} from '@material-ui/core'
import { NEW_TIME_SLOT_DATE_FORM } from 'constants/forms'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import connect from 'react-redux/es/connect/connect'
import styles from './JobTimeslotStyles'

class JobNewTimeslotDialog extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      dialogOpened: false,
    }
  }

  openDialog = () => {
    const { isFormValid } = this.props
    this.setState({ dialogOpened: !!isFormValid })
  }

  handleDialogResponse = event => {
    const { dispatch } = this.props
    const { value } = event.currentTarget
    if (value === 'true') {
      dispatch(submit(NEW_TIME_SLOT_DATE_FORM))
    }
    this.setState({ dialogOpened: false })
  }

  render() {
    const { translate, isFormValid, values } = this.props
    if (values !== undefined) {
      const { addButtonClicked } = values
      this.setState({ addButtonClicked })
    }
    const { dialogOpened, addButtonClicked } = this.state
    return (
      <>
        {dialogOpened && (
          <ConfirmDialog
            title={translate(
              `job.replace_ho_date.date_validation_pop_up.title${
                addButtonClicked ? '_add' : '_replace'
              }`,
            )}
            onClose={this.handleDialogResponse}
          >
            <Typography component="p">
              {translate(
                `job.replace_ho_date.date_validation_pop_up.text${
                  addButtonClicked ? '_add' : '_replace'
                }`,
              )}
            </Typography>
          </ConfirmDialog>
        )}
        <Card>
          <CardActions>
            {!isFormValid && (
              <Typography component="p">
                {translate('job.replace_ho_date.form_validation')}
              </Typography>
            )}
            {isFormValid && values && (
              <Button
                variant="contained"
                color="secondary"
                onClick={this.openDialog}
              >
                {translate('job.replace_ho_date.btn_validate')}
              </Button>
            )}
          </CardActions>
        </Card>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isFormValid: isValid(NEW_TIME_SLOT_DATE_FORM)(state),
  values: getFormValues(NEW_TIME_SLOT_DATE_FORM)(state),
})

JobNewTimeslotDialog.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  isFormValid: PropTypes.bool,
  values: PropTypes.shape({ addButtonClicked: PropTypes.bool.isRequired }),
}

JobNewTimeslotDialog.defaultProps = {
  isFormValid: true,
  values: undefined,
}

export default compose(
  withStyles(styles),
  i18n,
  connect(mapStateToProps, null),
)(JobNewTimeslotDialog)
