import axios from 'axios'

export default class AlgoliaMock {
  constructor(url) {
    this.baseURL = url
    this.index = {
      path: undefined,
      name: undefined,
      search: this.search,
      searchForFacetValues: this.searchForFacetValues,
    }
    this.mockServer = axios.create({ baseURL: url, timeout: 1000 })
  }

  search(params, callback) {
    if (!this.index.name) {
      callback(new Error('Need to define an indexName'))
    }

    this.mockServer
      .get(this.index.path)
      .then(res => callback(null, res.data))
      .catch(err => callback(err))
  }

  searchForFacetValues(params, callback) {
    if (!this.index.name) {
      callback(new Error('Need to define an indexName'))
    }

    this.mockServer
      .get(this.index.path)
      .then(res => callback(null, res.data))
      .catch(err => callback(err))
  }

  initIndex(indexName) {
    this.index.name = indexName
    this.index.path = `/${indexName}`
    return this
  }
}
