import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import {
  withStyles,
  Paper,
  Card,
  Tabs,
  Tab,
  Button,
  Typography,
  MobileStepper,
} from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import {
  PENDING_MATCHING_STATUS,
  PENDING_RESCHEDULING_STATUS,
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  DELIVERY_MODE_BY_CRAFTSMAN,
  TAB_MATCHING_MATCHING_PROS,
  TAB_MATCHING_PROS,
  TAB_MATCHING_RELIABLE,
  TAB_MATCHING_AUTO_RANKING,
} from 'constants/Jobs'
import TabContainer from 'components/shared/TabContainer/TabContainer'
import ManualMatchingForm from './ManualMatchingForm/ManualMatchingForm'
import RescheduleManualMatching from './RescheduleManualMatching/RescheduleManualMatching'
import DeclinedManualMatching from './DeclinedManualMatching/DeclinedManualMatching'
import ProsSearch from './ProsSearch/ProsSearch'
import styles from './ManualMatchingStyles'

class ManualMatching extends PureComponent {
  constructor(props) {
    super(props)
    this.delaySearchPros = debounce(this.delaySearchPros.bind(this), 600)
    this.state = {
      tabValue: 0,
      search: {
        proLegacyId: '',
        proLastName: '',
      },
    }
  }

  componentDidMount() {
    const { setScrollTopValue } = this.props
    setScrollTopValue(true)
  }

  handleTabChange = (_, value) => {
    this.setState({ tabValue: value })
  }

  handleReset = () => {
    const { jobId, resetProsSearchForm } = this.props
    this.setState(
      {
        search: {
          proLegacyId: '',
          proLastName: '',
        },
      },
      () => {
        const { search } = this.state
        resetProsSearchForm({ ...search, jobId })
      },
    )
  }

  delaySearchPros = jobId => {
    const { getMatchingProsList, getMatchingProsListFromAlgolia } = this.props
    const { search } = this.state
    getMatchingProsList({ ...search, jobId })
    getMatchingProsListFromAlgolia({ ...search, jobId })
  }

  handleSearchChange = (fieldName, event) => {
    const { jobId } = this.props
    this.setState(
      prevState => ({
        search: {
          ...prevState.search,
          [fieldName]: event.target.value,
        },
      }),
      () => {
        this.delaySearchPros(jobId)
      },
    )
  }

  render() {
    const {
      classes,
      matchingPros,
      matchingProsAlgolia,
      matchingProsAlgoliaRanking,
      reliableList,
      reliableCount,
      matchingProsCount,
      matchingProsAlgoliaCount,
      matchingProsAlgoliaRankingCount,
      acceptedPro,
      isJobPendingAssign,
      timeslotDate,
      timeslot,
      timeslots,
      status,
      jobId,
      translate,
      declineJob,
      reschedulingJob,
      getMatchingProsList,
      getReliableProList,
      deliveryMode,
      rematchingJob,
      signedReceipt,
      isAcceptedProInTest,
      history: { goBack },
    } = this.props
    const { tabValue } = this.state
    const selectedProIri = acceptedPro ? acceptedPro['@id'] : null

    return (
      <Paper className={classes.matchingDetails}>
        <MobileStepper
          steps={0}
          position="static"
          className={classes.root}
          backButton={
            <Button size="small" onClick={goBack}>
              <KeyboardArrowLeft />
              {translate('job.manual_matching.back')}
            </Button>
          }
        />
        <Card className={classes.wrapper}>
          <DeclinedManualMatching
            status={status}
            declineJob={declineJob}
            signedReceipt={signedReceipt}
          />
          <div className={classes.alertWrapper}>
            <Typography component="p" paragraph>
              {translate('job.manual_matching.help_text')}
            </Typography>
            {status === PENDING_MANUAL_ASSIGNMENT_STATUS &&
              deliveryMode !== DELIVERY_MODE_BY_CRAFTSMAN && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonBigger}
                  onClick={() => rematchingJob()}
                >
                  {translate('resources.jobs.button.rematch_auto')}
                </Button>
              )}
            <Typography component="p" paragraph>
              {translate('job.manual_matching.notice_text')}
            </Typography>
            {status === PENDING_RESCHEDULING_STATUS ? (
              <RescheduleManualMatching
                reschedulingJob={reschedulingJob}
                declineJob={declineJob}
                timeslots={timeslots}
              />
            ) : null}

            {(matchingPros || []).length === 0 &&
              status !== PENDING_MATCHING_STATUS && (
                <Typography component="p" color="secondary" paragraph>
                  <strong>
                    {translate('job.manual_matching.noProMatched')}
                  </strong>
                </Typography>
              )}
          </div>
          <Tabs
            className={classes.tabs}
            value={tabValue}
            onChange={this.handleTabChange}
          >
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.tabMatchingPro')}
            />
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.tabOtherstabMatchingPro')}
            />
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.tabGoToPro')}
            />
            <Tab
              className={classes.tab}
              label={translate('job.manual_matching.simulation')}
            />
          </Tabs>
          {tabValue === TAB_MATCHING_MATCHING_PROS && (
            <TabContainer>
              <ProsSearch
                handleSearchChange={this.handleSearchChange}
                handleReset={this.handleReset}
              />
              <ManualMatchingForm
                status={status}
                matchingPros={matchingPros}
                total={matchingProsCount}
                loadProList={getMatchingProsList}
                isJobPendingAssign={isJobPendingAssign}
                jobId={jobId}
                initialValues={{
                  selectedProIri,
                  timeslot,
                  timeslotDate,
                }}
                tabValue={tabValue}
                acceptedPro={acceptedPro}
                isAcceptedProInTest={isAcceptedProInTest}
              />
            </TabContainer>
          )}
          {tabValue === TAB_MATCHING_PROS && (
            <TabContainer>
              <ProsSearch
                handleSearchChange={this.handleSearchChange}
                handleReset={this.handleReset}
              />
              <ManualMatchingForm
                status={status}
                matchingProsAlgolia={matchingProsAlgolia}
                total={matchingProsAlgoliaCount}
                isJobPendingAssign={isJobPendingAssign}
                jobId={jobId}
                initialValues={{
                  selectedProIri,
                  timeslot,
                  timeslotDate,
                }}
                tabValue={tabValue}
              />
            </TabContainer>
          )}
          {tabValue === TAB_MATCHING_RELIABLE && (
            <TabContainer>
              <ManualMatchingForm
                status={status}
                matchingPros={reliableList}
                loadProList={getReliableProList}
                total={reliableCount}
                isJobPendingAssign={isJobPendingAssign}
                jobId={jobId}
                initialValues={{
                  selectedProIri,
                  timeslot,
                  timeslotDate,
                }}
                tabValue={tabValue}
              />
            </TabContainer>
          )}
          {tabValue === TAB_MATCHING_AUTO_RANKING && (
            <TabContainer>
              <ManualMatchingForm
                status={status}
                matchingProsAlgolia={matchingProsAlgoliaRanking}
                total={matchingProsAlgoliaRankingCount}
                isJobPendingAssign={isJobPendingAssign}
                jobId={jobId}
                initialValues={{
                  selectedProIri,
                  timeslot,
                  timeslotDate,
                }}
                tabValue={tabValue}
              />
            </TabContainer>
          )}
        </Card>
      </Paper>
    )
  }
}

ManualMatching.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    alertWrapper: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    matchingDetails: PropTypes.string.isRequired,
    tabs: PropTypes.string.isRequired,
    tab: PropTypes.string.isRequired,
  }).isRequired,
  matchingPros: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgolia: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgoliaRanking: PropTypes.arrayOf(PropTypes.shape({})),
  reliableList: PropTypes.arrayOf(PropTypes.shape({})),
  reliableCount: PropTypes.number.isRequired,
  matchingProsCount: PropTypes.number.isRequired,
  matchingProsAlgoliaCount: PropTypes.number.isRequired,
  matchingProsAlgoliaRankingCount: PropTypes.number.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  acceptedPro: PropTypes.shape({
    '@id': PropTypes.string,
  }),
  isJobPendingAssign: PropTypes.bool,
  timeslotDate: PropTypes.string,
  timeslot: PropTypes.string,
  jobId: PropTypes.string,
  status: PropTypes.string,
  translate: PropTypes.func.isRequired,
  declineJob: PropTypes.func.isRequired,
  reschedulingJob: PropTypes.func.isRequired,
  resetProsSearchForm: PropTypes.func.isRequired,
  getMatchingProsList: PropTypes.func.isRequired,
  getMatchingProsListFromAlgolia: PropTypes.func.isRequired,
  getReliableProList: PropTypes.func.isRequired,
  setScrollTopValue: PropTypes.func.isRequired,
  timeslots: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      stopTime: PropTypes.string,
    }),
  ),
  deliveryMode: PropTypes.string,
  rematchingJob: PropTypes.func.isRequired,
  signedReceipt: PropTypes.bool,
  isAcceptedProInTest: PropTypes.bool.isRequired,
}

ManualMatching.defaultProps = {
  matchingPros: [],
  matchingProsAlgolia: [],
  matchingProsAlgoliaRanking: [],
  reliableList: [],
  acceptedPro: undefined,
  isJobPendingAssign: false,
  timeslotDate: undefined,
  jobId: undefined,
  timeslot: undefined,
  status: undefined,
  timeslots: undefined,
  history: {
    goBack: () => {},
  },
  deliveryMode: undefined,
  signedReceipt: undefined,
}

export default compose(withStyles(styles), i18n, withRouter)(ManualMatching)
