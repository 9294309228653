export default () => ({
  dateRange: {
    fontFamily: 'montserrat',
    fontSize: '14px',
    lineHeight: '25px',
  },
  label: {
    fontFamily: 'montserrat',
    fontSize: '12px',
    lineHeight: '1rem',
    color: '#737D7C',
  },
})
