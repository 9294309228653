import React from 'react'
import translateService from './translateService'

/**
 * I18nProvider
 *
 * Add translate phrases to component to wrap
 *
 * @param {func} ComponentToWrap React component
 * @return {func}
 */
const i18n = ComponentToWrap => {
  // Retreive phrase on tree by component key or common key
  const t = (ref, args = undefined) => translateService(ref, args)

  // Add phrases to component
  const I18nProvider = props => <ComponentToWrap {...props} translate={t} />

  return I18nProvider
}

export default i18n
