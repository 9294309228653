import React from 'react'
import { FormControl, withStyles } from '@material-ui/core'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import {
  DASHBOARD_GEOZONES_LIST,
  DASHBOARD_SELECTED_ZONE,
} from 'constants/dashboard'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import styles from './GeozoneSelectorStyles'

const GeozoneSelector = ({ showSelector, classes }) => {
  const handleOnchange = ({ currentTarget: { value } }) => {
    localStorage.setItem(DASHBOARD_SELECTED_ZONE, value)
  }

  if (!showSelector) {
    return null
  }

  return (
    <form className={classes.root}>
      <FormControl>
        <Field
          onChange={handleOnchange}
          className={classes.geozoneSelector}
          name="geozones_list"
          id="geozoneSelect"
          choices={DASHBOARD_GEOZONES_LIST}
          component={RenderSelectField}
          optionValue="value"
          optionText={({ key }) => key}
        />
      </FormControl>
    </form>
  )
}

GeozoneSelector.propTypes = {
  showSelector: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    geozoneSelector: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(withStyles(styles))(GeozoneSelector)
