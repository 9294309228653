import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import { setBarTitle } from 'store/Application/ApplicationActions'
import { compose } from 'redux'
import AllInvoicesListContainer from 'components/Invoices/AllInvoicesList/AllInvoicesListContainer'

const AllInvoices = ({ setTitle, translate }) => {
  useEffect(() => {
    setTitle(translate('resources.allInvoices.navbar.title.all'))
  }, [setTitle, translate])

  return <AllInvoicesListContainer />
}

const mapDispatchToProps = dispatch => ({
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
})

AllInvoices.propTypes = {
  translate: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
}

export default compose(i18n, connect(null, mapDispatchToProps))(AllInvoices)
