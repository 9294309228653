const styles = theme => ({
  textContainer: {
    margin: '6px 0px',
  },
  textarea: {
    height: '80px',
  },
  counter: {
    textAlign: 'right',
  },
  buttonBigger: theme.buttonBigger,
  buttonReload: theme.buttonReload,
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: '500',
    cursor: 'pointer',
  },
  inlineSelect: {
    float: 'right',
    marginLeft: '16px',
    marginBottom: '8px',
  },
  selectEmpty: {
    marginTop: '8px !important',
    minWidth: '160px',
  },
})

export default styles
