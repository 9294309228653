/* eslint-disable no-underscore-dangle */
import auth0 from 'auth0-js'
import config from 'config'

class Auth0 {
  constructor(clientName) {
    this._auth0conf = config.auth0
    this._clientName = clientName
    this._auth = new auth0.WebAuth({
      domain: config.auth0.domain,
      clientID: config.auth0.clientID,
      responseType: 'token id_token',
      scope: 'openid offline_access',
      redirectUri: `${config.auth0.redirectUri}/`,
    })
  }

  get auth() {
    return this._auth
  }

  set auth(value) {
    this._auth = value
  }

  get auth0conf() {
    return this._auth0conf
  }

  set auth0conf(value) {
    this._auth0conf = value
  }

  get clientName() {
    return this._clientName
  }

  set clientName(value) {
    this._clientName = value
  }

  passwordlessStart(credential) {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessStart(
        {
          email: credential.email,
          connection: 'email',
          send: 'link',
          authParams: {
            audience: this._auth0conf.audience,
            scope: 'openid email offline_access profile',
          },
        },
        (err, response) => {
          if (err) {
            const { original, ...error } = err
            reject(error)
            return
          }
          resolve(response)
        },
      )
    }).catch(error => error)
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      this.auth.checkSession(
        {
          audience: this._auth0conf.audience,
          scope: 'openid email',
        },
        (err, response) => {
          if (err) {
            const { original, ...error } = err
            reject(error)
            return
          }
          resolve(response)
        },
      )
    }).catch(error => error)
  }

  logout() {
    this.auth.logout({
      clientID: this._auth0conf.clientID,
      returnTo: `${config.url}/login`,
    })
  }
}

const auth = new Auth0('Backoffice')

export default auth
