import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getCountJobsByStatuses } from 'store/jobs/jobActions'
import { getCountIncidentsByStatuses } from 'store/incidents/incidentActions'
import { setShowGeozoneSelector } from 'store/Application/ApplicationActions'
import { selectedGeozoneSelector } from 'store/Application/ApplicationSelectors'
import Dashboard from './Dashboard'
import {
  getCountFirmsByDocumentStatuses,
  getCountFirmsByDocVerifiedStatuses,
  getCountFirmsByStatuses,
} from '../../store/firms/firmActions'

const DashboardContainer = ({
  getCountJobs,
  getCountIncidents,
  getCountFirms,
  getCountFirmsDocument,
  getCountFirmsDocVerified,
  setGeozoneSelectorVisible,
  selectedZone,
}) => {
  useEffect(() => {
    getCountJobs(selectedZone)
    getCountIncidents(selectedZone)
    getCountFirms()
    getCountFirmsDocument()
    getCountFirmsDocVerified()
    setGeozoneSelectorVisible(true)
  }, [
    getCountJobs,
    getCountIncidents,
    getCountFirms,
    getCountFirmsDocument,
    getCountFirmsDocVerified,
    setGeozoneSelectorVisible,
    selectedZone,
  ])

  return <Dashboard />
}
DashboardContainer.propTypes = {
  getCountJobs: PropTypes.func.isRequired,
  getCountIncidents: PropTypes.func.isRequired,
  getCountFirms: PropTypes.func.isRequired,
  getCountFirmsDocument: PropTypes.func.isRequired,
  getCountFirmsDocVerified: PropTypes.func.isRequired,
  setGeozoneSelectorVisible: PropTypes.func.isRequired,
  selectedZone: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  selectedZone: selectedGeozoneSelector(state),
})
const mapDispatchToProps = dispatch => ({
  getCountJobs: geozone =>
    dispatch(getCountJobsByStatuses.request({ geozone })),
  getCountIncidents: geozone =>
    dispatch(getCountIncidentsByStatuses.request({ geozone })),
  getCountFirms: () => dispatch(getCountFirmsByStatuses.request()),
  getCountFirmsDocument: () =>
    dispatch(getCountFirmsByDocumentStatuses.request()),
  getCountFirmsDocVerified: () =>
    dispatch(getCountFirmsByDocVerifiedStatuses.request()),
  setGeozoneSelectorVisible: showSelector =>
    dispatch(setShowGeozoneSelector({ showSelector })),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DashboardContainer,
)
