import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@material-ui/core'

const LinkCustom = props => {
  const { disabled, children } = props
  if (disabled) {
    return children
  }

  return <Link {...props} />
}

LinkCustom.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

LinkCustom.defaultProps = {
  disabled: false,
}

export default LinkCustom
