import React from 'react'
import PropTypes from 'prop-types'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import Invoice from 'components/Jobs/JobDetails/JobDetailsWorkflow/ValidateBills/Invoice/Invoice'
import Receipt from 'components/Jobs/JobDetails/JobDetailsWorkflow/ValidateBills/Receipt/Receipt'
import UpdateInvoiceContainer from 'components/Jobs/JobDetails/JobDetailsWorkflow/ValidateBills/UpdateInvoice/UpdateInvoiceContainer'

const DialogList = ({
  receiptOpened,
  jobIri,
  invoiceOpened,
  invoiceId,
  onCloseDialog,
  updateInvoiceOpened,
  translate,
  displayDialogCancelDisputeInvoice,
}) => (
  <>
    {receiptOpened && (
      <SimpleDialog title="" onClose={onCloseDialog}>
        <Receipt jobIri={jobIri} />
      </SimpleDialog>
    )}

    {invoiceOpened && (
      <SimpleDialog title="" onClose={onCloseDialog}>
        <Invoice invoiceId={invoiceId} />
      </SimpleDialog>
    )}

    {updateInvoiceOpened && (
      <SimpleDialog
        title=""
        onClose={onCloseDialog}
        dialogProps={{ maxWidth: 'lg' }}
        disableClose
      >
        <UpdateInvoiceContainer
          invoiceId={invoiceId}
          closeDialog={onCloseDialog}
        />
      </SimpleDialog>
    )}

    {displayDialogCancelDisputeInvoice && (
      <ConfirmDialog
        title={translate(
          'resources.jobs.validateBills.confirmDialogCancelDispute.title',
        )}
        onClose={onCloseDialog}
      >
        {`${translate(
          'resources.jobs.validateBills.confirmDialogCancelDispute.helpText',
        )}`}
      </ConfirmDialog>
    )}
  </>
)

DialogList.propTypes = {
  receiptOpened: PropTypes.bool,
  invoiceOpened: PropTypes.bool,
  updateInvoiceOpened: PropTypes.bool,
  displayDialogCancelDisputeInvoice: PropTypes.bool,
  jobIri: PropTypes.string,
  invoiceId: PropTypes.number,
  onCloseDialog: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

DialogList.defaultProps = {
  receiptOpened: false,
  invoiceOpened: false,
  updateInvoiceOpened: false,
  displayDialogCancelDisputeInvoice: false,
  invoiceId: null,
  jobIri: null,
}

export default DialogList
