import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import JobMessageList from './JobMessageList'

const JobMessageDialog = ({
  onClose,
  conversation,
  conversationMessagesLoading,
  getCurrentConversation,
  conversationId,
}) => {
  useEffect(() => {
    getCurrentConversation(conversationId)
  }, [conversationId, getCurrentConversation])

  if (!conversationMessagesLoading) {
    return (
      <SimpleDialog
        title=""
        onClose={onClose}
        dialogProps={{ maxWidth: 'lg', fullWidth: true }}
      >
        <JobMessageList conversation={conversation} />
      </SimpleDialog>
    )
  }

  return null
}

JobMessageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  getCurrentConversation: PropTypes.func.isRequired,
  conversationId: PropTypes.string.isRequired,
  conversationMessagesLoading: PropTypes.bool.isRequired,
  conversation: PropTypes.shape({}).isRequired,
}

export default JobMessageDialog
