import React, { useState } from 'react'
import { compose } from 'redux'
import { Button, Link, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import GenerateReinterventionAfterSaleForm from './GenerateReinterventionAfterSaleForm'
import styles from './GenerateReinterventionAfterSaleStyles'

const GenerateReinterventionAfterSale = ({ translate, classes, incidents }) => {
  const [dialogOpened, setDialogOpened] = useState(false)

  return (
    <>
      <Button
        name="generateReinterventionAfterSale"
        variant="contained"
        className={classes.buttonBigger}
        component={Link}
        onClick={() => setDialogOpened(true)}
      >
        {translate('afterSale.manual.generate_intervention')}
      </Button>
      {dialogOpened && (
        <GenerateReinterventionAfterSaleForm
          incidents={incidents}
          classes={classes}
          setDialogOpened={setDialogOpened}
          initialValues={{ useInitialPro: true }}
        />
      )}
    </>
  )
}

GenerateReinterventionAfterSale.propTypes = {
  translate: PropTypes.func.isRequired,
  incidents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  classes: PropTypes.shape({
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(
  i18n,
  withStyles(styles),
)(GenerateReinterventionAfterSale)
