const styles = theme => ({
  card: {
    flexGrow: 1,
    width: '100%',
    fontFamily: 'montserrat',
  },
  item: {
    padding: '12px',
  },
  title: {
    cardHeader: {
      fontSize: '10rem',
    },
  },
  button: {
    marginBottom: '15px',
  },
  linkBold: {
    fontWeight: '600',
    color: '#143C58',
    textDecoration: 'underline',
  },
  buttonBigger: theme.buttonBigger,
  labels: {
    margin: '30px',
  },
  tableDate: {
    margin: '1rem 0 0',
  },
  matchingDate: {
    fontWeight: '800',
    marginTop: 0,
    marginLeft: '-39px',
    listStyleType: 'none',
  },
})

export default styles
