import { connect } from 'react-redux'
import { doDisplayRespSelectDialog } from 'store/incidents/incidentActions'
import { selectDisplayedDialogs } from 'store/incidents/incidentSelectors'
import Dialogs from './Dialogs'

const mapStateToProps = state => ({
  displayedDialogs: selectDisplayedDialogs(state),
})

const mapDispatchToProps = dispatch => ({
  hideRespSelectDialog: () =>
    dispatch(doDisplayRespSelectDialog({ payload: { displayed: false } })),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs)
