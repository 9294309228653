export default theme => ({
  container: {
    whiteSpace: 'nowrap',
  },
  button: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
})
