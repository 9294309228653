import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import {
  withStyles,
  Typography,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import i18n from 'providers/i18n/I18nProvider'
import classNames from 'classnames'
import { INVOICE } from 'constants/Jobs'
import CopyToClipboardButton from 'components/shared/Buttons/CopyToClipboardButton'
import Icon from 'components/shared/Icon/Icon'
import styles from './PanelStyles'

const JobPanel = props => {
  const { id, expanded, handleChange, classes, title, children } = props

  const urlWithHashtagId = `${window.location.origin}${window.location.pathname}#${id}`

  return (
    <>
      <span id={id} />
      <ExpansionPanel
        expanded={expanded}
        onChange={handleChange}
        className={classes.expansionPanelBloc}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          classes={{ content: classes.panelWithIcon }}
        >
          <Typography className={classes.heading}>{title}</Typography>
          <CopyToClipboardButton whatToCopy={urlWithHashtagId}>
            <Icon className={classes.icon} icon="link-copy.svg" />
          </CopyToClipboardButton>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          className={classNames({
            [classes.expansionPanelDetailsBlock]: id === INVOICE,
          })}
        >
          {children}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  )
}

JobPanel.propTypes = {
  classes: PropTypes.shape({
    expansionPanelBloc: PropTypes.string,
    expansionPanelDetailsBlock: PropTypes.string,
    panelWithIcon: PropTypes.string,
    heading: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  expanded: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
}

export default compose(i18n, withStyles(styles))(JobPanel)
