const styles = () => ({
  messageModal: {
    color: '#143C58',
    marginBottom: '20px',
  },
  label: {
    display: 'inline-block',
    marginRight: '9px',
  },
  value: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginRight: '45px',
  },
  conversationWrapper: {
    color: '#143C58',
    borderCollapse: 'separate',
    borderSpacing: '10px 20px',
    backgroundColor: '#ebebeb',
    borderRadius: '4px',
  },
  message: {
    display: 'table-row',
    paddingBottom: '20px',
  },
  author: {
    display: 'table-cell',
    fontWeight: 'bold',
  },
  content: {
    display: 'table-cell',
  },
})

export default styles
