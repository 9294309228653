import React from 'react'
import PropTypes from 'prop-types'
import { INVOICES_STATES } from 'constants/invoices'
import InvoicesFilters from './InvoicesFilters'

const InvoicesFiltersContainer = ({
  handleSearchChange,
  handleFiltersChange,
  handleExportClick,
  invoiceStates,
}) => (
  <InvoicesFilters
    handleSearchChange={handleSearchChange}
    handleFiltersChange={handleFiltersChange}
    handleExportClick={handleExportClick}
    invoiceStates={invoiceStates}
  />
)

InvoicesFiltersContainer.propTypes = {
  handleSearchChange: PropTypes.func.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  handleExportClick: PropTypes.func.isRequired,
  invoiceStates: PropTypes.arrayOf(PropTypes.shape({})),
}

InvoicesFiltersContainer.defaultProps = {
  invoiceStates: INVOICES_STATES,
}

export default InvoicesFiltersContainer
