import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Typography, withStyles, Card, CardContent } from '@material-ui/core'
import { STATUSES_DISPLAYING_VALIDATE_BILLS } from 'constants/Jobs'
import DialogList from 'components/Jobs/JobDetails/JobDetailsWorkflow/Dialog/DialogList'
import ReceiptItemContainer from '../ItemList/Receipt/ReceiptItemContainer'
import styles from './ReceiptStyles'

class Receipt extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      confirmDialogReceipt: false,
      dialogReceiptOpened: false,
      anchorElReceipt: null,
      openUploaderPopIn: false,
    }
  }

  handleDialogReceipt = () => {
    const { dialogReceiptOpened } = this.state
    this.setState({
      dialogReceiptOpened: !dialogReceiptOpened,
    })
  }

  handlePopover = (event, anchor) => {
    /* eslint-disable-next-line */
    this.setState({ [anchor]: this.state[anchor] ? null : event.currentTarget })
  }

  render() {
    const {
      classes,
      translate,
      jobStatus,
      jobIri,
      dispatchUploadJobBFT,
      dispatchReceiptSignature,
    } = this.props

    const { dialogReceiptOpened } = this.state

    if (!STATUSES_DISPLAYING_VALIDATE_BILLS.includes(jobStatus)) {
      return <div className={classes.jobWorkflowStep} />
    }

    const { anchorElReceipt } = this.state

    return (
      <Card>
        <CardContent>
          <Typography component="p" paragraph>
            {translate('resources.jobs.validateBills.helpText')}
          </Typography>
          <ReceiptItemContainer
            onClickDialogReceipt={this.handleDialogReceipt}
            dispatchReceiptSignature={dispatchReceiptSignature}
            onClickPopOver={event =>
              this.handlePopover(event, 'anchorElReceipt')
            }
            onValidationUploaderPopIn={dispatchUploadJobBFT}
            anchorElReceipt={anchorElReceipt}
          />
          <DialogList
            receiptOpened={dialogReceiptOpened}
            jobIri={jobIri}
            onCloseDialog={this.handleDialogReceipt}
            translate={translate}
          />
        </CardContent>
      </Card>
    )
  }
}

Receipt.propTypes = {
  classes: PropTypes.shape({
    jobWorkflowStep: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    receiptItemContainer: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  jobIri: PropTypes.string.isRequired,
  dispatchUploadJobBFT: PropTypes.func.isRequired,
  jobStatus: PropTypes.string.isRequired,
  receiptSignature: PropTypes.shape({}),
  dispatchReceiptSignature: PropTypes.func.isRequired,
}

Receipt.defaultProps = {
  receiptSignature: null,
}

export default compose(withStyles(styles))(Receipt)
