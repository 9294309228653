import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { pdfjs, Document, Page } from 'react-pdf'
import { withStyles, Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextNextIcon from '@material-ui/icons/NavigateNext'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import ZoomOutIcon from '@material-ui/icons/ZoomOut'
import Rotate90Icon from '@material-ui/icons/Rotate90DegreesCcw'
import i18n from 'providers/i18n/I18nProvider'
import { styles } from './PdfStyles'

/* TODO  Fix issue https://github.com/wojtekmaj/react-pdf/issues/280 */
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

class Pdf extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      numPages: null,
      pageNumber: 1,
      scale: 1.0,
      rotate: 0,
    }
  }

  componentDidMount() {
    const { pageNumber } = this.props
    this.setState({
      pageNumber,
    })
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  nextPage = () => {
    const { pageNumber, numPages } = this.state
    let nextPageNumber = pageNumber + 1
    if (pageNumber + 1 > numPages) {
      nextPageNumber = numPages
    }

    this.setState({
      pageNumber: nextPageNumber,
    })
  }

  previewPage = () => {
    const { pageNumber } = this.state
    let previewPageNumber = pageNumber - 1
    if (pageNumber - 1 < 1) {
      previewPageNumber = 1
    }

    this.setState({
      pageNumber: previewPageNumber,
    })
  }

  zoomIn = () => {
    const { scale } = this.state
    this.setState({
      scale: scale + 0.1,
    })
  }

  zoomOut = () => {
    const { scale } = this.state
    this.setState({
      scale: scale - 0.1,
    })
  }

  rotate = () => {
    let { rotate } = this.state
    rotate += 90
    this.setState({
      rotate: rotate > 270 ? 0 : rotate,
    })
  }

  render() {
    const { file, downloadFilename, classes, translate } = this.props
    const { pageNumber, numPages, scale, rotate } = this.state

    return (
      <div className={classes.root}>
        <Document
          className={classes.document}
          file={file}
          onLoadSuccess={this.onDocumentLoadSuccess}
          error={translate('app.pdf.error')}
        >
          <Page
            className={classes.page}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderInteractiveForms={false}
            scale={scale}
            rotate={rotate}
          />
        </Document>
        {numPages && (
          <div className={classes.menu}>
            <Button onClick={this.rotate}>
              <Rotate90Icon />
            </Button>

            <Button onClick={this.zoomIn} disabled={scale === 1}>
              <ZoomInIcon />
            </Button>

            <Button onClick={this.zoomOut} disabled={scale === 0}>
              <ZoomOutIcon />
            </Button>

            <Button onClick={this.previewPage} disabled={pageNumber === 1}>
              <NavigateBeforeIcon />
            </Button>
            {translate('app.pdf.pagination', {
              currentPage: pageNumber,
              maxPage: numPages,
            })}
            <Button onClick={this.nextPage} disabled={pageNumber === numPages}>
              <NavigateNextNextIcon />
            </Button>
            <Button href={file} download={downloadFilename}>
              <SaveIcon />
            </Button>
          </div>
        )}
      </div>
    )
  }
}

Pdf.defaultProps = {
  pageNumber: 1,
}

Pdf.propTypes = {
  file: PropTypes.string.isRequired,
  pageNumber: PropTypes.number,
  downloadFilename: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    document: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
    menu: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(i18n, withStyles(styles))(Pdf)
