import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import {
  withStyles,
  Button,
  Grid,
  Typography,
  DialogContent,
} from '@material-ui/core'

import RenderSelectField from 'components/shared/Form/RenderSelectField'
import RenderTextField from 'components/shared/Form/RenderTextField'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import { INCIDENT_ORIGINS } from 'constants/incidents'
import IncidentFormDialogButtons from './IncidentFormDialogButtons'
import styles from './IncidentFormStyles'

const IncidentForm = ({
  translate,
  handleSubmit,
  handleChangeNature,
  handleClose,
  incidentTypes,
  incidentNatures,
  selectedNature,
  selectedType,
  classes,
  confirmationDialogOpened,
  openConfirmationDialog,
  formErrors,
}) => {
  const [formHasErrors, setFormHasErrors] = useState(false)

  useEffect(() => {
    setFormHasErrors(false)
    Object.keys(formErrors).map(error => {
      if (formErrors[error] !== undefined) {
        setFormHasErrors(true)
      }
      return formHasErrors
    })
  }, [formErrors, formHasErrors])

  return (
    <div className={classes.formMargin}>
      <form onSubmit={handleSubmit}>
        {confirmationDialogOpened && (
          <SimpleDialog
            title={translate('resources.incidents.validation.title')}
            onClose={handleClose}
            type="custom"
            customActions={
              <IncidentFormDialogButtons
                handleClose={handleClose}
                handleConfirm={handleSubmit}
                translate={translate}
              />
            }
          >
            <DialogContent>
              <Grid item xs={12}>
                <Typography component="p">
                  {translate('resources.incidents.validation.text')}
                </Typography>
              </Grid>
            </DialogContent>
          </SimpleDialog>
        )}
        <Grid container spacing={3}>
          <Grid item className={classes.container}>
            <Grid item xs={6}>
              <Field
                className={classes.selectEmpty}
                name="incidentNature"
                id="incidentNatureSelect"
                choices={incidentNatures}
                component={RenderSelectField}
                label={translate(
                  'resources.incidents.post_description_form.nature_label',
                )}
                allowEmpty
                optionText={element =>
                  translate(`resources.incidents.natures.${element.label}`)
                }
                optionValue="code"
                onChange={handleChangeNature}
              />
            </Grid>
            <Grid item xs={6} className={classes.inputIncidentType}>
              <Field
                className={classes.selectEmpty}
                name="incidentType"
                id="incidentTypeSelect"
                choices={incidentTypes}
                component={RenderSelectField}
                label={translate(
                  'resources.incidents.post_description_form.type_label',
                )}
                allowEmpty
                disabled={selectedNature === null}
                optionText="label"
                optionValue="code"
              />
            </Grid>
            <Grid item xs={6} className={classes.inputIncidentType}>
              <Field
                className={classes.selectEmpty}
                name="incidentOrigin"
                id="incidentOrigin"
                choices={INCIDENT_ORIGINS}
                component={RenderSelectField}
                label={translate(
                  'resources.incidents.post_description_form.origin_label',
                )}
                allowEmpty
                disabled={selectedType === null}
                optionText="label"
                optionValue="code"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Field
              name="description"
              type="textarea"
              component={RenderTextField}
              className={classes.inputTextarea}
              placeholder={translate(
                'resources.incidents.post_description_form.description_placeholder',
              )}
              multiline
              maxRows={2}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={openConfirmationDialog}
              className={classes.buttonBigger}
              variant="contained"
              color="secondary"
              disabled={formHasErrors}
            >
              {translate(
                'resources.incidents.post_description_form.submit_button',
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

IncidentForm.propTypes = {
  handleChangeNature: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  openConfirmationDialog: PropTypes.func.isRequired,
  incidentTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  incidentNatures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formErrors: {
    selectedNature: PropTypes.string,
    selectedType: PropTypes.string,
    selectedOrigin: PropTypes.string,
  },
  classes: PropTypes.shape({
    formMargin: PropTypes.string.isRequired,
    inputText: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    selectEmpty: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    inputIncidentType: PropTypes.string.isRequired,
    inputTextarea: PropTypes.string.isRequired,
  }).isRequired,
  selectedNature: PropTypes.string,
  selectedType: PropTypes.string,
  confirmationDialogOpened: PropTypes.bool.isRequired,
}

IncidentForm.defaultProps = {
  selectedNature: null,
  selectedType: null,
  formErrors: {
    selectedNature: undefined,
    selectedType: undefined,
    selectedOrigin: undefined,
  },
}

export default compose(withStyles(styles))(IncidentForm)
