import { AbstractActions } from './abstractActions'

export const initialState = {
  status: null,
  list: [],
  categoryList: [],
}

export class AbstractReducer {
  static getType() {
    return AbstractActions
  }

  static reducer(state = initialState, action) {
    switch (action.type) {
      case this.getType().GET_TYPE_LIST().SUCCESS:
        return {
          ...state,
          list: action.payload.hits,
        }
      case this.getType().GET_TYPE_CATEGORIES_LIST().SUCCESS:
        return {
          ...state,
          categoryList: action.payload.facetHits,
        }
      default: {
        return state
      }
    }
  }
}
