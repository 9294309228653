import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withStyles, Grid, Button } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import styles from './DialogTwoButtonsActionStyle'

const DialogTwoButtonsAction = ({
  onValidateClick,
  onCancelClick,
  classes,
  translate,
  validateLabel,
  cancelLabel,
  justifyContainer,
  disableValidate,
}) => (
  <Grid container justifyContent={justifyContainer}>
    <Grid item className={classes.margin}>
      <Button
        onClick={onValidateClick}
        variant="contained"
        color="secondary"
        className={classes.buttonBigger}
        disabled={disableValidate}
      >
        {translate(validateLabel)}
      </Button>
    </Grid>
    <Grid item className={classes.margin}>
      <Button onClick={onCancelClick} className={classes.buttonReload}>
        {translate(cancelLabel)}
      </Button>
    </Grid>
  </Grid>
)

DialogTwoButtonsAction.defaultProps = {
  validateLabel: 'app.action.confirm',
  cancelLabel: 'app.action.cancel',
  justifyContainer: 'center',
  disableValidate: false,
}

DialogTwoButtonsAction.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    buttonReload: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
  }).isRequired,
  onValidateClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  validateLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  justifyContainer: PropTypes.string,
  disableValidate: PropTypes.bool,
}

export default compose(i18n, withStyles(styles))(DialogTwoButtonsAction)
