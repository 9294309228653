import { connect } from 'react-redux'
import {
  orderNumberSelector,
  jobIdSelector,
  syliusIdSelector,
  orderCheckoutSelector,
  storeNameSelector,
  currencySelector,
  subtotalSelector,
  subtotalProSelector,
  partnerIdSelector,
} from 'store/jobs/jobSelectors'
import { partnerLabelSelector } from 'store/worksites/worksiteSelectors'
import JobDetailsOrder from './JobDetailsOrder'

const mapStateToProps = state => ({
  orderNumber: orderNumberSelector(state),
  jobId: jobIdSelector(state),
  syliusId: syliusIdSelector(state),
  orderCheckout: orderCheckoutSelector(state),
  partnerLabel: partnerLabelSelector(state),
  storeName: storeNameSelector(state),
  currency: currencySelector(state),
  subtotal: subtotalSelector(state),
  subtotalPro: subtotalProSelector(state),
  partnerId: partnerIdSelector(state),
})

export default connect(mapStateToProps)(JobDetailsOrder)
