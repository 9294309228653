import Cookies from 'universal-cookie'
import * as FEATURES from 'constants/features'

const cookies = new Cookies()

export const getEnabledFeatures = () => {
  const values = cookies.get('features')
  const featureNames = values ? values.split(',') : []
  return Object.values(FEATURES).filter(({ name }) =>
    featureNames.includes(name),
  )
}

export const isFeatureEnabled = feature =>
  getEnabledFeatures().includes(feature)

export const enableFeatures = features => {
  cookies.set('features', features.map(feature => feature.name).join(','), {
    path: '/',
    maxAge: 3 * 24 * 3600,
  })
}

export const isFeatureFlagEnabled = (featureFlagsState, feature) =>
  !!featureFlagsState && featureFlagsState[feature]
