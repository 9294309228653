import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  MobileStepper,
  Button,
  Grid,
  Link,
} from '@material-ui/core'
import config from 'config'
import { KeyboardArrowLeft } from '@material-ui/icons'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  VALIDATION,
  DETAILS,
  INFORMATION_PACKAGE,
  LEGAL_INFORMATIONS,
  ZONE_INTERVENTION,
  PACKAGE,
  DOCUMENTATION,
  DOCUMENTATION_PRO,
  COLLABORATORS,
  HISTORY,
  CONTRACT_SIGNATURES_STATUS_FINISHED,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
} from 'constants/firms'
import { DATE_FORMAT } from 'constants/date'
import { dateFormatter } from 'helpers/date'
import { PERM_FIRM_READ_LEGAL } from 'constants/permissions'
import { withRouter } from 'react-router-dom'
import FirmDetailsPackageContainer from 'components/Firms/FirmDetails/FirmDetailsPackage/FirmDetailsPackageContainer'
import FirmDetailsProContainer from 'components/Firms/FirmDetails/FirmDetailsPro/FirmDetailsProContainer'
import FirmLegalInformationsContainer from 'components/Firms/FirmDetails/FirmLegalInformations/FirmLegalInformationsContainer'
import PackageContract from 'components/Firms/FirmDetails/PackageContract/PackageContract'
import FirmInterventionZone from 'components/Firms/FirmDetails/FirmInterventionZone/FirmInterventionZoneContainer'
import FirmCommentsContainer from 'components/Firms/FirmDetails/FirmComments/FirmCommentsContainer'
import FirmCollaboratorsContainer from 'components/Firms/FirmDetails/FirmCollaborators/FirmCollaboratorsContainer'
import FirmInformationsPackage from 'components/Firms/FirmDetails/FirmInformationsPackage/FirmInformationsPackageContainer'
import FirmValidationProContainer from 'components/Firms/FirmDetails/FirmValidationPro/FirmValidationProContainer'
import FirmSaveButton from 'components/Firms/FirmDetails/FirmSaveButton/FirmSaveButton'
import FirmFiles from 'components/Firms/FirmDetails/FirmFiles/FirmFiles'
import Icon from 'components/shared/Icon/Icon'
import { hasPermission } from 'services/permissions'
import { styles } from './FirmDetailsStyle'

const FirmDetails = ({
  classes,
  translate,
  firm,
  contractSignatureId,
  contractSignatureStatus,
  contractSignedAt,
  getFirmCertificates,
  handleSubmit,
  getFirmContractData,
  history: { goBack },
}) => {
  useEffect(() => {
    getFirmCertificates()
  }, [getFirmCertificates])

  const [expandedItems, setExpandedItems] = useState({
    [VALIDATION]: true,
    [DETAILS]: true,
    [INFORMATION_PACKAGE]: true,
    [LEGAL_INFORMATIONS]: true,
    [ZONE_INTERVENTION]: true,
    [PACKAGE]: true,
    [DOCUMENTATION]: true,
    [DOCUMENTATION_PRO]: true,
    [COLLABORATORS]: true,
    [HISTORY]: true,
  })

  let startAt = ''
  let endAt = ''
  const initialFirmUnavailabilityPeriods = firm.firmUnavailabilityPeriods
  const isFirmAvailable = firm.available

  if (
    firm.firmUnavailabilityPeriods &&
    firm.firmUnavailabilityPeriods.length > 0
  ) {
    startAt = dateFormatter(
      firm.firmUnavailabilityPeriods[0].startAt,
      DATE_FORMAT,
    )
    endAt = dateFormatter(firm.firmUnavailabilityPeriods[0].endAt, DATE_FORMAT)
  }

  const handleChange = panel => (_, expanded) => {
    setExpandedItems({
      ...expandedItems,
      [panel]: expanded,
    })
  }
  const displayContract =
    contractSignatureId &&
    contractSignatureStatus === CONTRACT_SIGNATURES_STATUS_FINISHED

  return (
    <div>
      <MobileStepper
        steps={0}
        position="static"
        className={classes.root}
        backButton={
          <Button size="small" onClick={goBack}>
            <KeyboardArrowLeft />
            {translate('job.back')}
          </Button>
        }
      />
      {[
        PACKAGE_STATUS_PENDING_TEST_JOB.code,
        PACKAGE_STATUS_IN_PROGRESS_TEST_JOB.code,
      ].includes(firm.packageStatusV2) && (
        <ExpansionPanel
          expanded={expandedItems[VALIDATION]}
          onChange={handleChange(VALIDATION)}
          className={classes.ExpansionPanelBloc}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {translate('resources.firms.accordions.validate_pro.title')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FirmValidationProContainer
              firm={firm}
              handleSubmit={handleSubmit}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      <ExpansionPanel
        expanded={expandedItems[DETAILS]}
        onChange={handleChange(DETAILS)}
        className={classes.ExpansionPanelBloc}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {translate('resources.firms.accordions.informations_firm')}
          </Typography>
          {initialFirmUnavailabilityPeriods &&
            initialFirmUnavailabilityPeriods.length > 0 &&
            (!isFirmAvailable ? (
              <>
                <Icon className={classes.icon} icon="circle-red.svg" />
                <Typography className={classes.unavailabilityInfosActive}>
                  {translate('firm.unavailabilityDates', {
                    startAt,
                    endAt,
                  })}
                </Typography>
              </>
            ) : (
              <>
                <Icon className={classes.icon} icon="circle-green.svg" />
                <Typography className={classes.unavailabilityInfosInFuture}>
                  {translate('firm.unavailabilityDates', {
                    startAt,
                    endAt,
                  })}
                </Typography>
              </>
            ))}
        </ExpansionPanelSummary>
        <Link
          className={classes.linkProDirectory}
          href={`${config.proWebSiteDirectory.url}/${firm.url}`}
          target="_blank"
        >
          {translate('resources.firms.pro_directory_link')}
        </Link>
        <ExpansionPanelDetails>
          <FirmDetailsProContainer firm={firm} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {hasPermission(PERM_FIRM_READ_LEGAL) && (
        <ExpansionPanel
          expanded={expandedItems[LEGAL_INFORMATIONS]}
          onChange={handleChange(LEGAL_INFORMATIONS)}
          className={classes.ExpansionPanelBloc}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>
              {translate('resources.firms.accordions.legal_informations')}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <FirmLegalInformationsContainer firm={firm} />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}
      <ExpansionPanel
        expanded={expandedItems[INFORMATION_PACKAGE]}
        onChange={handleChange(INFORMATION_PACKAGE)}
        className={classes.ExpansionPanelBloc}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {translate('resources.firms.accordions.informations_packages')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FirmInformationsPackage firm={firm} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expandedItems[ZONE_INTERVENTION]}
        onChange={handleChange(ZONE_INTERVENTION)}
        className={classes.ExpansionPanelBloc}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {translate('resources.firms.accordions.interventionArea')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FirmInterventionZone />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expandedItems[PACKAGE]}
        onChange={handleChange(PACKAGE)}
        className={classes.ExpansionPanelBloc}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {translate('resources.firms.accordions.packages')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FirmDetailsPackageContainer />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expandedItems[DOCUMENTATION_PRO]}
        onChange={handleChange(DOCUMENTATION_PRO)}
        className={classes.ExpansionPanelBloc}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {translate('resources.firms.accordions.documents')}
          </Typography>
        </ExpansionPanelSummary>
        {displayContract && (
          <ExpansionPanelDetails className={classes.btnContainer}>
            <Typography className={classes.subheading}>
              {translate('resources.firms.accordions.contracts')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card>
                  <CardContent className={classes.cardOk}>
                    <PackageContract
                      contractType="package"
                      contractSignedAt={contractSignedAt}
                      firm={firm}
                      getFirmContractData={getFirmContractData}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        )}
        <ExpansionPanelDetails>
          <Link
            href={`${config.legacy.proLink}${firm.legacyId}`}
            variant="body1"
            target="_blank"
          >
            {translate('resources.firms.fields.document.pro_file_link')}
          </Link>
        </ExpansionPanelDetails>
        <ExpansionPanelDetails className={classes.btnContainer}>
          <Typography className={classes.subheading}>
            {translate('resources.firms.accordions.mandatory_documents')}
          </Typography>
          <FirmFiles mandatory />
        </ExpansionPanelDetails>
        <ExpansionPanelDetails className={classes.btnContainer}>
          <Typography className={classes.subheading}>
            {translate('resources.firms.accordions.additional_documents')}
          </Typography>
          <FirmFiles mandatory={false} />
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        expanded={expandedItems[COLLABORATORS]}
        onChange={handleChange(COLLABORATORS)}
        className={classes.ExpansionPanelBloc}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {translate('resources.firms.accordions.collaborators')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FirmCollaboratorsContainer users={firm.users} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        expanded={expandedItems[HISTORY]}
        onChange={handleChange(HISTORY)}
        className={classes.ExpansionPanelBloc}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {translate('resources.firms.accordions.comments')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FirmCommentsContainer />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Grid className={classes.cardButtonSave}>
        <FirmSaveButton
          handleClick={() => handleSubmit()}
          variant="contained"
          color="secondary"
          text="resources.firms.buttons.save"
          bigger
        />
      </Grid>
    </div>
  )
}

FirmDetails.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    ExpansionPanelBloc: PropTypes.string.isRequired,
    linkProDirectory: PropTypes.string.isRequired,
    flexWrap: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    packageContract: PropTypes.string.isRequired,
    summaryDoc: PropTypes.string.isRequired,
    cardButtonSave: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    btnContainer: PropTypes.string.isRequired,
    cardOk: PropTypes.string.isRequired,
    unavailabilityInfosActive: PropTypes.string.isRequired,
    unavailabilityInfosInFuture: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  firm: PropTypes.shape({
    packageStatusV2: PropTypes.string.isRequired,
    legacyId: PropTypes.number,
    users: PropTypes.arrayOf(PropTypes.shape({})),
    url: PropTypes.string,
    firmUnavailabilityPeriods: PropTypes.arrayOf(
      PropTypes.shape({
        startAt: PropTypes.string.isRequired,
        endAt: PropTypes.string.isRequired,
      }),
    ),
    available: PropTypes.bool.isRequired,
  }).isRequired,
  contractSignatureId: PropTypes.string,
  contractSignatureStatus: PropTypes.string,
  contractSignedAt: PropTypes.string,
  getFirmCertificates: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getFirmContractData: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
}

FirmDetails.defaultProps = {
  contractSignatureId: undefined,
  contractSignatureStatus: undefined,
  contractSignedAt: undefined,
}

FirmDetails.defaultProps = {
  history: {
    goBack: () => {},
  },
}

export default compose(i18n, withRouter, withStyles(styles))(FirmDetails)
