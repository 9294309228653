import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { TableCell, TableRow } from '@material-ui/core'
import {
  FIELD_SELECTED_PRO_IRI_NAME,
  PRO_NOT_NOTIFIED_STATUS,
  PENDING_NOTIFICATION_STATUS,
} from 'constants/Jobs'
import { Field } from 'redux-form'
import i18n from 'providers/i18n/I18nProvider'
import RadioButton from 'components/shared/RadioButton/RadioButton'

const ManualMatchingFormItem = ({
  translate,
  requiredNumber,
  selectedProIri,
  isJobPendingAssign,
  matchingPro,
  pro,
  isTabValueZero,
  status,
}) => {
  const proIri = pro['@id']

  const getProResponse = () => {
    if (matchingPro.proResponse.value) {
      if (
        status === 'job_declined' &&
        !matchingPro.proResponse.value.includes(
          translate('resources.jobs.proTable.declined'),
        )
      ) {
        return (
          <>
            {translate('resources.jobs.proTable.declined')}
            {matchingPro.proResponse.value}
          </>
        )
      }
      return matchingPro.proResponse.value
    }

    return translate('resources.jobs.proTable.noResponse')
  }
  return (
    <TableRow key={proIri}>
      <TableCell component="th">
        <Field
          validate={requiredNumber}
          checked={selectedProIri === proIri}
          type="radio"
          name={FIELD_SELECTED_PRO_IRI_NAME}
          value={proIri}
          props={{ disabled: !isJobPendingAssign }}
          component={RadioButton}
        />
      </TableCell>
      {isTabValueZero && (
        <TableCell>
          <b>{matchingPro.index !== null ? matchingPro.index : '-'}</b>
        </TableCell>
      )}
      <TableCell>{pro.legacyId}</TableCell>
      <TableCell>{`${pro.firstName} ${pro.lastName}`}</TableCell>
      <TableCell>{pro.phoneNumber}</TableCell>
      <TableCell>
        {translate(`resources.jobs.proTable.isLM.${pro.isLM ? 'yes' : 'no'}`)}
      </TableCell>
      <TableCell>
        {translate(
          `resources.jobs.proTable.removal.${pro.removal ? 'yes' : 'no'}`,
        )}
      </TableCell>
      <TableCell>{pro.manualRating}</TableCell>
      {isTabValueZero && <TableCell>{matchingPro.countAcceptedJobs}</TableCell>}
      <TableCell>
        {matchingPro.notificationsCountForJob
          ? matchingPro.notificationsCountForJob
          : 0}
        {status === PENDING_NOTIFICATION_STATUS
          ? ` ${translate(
              'resources.jobs.proTable.plusOnePendingNotification',
            )}`
          : ''}
      </TableCell>
      <TableCell>
        {status === PRO_NOT_NOTIFIED_STATUS ||
        status === PENDING_NOTIFICATION_STATUS
          ? translate('resources.jobs.proTable.nan')
          : getProResponse()}
      </TableCell>
    </TableRow>
  )
}

ManualMatchingFormItem.propTypes = {
  translate: PropTypes.func.isRequired,
  requiredNumber: PropTypes.func.isRequired,
  selectedProIri: PropTypes.string,
  isJobPendingAssign: PropTypes.bool,
  matchingPro: PropTypes.shape({
    countAcceptedJobs: PropTypes.number,
    proResponse: PropTypes.shape({
      value: PropTypes.string,
    }),
    index: PropTypes.number,
    notificationsCountForJob: PropTypes.number,
  }),
  pro: PropTypes.shape({
    legacyId: PropTypes.string,
    '@id': PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    isLM: PropTypes.bool,
    removal: PropTypes.string,
    manualRating: PropTypes.string,
  }),
  isTabValueZero: PropTypes.bool,
  status: PropTypes.string,
}

ManualMatchingFormItem.defaultProps = {
  selectedProIri: null,
  status: PRO_NOT_NOTIFIED_STATUS,
  pro: {
    legacyId: null,
  },
  matchingPro: {
    countAcceptedJobs: null,
    proResponse: {
      value: null,
    },
    index: null,
    notificationsCountForJob: null,
  },
  isJobPendingAssign: false,
  isTabValueZero: false,
}

export default compose(i18n)(ManualMatchingFormItem)
