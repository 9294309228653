import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import get from 'lodash/get'
import uuidv1 from 'uuid/v1'
import { Cancel } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormHelperText from '@material-ui/core/FormHelperText'
import Select from 'react-select'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import classnames from 'classnames'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import i18n from 'providers/i18n/I18nProvider'

const styles = theme => ({
  container: {
    marginTop: -theme.spacing(1 / 2),
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    marginRight: theme.spacing(1 / 2),
    marginBottom: theme.spacing(1 / 2),
  },
  chipFocused: {
    backgroundColor: 'inherit',
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    width: 'fit-content',
  },
  noOptionsMessage: {
    opacity: 1,
  },
  error: {
    color: '#f44336',
  },
})

/**
 * Usage :
 *
 *    <Field
        name="favoriteColors"
        component={RenderSelectMultipleAutocompleteField}
        label="Favorites Colors"
        choices={[
          { name: 'Red', value: 'ff0000' },
          { name: 'Green', value: '00ff00' },
          { name: 'Blue', value: '0000ff' },
        ]}
        allowEmpty
      />
 */

class RenderSelectMultipleAutocompleteField extends PureComponent {
  getNoOptionMessage = () => {
    const { translate } = this.props

    return translate('app.message.noResult')
  }

  renderMultiValue = props => (
    <Chip
      tabIndex={-1}
      className={classnames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      label={props.children}
      onDelete={props.removeProps.onClick}
      deleteIcon={<Cancel {...props.removeProps} />}
    />
  )

  renderMenu = props => (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )

  renderValueContainer = props => (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )

  renderSingleValue = props => (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )

  renderPlaceholder = props => (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )

  renderOption = props => (
    <MenuItem
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )

  renderInputComponent = ({ inputRef, ...props }) => (
    <div ref={inputRef} {...props} />
  )

  renderControl = props => {
    const {
      children,
      innerProps,
      innerRef,
      selectProps: { classes, TextFieldProps },
    } = props

    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent: this.renderInputComponent,
          inputProps: {
            className: classes.input,
            ref: innerRef,
            children,
            ...innerProps,
          },
        }}
        {...TextFieldProps}
      />
    )
  }

  renderNoOptionMessage = props => (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )

  renderOptionText = element => {
    const { optionText } = this.props

    return get(element, optionText, null)
  }

  renderOptionValue = element => {
    const { optionValue } = this.props

    return get(element, optionValue, null)
  }

  render() {
    const {
      classes,
      theme,
      input,
      label,
      meta: { touched, error, active },
      choices,
      optionText,
      optionValue,
      id,
      allowEmpty,
      ...custom
    } = this.props

    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="stretch"
        className={classes.container}
      >
        <Grid item>
          <Select
            {...input}
            {...custom}
            inputProps={{
              name: input.name,
              id,
            }}
            inputId={id}
            TextFieldProps={{
              label,
              InputLabelProps: {
                htmlFor: id,
                shrink: !!active || input.value.length > 0,
                className: touched && error ? classes.error : '',
              },
            }}
            onChange={value => input.onChange(value)}
            onBlur={() => input.onBlur(input.value)}
            isMulti
            isSearchable
            options={choices.map(choice => ({
              label: this.renderOptionText(choice, optionText),
              value: this.renderOptionValue(choice, optionValue),
            }))}
            classes={classes}
            styles={{
              input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                  font: 'inherit',
                },
              }),
            }}
            placeholder=""
            noOptionsMessage={this.getNoOptionMessage}
            components={{
              MultiValue: this.renderMultiValue,
              Menu: this.renderMenu,
              ValueContainer: this.renderValueContainer,
              SingleValue: this.renderSingleValue,
              Placeholder: this.renderPlaceholder,
              Option: this.renderOption,
              Control: this.renderControl,
              NoOptionMessage: this.renderNoOptionMessage,
            }}
          />
          {touched && error && (
            <FormHelperText className={classes.error}>{error}</FormHelperText>
          )}
        </Grid>
      </Grid>
    )
  }
}

RenderSelectMultipleAutocompleteField.propTypes = {
  classes: PropTypes.shape({
    chip: PropTypes.string.isRequired,
    chipFocused: PropTypes.string.isRequired,
    paper: PropTypes.string.isRequired,
    valueContainer: PropTypes.string.isRequired,
    singleValue: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    noOptionsMessage: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      text: PropTypes.shape({
        primary: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  translate: PropTypes.func.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.shape({})),
    ]).isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.shape({})),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    active: PropTypes.bool,
    touch: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
  id: PropTypes.string,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  optionValue: PropTypes.string,
  allowEmpty: PropTypes.bool,
}

RenderSelectMultipleAutocompleteField.defaultProps = {
  label: '',
  id: `select-${uuidv1()}`,
  optionText: 'name',
  optionValue: 'id',
  meta: {},
  allowEmpty: false,
  choices: [],
}

export default compose(
  i18n,
  withStyles(styles, { withTheme: true }),
)(RenderSelectMultipleAutocompleteField)
