import React from 'react'
import PropTypes from 'prop-types'
import { Radio } from '@material-ui/core'

const RadioButton = ({ input, disabled }) => (
  <Radio {...{ ...input, ...{ disabled } }} />
)

RadioButton.propTypes = {
  input: PropTypes.shape({}),
  disabled: PropTypes.bool,
}

RadioButton.defaultProps = {
  input: undefined,
  disabled: false,
}

export default RadioButton
