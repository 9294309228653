export const DASHBOARD_CARD_TYPE_CUSTOM = 'custom'
export const DASHBOARD_CARD_TYPE_OTHER = 'other'
export const DASHBOARD_CARD_TYPE_URGENT = 'urgent'
export const DASHBOARD_CARD_TYPE_NEW_INCIDENT = 'new'
export const GEOZONES_SELECTOR_FORM = 'geozone_selector_form'
export const DASHBOARD_SELECTED_ZONE = 'selected_zone'

export const DASHBOARD_GEOZONES_LIST = [
  {
    key: 'France',
    value: 'all',
  },
  {
    key: 'Zone Sud Ouest',
    value: 'zone_sud_ouest',
  },
  {
    key: 'Zone Sud Est',
    value: 'zone_sud_est',
  },
  {
    key: 'Zone Ouest',
    value: 'zone_ouest',
  },
  {
    key: 'Zone Nord',
    value: 'zone_nord',
  },
  {
    key: 'Zone Île-De-France',
    value: 'zone_ile_de_france',
  },
  {
    key: 'Zone Est',
    value: 'zone_est',
  },
  {
    key: 'Zone DOM',
    value: 'zone_dom',
  },
]
export const GEOZONES_DEPARTMENTS = {
  all: [],
  zone_sud_ouest: [
    '16',
    '17',
    '18',
    '19',
    '23',
    '24',
    '31',
    '32',
    '33',
    '36',
    '40',
    '46',
    '47',
    '64',
    '65',
    '81',
    '82',
    '87',
    '09',
  ],
  zone_sud_est: [
    '11',
    '12',
    '13',
    '15',
    '20',
    '26',
    '30',
    '34',
    '38',
    '42',
    '43',
    '48',
    '63',
    '66',
    '69',
    '71',
    '73',
    '74',
    '83',
    '84',
    '98',
    '01',
    '03',
    '04',
    '05',
    '06',
    '07',
    '2A',
    '2B',
  ],
  zone_ouest: [
    '22',
    '29',
    '35',
    '37',
    '41',
    '44',
    '49',
    '53',
    '56',
    '72',
    '79',
    '85',
    '86',
  ],
  zone_nord: ['14', '27', '50', '59', '60', '61', '62', '76', '80', '02'],
  zone_ile_de_france: [
    '28',
    '45',
    '75',
    '77',
    '78',
    '91',
    '92',
    '93',
    '94',
    '95',
  ],
  zone_est: [
    '10',
    '21',
    '25',
    '39',
    '51',
    '52',
    '54',
    '55',
    '57',
    '58',
    '67',
    '68',
    '70',
    '88',
    '89',
    '90',
    '08',
  ],
  zone_dom: ['96', '97'],
}
