import {
  FETCH_INCIDENT_TYPES,
  FETCH_INCIDENT,
  POST_INCIDENT_REQ,
  RESET_INCIDENT_LIST,
  FETCH_INCIDENT_STATUSES,
  GET_COUNT_INCIDENTS_BY_STATUSES,
  FETCH_INCIDENT_WAITING_FOR_LIST,
  RESP_SELECT_DIALOG_DISPLAYED,
  STATUS_CHANGE_CONFIRM_DIALOG_DISPLAYED,
} from './incidentActions'

import { initialState } from './incidentSelectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INCIDENT_STATUSES.SUCCESS:
      return {
        ...state,
        incidentStatuses: action.payload,
      }
    case FETCH_INCIDENT_WAITING_FOR_LIST.SUCCESS:
      return {
        ...state,
        incidentWaitingForList: action.payload,
      }
    case FETCH_INCIDENT_TYPES.SUCCESS:
      return {
        ...state,
        incidentTypes: action.payload['hydra:member'],
      }
    case RESET_INCIDENT_LIST:
      return {
        ...initialState,
        incidentTypes: state.incidentTypes,
        incidentStatuses: state.incidentStatuses,
        incidentWaitingForList: state.incidentWaitingForList,
      }
    case POST_INCIDENT_REQ.SUCCESS:
      return {
        ...state,
        postedIncidentId: action.payload.incidentId || null,
      }
    case GET_COUNT_INCIDENTS_BY_STATUSES.SUCCESS:
      return {
        ...state,
        incidentCounts: action.payload,
      }
    case RESP_SELECT_DIALOG_DISPLAYED: {
      const { displayed } = action.payload

      if (displayed) {
        return {
          ...state,
          dialogs: {
            ...state.dialogs,
            respSelect: {
              ...state.dialogs.respSelect,
              displayed,
              doDisplayStatusChangeConfirmDialogAction:
                action.payload.doDisplayStatusChangeConfirmDialogAction,
            },
          },
          currentUpdated: {
            ...state.currentUpdated,
            id: action.payload.incidentId,
            type: action.payload.type,
          },
        }
      }

      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          respSelect: initialState.dialogs.respSelect,
        },
        currentUpdated: initialState.currentUpdated,
      }
    }
    case STATUS_CHANGE_CONFIRM_DIALOG_DISPLAYED: {
      const { displayed } = action.payload

      if (displayed) {
        return {
          ...state,
          dialogs: {
            ...state.dialogs,
            statusChangeConfirm: {
              ...state.dialogs.statusChangeConfirm,
              displayed,
            },
          },
          currentUpdated: {
            ...state.currentUpdated,
            id: action.payload.incidentId,
            newStatus: action.payload.newStatus,
            type: action.payload.type,
          },
        }
      }

      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          statusChangeConfirm: initialState.dialogs.statusChangeConfirm,
        },
        currentUpdated: initialState.currentUpdated,
      }
    }
    case FETCH_INCIDENT.REQUEST:
    case FETCH_INCIDENT.SUCCESS:
    case FETCH_INCIDENT.FAILURE:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          displayed: initialState.dialogs.displayed,
        },
      }
    default: {
      return state
    }
  }
}
