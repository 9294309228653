export default theme => ({
  table: {
    display: 'inherit',
    width: 'auto',
    margin: '3rem',
    backgroundColor: '#dae1e7',
    borderRadius: '5px',
    overflowX: 'auto',
  },
  row: {
    padding: '1.2rem',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  results: {
    margin: '3rem',
  },
})
