import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import Pdf from 'components/shared/Pdf/Pdf'
import { getReceiptJob } from 'store/jobs/jobActions'
import { receiptJobSelector } from 'store/jobs/jobSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'

class Receipt extends PureComponent {
  componentDidMount() {
    const { getReceipt } = this.props
    getReceipt()
  }

  render() {
    const { receipt, isLoading, translate } = this.props
    return (
      receipt.content &&
      !isLoading && (
        <Pdf
          file={`data:application/pdf;base64,${receipt.content}`}
          pageNumber={1}
          downloadFilename={`${translate('resources.jobs.fields.receipt')}.pdf`}
          loading={isLoading}
        />
      )
    )
  }
}

Receipt.defaultProps = {
  receipt: {
    name: null,
    content: null,
  },
}

Receipt.propTypes = {
  receipt: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  }),
  getReceipt: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  receipt: receiptJobSelector(state),
  isLoading: isLoadingSelector(state),
})

const mapDispatchToProps = (dispatch, { jobIri }) => ({
  getReceipt: () => dispatch(getReceiptJob.request({ jobIri })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
)(Receipt)
