const styles = () => ({
  root: {
    padding: '0.7rem 0.2rem',
    fontWeight: '600',
    textTransform: 'none',
    marginLeft: '2rem',
  },
})

export default styles
