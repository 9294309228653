import React from 'react'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { HOME_PATH } from 'constants/routes'
import { withStyles } from '@material-ui/core'
import styles from './ErrorStyles'

const Error = ({ classes, translate }) => (
  <div className={classes.error}>
    <p className={classes.message}>{translate(`error.message`)}</p>
    <a className={classes.buttonBigger} href={HOME_PATH}>
      {translate(`error.button`)}
    </a>
  </div>
)

Error.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    error: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(i18n, withStyles(styles), withRouter)(Error)
