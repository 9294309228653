import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { Typography, withStyles } from '@material-ui/core'
import JobPicturesCarousel from 'components/shared/Carousel/JobPicturesCarousel'
import Label from 'components/shared/Label/Label'
import styles from './JobDetailsOrderPicturesStyles'

const JobDetailsOrderPictures = ({
  hoPictures,
  proPictures,
  translate,
  classes,
}) => (
  <>
    <div className={classes.carouselWidth}>
      <Label label={translate('resources.jobs.fields.ho_pictures')} />
      {hoPictures.length > 0 ? (
        <JobPicturesCarousel pictures={hoPictures} />
      ) : (
        <Typography variant="body2" gutterBottom>
          {translate('resources.jobs.fields.no_ho_pictures')}
        </Typography>
      )}
    </div>
    <div className={classes.carouselWidth}>
      <Label label={translate('resources.jobs.fields.pro_pictures')} />
      {proPictures.length > 0 ? (
        <JobPicturesCarousel pictures={proPictures} />
      ) : (
        <Typography variant="body2" gutterBottom>
          {translate('resources.jobs.fields.no_pro_pictures')}
        </Typography>
      )}
    </div>
  </>
)

JobDetailsOrderPictures.propTypes = {
  classes: PropTypes.shape({
    carouselWidth: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  proPictures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  hoPictures: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
}

JobDetailsOrderPictures.defaultProps = {
  proPictures: [],
  hoPictures: [],
}

export default compose(i18n, withStyles(styles))(JobDetailsOrderPictures)
