import { differenceInHours, parseISO } from 'date-fns'
import {
  INVOICE_ITEM_TYPE_PACKAGE,
  SENDING_FAILED_IF_STATE_NEW_SINCE_MORE_THAN_HOURS,
  INVOICE_STATE_AWAITING_VALIDATION,
  INVOICE_STATE_CONTESTED,
  INVOICE_STATE_MANUAL,
  INVOICE_STATE_PAID,
  INVOICE_STATE_VALIDATED,
  INVOICE_STATE_NEW,
  INVOICE_STATE_NEW_AWAITING_SENDING,
  INVOICE_STATE_NEW_SENDING_FAILED,
  SALE_INVOICE_ITEM_NB_LINES,
} from 'constants/invoices'

export const getInvoiceIdFromIri = iri =>
  iri.replace(/\/(purchase-|sale-)?invoices\//gi, '')

export const getPackageFromPurchaseInvoice = items =>
  items.filter(item => item.type === INVOICE_ITEM_TYPE_PACKAGE).shift()

export const getJobInformationFromInvoiceItem = item => {
  const parts = item.name.split('\n')

  if (parts.length !== 2) {
    return { packageName: null, customer: null, timeslot: null }
  }

  const packageName = parts[0]
  // Returns pro's fullname with his gender
  const customer = parts[1]
    .substring(0, parts[1].length - 14)
    .split(' ')
    .splice(1)
    .join(' ')
  const timeslot = parts[1].substring(parts[1].length - 10, parts[1].length)

  return { packageName, customer, timeslot }
}

export const normalizeFilterValues = data => ({
  types: data['document-type'],
  status: data['document-status'],
  dates: data['partner-creation'],
})

export const getChipType = state => {
  switch (state) {
    case INVOICE_STATE_VALIDATED:
    case INVOICE_STATE_PAID:
      return 'success'
    case INVOICE_STATE_CONTESTED:
    case INVOICE_STATE_NEW_SENDING_FAILED:
      return 'error'
    case INVOICE_STATE_MANUAL:
    case INVOICE_STATE_AWAITING_VALIDATION:
    default:
      return 'info'
  }
}

export const getDisplayedState = (state, stateUpdatedAt) => {
  if (INVOICE_STATE_NEW === state && stateUpdatedAt) {
    return differenceInHours(new Date(), parseISO(stateUpdatedAt)) >
      SENDING_FAILED_IF_STATE_NEW_SINCE_MORE_THAN_HOURS
      ? INVOICE_STATE_NEW_SENDING_FAILED
      : INVOICE_STATE_NEW_AWAITING_SENDING
  }
  return state
}

export const getProductItemsFromInvoice = invoice =>
  invoice.items.filter(item => item.type === INVOICE_ITEM_TYPE_PACKAGE)

export const getProductFromSaleInvoiceItem = saleInvoiceItem => {
  const parts = saleInvoiceItem.name.split('\n')

  if (parts.length !== SALE_INVOICE_ITEM_NB_LINES) {
    return null
  }

  return parts[0]
}

export const getProductsFromSaleInvoice = saleInvoice => {
  const products = []
  getProductItemsFromInvoice(saleInvoice).forEach(item => {
    const product = getProductFromSaleInvoiceItem(item)
    if (product) {
      products.push(product)
    }
  })

  return products
}
