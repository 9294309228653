import axios from 'axios'
import merge from 'lodash/merge'
import { api } from 'config'
import cloneDeep from 'lodash/cloneDeep'
import { ACCESS_TOKEN, ID_TOKEN } from 'constants/storage'

const commonOptions = {
  baseURL: null,
  headers: {
    apikey: api.kongApiKey,
    // Prevent API calls to be cached by shitty IE
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  },
}

class Client {
  client = null

  options = {}

  constructor(customOptions = {}) {
    this.options = merge(commonOptions, customOptions)

    this.client = axios.create(this.options)
    this.client.interceptors.request.use(async paramConfig => {
      const config = cloneDeep(paramConfig)

      config.headers.Identification = `Bearer ${window.localStorage.getItem(
        ID_TOKEN,
      )}`

      config.headers.Authorization = `Bearer ${window.localStorage.getItem(
        ACCESS_TOKEN,
      )}`

      return config
    })
  }

  getInstance() {
    return this.client
  }
}

export default Client
