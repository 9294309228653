import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { Field } from 'redux-form'
import { FormControl, Grid, withStyles } from '@material-ui/core'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectMultipleAutocompleteField from 'components/shared/Form/RenderSelectMultipleAutocompleteField'
import styles from './FirmsFiltersStyles'

function generateOnChange(handleFiltersChange, name, onChange) {
  return handleFiltersChange
    ? values => handleFiltersChange(name, values)
    : onChange
}

const FirmsFilter = ({
  autocomplete,
  classes,
  handleFiltersChange,
  label,
  onChange,
  translate,
  component,
  ...rest
}) => {
  const additionalProps = autocomplete
    ? {
        component: component || RenderSelectMultipleAutocompleteField,
        id: `${rest.name}AutocompleteSelect`,
        optionValue: rest.optionValue ? rest.optionValue : 'code',
      }
    : {
        component: RenderTextField,
      }
  return (
    <Grid item className={classes.inputSizing}>
      <FormControl className={classes.formControl}>
        <Field
          label={translate(label)}
          onChange={generateOnChange(handleFiltersChange, rest.name, onChange)}
          translate={translate}
          {...rest}
          {...additionalProps}
        />
      </FormControl>
    </Grid>
  )
}

FirmsFilter.defaultProps = {
  autocomplete: false,
  handleFiltersChange: null,
  onChange: () => null,
  component: undefined,
}

FirmsFilter.propTypes = {
  component: PropTypes.node,
  autocomplete: PropTypes.bool,
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    inputSizing: PropTypes.string.isRequired,
  }).isRequired,
  handleFiltersChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  translate: PropTypes.func.isRequired,
}

export default compose(i18n, withStyles(styles))(FirmsFilter)
