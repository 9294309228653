import { initialState } from './partnersSelectors'
import { GET_PARTNERS } from './partnersActions'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PARTNERS.SUCCESS:
      return {
        ...state,
        partners: action.payload['hydra:member'],
      }
    default: {
      return state
    }
  }
}
