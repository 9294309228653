import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { dateFormatter } from 'helpers/date'
import i18n from 'providers/i18n/I18nProvider'
import { getJobIdFromIri } from 'helpers/utils/job/job'
import InlineTextField from 'components/shared/InlineTextField/InlineTextField'
import FieldPrice from 'components/shared/FieldPrice/FieldPrice'
import { PARTNER_LM } from 'constants/partners'

const JobDetails = ({
  orderNumber,
  jobId,
  syliusId,
  orderCheckout,
  partnerLabel,
  storeName,
  currency,
  subtotal,
  translate,
  subtotalPro,
  partnerId,
}) => (
  <>
    {partnerId === PARTNER_LM.id && (
      <InlineTextField
        label={translate('resources.jobs.fields.orderId')}
        field={orderNumber}
        isBold
      />
    )}
    <InlineTextField
      label={translate('resources.jobs.fields.jobId')}
      field={getJobIdFromIri(jobId)}
      isBold
    />
    <InlineTextField
      label={translate('resources.jobs.fields.syliusId')}
      field={syliusId}
      isBold
    />
    <InlineTextField
      label={translate('resources.jobs.fields.checkout_completed_at')}
      field={dateFormatter(orderCheckout, translate('app.date_format.textual'))}
      isBold
    />
    <InlineTextField
      label={translate('resources.jobs.fields.partnerLabel')}
      field={partnerLabel}
      isBold
    />
    <InlineTextField
      label={translate('resources.jobs.fields.storeName')}
      field={storeName}
      isBold
    />

    <FieldPrice
      label={translate('resources.jobs.fields.partner_price')}
      currency={currency}
      field={subtotal}
      isBold
    />

    <FieldPrice
      label={translate('resources.jobs.fields.pro_price')}
      currency={currency}
      field={subtotalPro}
      isBold
    />
  </>
)

JobDetails.propTypes = {
  translate: PropTypes.func.isRequired,
  orderNumber: PropTypes.string,
  jobId: PropTypes.string,
  syliusId: PropTypes.string,
  orderCheckout: PropTypes.string,
  partnerLabel: PropTypes.string,
  storeName: PropTypes.string,
  currency: PropTypes.string,
  subtotal: PropTypes.number,
  subtotalPro: PropTypes.number,
  partnerId: PropTypes.number.isRequired,
}

JobDetails.defaultProps = {
  orderNumber: undefined,
  jobId: undefined,
  syliusId: undefined,
  orderCheckout: undefined,
  partnerLabel: undefined,
  storeName: undefined,
  currency: undefined,
  subtotal: undefined,
  subtotalPro: undefined,
}

export default compose(i18n, memo)(JobDetails)
