export const styles = theme => ({
  root: {
    width: '100%',
    padding: '3rem 2rem 0',
    background: 'none',
  },
  heading: {
    fontSize: '1.4rem',
    fontWeight: '500',
    color: theme.palette.primary.main,
  },
  subheading: {
    fontSize: '1.2rem',
    fontWeight: '500',
    color: theme.palette.primary.main,
    marginBottom: '20px',
  },
  summaryDoc: {
    clear: 'both',
  },
  packageContract: {
    fontFamily: 'montserrat',
    padding: '2rem',
    marginTop: '2rem',
    marginRight: '0.5rem',
    background: '#f3f7f9',
    borderRadius: '4px',
  },
  ExpansionPanelBloc: {
    margin: '3rem !important',
    display: 'block',
    padding: '2rem',
    background: 'white',
  },
  linkProDirectory: {
    padding: '0px 16px',
  },
  cardButtonSave: {
    margin: '3rem',
    background: 'none',
  },
  buttonBigger: theme.buttonBigger,
  btnContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  cardOk: {
    backgroundColor: theme.palette.blueLight.main,
    margin: '10px 0',
  },
  icon: {
    width: '10px',
    height: '10px',
    marginLeft: '15px',
    marginTop: '9px',
  },
  unavailabilityInfosActive: {
    color: '#912824',
    fontSize: '0.7rem',
    fontWeight: '500',
    marginLeft: '5px',
    marginTop: '10px',
  },
  unavailabilityInfosInFuture: {
    fontSize: '0.7rem',
    fontWeight: '500',
    marginLeft: '5px',
    marginTop: '10px',
  },
})
