import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Icon from 'components/shared/Icon/Icon'
import FirmCollaboratorsPopin from './FirmCollaboratorsPopin'

import styles from './FirmCollaboratorsStyle'

const FirmCollaborators = ({ users, translate, classes }) => {
  const [popinOpened, setPopinOpened] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{translate('collaborators.fields.civility')}</TableCell>
            <TableCell>{translate('collaborators.fields.lastname')}</TableCell>
            <TableCell>{translate('collaborators.fields.firstname')}</TableCell>
            <TableCell>{translate('collaborators.fields.status')}</TableCell>
            <TableCell>{translate('collaborators.fields.email')}</TableCell>
            <TableCell>{translate('collaborators.fields.phone')}</TableCell>
            <TableCell>{translate('collaborators.fields.mobile')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(users || []).map(user => {
            const status =
              user.owner === true
                ? translate('collaborators.fields.manager')
                : translate('collaborators.fields.installer')

            return (
              <TableRow key={user['@id']}>
                <TableCell>{user.gender}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>
                  <b>{status}</b>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.fixedPhone || '-'}</TableCell>
                <TableCell>{user.mobilePhone}</TableCell>
                <TableCell>
                  <Icon
                    className={classes.icon}
                    icon="edit.svg"
                    onClick={() => {
                      setCurrentUser({ ...user, status })
                      setPopinOpened(true)
                    }}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {popinOpened && (
        <FirmCollaboratorsPopin
          open={popinOpened}
          handleClose={() => setPopinOpened(false)}
          user={currentUser}
        />
      )}
    </>
  )
}

FirmCollaborators.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    multiLine: PropTypes.string.isRequired,
    tdDate: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string,
      gender: PropTypes.string,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      email: PropTypes.string,
      fixedPhone: PropTypes.string,
      mobilePhone: PropTypes.string,
    }),
  ),
}

FirmCollaborators.defaultProps = {
  users: [],
}

export default compose(withStyles(styles), i18n)(FirmCollaborators)
