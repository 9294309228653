export default {
  root: {
    width: '100%',
    overflowX: 'auto',
    backgroundColor: '#d9e1e8',
  },
  menuLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
    fontWeight: 'bold',
  },
  jobOrderIdContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imgPartner: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
  },
  partnerLabel: {
    padding: '0.5rem',
  },
}
