export default theme => ({
  buttonSecondary: {
    ...theme.buttonSecondary,
    marginBottom: '0',
    marginTop: '16px',
  },
  inputText: {
    width: '100%',
    height: '65px',
    fontSize: '0.9rem',
  },
  formControl: {
    margin: theme.spacing(1),
    width: 'auto',
    minWidth: '200px',
  },
  checkboxInterventionZone: {
    flexDirection: 'row',
  },
  margin: {
    margin: '2rem 2rem 0 0',
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  input: {
    width: '80%',
    minWidth: '160px',
    marginBottom: theme.spacing(1),
  },
  simpleInputText: {
    width: '100%',
    fontSize: '0.9rem',
    border: '0',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    padding: '6px 0 7px',
    '&:focus': {
      outline: '0',
      borderBottom: '2px solid #143C58',
    },
  },
})
