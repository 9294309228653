import React, { PureComponent } from 'react'

function WithHover(WrappedComponent) {
  return class extends PureComponent {
    constructor(props) {
      super(props)
      this.state = { isHovered: false }
    }

    onMouseEnter = () => {
      this.setState({ isHovered: true })
    }

    onMouseLeave = () => {
      this.setState({ isHovered: false })
    }

    render() {
      const { isHovered } = this.state
      return (
        <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <WrappedComponent {...this.props} isHovered={isHovered} />
        </div>
      )
    }
  }
}

export default WithHover
