import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Switch from '@material-ui/core/Switch'

/**
 * Usage :
 *
 *    <Field
        name="rememberMe"
        component={RenderSwitch}
        label="Remember me ?"
      />
 */

const RenderSwitch = ({
  input,
  label,
  isDisabled,
  meta: { touched, error },
}) => (
  <FormControl error={touched && error !== undefined}>
    <FormControlLabel
      control={
        <Switch
          disabled={isDisabled}
          checked={!!input.value}
          onChange={input.onChange}
        />
      }
      label={label}
    />
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)

RenderSwitch.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    touch: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
}

RenderSwitch.defaultProps = {
  label: '',
  meta: {},
  isDisabled: false,
}

export default RenderSwitch
