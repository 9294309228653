import React from 'react'
import { Route } from 'react-router-dom'
import Job from 'pages/JobDetails/JobDetails'
import JobsSearch from 'pages/JobsSearch/JobsSearch'
import Dashboard from 'pages/Dashboard/Dashboard'
import { DASHBOARD_PATH, JOB_SINGLE, JOBS_SEARCH_PATH } from 'constants/routes'

const JobRoutes = () => (
  <>
    <Route
      exact
      path={DASHBOARD_PATH}
      render={props => <Dashboard {...props} />}
    />
    <Route
      exact
      path={JOBS_SEARCH_PATH}
      render={props => <JobsSearch {...props} />}
    />
    <Route exact path={JOB_SINGLE} render={props => <Job {...props} />} />
  </>
)

export default JobRoutes
