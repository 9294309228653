import {
  createRequestTypes,
  generateRequestActions,
} from 'helpers/store/actionsHelpers'

export const GET_CONVERSATION_MESSAGES_REQ = createRequestTypes(
  'GET_CONVERSATION_MESSAGES',
)

export const getConversationMessages = generateRequestActions(
  GET_CONVERSATION_MESSAGES_REQ,
)

export const GET_CONVERSATIONS_REQ = createRequestTypes('GET_CONVERSATIONS')

export const getJobConversations = generateRequestActions(GET_CONVERSATIONS_REQ)
