import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableSortLabel, Tooltip } from '@material-ui/core'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'

const SortableTableCell = ({
  sortField,
  label,
  orderBy,
  sort,
  handleChangeSort,
  translate,
}) => (
  <TableCell sortDirection={orderBy === sortField ? sort : false}>
    <Tooltip
      title={translate('table.sort')}
      disableFocusListener
      disableTouchListener
    >
      <TableSortLabel
        active={orderBy === sortField}
        direction={sort}
        onClick={() => handleChangeSort(sortField)}
      >
        {label}
      </TableSortLabel>
    </Tooltip>
  </TableCell>
)

SortableTableCell.propTypes = {
  sortField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
  sort: PropTypes.string.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

SortableTableCell.defaultProps = {
  orderBy: null,
}

export default compose(i18n)(SortableTableCell)
