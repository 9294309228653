import omit from 'lodash/omit'
import { ACCESS_TOKEN, ID_TOKEN } from 'constants/storage'
import {
  APPLICATION_IS_READY,
  AUTH_SET_LOGGED_IN,
  APPLICATION_IS_LOADING,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SET_BAR_TITLE,
  SET_SCROLL_TOP,
  SET_SHOW_JOB_STATUS,
  SET_SHOW_GEOZONE_SELECTOR,
  SET_AUTH_TOKENS,
  AUTH_LOGOUT,
  AUTH_LOGIN_ENTERPRISE,
} from './ApplicationActions'

export const initialState = {
  isReady: false,
  isLoading: {},
  loggedIn: false,
  authLinkEmail: null,
  authLoginLoading: false,
  scrollTop: false,
  barTitle: '',
  notification: {
    messageType: null,
    message: null,
    isNotified: false,
  },
  [ID_TOKEN]: null,
  [ACCESS_TOKEN]: null,
}

const application = (state = initialState, action = {}) => {
  switch (action.type) {
    case AUTH_LOGIN_ENTERPRISE.REQUEST:
      return {
        ...state,
        authLoginLoading: true,
      }
    case AUTH_LOGIN_ENTERPRISE.SUCCESS:
      return {
        ...state,
        authLinkEmail: action.actionParams?.email,
        authLoginLoading: false,
      }
    case AUTH_LOGIN_ENTERPRISE.FAILURE:
      return {
        ...state,
        authLoginLoading: false,
      }
    case APPLICATION_IS_READY:
      return {
        ...state,
        isReady: action.isReady,
      }
    case AUTH_SET_LOGGED_IN: {
      return {
        ...state,
        loggedIn: action.payload,
      }
    }
    case APPLICATION_IS_LOADING: {
      if (action.isLoading === true) {
        return {
          ...state,
          isLoading: {
            ...state.isLoading,
            [action.trigger]: action.isLoading,
          },
        }
      }

      return {
        ...state,
        isLoading: omit(state.isLoading, action.trigger),
      }
    }
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          isNotified: true,
          message: action.payload.message,
          messageType: action.payload.messageType,
        },
      }
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          isNotified: false,
          message: null,
          messageType: null,
        },
      }
    case SET_BAR_TITLE:
      return {
        ...state,
        barTitle: action.barTitle,
      }
    case SET_SHOW_JOB_STATUS:
      return {
        ...state,
        showJobDetailsStatus: action.showJobDetailsStatus,
      }
    case SET_SHOW_GEOZONE_SELECTOR:
      return {
        ...state,
        showGeozoneSelector: action.showSelector,
      }
    case SET_SCROLL_TOP:
      return {
        ...state,
        scrollTop: action.payload,
      }
    case SET_AUTH_TOKENS: {
      return {
        ...state,
        [ID_TOKEN]: action.payload[ID_TOKEN],
        [ACCESS_TOKEN]: action.payload[ACCESS_TOKEN],
      }
    }
    case AUTH_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return {
        ...state,
      }
  }
}

export default application
