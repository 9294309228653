import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { compose } from 'redux'
import Typography from '@material-ui/core/Typography'
import {
  PENDING_ASSIGNMENT_STATUS,
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  PENDING_REALIZATION_STATUS,
  PENDING_RESCHEDULING_STATUS,
} from 'constants/Jobs'
import JobTimeslotTable from './JobTimeslotTable'
import JobNewTimeslot from './JobNewTimeslot'

const JobTimeslot = ({ timeslots, status, translate }) => (
  <>
    <JobTimeslotTable timeslots={timeslots} />
    <br />
    {[
      PENDING_ASSIGNMENT_STATUS,
      PENDING_MANUAL_ASSIGNMENT_STATUS,
      PENDING_REALIZATION_STATUS,
      PENDING_RESCHEDULING_STATUS,
    ].includes(status) && (
      <>
        <Typography variant="h5" gutterBottom>
          {translate('job.replace_ho_date.title')}
        </Typography>
        <JobNewTimeslot status={status} />
      </>
    )}
  </>
)

JobTimeslot.propTypes = {
  timeslots: PropTypes.arrayOf(
    PropTypes.shape({
      startTime: PropTypes.string,
      stopTime: PropTypes.string,
    }),
  ),
  translate: PropTypes.func.isRequired,
  status: PropTypes.string,
}

JobTimeslot.defaultProps = {
  timeslots: [],
  status: undefined,
}

export default compose(i18n)(JobTimeslot)
