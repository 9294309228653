export default theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.primary,
    paddingTop: 0,
    paddingBottom: 0,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  menuCollapse: {
    backgroundColor: 'white !important',
  },
  adminLink: {
    textDecoration: 'none',
    display: 'block',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    paddingLeft: '0',
    paddingRight: '0',
    borderBottom: 'none',
    margin: '-3rem 1rem 2rem 1rem',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  isActive: {
    backgroundColor: theme.palette.menu,
    opacity: 1,
  },
  imgIconLink: {
    backgroundSize: 'contain',
    height: '42px',
    width: '42px',
    margin: '0.2rem auto 0.8rem',
  },
  popOver: {
    marginTop: '65px',
  },
  arrow: {
    marginLeft: '5px',
  },

  adminName: {
    paddingRight: '15px',
    backgroundImage:
      'url(https://res.cloudinary.com/quotatis/image/upload/v1620206563/FrontApp/icons/arrow-bottom-white.svg)',
    backgroundSize: '10px 10px',
    backgroundPosition: '100% 50%',
    backgroundRepeat: 'no-repeat',
  },
})
