import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field, FieldArray } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import i18n from 'providers/i18n/I18nProvider'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import RenderCheckbox from 'components/shared/Form/RenderCheckbox'
import DateInput from 'components/shared/DateInput/DateInput'
import { EDIT_INVOICE_FORM } from 'constants/forms'
import {
  FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT,
  FIELD_INVOICE_ISSUED_AT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE,
  FIELD_INVOICE_ITEMS,
  FIELD_INVOICE_ITEMS_FLAG,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS,
  FIELD_INVOICE_ORDER_NUMBER,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER,
  FIELD_INVOICE_TAX_TOTAL,
  FIELD_INVOICE_SUBTOTAL,
  FIELD_INVOICE_TOTAL,
  INVOICE_ACTION_UPDATE_THEN_VALIDATE,
  INVOICE_ACTION_UPDATE,
} from 'constants/invoices'
import { FIRM_LEGAL_STATUS, FIRM_VATS } from 'constants/firms'
import styles from './UpdateInvoiceStyle'
import { validate } from './UpdateInvoiceValidation'
import InvoiceItemContainer from './InvoiceItem/InvoiceItemContainer'

const renderInvoiceItems = ({ fields }) => (
  <>
    {fields.map((item, index) => (
      <InvoiceItemContainer
        /* eslint-disable-next-line react/no-array-index-key */
        key={index}
        index={index}
        item={item}
      />
    ))}
  </>
)

renderInvoiceItems.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

class UpdateInvoice extends PureComponent {
  constructor(props) {
    super(props)

    this.handleClickAddItem = this.handleClickAddItem.bind(this)
  }

  componentDidMount() {
    const { forceValidation } = this.props

    forceValidation()
  }

  handleClickAddItem() {
    const { addNewItem } = this.props
    addNewItem()
  }

  render() {
    const {
      classes,
      translate,
      onSubmit,
      handleSubmit,
      handleChangeSubjectToVat,
      closeDialog,
    } = this.props

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <form>
            <Grid container justifyContent="flex-start" direction="row">
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader
                    title={translate('resources.invoices.cards.pro')}
                    titleTypographyProps={{ className: classes.h3 }}
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}>
                        <Field
                          name={FIELD_INVOICE_PROVIDER_BILLING_DATA_COMPANY}
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.pro_company_name',
                          )}
                          disabled
                        />
                        <Field
                          name={FIELD_INVOICE_PROVIDER_BILLING_DATA_LASTNAME}
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.legal_representative_last_name',
                          )}
                          disabled
                        />
                        <Field
                          name={FIELD_INVOICE_PROVIDER_BILLING_DATA_FIRSTNAME}
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.legal_representative_first_name',
                          )}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Grid item xs={12}>
                          <Field
                            name={
                              FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT
                            }
                            id={`${FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT}Select`}
                            className={classes.input}
                            choices={FIRM_VATS}
                            component={RenderSelectField}
                            label={translate('resources.invoices.fields.vat')}
                            optionValue="key"
                            optionText="name"
                            onChange={handleChangeSubjectToVat}
                            disabled
                          />
                          <Field
                            name={FIELD_INVOICE_PROVIDER_BILLING_DATA_STREET}
                            className={classes.input}
                            component={RenderTextField}
                            label={translate(
                              'resources.invoices.fields.billing_address',
                            )}
                            disabled
                          />
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={4}>
                            <Field
                              name={
                                FIELD_INVOICE_PROVIDER_BILLING_DATA_POSTCODE
                              }
                              className={classes.input}
                              component={RenderTextField}
                              label={translate(
                                'resources.invoices.fields.billing_address_postal_code',
                              )}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Field
                              name={FIELD_INVOICE_PROVIDER_BILLING_DATA_CITY}
                              className={classes.input}
                              component={RenderTextField}
                              label={translate(
                                'resources.invoices.fields.billing_address_city',
                              )}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader
                    title={translate('resources.invoices.cards.header')}
                    titleTypographyProps={{ className: classes.h3 }}
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={2}>
                        <Field
                          name={FIELD_INVOICE_ISSUED_AT}
                          className={classes.input}
                          component={DateInput}
                          label={translate(
                            'resources.invoices.fields.creation_date',
                          )}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={4} />
                      <Grid item xs={2}>
                        <Field
                          name={FIELD_INVOICE_ORDER_NUMBER}
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.partner_order_id',
                          )}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader
                    title={translate('resources.invoices.cards.order')}
                    titleTypographyProps={{ className: classes.h3 }}
                  />
                  <CardContent>
                    <Field
                      name={FIELD_INVOICE_ITEMS_FLAG}
                      isDisabled
                      customStyle={{ display: 'none' }}
                      component={RenderCheckbox}
                    />
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {translate(
                              'resources.invoices.details.columns.reference',
                            )}
                          </TableCell>
                          <TableCell>
                            {translate(
                              'resources.invoices.details.columns.name',
                            )}
                          </TableCell>
                          <TableCell>
                            {translate(
                              'resources.invoices.details.columns.quantity',
                            )}
                          </TableCell>
                          <TableCell>
                            {translate(
                              'resources.invoices.details.columns.unitPrice',
                            )}
                          </TableCell>
                          <TableCell>
                            {translate(
                              'resources.invoices.details.columns.subtotal',
                            )}
                          </TableCell>
                          <TableCell>
                            {translate(
                              'resources.invoices.details.columns.vatRate',
                            )}
                          </TableCell>
                          <TableCell>
                            <Fab
                              color="primary"
                              aria-label="add"
                              onClick={this.handleClickAddItem}
                            >
                              <Add />
                            </Fab>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray
                          name={FIELD_INVOICE_ITEMS}
                          component={renderInvoiceItems}
                        />
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={3} />
                          <TableCell align="right">
                            {translate(
                              'resources.invoices.fields.totals.subtotal',
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name={FIELD_INVOICE_SUBTOTAL}
                              component={RenderTextField}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              disabled
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            {translate(
                              'resources.invoices.fields.totals.taxTotal',
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name={FIELD_INVOICE_TAX_TOTAL}
                              component={RenderTextField}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              disabled
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">
                            {translate(
                              'resources.invoices.fields.totals.total',
                            )}
                          </TableCell>
                          <TableCell>
                            <Field
                              name={FIELD_INVOICE_TOTAL}
                              component={RenderTextField}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              }}
                              disabled
                            />
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardHeader
                    title={translate('resources.invoices.cards.footer')}
                    titleTypographyProps={{ className: classes.h3 }}
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}>
                        <Field
                          name={
                            FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS
                          }
                          id={`${FIELD_INVOICE_PROVIDER_BILLING_DATA_LEGAL_STATUS}Select`}
                          className={classes.input}
                          component={RenderSelectField}
                          label={translate(
                            'resources.invoices.fields.legal_status',
                          )}
                          choices={FIRM_LEGAL_STATUS}
                          optionValue="code"
                          disabled
                        />
                        <Field
                          name={
                            FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_NUMBER
                          }
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.registration_number',
                          )}
                          disabled
                        />
                        <Field
                          name={
                            FIELD_INVOICE_PROVIDER_BILLING_DATA_CAPITAL_AMOUNT
                          }
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.capital_amount',
                          )}
                          type="number"
                          disabled
                        />
                        <Field
                          name={FIELD_INVOICE_PROVIDER_BILLING_DATA_VAT_NUMBER}
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.vat_number',
                          )}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name={
                            FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_STREET
                          }
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.head_quarters_address',
                          )}
                          disabled
                        />
                        <Grid container item xs={12}>
                          <Grid item xs={4}>
                            <Field
                              name={
                                FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_POSTCODE
                              }
                              className={classes.input}
                              component={RenderTextField}
                              label={translate(
                                'resources.invoices.fields.head_quarters_postal_code',
                              )}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Field
                              name={
                                FIELD_INVOICE_PROVIDER_BILLING_DATA_HEADQUARTERS_CITY
                              }
                              className={classes.input}
                              component={RenderTextField}
                              label={translate(
                                'resources.invoices.fields.head_quarters_city',
                              )}
                              disabled
                            />
                          </Grid>
                        </Grid>
                        <Field
                          name={
                            FIELD_INVOICE_PROVIDER_BILLING_DATA_REGISTRATION_CITY
                          }
                          className={classes.input}
                          component={RenderTextField}
                          label={translate(
                            'resources.invoices.fields.registration_city',
                          )}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Tooltip
                  title={translate(
                    'resources.invoices.actions.updateAndValidateTooltip',
                  )}
                  aria-label="update and validate"
                  placement="top"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit((values, dispatch, props) => {
                      onSubmit(
                        {
                          ...values,
                          action: INVOICE_ACTION_UPDATE_THEN_VALIDATE,
                        },
                        dispatch,
                        props,
                      )
                      closeDialog()
                    })}
                  >
                    {translate('resources.invoices.actions.updateAndValidate')}
                  </Button>
                </Tooltip>
                &nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit((values, dispatch, props) =>
                    onSubmit(
                      {
                        ...values,
                        action: INVOICE_ACTION_UPDATE,
                      },
                      dispatch,
                      props,
                    ),
                  )}
                >
                  {translate('resources.invoices.actions.update')}
                </Button>
                &nbsp;
              </Grid>
            </Grid>
          </form>
        </FormControl>
      </div>
    )
  }
}

UpdateInvoice.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    formControl: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    h3: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  addNewItem: PropTypes.func.isRequired,
  handleChangeSubjectToVat: PropTypes.func.isRequired,
  forceValidation: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
}

export default compose(
  i18n,
  reduxForm({
    form: EDIT_INVOICE_FORM,
    validate,
    onSubmit: (values, _, { updateInvoice }) => updateInvoice(values),
  }),
  withStyles(styles),
)(UpdateInvoice)
