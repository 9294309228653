import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { Field } from 'redux-form'
import { withStyles } from '@material-ui/core'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import styles from './JobDetailsIncidentsPopinStyles'

const IncidentSelectProResponsible = ({ proList, classes }) => {
  const [pro, setPro] = useState(null)
  if (proList.length === 0) {
    return null
  }

  const handleChange = event => {
    setPro(event.target.value)
  }

  return (
    <Field
      className={classes.selectEmpty}
      name="pro"
      id="prosListIncidentSelect"
      component={RenderSelectField}
      value={pro}
      choices={proList}
      onChange={handleChange}
      allowEmpty
      optionText="label"
      optionValue="value"
    />
  )
}

IncidentSelectProResponsible.propTypes = {
  classes: PropTypes.shape({
    selectEmpty: PropTypes.string.isRequired,
  }).isRequired,
  proList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default compose(i18n, withStyles(styles))(IncidentSelectProResponsible)
