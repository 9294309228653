import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import { setBarTitle } from 'store/Application/ApplicationActions'
import FirmsListContainer from 'components/Firms/FirmsList/FirmsListContainer'
import { ALL_PROS_LIST } from 'constants/firms'
import PropTypes from 'prop-types'
import { compose } from 'redux'

const AllFirms = ({ setTitle, translate }) => {
  useEffect(() => {
    setTitle(translate('resources.firms.navbar.title.all'))
  }, [setTitle, translate])

  return <FirmsListContainer listName={ALL_PROS_LIST} />
}

const mapDispatchToProps = dispatch => ({
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
})

AllFirms.propTypes = {
  translate: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
}

export default compose(i18n, connect(null, mapDispatchToProps))(AllFirms)
