import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { Code } from '@material-ui/icons'

const CopyToClipboardButton = props => {
  const { children, whatToCopy, stopPropagation } = props

  const copyToClipboard = event => {
    if (stopPropagation) {
      event.stopPropagation()
    }
    const textField = document.createElement('textarea')
    textField.innerText = whatToCopy
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  const whatToPrint = children || <Code />

  return (
    document.queryCommandSupported('copy') && (
      <Button onClick={copyToClipboard}>{whatToPrint}</Button>
    )
  )
}

CopyToClipboardButton.propTypes = {
  whatToCopy: PropTypes.string.isRequired,
  children: PropTypes.node,
  stopPropagation: PropTypes.bool,
}

CopyToClipboardButton.defaultProps = {
  children: undefined,
  stopPropagation: true,
}

export default CopyToClipboardButton
