import {
  createRequestTypes,
  generateRequestActions,
  generateSimpleAction,
} from 'helpers/store/actionsHelpers'

export const FIRM_GET_SINGLE_FILE = createRequestTypes('FIRM_GET_SINGLE_FILE')
export const firmGetSingleFile = generateRequestActions(FIRM_GET_SINGLE_FILE)
export const REMOVE_TMP_FILE = 'REMOVE_TMP_FILE'
export const OPEN_FILE = 'OPEN_FILE'
export const UPLOAD_TMP_FILE = createRequestTypes('UPLOAD_TMP_FILE')
export const SAVE_UPLOAD_FILE = createRequestTypes('SAVE_UPLOAD_FILE')
export const GET_FILE = createRequestTypes('GET_FILE')
export const uploadTmpFile = generateRequestActions(UPLOAD_TMP_FILE)
export const removeTmpFile = generateSimpleAction(REMOVE_TMP_FILE)
export const saveUploadFile = generateRequestActions(SAVE_UPLOAD_FILE)
export const openFile = generateSimpleAction(OPEN_FILE)
export const getFile = generateRequestActions(GET_FILE)
export const DELETE_DOCUMENT = createRequestTypes('DELETE_DOCUMENT')
export const deleteDocument = generateRequestActions(DELETE_DOCUMENT)
