import { createSelector } from 'reselect'
import get from 'lodash/get'
import { formValueSelector, getFormValues } from 'redux-form'
import {
  FIRM_CONTRACT_OPTIONS,
  FIRM_VATS,
  PACKAGES_SUBSCRIPTION_WORDING,
  PACKAGES_SUBSCRIPTION_WORDING_CANDIDATE,
  PACKAGES_SUBSCRIPTION_WORDING_VALIDATION,
  PACKAGE_STATUS_CANDIDATE_ORIGINS,
} from 'constants/firms'
import { featureFlagSelector } from 'store/Application/ApplicationSelectors'
import { PackageSelector } from 'store/products/packageSelector'
import { storesListSelector } from 'store/stores/storeSelectors'
import { departmentsListSelector } from 'store/departments/departmentSelectors'
import { formatRankingRating } from 'helpers/utils/firm/firm'
import { convertArrayToObject } from 'helpers/utils/common'
import { mapCounterByCategories } from 'helpers/dashboard/card'
import {
  EDIT_FIRM_DETAILS_PRO_FORM,
  EDIT_FIRM_DETAILS_PACKAGES_FORM,
  EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
  EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM,
  EDIT_FIRM_INTERVENTION_ZONE_FORM,
  ADD_FIRM_COMMENT_FORM,
  FILTER_FIRMS_LIST_FORM,
  FIRM_CERTIFICATE_FORM,
} from 'constants/forms'
import { REGEXP_DEPARTMENT } from 'constants/regexp'
import { dateFormatter } from 'helpers/date'
import { DATE_FORMAT } from 'constants/date'

const firmPackagesSubscriptionWording = convertArrayToObject(
  PACKAGES_SUBSCRIPTION_WORDING,
  'key',
)

const firmVats = convertArrayToObject(FIRM_VATS, 'key')

export const initialDataToSubmit = {
  isFormReady: {
    [EDIT_FIRM_DETAILS_PRO_FORM]: false,
    [EDIT_FIRM_DETAILS_PACKAGES_FORM]: false,
    [EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM]: false,
    [EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM]: false,
    [EDIT_FIRM_INTERVENTION_ZONE_FORM]: false,
    [ADD_FIRM_COMMENT_FORM]: false,
  },
  payload: {},
}

export const defaultSearchParams = {
  searchParams: {
    query: '',
    tagFilters: ['FR'],
    facetFilters: [],
    page: 0,
    hitsPerPage: 20,
  },
}

export const initialState = {
  list: [],
  searchParams: {
    query: '',
    tagFilters: [],
    facetFilters: [],
    page: 0,
    rowsPerPage: 50,
    total: 0,
  },
  searchCountParams: {
    query: '',
    tagFilters: ['FR'],
    count: true,
  },
  dataToSubmit: initialDataToSubmit,
  firmsCount: [],
  firmsDocumentCount: [],
  firmsDocVerifiedCount: [],
  postalCodes: [],
}

const firmSelector = state => get(state, 'firms')

export const searchParamsSelector = createSelector(firmSelector, state =>
  get(state, 'searchParams'),
)

export const firmsListSelector = createSelector(firmSelector, state =>
  get(state, 'list', []),
)

export const currentFirmSelector = createSelector(firmSelector, state =>
  get(state, 'currentFirm.details'),
)

export const currentFirmIdSelector = createSelector(firmSelector, state =>
  get(state, 'currentFirm.firmId'),
)

export const currentFirmCertificateSelector = createSelector(
  firmSelector,
  state => get(state, 'currentFirm.certificate'),
)

export const currentFirmIriSelector = createSelector(
  currentFirmSelector,
  state => get(state, '@id'),
)

export const currentFirmProductSubscriptionSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'firmProductSubscription', {}),
)

export const currentFirmProductsSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'firmProductSubscription.productSubscriptions', []),
)

export const currentFirmPostcodes = createSelector(
  currentFirmSelector,
  departmentsListSelector,
  (firmState, departementListState) => {
    const postcodes = get(firmState, 'firmProductSubscription.postcodes', [])

    return departementListState
      .filter(department => postcodes.includes(department.code))
      .map(department => ({
        label: department.name,
        value: department.code,
      }))
  },
)

export const currentFirmMatchingOption = createSelector(
  currentFirmSelector,
  firmState => get(firmState, 'firmProductSubscription.matchingOption'),
)

export const currentFirmMatchingPoint = createSelector(
  currentFirmSelector,
  firmState => get(firmState, 'firmProductSubscription.matchingPoint'),
)

export const currentFirmMatchingRadius = createSelector(
  currentFirmSelector,
  firmState => get(firmState, 'firmProductSubscription.matchingRadius'),
)

export const currentFirmMatchingAddress = createSelector(
  currentFirmSelector,
  firmState => get(firmState, 'firmProductSubscription.matchingAddress'),
)

export const firmNameSelector = createSelector(currentFirmSelector, state =>
  get(state, 'name'),
)

export const firmEmailSelector = createSelector(currentFirmSelector, state =>
  get(state, 'email'),
)

export const firmProGenderSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'users[0].gender'),
)

export const firmProFisrtNameSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'users[0].firstName'),
)

export const firmProLastNameSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'users[0].lastName'),
)

export const firmProEmailSelector = createSelector(currentFirmSelector, state =>
  get(state, 'users[0].email'),
)

export const firmMobilePhoneNumberSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'mobilePhoneNumber'),
)

export const firmFixedPhoneNumberSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'fixedPhoneNumber'),
)

export const firmLegacyIdSelector = createSelector(currentFirmSelector, state =>
  get(state, 'legacyId'),
)

export const firmAccountingNumberSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'accountingNumber'),
)

export const getCurrentFirmContractOptions = createSelector(
  currentFirmSelector,
  state => get(state, 'contractOptions'),
)

export const getCurrentFirmContractOptionsNames = createSelector(
  getCurrentFirmContractOptions,
  state => (state || []).map(item => item.name),
)

export const getCurrentFirmContractOptionsUris = createSelector(
  getCurrentFirmContractOptions,
  state => (state || []).map(item => item['@id']),
)

const firmContractOptions = convertArrayToObject(FIRM_CONTRACT_OPTIONS)
export const firmContractOptionsLabelSelector = createSelector(
  currentFirmSelector,
  state => {
    const contractOptions = get(state, 'contractOptions')

    return contractOptions && contractOptions.length > 0
      ? contractOptions
          .map(option => firmContractOptions[option?.name]?.name)
          .join(' / ')
      : firmContractOptions?.NO_CONTRACT?.name
  },
)

export const firmFilesCompletedAtSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'filesCompletedAt'),
)

export const firmFilesCompletedAtFormattedSelector = createSelector(
  currentFirmSelector,
  currentFirm => {
    const filesCompletedAt = get(currentFirm, 'filesCompletedAt')

    return filesCompletedAt
      ? dateFormatter(filesCompletedAt, DATE_FORMAT)
      : '----'
  },
)

export const firmCertificateSelector = createSelector(
  currentFirmSelector,
  currentFirmDetails => get(currentFirmDetails, 'firmCertificates', []),
)

export const currentFirmPackagesCodeListSelector = createSelector(
  PackageSelector.typeListIndexedByCodeSelector(),
  currentFirmProductsSelector,
  (packagesList, data) =>
    data
      .filter(item => !!packagesList[item.productCode])
      .reduce((acc, item) => ({ ...acc, [item.productCode]: true }), {}),
)

export const currentFirmPackageLabelListSelector = createSelector(
  PackageSelector.typeListSelector(),
  currentFirmProductsSelector,
  (packagesList, data) =>
    data.reduce(
      (acc, item) => ({
        ...acc,
        [packagesList
          .filter(pk => pk.code === item.productCode)
          .map(filterPk => filterPk.name)]: true,
      }),
      {},
    ),
)

// Configuration Firm Selectors
export const firmIsRemovalSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'isRemoval'),
)

export const firmIsSaturdaySelector = createSelector(
  currentFirmSelector,
  state => get(state, 'isSaturday'),
)

export const firmIsSundaySelector = createSelector(currentFirmSelector, state =>
  get(state, 'isSunday'),
)

export const firmIsWeekendSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'isWeekend'),
)

export const firmReliableSelector = createSelector(currentFirmSelector, state =>
  get(state, 'reliable'),
)

export const firmOriginSelector = createSelector(currentFirmSelector, state =>
  get(state, 'origin'),
)

export const firmManualRatingSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'manualRating'),
)

export const firmRankingRatingSelector = createSelector(
  currentFirmSelector,
  currentFirm => {
    const rankingRating = get(currentFirm, 'rankingRating', 0)

    return formatRankingRating(rankingRating)
  },
)

export const currentFirmPackageRatingSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'packageRating'),
)

export const currentFirmPackageRatingCountSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'packageRatingCount'),
)

export const currentFirmLeadRatingSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'leadRating'),
)

export const currentFirmLeadRatingCountSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'leadRatingCount'),
)

export const firmStoreCodesSelector = createSelector(
  currentFirmSelector,
  storesListSelector,
  (firmState, storesListState) => {
    const storesCodes = get(firmState, 'storeCodes')

    return storesListState
      .filter(store => storesCodes.includes(store.code))
      .map(store => ({
        label: store.name,
        value: store.code,
      }))
  },
)

export const firmPackageStatusSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'packageStatus'),
)

export const firmPackageStatusV2Selector = createSelector(
  currentFirmSelector,
  state => get(state, 'packageStatusV2'),
)

export const firmUserSuspensionReason = createSelector(
  currentFirmSelector,
  state => get(state, 'suspensionReason'),
)

const firmPackageStatusCandidateOrigins = convertArrayToObject(
  PACKAGE_STATUS_CANDIDATE_ORIGINS,
)
export const firmPackageStatusCandidateOriginLabelSelector = createSelector(
  currentFirmSelector,
  currentFirm => {
    const packageStatusCandidateOrigin = get(
      currentFirm,
      'packageStatusCandidateOrigin',
      null,
    )

    return firmPackageStatusCandidateOrigins[packageStatusCandidateOrigin].name
  },
)

export const firmIsDirectorySelector = createSelector(
  currentFirmSelector,
  currentFirm =>
    get(currentFirm, 'contractOptions', []).some(
      contractOption => contractOption.name === FIRM_CONTRACT_OPTIONS[3].code,
    ),
)

export const displayedInProDirectorySelector = createSelector(
  currentFirmSelector,
  currentFirm => get(currentFirm, 'displayedInProDirectory', false),
)

// Legal informations firm selectors
export const currentFirmInformationsSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'firmLegalData', {}),
)

export const currentFirmTypeSelector = createSelector(
  currentFirmSelector,
  firmSelector,
  (_, props) => props,
  (currentFirm, firm, type) =>
    currentFirm.firmFiles
      .map(firmFile => get(firm, `firmFiles.${firmFile['@id']}`, {}))
      .filter(firmFile => firmFile.type === type),
)

export const firmFilesSelector = createSelector(
  currentFirmSelector,
  currentFirm => get(currentFirm, 'firmFiles', []),
)

export const firmDocumentsSelector = createSelector(
  currentFirmSelector,
  currentFirm => get(currentFirm, 'documents', []),
)

export const certificateFilesSelector = createSelector(
  currentFirmSelector,
  currentFirm =>
    get(currentFirm, 'firmFiles', []).filter(
      ({ type }) => type === 'certificate',
    ),
)

export const firmLegalInformationsIriSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, '@id'),
)

export const legalStatusSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'legalStatus'),
)

export const capitalAmountSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'capitalAmount'),
)

export const registrationCitySelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'registrationCity', ''),
)

export const registrationNumberSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'registrationNumber'),
)

export const shortRegistrationNumberSelector = createSelector(
  registrationNumberSelector,
  registrationNumber => registrationNumber.substring(0, 9),
)

export const postalCodeSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'postalCode'),
)

export const businessDirectorySelector = createSelector(
  shortRegistrationNumberSelector,
  postalCodeSelector,
  (shortRegistrationNumber, postalCode) =>
    shortRegistrationNumber && REGEXP_DEPARTMENT.test(postalCode)
      ? `${shortRegistrationNumber}RM${postalCode}`
      : null,
)

export const headquartersStreetSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'headQuartersAddress'),
)

export const headquartersCitySelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'headQuartersCity'),
)

export const headquartersPostcodeSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'headQuartersPostalCode'),
)

export const streetSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'billingAddress'),
)

export const citySelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'billingAddressCity'),
)

export const postcodeSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'billingAddressPostalCode'),
)

export const vatNumberSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'vatNumber'),
)

export const swiftCodeSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'swiftCode'),
)

export const firmSubjectToVatSelector = createSelector(
  currentFirmInformationsSelector,
  state => {
    const subjectToVat = get(state, 'subjectToVat')

    switch (subjectToVat) {
      case true:
        return firmVats.SUBJECT_TO_VAT.key
      case false:
        return firmVats.NOT_SUBJECT_TO_VAT.key
      default:
        return ''
    }
  },
)

export const internationalBankAccountNumberSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'internationalBankAccountNumber'),
)

export const firstNameSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'legalRepresentativeFirstName'),
)

export const lastNameSelector = createSelector(
  currentFirmInformationsSelector,
  state => get(state, 'legalRepresentativeLastName'),
)

export const payloadToSubmitSelector = createSelector(firmSelector, state =>
  get(state, 'dataToSubmit.payload'),
)

export const contractSignatureIdSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'contractSignatureId'),
)

export const contractSignatureStatusSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'contractSignatureStatus'),
)

export const contractSignedAtSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'contractSignedAt'),
)

export const isFirmReadyToSubmitSelector = createSelector(
  firmSelector,
  state =>
    get(
      state,
      `dataToSubmit.isFormReady.${EDIT_FIRM_DETAILS_PRO_FORM}`,
      false,
    ) &&
    get(
      state,
      `dataToSubmit.isFormReady.${EDIT_FIRM_DETAILS_PACKAGES_FORM}`,
      false,
    ) &&
    get(
      state,
      `dataToSubmit.isFormReady.${EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM}`,
      false,
    ) &&
    get(
      state,
      `dataToSubmit.isFormReady.${EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM}`,
      false,
    ) &&
    get(
      state,
      `dataToSubmit.isFormReady.${EDIT_FIRM_INTERVENTION_ZONE_FORM}`,
      false,
    ) &&
    get(state, `dataToSubmit.isFormReady.${ADD_FIRM_COMMENT_FORM}`, false),
)

export const firmCountJobsDoneSelector = createSelector(
  firmSelector,
  currentFirmSelector,
  (countDone, firm) =>
    get(countDone, 'currentFirm.nbJobsDone[hydra:totalItems]', 0) +
    get(firm, 'nbJobsDoneBefore', 0),
)

export const editFormDetailsProFormSelector = () =>
  formValueSelector(EDIT_FIRM_DETAILS_PRO_FORM)

export const isFirmValidatedForPackageSelector = state => {
  const currentPackageStatus = editFormDetailsProFormSelector()(
    state,
    'packageStatusV2',
  )

  return (
    PACKAGES_SUBSCRIPTION_WORDING_VALIDATION.filter(
      element => element.code === currentPackageStatus,
    ).length !== 0
  )
}

export const firmAvailablePackageStatusSelector = createSelector(
  firmPackageStatusV2Selector,
  featureFlagSelector,
  packageStatusV2 =>
    packageStatusV2 === firmPackagesSubscriptionWording.candidate.code
      ? PACKAGES_SUBSCRIPTION_WORDING_CANDIDATE
      : PACKAGES_SUBSCRIPTION_WORDING,
)

export const getFormValuesFromFilterFirmsListForm = state =>
  getFormValues(FILTER_FIRMS_LIST_FORM)(state) || {
    departments: [],
  }

export const formValueEditFirmInterventionZoneFormSelector = () =>
  formValueSelector(EDIT_FIRM_INTERVENTION_ZONE_FORM)

export const selectedDepartmentsSelector = state => {
  const departments =
    formValueEditFirmInterventionZoneFormSelector()(state, 'postcodes') || []

  return departments.map(department => department.value)
}

const firmsStatusesSelector = createSelector(firmSelector, state => {
  const firmsCount = get(state, 'firmsCount', []).map(counter => ({
    status: counter.value,
    count: counter.count,
  }))
  const firmsDocumentCount = get(state, 'firmsDocumentCount', []).map(
    counter => ({
      status: counter.value,
      count: counter.count,
    }),
  )
  const firmsDocVerifiedCount = get(state, 'firmsDocVerifiedCount', []).map(
    counter => ({
      status: counter.value,
      count: counter.count,
    }),
  )

  return [...firmsCount, ...firmsDocumentCount, ...firmsDocVerifiedCount]
})

export const firmsCountersSelector = (state, categories) =>
  mapCounterByCategories(categories, firmsStatusesSelector(state))

export const organismeAndCertificateSelector = createSelector(
  currentFirmSelector,
  firm =>
    get(firm, 'firmCertificates', [])
      .filter(item => item.certificateCategories.length !== 0)
      .reduce(
        (result, item) => ({
          ...result,
          [item.certificate.name]: item.certificateCategories.map(
            ({ name }) => name,
          ),
        }),
        {},
      ),
)

export const firmCertificateLoadingSelector = createSelector(
  currentFirmCertificateSelector,
  firm => get(firm, 'loading', null),
)

const rsrcToFormFieldChoice = rsrc => ({
  valueOption: rsrc['@id'],
  labelOption: rsrc.name,
})

export const firmCertificateTypesToChoicesSelector = createSelector(
  currentFirmCertificateSelector,
  firm => get(firm, 'types', []).map(rsrcToFormFieldChoice),
)

export const firmCertificateTypesSelector = createSelector(
  currentFirmCertificateSelector,
  firm =>
    get(firm, 'types', []).map(certificate => ({
      id: certificate['@id'],
      type: certificate.type,
    })),
)

const firmCertificateFormValueSelector = formValueSelector(
  FIRM_CERTIFICATE_FORM.NAME,
)

export const certificateSelectedSelector = state =>
  firmCertificateFormValueSelector(state, FIRM_CERTIFICATE_FORM.FIELDS.TYPE)

export const categoriesSelectedSelector = state =>
  firmCertificateFormValueSelector(
    state,
    FIRM_CERTIFICATE_FORM.FIELDS.CATEGORIES,
  )

export const expirationDateSelectedSelector = state =>
  firmCertificateFormValueSelector(
    state,
    FIRM_CERTIFICATE_FORM.FIELDS.EXPIRATION_DATE,
  )

export const certificateReferenceSelectedSelector = state =>
  firmCertificateFormValueSelector(
    state,
    FIRM_CERTIFICATE_FORM.FIELDS.EXPIRATION_DATE,
  )

export const firmCertificateFormTypeValueSelector = state =>
  firmCertificateFormValueSelector(state, FIRM_CERTIFICATE_FORM.FIELDS.TYPE)

const firmCertificateCategoriesToChoicesSelector = createSelector(
  currentFirmCertificateSelector,
  (_, typeIri) => typeIri,
  (firm, typeIri) =>
    get(firm, `categoriesByTypeIri.${typeIri}`, []).map(rsrcToFormFieldChoice),
)

export const firmCertificateFormCategoriesToChoicesSelector = createSelector(
  firmCertificateFormTypeValueSelector,
  state => state,
  (typeIri, state) =>
    firmCertificateCategoriesToChoicesSelector(state, typeIri),
)

export const certificateTypeSelectedSelector = createSelector(
  currentFirmCertificateSelector,
  certificateSelectedSelector,
  (firmCertificate, certificateId) => {
    const certificateType = get(firmCertificate, 'types', []).find(
      type => type['@id'] === certificateId,
    )
    return certificateType ? certificateType.type : null
  },
)

export const firmOnboardingScoreSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'onboardingScore'),
)

export const firmVerifiedDocumentsSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'verifiedDocuments'),
)

export const currentFirmAvailableSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'available'),
)

export const currentFirmUnavailabilityPeriodsSelector = createSelector(
  currentFirmSelector,
  state => get(state, 'firmUnavailabilityPeriods'),
)
