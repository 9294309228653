import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { withStyles } from '@material-ui/core'
import styles from './RenderDateRangePickerStyle'

const RenderDateRangePicker = ({
  id,
  label,
  classes,
  meta: { touched, error },
  input,
}) => (
  <FormControl error={touched && error !== undefined}>
    <label className={classes.label} htmlFor={id}>
      {label}
    </label>
    <DateRangePicker
      className={classes.dateRange}
      name={input.name}
      onChange={date => {
        input.onChange(date)
      }}
      value={input.value}
      id={id}
    />
  </FormControl>
)

RenderDateRangePicker.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    dateRange: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    touch: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func,
  }).isRequired,
}

export default withStyles(styles)(RenderDateRangePicker)
