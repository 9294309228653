import { all } from 'redux-saga/effects'
import application from './Application/ApplicationSagas'
import departments from './departments/departmentSagas'
import firms from './firms/firmSagas'
import jobs from './jobs/jobSagas'
import worksites from './worksites/worksiteSagas'
import firmComments from './firmComments/firmCommentSagas'
import stores from './stores/storeSagas'
import packages from './products/packageSagas'
import trades from './trades/tradeSagas'
import invoices from './invoices/invoiceSagas'
import saleInvoices from './saleInvoices/saleInvoiceSagas'
import proDocuments from './proDocuments/proDocumentSagas'
import upload from './upload/uploadSagas'
import invoiceTransitions from './invoices/transitions/invoiceTransitionSagas'
import jobTransitions from './jobs/transitions/jobTransitionSagas'
import incident from './incidents/incidentSaga'
import localStorage from './localStorage/localStorageSagas'
import firmCertificateFileSagas from './firmCertificateFile/firmCertificateFileSagas'
import conversationsSagas from './conversations/conversationsSagas'
import firmContractOptionsSagas from './firmContractOptions/firmContractOptionsSagas'
import partnersSagas from './partners/partnersSagas'
import reserves from './reserves/reservesSagas'

export default function*() {
  yield all([
    application(),
    departments(),
    firms(),
    jobs(),
    worksites(),
    firmComments(),
    stores(),
    packages(),
    trades(),
    invoices(),
    saleInvoices(),
    proDocuments(),
    upload(),
    invoiceTransitions(),
    jobTransitions(),
    incident(),
    localStorage(),
    firmCertificateFileSagas(),
    conversationsSagas(),
    firmContractOptionsSagas(),
    partnersSagas(),
    reserves(),
  ])
}
