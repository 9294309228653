import { Button, styled } from '@material-ui/core'
import { ORANGE_QUOTATIS } from 'theme/constants'

export default theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  Fab: {
    width: '40px',
    height: '40px',
    left: '45px',
    verticalAlign: 'bottom',
  },
  head: {
    backgroundColor: theme.palette.background.primary,
  },
})

export const CustomButton = styled(Button)({
  '&:disabled': {
    color: 'black !important',
    backgroundColor: ORANGE_QUOTATIS,
    opacity: '1',
  },
})
