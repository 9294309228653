const departmentsSelectorStyles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  optionText: {
    '&>span': {
      color: 'black',
      display: 'inline-flex',
      textAlign: 'inherit',
    },
  },
  option: {
    display: 'flex',
    margin: '0 0 0 25px',
  },
  selectAll: {
    display: 'flex',
  },
})

export default departmentsSelectorStyles
