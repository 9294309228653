import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Button, withStyles } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import Icon from 'components/shared/Icon/Icon'
import classNames from 'classnames'
import styles from './JobDetailsNavigationStyles'

const JobDetailsNavigation = ({
  translate,
  classes,
  scroll,
  anchorNavigation,
  nbItems,
}) => (
  <ul className={classes.tabs}>
    {anchorNavigation.map(item => (
      <li key={item.key} className={classes.tab}>
        <Button
          className={classNames({
            [classes.link]: true,
          })}
          onClick={() => scroll(item.key)}
        >
          <Icon className={classes.icon} icon="arrow_down_grey.svg" />
          {translate(item.title) +
            (nbItems[item.key] ? ` (${nbItems[item.key]})` : '')}
        </Button>
      </li>
    ))}
  </ul>
)

JobDetailsNavigation.propTypes = {
  translate: PropTypes.func.isRequired,
  scroll: PropTypes.func.isRequired,
  anchorNavigation: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  nbItems: PropTypes.objectOf(PropTypes.number),
  classes: PropTypes.shape({
    listUnlisted: PropTypes.string,
    tabs: PropTypes.string,
    tab: PropTypes.string,
    item: PropTypes.string,
    sticky_anchor: PropTypes.string,
    link: PropTypes.string,
    activeLink: PropTypes.string,
    card: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
}

JobDetailsNavigation.defaultProps = {
  anchorNavigation: [],
  nbItems: {},
}

export default compose(i18n, withStyles(styles))(JobDetailsNavigation)
