import { withStyles } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'

export default withStyles({
  root: {
    height: '10px',
    width: '50%',
    fontSize: '12px',
    borderRadius: '20px',
    margin: '0 6px',
  },
  bar: {
    borderRadius: '20px',
  },
})(LinearProgress)
