export default theme => ({
  buttonBigger: theme.buttonBigger,
  buttonReload: theme.buttonReload,
  selectField: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: '500',
    cursor: 'pointer',
  },
  input: {
    width: '80%',
    minWidth: '160px',
  },
  urgentWord: {
    fontSize: 14,
    color: theme.palette.error.main,
    fontWeight: '600',
    margin: '0px 24px',
  },
})
