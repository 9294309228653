import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import Icon from 'components/shared/Icon/Icon'
import WithHover from 'components/shared/WithHover/WithHover'
import Bulle from 'components/shared/Bulle/Bulle'
import styles from './ViewMoreCellStyles'

const ViewMoreCell = ({ cellContent, bubbleContent, isHovered, classes }) => (
  <div
    className={
      !bubbleContent ? classes.contentContainer : classes.contentContainerFlex
    }
  >
    <div className={classes.content}>{cellContent}</div>
    {bubbleContent && (
      <>
        <Icon className={classes.icon} icon="eye.svg" />
        <Bulle isVisible={isHovered} content={bubbleContent} />
      </>
    )}
  </div>
)

ViewMoreCell.propTypes = {
  cellContent: PropTypes.string,
  bubbleContent: PropTypes.string,
  isHovered: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    icon: PropTypes.string,
    contentContainer: PropTypes.string,
    content: PropTypes.string,
    contentContainerFlex: PropTypes.string,
  }),
}

ViewMoreCell.defaultProps = {
  cellContent: '',
  bubbleContent: '',
  classes: undefined,
}

export default compose(withStyles(styles), WithHover)(ViewMoreCell)
