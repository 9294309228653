import { compose } from 'redux'
import connect from 'react-redux/es/connect/connect'
import { formattedJobIdSelector } from 'store/jobs/jobSelectors'
import {
  additionalInvoiceNaturesSelector,
  additionalInvoiceLineTypesSelector,
  additionalProvidersSelector,
  additionalAmountOptionsSelector,
  additionalRebillingSelector,
  additionalRebillingCustomersSelector,
  additionalRebillingCustomersInLineSelector,
} from 'store/invoices/invoiceSelectors'
import { getFormValues } from 'redux-form'
import { GENERATE_ADDITIONNAL_INVOICE_FORM } from 'constants/forms'
import GenerateAfterSaleInvoicesForm from './GenerateAfterSaleInvoicesForm'

const mapStateToProps = state => ({
  jobId: formattedJobIdSelector(state),
  invoiceNaturesOptions: additionalInvoiceNaturesSelector(state),
  invoiceLineTypesOptions: additionalInvoiceLineTypesSelector(state),
  providersOptions: additionalProvidersSelector(state),
  amountsOptions: additionalAmountOptionsSelector(state),
  rebilling: additionalRebillingSelector(state),
  rebillingCustomers: additionalRebillingCustomersSelector(state),
  formValues: getFormValues(GENERATE_ADDITIONNAL_INVOICE_FORM)(state),
  rebillingCustomersOptions: additionalRebillingCustomersInLineSelector(state),
})

export default compose(connect(mapStateToProps))(GenerateAfterSaleInvoicesForm)
