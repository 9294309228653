const styles = theme => ({
  h4: {
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
  },
  jobWorkflowStep: {
    'flex-basis': '100%',
    [theme.breakpoints.up('md')]: {
      'flex-basis': '33%',
    },
  },
  card: {
    width: '100%',
    margin: '5px',
  },
  buttonBigger: theme.buttonBigger,
})

export default styles
