import { createSelector } from 'reselect'
import get from 'lodash/get'

export const initialState = {
  partners: [],
}

const partnersSelector = state => get(state, 'partners')

export const partnerListSelector = createSelector(partnersSelector, state => {
  const partners = get(state, 'partners', [])

  return partners?.map(partner => ({
    label: partner.label,
    value: partner.channel,
  }))
})

export const partnerByChannelSelector = createSelector(
  partnersSelector,
  state => {
    const partners = get(state, 'partners', [])
    const partnerByChannel = {}

    partners.forEach(partner => {
      partnerByChannel[partner.channel] = partner
    })

    return partnerByChannel
  },
)

export const hasPartnersSelector = createSelector(
  partnersSelector,
  state => get(state, 'partners', []).length > 0,
)
