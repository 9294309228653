import { select } from 'redux-saga/effects'
import { handleAlgoliaRequest } from 'helpers/store/sagasHelpers'
import { searchProduct } from 'services/algolia'
import { AbstractSelector } from './abstractSelector'
import { AbstractActions } from './abstractActions'

export class AbstractSagas {
  static getType() {
    return ''
  }

  static getAction() {
    return AbstractActions
  }

  static getSelector() {
    return AbstractSelector
  }

  static handleTypeListRequest() {
    const selector = this.getSelector()
    const typeList = this.getAction().getTypeList()
    const type = this.getType()

    return function*() {
      const hasRecords = yield select(selector.hasTypeSelector())

      if (!hasRecords) {
        // TODO: don't forget to paginate (get 200 element is enough for now)
        yield handleAlgoliaRequest(
          typeList,
          searchProduct(type, { hitsPerPage: 200 }),
        )
      }
    }
  }
}
