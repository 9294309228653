import polyglot from './initPolyglot'

/**
 * Retreive phrase on tree by component key or common key
 *
 * @param {string} ref           key on yml tree
 * @param {obj}    args          polylang args to inject on the phrase
 * @param {bool}   common        true if it's a common phrase / False for Component phrase
 * @param {string} componentName react component name
 */
const t = (ref, args = {}) => polyglot.t(ref, args)

export default t
