import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles, Paper } from '@material-ui/core'
import SimpleTable from 'components/shared/Tables/SimpleTable'
import InvoicesFiltersContainer from 'components/Invoices/InvoicesFilters/InvoicesFiltersContainer'
import styles from './InvoicesListStyles'
import InvoicesListTableBody from './InvoicesListTableBody'
import InvoicesListTableHead from './InvoicesListTableHead'

const InvoicesList = ({
  classes,
  rows,
  searchParams,
  isLoading,
  handleChangePage,
  handleChangePerPage,
  handleChangeSort,
  handleSearchChange,
  handleFiltersChange,
  handleExportClick,
}) => (
  <Paper className={classes.root}>
    <InvoicesFiltersContainer
      handleSearchChange={handleSearchChange}
      handleFiltersChange={handleFiltersChange}
      handleExportClick={handleExportClick}
    />
    <SimpleTable
      rows={rows}
      orderBy={searchParams.orderBy}
      page={searchParams.page}
      sort={searchParams.sort}
      total={searchParams.total}
      isLoading={isLoading}
      handleChangePage={handleChangePage}
      rowsPerPage={searchParams.rowsPerPage}
      handleChangePerPage={handleChangePerPage}
      handleSearchChange={handleSearchChange}
      TableBodyContent={InvoicesListTableBody}
      TableHeadContent={InvoicesListTableHead}
      handleChangeSort={handleChangeSort}
    />
  </Paper>
)

InvoicesList.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchParams: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    sort: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    orderBy: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangePerPage: PropTypes.func.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  handleExportClick: PropTypes.func.isRequired,
}

export default compose(withStyles(styles))(InvoicesList)
