import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { generateDisputeDocuments as generateDisputeDocumentsAction } from 'store/jobs/jobActions'
import { jobIdSelector } from 'store/jobs/jobSelectors'
import PropTypes from 'prop-types'
import GenerateDisputeDocuments from './GenerateDisputeDocuments'

const GenerateDisputeDocumentsContainer = ({
  jobIri,
  generateDisputeDocuments,
}) => (
  <GenerateDisputeDocuments
    generateDisputeDocuments={generateDisputeDocuments}
    jobIri={jobIri}
  />
)

GenerateDisputeDocumentsContainer.propTypes = {
  jobIri: PropTypes.string,
  generateDisputeDocuments: PropTypes.func.isRequired,
}

GenerateDisputeDocumentsContainer.defaultProps = {
  jobIri: undefined,
}

const mapStateToProps = state => ({
  jobIri: jobIdSelector(state),
})

const mapDispatchToProps = dispatch => ({
  generateDisputeDocuments: jobIri =>
    dispatch(generateDisputeDocumentsAction.request({ jobIri })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(GenerateDisputeDocumentsContainer)
