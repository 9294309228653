import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Chip as MaterialUiChip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './ChipStyles'

const Chip = ({ label, avatar, onClick, classes, type, icon, onLoad }) => {
  useEffect(() => {
    if (onLoad) {
      onLoad()
    }
  })

  return (
    <MaterialUiChip
      label={label}
      avatar={avatar}
      onClick={onClick}
      icon={icon}
      className={classes[type]}
    />
  )
}

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  type: PropTypes.oneOf([
    'success',
    'warning',
    'error',
    'info',
    'smallSuccess',
  ]),
  icon: PropTypes.element,
  avatar: PropTypes.func,
  onClick: PropTypes.func,
  onLoad: PropTypes.func,
}

Chip.defaultProps = {
  icon: null,
  avatar: null,
  onClick: null,
  onLoad: null,
  type: 'info',
}

export default withStyles(styles)(Chip)
