import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles, Paper } from '@material-ui/core'
import SimpleTable from 'components/shared/Tables/SimpleTable'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import SaleInvoicesFilters from 'components/SaleInvoices/SaleInvoicesFilters/SaleInvoicesFilters'
import styles from './SaleInvoicesListStyles'
import SaleInvoicesListTableBody from './SaleInvoicesListTableBody'
import SaleInvoicesListTableHead from './SaleInvoicesListTableHead'
import SaleInvoicesPreviewContainer from '../SaleInvoicesPreview/SaleInvoicesPreviewContainer'

const SaleInvoicesList = ({
  classes,
  rows,
  searchParams,
  isLoading,
  handleChangePage,
  handleChangePerPage,
  handleChangeSort,
  handleSearchChange,
  handleFiltersChange,
  handleExportClick,
}) => {
  const [dialogPreviewOpened, togglePreviewDialog] = useState(false)
  const [saleInvoiceIri, setSaleInvoiceIri] = useState(null)

  const handleClickForPreview = iri => {
    setSaleInvoiceIri(iri)
    togglePreviewDialog(!dialogPreviewOpened)
  }

  return (
    <Paper className={classes.root}>
      {/* TODO Remove this condition to enable filters & export button */}
      {false && (
        <SaleInvoicesFilters
          handleSearchChange={handleSearchChange}
          handleFiltersChange={handleFiltersChange}
          handleExportClick={handleExportClick}
        />
      )}
      <SimpleTable
        rows={rows}
        orderBy={searchParams.orderBy}
        page={searchParams.page}
        sort={searchParams.sort}
        total={searchParams.total}
        isLoading={isLoading}
        handleChangePage={handleChangePage}
        rowsPerPage={searchParams.rowsPerPage}
        handleChangePerPage={handleChangePerPage}
        handleSearchChange={handleSearchChange}
        TableBodyContent={SaleInvoicesListTableBody}
        TableHeadContent={SaleInvoicesListTableHead}
        handleChangeSort={handleChangeSort}
        additionalProps={{
          handleClickForPreview,
        }}
      />
      {dialogPreviewOpened && (
        <SimpleDialog
          title=""
          onClose={() => togglePreviewDialog(!dialogPreviewOpened)}
        >
          <SaleInvoicesPreviewContainer saleInvoiceIri={saleInvoiceIri} />
        </SimpleDialog>
      )}
    </Paper>
  )
}

SaleInvoicesList.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  searchParams: PropTypes.shape({
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    sort: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangePerPage: PropTypes.func.isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  handleExportClick: PropTypes.func.isRequired,
}

export default compose(withStyles(styles))(SaleInvoicesList)
