import React, { PureComponent } from 'react'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import {
  STATUSES_DISPLAYING_PAYMENT_STEP,
  JOB_DONE_STATUS,
} from 'constants/Jobs'
import { INVOICE_STATE_AWAITING_PAYMENT } from 'constants/invoices'
import styles from './PaymentStepStyles'

class PaymentStep extends PureComponent {
  constructor(props) {
    super(props)

    this.state = { dialogOpened: false }
  }

  openDialog = () => {
    this.setState({ dialogOpened: true })
  }

  handleDialogResponse = event => {
    const { value } = event.currentTarget
    if (value === 'true') {
      const { dispatchInvoicePayTransition, invoiceId } = this.props
      dispatchInvoicePayTransition(invoiceId)
    }
    this.setState({ dialogOpened: false })
  }

  render() {
    const { translate, classes, jobStatus, invoiceStatus } = this.props
    if (!STATUSES_DISPLAYING_PAYMENT_STEP.includes(jobStatus)) {
      return <div className={classes.jobWorkflowStep} />
    }
    const { dialogOpened } = this.state

    return (
      <>
        {dialogOpened && (
          <ConfirmDialog
            title={translate('resources.jobs.subtitles.confirmPaymentTitle')}
            onClose={this.handleDialogResponse}
          >
            {translate('resources.jobs.subtitles.confirmPayment')}
          </ConfirmDialog>
        )}

        <Card className={classes.card}>
          <CardHeader
            title={translate('resources.jobs.subtitles.paymentStep')}
          />
          <CardContent>
            <Typography component="p" paragraph>
              {translate('resources.jobs.validationPayment.helpText')}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className={classes.buttonBigger}
              type="submit"
              onClick={this.openDialog}
              disabled={
                jobStatus === JOB_DONE_STATUS ||
                invoiceStatus !== INVOICE_STATE_AWAITING_PAYMENT
              }
            >
              {translate('resources.jobs.button.payment')}
            </Button>
          </CardContent>
        </Card>
      </>
    )
  }
}

PaymentStep.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatchInvoicePayTransition: PropTypes.func.isRequired,
  classes: PropTypes.shape().isRequired,
  invoiceId: PropTypes.number,
  invoiceStatus: PropTypes.string,
  jobStatus: PropTypes.string.isRequired,
}

PaymentStep.defaultProps = {
  invoiceId: undefined,
  invoiceStatus: undefined,
}

export default compose(i18n, withStyles(styles))(PaymentStep)
