import React from 'react'
import { CardMedia } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ReactSVG } from 'react-svg'

const Icon = ({ icon, type, className, ...props }) => {
  const [loading, setLoading] = React.useState(false)
  const [mediaUrl, setMediaUrl] = React.useState(null)

  React.useEffect(() => {
    setLoading(true)
    const importIcon = async () => {
      try {
        const iconComponent = await import(`assets/${icon}`)
        setMediaUrl(iconComponent.default)
      } finally {
        setLoading(false)
      }
    }
    importIcon()
  }, [icon])

  if (!loading && mediaUrl) {
    return type === 'svg' ? (
      <ReactSVG src={mediaUrl} className={className} {...props} />
    ) : (
      <CardMedia className={className} image={mediaUrl} {...props} />
    )
  }

  return null
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string.isRequired,
  props: PropTypes.shape({}),
}

Icon.defaultProps = {
  props: null,
  type: 'svg',
}

export default Icon
