import {
  generateRequestActions,
  generateSimpleAction,
  createRequestTypes,
} from 'helpers/store/actionsHelpers'

export const GET_FIRM_COMMENTS_LIST = createRequestTypes(
  'GET_FIRM_COMMENTS_LIST',
)
export const ADD_FIRM_COMMENT = 'ADD_FIRM_COMMENT'
export const SAVE_FIRM_COMMENT = createRequestTypes('SAVE_FIRM_COMMENT')

export const getFirmCommentsList = generateRequestActions(
  GET_FIRM_COMMENTS_LIST,
)
export const addFirmComment = generateSimpleAction(ADD_FIRM_COMMENT)
export const saveFirmComment = generateRequestActions(SAVE_FIRM_COMMENT)
