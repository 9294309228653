import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import Label from 'components/shared/Label/Label'
import WrapperInline from 'components/shared/WrapperInline/WrapperInline'

/**
 * InlineTextField
 *
 * render a text field on inline
 *
 * @param {string} label label to display
 * @param {string} field input field to display
 */
const InlineTextHtml = ({ label, field, labelOnNewLine }) => (
  <WrapperInline>
    <Label label={label} />
    {labelOnNewLine && <br />}
    <Typography
      style={{ display: 'inline-block' }}
      variant="body2"
      gutterBottom
      dangerouslySetInnerHTML={{ __html: field }}
    />
  </WrapperInline>
)

InlineTextHtml.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  labelOnNewLine: PropTypes.bool,
}

InlineTextHtml.defaultProps = {
  field: undefined,
  label: undefined,
  labelOnNewLine: false,
}

export default InlineTextHtml
