export default theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '3rem 3rem 0',
    padding: '2rem 3rem 3rem',
    backgroundColor: '#fff',
    borderRadius: '5px',
    width: 'auto',
  },
  formControl: {
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    fontSize: 'auto',
    minWidth: '13vw',
    width: 'auto',
  },
  selectDepartement: {
    fontSize: 'auto',
    minWidth: '13vw',
    width: 'auto',
  },
  fullWidth: {
    width: 'auto',
    minWidth: '200px',
  },
  inputSizing: {
    height: 'fit-content',
    width: '18%',
    marginRight: '2%',
    boxSizing: 'border-box',
    padding: '0 0 1rem',
  },
  buttonSearch: {
    padding: '0.8rem 3rem',
    borderRadius: '3px',
    border: 'none',
    textTransform: 'none',
    margin: '1.5rem 2rem 0 0',
  },
  buttonReload: {
    padding: '0.7rem 2.9rem',
    borderRadius: '3px',
    border: '2px solid #143C58',
    textTransform: 'none',
    background: 'none',
    color: '#143C58',
    marginTop: '1.5rem',
  },
  blocButtonExport: {
    flex: 'auto',
  },
  buttonExport: {
    background: 'none',
    marginTop: '1.5rem',
    padding: '0.8rem 3rem',
    textTransform: 'none',
    float: 'right',
  },
  imgDownload: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
    backgroundSize: '100%',
  },

  // rightIcon: {
  //   marginRight: '0.5rem',
  // },
  // inputSizing: {
  //   minWidth: '18%',
  //   flex: 'auto',
  //   padding: '1rem',
  // },
  // selectDateSizing: {
  //   minWidth: '18%',
  //   flex: 'auto',
  //   padding: '1rem',
  // },
  // inputTagSizing: {
  //   maxWidth: 'initial',
  //   minWidth: '18%',
  //   width: 'auto',
  //   flex: 'auto',
  //   padding: '1rem',
  // },
})
