import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Button, Link, withStyles } from '@material-ui/core'
import translate from 'providers/i18n/translateService'
import customConfirmAlert from 'helpers/utils/customConfirmAlert'
import { desactivateFirmDirectory } from 'store/firms/firmActions'
import styles from './FirmDetailsProDirDesactiveBtnStyles'

const FirmDetailsProDirectoryDesactiveBtn = ({
  desactivateFirmDirectoryAction,
  classes,
}) => (
  <Button
    name="desactivateInProDirectory"
    className={classes.root}
    color="primary"
    component={Link}
    onClick={() =>
      customConfirmAlert({
        title: translate('resources.firms.fields.askConfirmation'),
        message: translate('resources.firms.fields.askConfirmationText'),
        buttons: [
          {
            label: translate(
              'resources.firms.fields.confirmationDesactiveProDirectory',
            ),
            onClick: () => desactivateFirmDirectoryAction(),
          },
          {
            label: translate(
              'resources.firms.fields.cancelDesactiveProDirectory',
            ),
            onClick: () => false,
          },
        ],
      })
    }
  >
    {translate('resources.firms.fields.displayedInProDirectory')}
  </Button>
)

FirmDetailsProDirectoryDesactiveBtn.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  desactivateFirmDirectoryAction: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  desactivateFirmDirectoryAction: () =>
    dispatch(desactivateFirmDirectory.request()),
})

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
)(FirmDetailsProDirectoryDesactiveBtn)
