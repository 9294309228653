import React, { memo } from 'react'
import PropTypes from 'prop-types'
import uuidv1 from 'uuid/v1'
import { compose } from 'redux'
import { withStyles, Typography } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import InlineTextField from 'components/shared/InlineTextField/InlineTextField'
import WrapperInline from 'components/shared/WrapperInline/WrapperInline'
import Label from 'components/shared/Label/Label'
import styles from './JobDetailsPackagesStyles'

const JobDetailsPackages = ({ classes, translate, products }) => (
  <>
    {products.map(product => (
      <>
        <InlineTextField
          key={product.id}
          field={product.name}
          isBold
          certificate={product.requiredCertificate}
        />
        <WrapperInline>
          <Label label={translate('resources.jobs.fields.consumables')} />
          <ul className={classes.consumablesList}>
            {product.consumables.length > 0
              ? product.consumables.map(consumable => (
                  <li key={uuidv1()}>
                    <Typography variant="body2" gutterBottom>
                      {consumable}
                    </Typography>
                  </li>
                ))
              : '-'}
          </ul>
        </WrapperInline>
        <WrapperInline>
          <Label label={translate('resources.jobs.fields.service_details')} />
          <ul className={classes.consumablesList}>
            {product.serviceDetails.length > 0
              ? product.serviceDetails.map(serviceDetail => (
                  <li key={uuidv1()}>
                    <Typography variant="body2" gutterBottom>
                      {serviceDetail}
                    </Typography>
                  </li>
                ))
              : '-'}
          </ul>
        </WrapperInline>
      </>
    ))}
  </>
)

JobDetailsPackages.propTypes = {
  classes: PropTypes.shape({
    consumablesList: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
}

JobDetailsPackages.defaultProps = {
  products: [],
}

export default compose(i18n, memo, withStyles(styles))(JobDetailsPackages)
