import { initialState } from './firmCertificateFileSelectors'
import { FIRM_GET_SINGLE_FILE_CONTENT } from './firmCertificateFileActions'

export default (state = initialState, action) => {
  switch (action.type) {
    case FIRM_GET_SINGLE_FILE_CONTENT.REQUEST:
      return {
        ...state,
        file: null,
        isLoading: true,
      }
    case FIRM_GET_SINGLE_FILE_CONTENT.SUCCESS:
      return {
        ...state,
        file: action.payload.content,
        isLoading: false,
      }
    case FIRM_GET_SINGLE_FILE_CONTENT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
