import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Button, Typography, Grid, withStyles } from '@material-ui/core'

import i18n from 'providers/i18n/I18nProvider'

import { PENDING_START_STATUS } from 'constants/Jobs'

import styles from './JobInProgressUtilitiesStyles'

const JobInProgressUtilities = ({
  classes,
  translate,
  status,
  dispatchStartJob,
}) => {
  if (PENDING_START_STATUS !== status) {
    return null
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Typography component="p">
          {translate('resources.jobs.jobInProgressUtilities.info')}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Button
          onClick={dispatchStartJob}
          variant="contained"
          className={classes.buttonBigger}
        >
          {translate('resources.jobs.button.validate_checkin')}
        </Button>
      </Grid>
    </Grid>
  )
}

JobInProgressUtilities.propTypes = {
  classes: PropTypes.shape({
    jobInProgressUtilities: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  dispatchStartJob: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
}

export default compose(i18n, withStyles(styles))(JobInProgressUtilities)
