import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'
import styles from './InvoiceContestationPopinStyles'

class InvoiceContestationPopIn extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { contestation: '', reserveLength: 0 }
  }

  handleClosing = () => {
    const { onClose } = this.props

    // Reset the state of the component on close
    this.setState({ contestation: '', reserveLength: 0 })

    return onClose()
  }

  handleValidation = () => {
    const { onValidation, onClose, dispatchResetDispute } = this.props
    const { contestation } = this.state

    dispatchResetDispute()
    // If file is uploaded
    onValidation(contestation)

    // Close the popin
    return onClose()
  }

  handleChangeContestation = ({ target }) => {
    this.setState({
      reserveLength: target.value.length,
      contestation: target.value,
    })
  }

  render() {
    const { open, onClose, translate, classes } = this.props
    const { contestation, reserveLength } = this.state

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate('resources.jobs.invoiceContestation.title')}
        </DialogTitle>
        <DialogContent className={classes.textContainer}>
          <DialogContentText id="alert-dialog-description" />
          <TextField
            className={classes.textContainer}
            id="outlined-multiline-flexible"
            label="Contestation"
            multiline
            rowsMax="20"
            value={contestation}
            onChange={this.handleChangeContestation}
            variant="outlined"
            inputProps={{ maxLength: 500 }}
            fullWidth
          />
          <div className={classes.counter}>{reserveLength}/500</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClosing} className={classes.buttonReload}>
            {translate('app.action.cancel')}
          </Button>
          <Button
            onClick={this.handleValidation}
            disabled={!contestation}
            autoFocus
            className={classes.buttonBigger}
          >
            {translate('app.action.validate')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

InvoiceContestationPopIn.propTypes = {
  classes: PropTypes.shape({
    counter: PropTypes.string.isRequired,
    textContainer: PropTypes.string.isRequired,
    buttonReload: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidation: PropTypes.func.isRequired,
  dispatchResetDispute: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}
export default compose(i18n, withStyles(styles))(InvoiceContestationPopIn)
