import React from 'react'
import FeaturesContainer from 'components/Features/FeaturesContainer'
import { PermissionsContainer } from 'components/Permissions/PermissionsContainer'

export default () => (
  <div>
    <FeaturesContainer />
    <PermissionsContainer />
  </div>
)
