import { connect } from 'react-redux'
import { TIMESLOT_VALUES } from 'components/shared/Form/RenderTimeslotSelect'
import {
  doDisplayStatusChangeConfirmDialog,
  updateIncidentStatus,
} from 'store/incidents/incidentActions'
import { selectCurrentUpdatedIncident } from 'store/incidents/incidentSelectors'
import {
  currentJobSelector,
  selectIncident,
  timeslotSelector,
} from 'store/jobs/jobSelectors'
import StatusChangeConfirmDialog from './StatusChangeConfirmDialog'

const mapStateToProps = state => {
  const currentUpdatedIncident = selectCurrentUpdatedIncident(state)
  const incidentTypeCode = selectIncident(state, currentUpdatedIncident.id).type
    .code
  const { minDatePro, minDateHo, status } = currentJobSelector(state)
  const jobTimeslotStr = timeslotSelector(state)
  const initialTimeslotStr = TIMESLOT_VALUES.includes(jobTimeslotStr)
    ? jobTimeslotStr
    : null

  return {
    currentUpdatedIncident,
    plannedDtStr: minDatePro || minDateHo,
    incidentTypeCode,
    proPlannedDtStr: minDatePro,
    initialTimeslotStr,
    status,
  }
}

const mapDispatchToProps = dispatch => {
  const hideStatusChangeConfirmDialog = () =>
    dispatch(
      doDisplayStatusChangeConfirmDialog({ payload: { displayed: false } }),
    )

  return {
    hideStatusChangeConfirmDialog,
    confirmDialogAction: ({ incidentId, newStatus, timeslots = null }) => {
      dispatch(
        updateIncidentStatus.request({
          id: incidentId,
          status: newStatus,
          timeslots,
        }),
      )
      hideStatusChangeConfirmDialog()
    },
  }
}

const mergeProps = (
  { currentUpdatedIncident: { id, newStatus }, ...otherStateProp },
  { confirmDialogAction, ...otherDispatchProps },
  ownProps,
) => ({
  ...ownProps,
  ...otherStateProp,
  ...otherDispatchProps,
  confirmDialogActionGenerator: (timeslots = null) => () =>
    confirmDialogAction({
      incidentId: id,
      newStatus,
      timeslots,
    }),
  newStatus,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(StatusChangeConfirmDialog)
