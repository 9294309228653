import React from 'react'
import { Chip, withStyles } from '@material-ui/core'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { INVOICE_STATE_BLOCKED } from 'constants/invoices'
import { JOB_IN_PROGRESS_STATUS } from 'constants/Jobs'
import styles from './ChipStatusStyles'

const ChipStatus = ({
  translate,
  classes,
  showStatus,
  status,
  invoiceStatus,
}) => {
  const label = () => {
    if (
      status === JOB_IN_PROGRESS_STATUS &&
      invoiceStatus === INVOICE_STATE_BLOCKED
    ) {
      return translate(`invoice_status.blocked`)
    }
    if (status && showStatus) {
      return translate(`resources.jobs.status.${status}`)
    }
    return null
  }

  return (
    <span className={classes.blockStatus}>
      <Chip label={label(status)} color="primary" />
    </span>
  )
}

ChipStatus.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    blockStatus: PropTypes.string.isRequired,
  }).isRequired,
  showStatus: PropTypes.bool.isRequired,
  status: PropTypes.string,
  invoiceStatus: PropTypes.string,
}

ChipStatus.defaultProps = {
  status: undefined,
  invoiceStatus: undefined,
}

export default compose(i18n, withStyles(styles))(ChipStatus)
