import { palette } from 'config'

import {
  BLUE_QUOTATIS,
  GREY_LIGHT_QUOTATIS,
  GREY_QUOTATIS,
  WHITE_QUOTATIS,
  BG_QUOTATIS,
  BOLD_WEIGHT,
  BLUE_LIGHT_QUOTATIS,
  ORANGE_QUOTATIS,
  BLACK_QUOTATIS,
  XL_SIZE,
  M_SIZE,
  L_SIZE,
  NORMAL_WEIGHT,
  BLACK_WEIGHT,
} from './constants'

const theme = {
  backgroundColor: '#f00',
  shadows: Array(25).fill('none'),
  spacing: 8,

  // COLOR

  palette: {
    primary: { main: BLUE_QUOTATIS },
    secondary: { main: ORANGE_QUOTATIS },
    black: { main: BLACK_QUOTATIS },
    grey: { main: GREY_QUOTATIS },
    blueLight: { main: BLUE_LIGHT_QUOTATIS },
    greyLight: { main: GREY_LIGHT_QUOTATIS },
    background: { main: BG_QUOTATIS },
    white: { main: WHITE_QUOTATIS },
    success: { main: '#3fb98c' },
    warning: { main: '#FFC258' },
    error: { main: '#fb5e6c' },
    info: { main: '#999999' },
  },

  // TYPO

  typography: {
    fontFamily: ['Montserrat'].join(','),
    useNextVariants: true,
    normal: {
      fontWeight: NORMAL_WEIGHT,
    },
    body1: {
      fontSize: '0.875rem',
    },
    bold: {
      fontWeight: 600,
    },
    h2: {
      fontSize: XL_SIZE,
      fontWeight: BLACK_WEIGHT,
    },
    h3: {
      fontSize: L_SIZE,
      fontWeight: BOLD_WEIGHT,
    },
    h4: {
      fontSize: M_SIZE,
      fontWeight: BOLD_WEIGHT,
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: '400',
      color: '#143C58',
    },
  },
  MuiExpansionPanelDetails: {
    root: {
      display: 'block',
    },
  },

  // SPEC

  overrides: {
    a: {
      color: '#143c58',
      textDecoration: 'none',
      fontWeight: '600',
    },

    // MENU

    MuiDrawer: {
      paperAnchorLeft: {
        zIndex: '1000',
        backgroundColor: palette.color,
      },
    },

    MuiListItem: {
      root: {
        display: 'block',
        paddingTop: '1rem',
        paddingBottom: '1rem',
      },
      button: {
        opacity: '0.5',
        transition: 'none',
        backgroundColor: 'none',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MenuItem: {
      isActive: {
        opacity: 1,
      },
    },
    MuiListItemIcon: {
      root: {
        display: 'block',
        margin: '1rem auto',
        marginRight: 'none',
        width: 'max-content',
        color: '#fff',
      },
    },
    MuiListItemText: {
      root: {
        display: 'block',
        padding: '0',
      },
      primary: {
        color: '#fff',
        fontWeight: 500,
        textAlign: 'center',
        fontSize: 13,
        whiteSpace: 'normal',
      },
      '&:hover': {
        primary: {
          textDecoration: 'none!important',
        },
      },
    },

    // APP BAR

    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        boxShadow: '0 0 10px #00000015',
      },
    },

    MuiIconButton: {
      colorInherit: {
        color: '#143c58',
      },
    },

    MuiTypography: {
      colorInherit: {
        color: '#143c58',
      },
    },

    // INPUT

    MuiInputBase: {
      input: {
        fontSize: '0.9rem',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '0.9rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '95%',
        overflow: 'hidden',
      },
    },
    MuiSelect: {
      select: {
        '&#geozoneSelect': {
          fontSize: '1.25rem',
          marginTop: '-2px',
          paddingTop: 0,
        },
      },
    },

    // FILTER

    MuiGrid: {
      container: {},
      item: {},
    },

    MuiSnackbarContent: {
      message: {
        color: '#232323',
      },
    },

    // TAB

    MuiTab: {
      root: {
        color: '#232323',
        '&:selected': {
          background: '#003c5a',
          color: 'white',
        },

        '&:hover': {
          backgroundColor: '#f3f7f9',
        },
      },
    },
    MuiPrivateTabIndicator: {
      root: {
        display: 'none',
      },
    },

    // TABLE

    MuiTable: {
      root: {
        borderRadius: '4px',
        overflow: 'hidden',
      },
    },

    MuiTableRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: '#f3f7f9',
        },
        '&:nth-child(odd)': {
          backgroundColor: '#fff',
        },
      },
    },

    MuiTableCell: {
      root: {
        fontSize: '0.8125rem',
        padding: '1.6rem .5rem',
        borderBottom: 'none',
        lineHeight: '1rem',

        '&:first-child': {
          paddingLeft: '1rem',
        },

        '&:last-child': {
          paddingRight: '1rem',
        },

        '&>a': {
          color: '#143C58',
          textDecoration: 'none',
          fontWeight: '600',
        },

        a: {
          color: '#143C58',
          textDecoration: 'none',
          fontWeight: '600',
        },
      },
      body: {
        textAlign: 'center',
      },
      head: {
        lineHeight: '1.50',
        fontSize: '0.75rem',
        padding: '1.2rem .5rem',
        color: 'white',
        backgroundColor: '#143C58',
        textAlign: 'center',

        '&:first-child': {
          paddingLeft: '1rem',
        },

        '&:last-child': {
          paddingRight: '1rem',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#fff',

        '&:hover': {
          color: '#999999',
          '&& $icon': {
            opacity: 0,
          },
        },
        '&$active': {
          color: '#fff',

          // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
          '&& $icon': {
            opacity: 1,
            color: '#fff',
          },
          '&:hover': {
            color: '#999999',
            '&& $icon': {
              opacity: 1,
              color: '#999999',
            },
          },
        },
      },
      icon: {
        marginRight: 0,
      },
    },
    // BUTTON

    MuiButtonBase: {
      root: {
        '&:focus': {
          outline: 'none',
        },
      },
    },

    MuiButton: {
      root: {
        '&$disabled': {
          color: '#143C58!important',
          opacity: '0.3',
        },
        '&:focus': {
          outline: 'none',
        },
        '&:hover': {
          color: 'inherit',
          textDecoration: 'none !important',
        },
      },
    },

    // CHIP

    Chip: {
      root: {
        color: 'white',
        backgroundColor: '#999999',
      },
      succes: {
        color: 'white',
        backgroundColor: '#176D6A',
      },
      info: {
        color: 'white',
        backgroundColor: '#143C58',
      },
    },

    // FORM

    // CHECKBOX BUTTON

    MuiCheckbox: {
      root: {
        color: '#143C58',
      },
      colorSecondary: {
        color: '#143C58',

        '&$checked': {
          color: '#143C58',
        },
      },
    },

    RenderSelectMultipleAutocompleteField: {
      valueContainer: {
        flexWrap: 'nowrap',
        maxWidth: '71vw',
      },
    },

    // SWITCH BUTTON

    MuiSwitch: {
      icon: {
        color: '#E6EAED',
        boxShadow: '2px 1px 2px #0003',
      },
      iconChecked: {
        color: '#143C58',
        boxShadow: '2px 1px 2px #0003',
      },
      bar: {
        opacity: '1',
        backgroundColor: '#DAE1E7',
        '$checked$checked + &': {
          opacity: '1',
          backgroundColor: '#DAE1E7',
        },
      },
      colorSecondary: {
        color: '#143C58',

        '&$checked': {
          color: '#143C58',
        },
      },
    },

    // PANEL

    MuiExpansionPanelSummary: {
      content: {
        margin: '20px 0',
      },
    },

    // POPOVER

    MuiPopover: {
      paper: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
    },

    // POPIN

    MuiDialogTitle: {
      root: {
        padding: '2rem 2rem 0',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '1rem 2rem',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0 2rem 2rem',
      },
    },

    // AVATAR

    MuiAvatar: {
      colorDefault: {
        backgroundColor: 'none',
        borderRadius: 0,
      },
    },

    // END SPEC
  },

  MuiCardHeader: {
    fontSize: '0.9rem',
  },

  buttonBigger: {
    marginBottom: '1rem',
    padding: '0.8rem 3rem',
    fontSize: '0.9rem',
    boxShadow: 'none',
    fontFamily: ['montserrat'].join(','),
    fontWeight: 500,
    borderRadius: '3px',
    textTransform: 'none',
    backgroundColor: ORANGE_QUOTATIS,
  },
  buttonReload: {
    padding: '0.7rem 2.9rem',
    borderRadius: '3px',
    border: '2px solid #143C58',
    textTransform: 'none',
    background: 'none',
    color: '#143C58',
    marginBottom: '1rem',
  },
  buttonSecondary: {
    padding: '0.7rem 2.9rem',
    borderRadius: '3px',
    border: '2px solid #143C58',
    textTransform: 'none',
    background: 'none',
    color: '#143C58',
    marginBottom: '1rem',
  },
  buttonDelete: {
    padding: '0.8rem 2.9rem',
    borderRadius: '3px',
    textTransform: 'none',
    color: '#fff',
    backgroundColor: '#C6414D',
    marginBottom: '1rem',
  },
  alignCenter: {
    display: 'block',
    margin: '0 auto',
  },
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
}

export default theme
