import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import uuidv1 from 'uuid/v1'
import { dateFormatter } from 'helpers/date'
import { DATE_FORMAT } from 'constants/date'
import { convertCentsToEuros } from 'helpers/utils/common'
import { TableCell, TableRow, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { JOB_PATH_PREFIX } from 'constants/routes'
import i18n from 'providers/i18n/I18nProvider'
import {
  getJobInformationFromInvoiceItem,
  getPackageFromPurchaseInvoice,
  getDisplayedState,
} from 'helpers/utils/invoice/invoice'
import { buildJobIri } from 'helpers/utils/job/job'
import styles from './InvoicesListStyles'

const InvoicesListTableBody = ({ rows, translate, classes }) =>
  rows.map(row => {
    const {
      orderNumber,
      state,
      stateUpdatedAt,
      jobId,
      store,
      issuedAt,
      providerBillingData,
      total,
      totalSalePrice,
      items,
    } = row

    const link = `${JOB_PATH_PREFIX}/${encodeURIComponent(
      buildJobIri(jobId),
    )}/show`

    if (!items) {
      return null
    }

    const packageItem = getPackageFromPurchaseInvoice(items)
    const { customer, timeslot } = getJobInformationFromInvoiceItem(packageItem)

    return (
      <TableRow key={uuidv1()}>
        <TableCell>
          <Link to={link} className={classes.menuLink}>
            {orderNumber}
          </Link>
        </TableCell>
        <TableCell>{`${store.name} (${store.codeAccount})`}</TableCell>
        <TableCell>{providerBillingData.company}</TableCell>
        <TableCell>
          {providerBillingData.firstName} {providerBillingData.lastName}
        </TableCell>
        <TableCell>{customer}</TableCell>
        <TableCell>{timeslot}</TableCell>
        <TableCell>
          {translate(
            `invoice.state.${getDisplayedState(state, stateUpdatedAt)}`,
          )}
        </TableCell>
        <TableCell>{dateFormatter(stateUpdatedAt, DATE_FORMAT)}</TableCell>
        <TableCell>{convertCentsToEuros(total)} €</TableCell>
        <TableCell>{dateFormatter(issuedAt, DATE_FORMAT)}</TableCell>
        <TableCell>{convertCentsToEuros(totalSalePrice)} €</TableCell>
      </TableRow>
    )
  })

InvoicesListTableBody.propTypes = {
  translate: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      stateUpdatedAt: PropTypes.string.isRequired,
      jobId: PropTypes.string.isRequired,
      store: PropTypes.shape({
        name: PropTypes.string.isRequired,
        codeAccount: PropTypes.string.isRequired,
      }).isRequired,
      issuedAt: PropTypes.string.isRequired,
      providerBillingData: PropTypes.shape({
        company: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }).isRequired,
      total: PropTypes.number.isRequired,
      totalSalePrice: PropTypes.number.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
  ).isRequired,
}

export default compose(i18n, withStyles(styles))(InvoicesListTableBody)
