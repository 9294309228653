export const styles = theme => ({
  titleTypeDocument: {
    float: 'left',
    marginBottom: '10px',
    marginRight: '10px',
    fontSize: '16px',
    maxWidth: '80%',
  },
  uploadButton: {
    minWidth: '206px',
  },
  uploadButtonInner: {
    color: '#143C58',
    border: '2px solid #143C58',
    padding: '0.7rem 1.3rem',
    maxWidth: '280px',
    background: 'none',
    borderRadius: '3px',
    marginBottom: '1rem',
    textTransform: 'none',
  },
  titleFile: {
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 2.8,
    textDecoration: 'underline',
  },
  titleMeta: {
    marginTop: '20px',
    fontSize: 14,
    fontWeight: '300',
  },
  icon: {
    width: '20px',
    height: '20px',
    backgroundSize: 'contain',
    margin: '1px 10px 0',
    float: 'left',
  },
  iconRight: {
    width: '20px',
    height: '20px',
    backgroundSize: 'contain',
    margin: '1px 10px 0',
    float: 'right',
  },
  divider: {
    clear: 'both',
    borderTop: '1px dashed black',
  },
  cardOk: {
    backgroundColor: theme.palette.blueLight.main,
    margin: '10px 0',
  },
  cardPending: {
    backgroundColor: '#EFF1F4',
    margin: '10px 0',
  },
  cardKo: {
    backgroundColor: '#f4eaea',
    margin: '10px 0',
  },
  fileLink: {
    cursor: 'pointer',
  },
})
