import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { change, isValid } from 'redux-form'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { setLSProLegacyId } from 'store/localStorage/localStorageActions'
import {
  EDIT_FIRM_DETAILS_PRO_FORM,
  EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
  EDIT_FIRM_DETAILS_PACKAGES_FORM,
  EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM,
  EDIT_FIRM_INTERVENTION_ZONE_FORM,
  ADD_FIRM_COMMENT_FORM,
} from 'constants/forms'
import FirmValidationPro from './FirmValidationPro'

const isformValid = currentValue => currentValue === true

const FirmValidationProContainer = ({
  handleSubmit,
  setLegacyId,
  firm,
  changePackageStatus,
  validFirmDetailsProFormState,
  validFirmDetailLegalInformationFormState,
  validFirmDetailsPackagesFormState,
  validFirmDetailsInfoPackageFormState,
  validFirmInterventionZoneFormState,
  validFirmCommentFormState,
}) => {
  const formValid = [
    validFirmDetailsProFormState,
    validFirmDetailLegalInformationFormState,
    validFirmDetailsPackagesFormState,
    validFirmDetailsInfoPackageFormState,
    validFirmInterventionZoneFormState,
    validFirmCommentFormState,
  ].every(isformValid)

  return (
    <FirmValidationPro
      handleSubmit={handleSubmit}
      status={firm.packageStatusV2}
      changePackageStatus={changePackageStatus}
      isFormValid={formValid}
      setLegacyId={setLegacyId}
    />
  )
}

FirmValidationProContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setLegacyId: PropTypes.func.isRequired,
  firm: PropTypes.shape({
    packageStatusV2: PropTypes.string.isRequired,
  }).isRequired,
  editFirmDetailLegalInformationFormState: PropTypes.shape({}),
  editFirmDetailsPackagesFormState: PropTypes.shape({}),
  changePackageStatus: PropTypes.func.isRequired,
  validFirmDetailsProFormState: PropTypes.bool.isRequired,
  validFirmDetailLegalInformationFormState: PropTypes.bool.isRequired,
  validFirmDetailsPackagesFormState: PropTypes.bool.isRequired,
  validFirmDetailsInfoPackageFormState: PropTypes.bool.isRequired,
  validFirmInterventionZoneFormState: PropTypes.bool.isRequired,
  validFirmCommentFormState: PropTypes.bool.isRequired,
}

FirmValidationProContainer.defaultProps = {
  editFirmDetailLegalInformationFormState: null,
  editFirmDetailsPackagesFormState: null,
}

const mapStateToProps = state => ({
  validFirmDetailsProFormState: isValid(EDIT_FIRM_DETAILS_PRO_FORM)(state),
  validFirmDetailLegalInformationFormState: isValid(
    EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
  )(state),
  validFirmDetailsPackagesFormState: isValid(EDIT_FIRM_DETAILS_PACKAGES_FORM)(
    state,
  ),
  validFirmDetailsInfoPackageFormState: isValid(
    EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM,
  )(state),
  validFirmInterventionZoneFormState: isValid(EDIT_FIRM_INTERVENTION_ZONE_FORM)(
    state,
  ),
  validFirmCommentFormState: isValid(ADD_FIRM_COMMENT_FORM)(state),
})

const mapDispatchToProps = dispatch => ({
  changePackageStatus: status =>
    dispatch(change(EDIT_FIRM_DETAILS_PRO_FORM, 'packageStatusV2', status)),
  setLegacyId: () => dispatch(setLSProLegacyId()),
  dispatch,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  i18n,
)(FirmValidationProContainer)
