import { all, takeLatest } from 'redux-saga/effects'
import { AbstractSagas } from './abstractSagas'
import { PackageActions } from './packageActions'
import { PackageSelector } from './packageSelector'

class PackageSagas extends AbstractSagas {
  static getType() {
    return 'package'
  }

  static getAction() {
    return PackageActions
  }

  static getSelector() {
    return PackageSelector
  }
}

export default function*() {
  yield all([
    takeLatest(
      PackageActions.GET_TYPE_LIST().REQUEST,
      PackageSagas.handleTypeListRequest(),
    ),
  ])
}
