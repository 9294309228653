import { all, call, takeLatest } from 'redux-saga/effects'
import { handleRequest } from 'helpers/store/sagasHelpers'
import client from 'services/httpClient/engineClient'
import { getSingleWorksite, GET_SINGLE_WORKSITE } from './worksiteActions'

export function* handleGetSingleWorksite({ worksiteId }) {
  const url = `/worksites/${worksiteId}`
  yield* handleRequest({
    requestActions: getSingleWorksite,
    promise: call(client.get, url),
  })
}

export default function*() {
  yield all([takeLatest(GET_SINGLE_WORKSITE.REQUEST, handleGetSingleWorksite)])
}
