export const HOME_PATH = '/'
export const LOGIN_PATH = '/login'
export const EMPTY_PATH = '/admin/empty'
export const ERROR_PATH = '/error'

export const ADMIN_PATH_PREFIX = '/admin'
export const JOB_PATH_PREFIX = '/admin/jobs'
export const PROS_PATH_PREFIX = '/admin/pros'
export const INVOICES_PATH_PREFIX = '/admin/invoices'
export const ALL_INVOICES_LIST_PREFIX = '/admin/all-invoices'
export const SALE_INVOICES_PATH_PREFIX = '/admin/sale-invoices'
export const FEATURES_PATH = '/admin/features'
export const VERSION_PATH_PREFIX = '/admin/version'

export const DASHBOARD_PATH = `${ADMIN_PATH_PREFIX}/dashboard`
export const JOBS_SEARCH_PATH = `${JOB_PATH_PREFIX}/search/partial`
export const JOB_SINGLE = `${JOB_PATH_PREFIX}/:jobId/show`
export const JOB_PATH_MANUAL_MATCHING_PREFIX = `${JOB_PATH_PREFIX}/manualMatching`
export const JOB_SINGLE_MANUAL_MATCHING = `${JOB_PATH_MANUAL_MATCHING_PREFIX}/:jobId`

export const PROS_LIST_PATH = `${PROS_PATH_PREFIX}/list`
export const PRO_SINGLE = `${PROS_PATH_PREFIX}/:firmId/show`
export const INVOICES_LIST_PATH = `${INVOICES_PATH_PREFIX}/list`
export const ALL_INVOICES_LIST_PATH = `${ALL_INVOICES_LIST_PREFIX}/list`
export const SALE_INVOICES_LIST_PATH = `${SALE_INVOICES_PATH_PREFIX}/list`

export const PRO_LIST_STATUS_TEST_PATH = `${PROS_LIST_PATH}/#`
