const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  inputText: {
    width: '100%',
    height: '65px',
    padding: '20px',
    fontSize: '0.9rem',
  },
  myMargin: {
    margin: '20px 0',
  },
  buttonBigger: theme.buttonBigger,
})

export default styles
