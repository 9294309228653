import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field, change } from 'redux-form'
import {
  Card,
  CardContent,
  FormControl,
  Grid,
  withStyles,
} from '@material-ui/core'
import { pick } from 'lodash'
import { isMocks } from 'services/isMocks'
import { showNotification } from 'store/Application/ApplicationActions'
import translate from 'providers/i18n/translateService'
import { ERROR } from 'constants/variant'
import { EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM } from 'constants/forms'
import {
  FIRM_LEGAL_STATUS,
  FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS,
  FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER,
  FIELD_LEGAL_INFORMATIONS_CAPITAL_AMOUNT,
  FIELD_LEGAL_INFORMATIONS_VAT_NUMBER,
  FIELD_LEGAL_INFORMATIONS_SWIFT_CODE,
  FIELD_LEGAL_INFORMATIONS_IBAN,
  FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS,
  FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY,
  FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE,
  FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS,
  FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY,
  FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE,
  FIELD_LEGAL_INFORMATIONS_REGISTRATION_CITY,
  FIELD_LEGAL_INFORMATIONS_POSTAL_CODE,
  FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY,
  FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_FIRST_NAME,
  FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_LAST_NAME,
  FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT,
  FIRM_VATS,
} from 'constants/firms'
import { REGEXP_DEPARTMENT } from 'constants/regexp'
import {
  PERM_FIRM_UPDATE_LEGAL,
  PERM_FIRM_UPDATE_IBAN,
} from 'constants/permissions'
import i18n from 'providers/i18n/I18nProvider'
import RenderCheckbox from 'components/shared/Form/RenderCheckbox'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import {
  required,
  isIban,
  isSwiftCode,
  isVat,
  department,
  maxLength,
} from 'helpers/form/validationHelpers'
import { hasPermission } from 'services/permissions'
import { convertArrayToObject } from 'helpers/utils/common'
import { applyValidationRules } from 'helpers/utils/validate'
import styles from './FirmLegalInformationsStyles'

const firmVats = convertArrayToObject(FIRM_VATS, 'key')

const validate = (values, props) => {
  const { isFirmValidatedForPackage } = props
  const { legalStatus } = values

  if (isFirmValidatedForPackage) {
    // Define here the rules which must be applied for this form
    const fieldsWithRules = {
      [FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS]: [required],
      [FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER]: [required],
      [FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS]: [required, maxLength(200)],
      [FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY]: [
        required,
        maxLength(50),
      ],
      [FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE]: [
        required,
        maxLength(10),
      ],
      [FIELD_LEGAL_INFORMATIONS_POSTAL_CODE]: [
        required,
        department,
        maxLength(10),
      ],
      [FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY]: [required],
      [FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS]: [
        required,
        maxLength(200),
      ],
      [FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY]: [required, maxLength(50)],
      [FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE]: [
        required,
        maxLength(10),
      ],
      [FIELD_LEGAL_INFORMATIONS_VAT_NUMBER]: [required, isVat, maxLength(50)],
      [FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_FIRST_NAME]: [
        required,
        maxLength(50),
      ],
      [FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_LAST_NAME]: [
        required,
        maxLength(50),
      ],
      [FIELD_LEGAL_INFORMATIONS_REGISTRATION_CITY]: [required, maxLength(50)],
      [FIELD_LEGAL_INFORMATIONS_CAPITAL_AMOUNT]: [required],
      [FIELD_LEGAL_INFORMATIONS_SWIFT_CODE]: [
        required,
        isSwiftCode,
        maxLength(50),
      ],
      [FIELD_LEGAL_INFORMATIONS_IBAN]: [required, isIban, maxLength(50)],
      [FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT]: [required],
    }

    // Apply these rules for all fields
    const errors = applyValidationRules(fieldsWithRules, values, props)

    let fieldsToVerify

    // Keep only usefull rules
    switch (legalStatus) {
      case undefined:
      case null:
      case '':
        fieldsToVerify = [FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS]
        break
      case 'EI':
        fieldsToVerify = [
          FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS,
          FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER,
          FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS,
          FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY,
          FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE,
          FIELD_LEGAL_INFORMATIONS_POSTAL_CODE,
          FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY,
          FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS,
          FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY,
          FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE,
          FIELD_LEGAL_INFORMATIONS_VAT_NUMBER,
          FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_FIRST_NAME,
          FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_LAST_NAME,
          FIELD_LEGAL_INFORMATIONS_SWIFT_CODE,
          FIELD_LEGAL_INFORMATIONS_IBAN,
          FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT,
        ]
        break
      default:
        fieldsToVerify = [
          FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS,
          FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER,
          FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS,
          FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY,
          FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE,
          FIELD_LEGAL_INFORMATIONS_REGISTRATION_CITY,
          FIELD_LEGAL_INFORMATIONS_CAPITAL_AMOUNT,
          FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS,
          FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY,
          FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE,
          FIELD_LEGAL_INFORMATIONS_VAT_NUMBER,
          FIELD_LEGAL_INFORMATIONS_SWIFT_CODE,
          FIELD_LEGAL_INFORMATIONS_IBAN,
          FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT,
        ]
        break
    }

    return pick(errors, fieldsToVerify)
  }

  return {}
}

class FirmLegalInformations extends PureComponent {
  constructor(props) {
    super(props)

    this.updateLegalStatus = this.updateLegalStatus.bind(this)
    this.updateBillingAddress = this.updateBillingAddress.bind(this)
    this.updateBillingAddressCity = this.updateBillingAddressCity.bind(this)
    this.updateBillingAddressPostalCode = this.updateBillingAddressPostalCode.bind(
      this,
    )
    this.generateBusinessDirectory = this.generateBusinessDirectory.bind(this)
    this.forceUppercase = this.forceUppercase.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { forceValidation, isFirmValidatedForPackage } = this.props

    if (isFirmValidatedForPackage !== prevProps.isFirmValidatedForPackage) {
      forceValidation()
    }
  }

  updateLegalStatus(event) {
    const { dispatch } = this.props

    if (!event.target.value || event.target.value === 'EI') {
      return
    }

    dispatch(
      change(
        EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
        FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT,
        firmVats.SUBJECT_TO_VAT.key,
      ),
    )
  }

  updateBillingAddress(event) {
    const { dispatch } = this.props

    dispatch(
      change(
        EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
        FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS,
        event.target.value,
      ),
    )
  }

  updateBillingAddressCity(event) {
    const { dispatch } = this.props

    dispatch(
      change(
        EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
        FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY,
        event.target.value,
      ),
    )
  }

  updateBillingAddressPostalCode(event) {
    const { dispatch } = this.props

    dispatch(
      change(
        EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
        FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE,
        event.target.value,
      ),
    )
  }

  generateBusinessDirectory(event) {
    const { dispatch, registrationNumber } = this.props
    const postalCode = event.target.value

    dispatch(
      change(
        EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
        FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY,
        REGEXP_DEPARTMENT.test(postalCode)
          ? `${registrationNumber}RM${postalCode}`
          : null,
      ),
    )
  }

  forceUppercase(event, fieldId) {
    const { dispatch } = this.props

    dispatch(
      change(
        EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
        fieldId,
        event.target.value.toUpperCase(),
      ),
    )

    event.preventDefault()
  }

  render() {
    const { classes, translate, handleSubmit } = this.props
    const disabled = !hasPermission(PERM_FIRM_UPDATE_LEGAL)
    const ibanSwiftDisabledToAgents = !hasPermission(PERM_FIRM_UPDATE_IBAN)

    return (
      <FormControl className={classes.formControl}>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="flex-start" direction="row">
            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardContent>
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS}
                    id={`${FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS}Select`}
                    className={classes.input}
                    component={RenderSelectField}
                    choices={FIRM_LEGAL_STATUS}
                    label={translate('resources.firms.fields.legal_status')}
                    optionValue="code"
                    allowEmpty
                    onChange={this.updateLegalStatus}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.registration_number',
                    )}
                    disabled
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_CAPITAL_AMOUNT}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.capital_amount')}
                    type="number"
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_VAT_NUMBER}
                    className={classes.inputForcedUppercase}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.vat_number')}
                    onBlur={event => {
                      this.forceUppercase(
                        event,
                        FIELD_LEGAL_INFORMATIONS_VAT_NUMBER,
                      )
                    }}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_SWIFT_CODE}
                    className={classes.inputForcedUppercase}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.swift_code')}
                    onBlur={event => {
                      this.forceUppercase(
                        event,
                        FIELD_LEGAL_INFORMATIONS_SWIFT_CODE,
                      )
                    }}
                    disabled={!isMocks ? ibanSwiftDisabledToAgents : false}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_IBAN}
                    className={classes.inputForcedUppercase}
                    component={RenderTextField}
                    onBlur={event => {
                      this.forceUppercase(event, FIELD_LEGAL_INFORMATIONS_IBAN)
                    }}
                    label={translate(
                      'resources.firms.fields.international_bank_account_number',
                    )}
                    disabled={!isMocks ? ibanSwiftDisabledToAgents : false}
                  />
                  {/* This field is used to force this form validation when packageStatus changed */}
                  <Field
                    name="checkBox"
                    isDisabled
                    customStyle={{ display: 'none' }}
                    component={RenderCheckbox}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardContent>
                  <Field
                    name={
                      FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_LAST_NAME
                    }
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.legal_representative_last_name',
                    )}
                    disabled={disabled}
                  />
                  <Field
                    name={
                      FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_FIRST_NAME
                    }
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.legal_representative_first_name',
                    )}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_REGISTRATION_CITY}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.registration_city',
                    )}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_POSTAL_CODE}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.postal_code')}
                    onChange={this.generateBusinessDirectory}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY}
                    className={classes.input}
                    component={RenderTextField}
                    disabled
                    label={translate(
                      'resources.firms.fields.business_directory',
                    )}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT}
                    id={`${FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT}Select`}
                    className={classes.input}
                    choices={FIRM_VATS}
                    component={RenderSelectField}
                    label={translate('resources.firms.fields.vat')}
                    optionValue="key"
                    optionText="name"
                    allowEmpty
                    disabled={disabled}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardContent>
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.head_quarters_address',
                    )}
                    onChange={this.updateBillingAddress}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.head_quarters_city',
                    )}
                    onChange={this.updateBillingAddressCity}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.head_quarters_postal_code',
                    )}
                    onChange={this.updateBillingAddressPostalCode}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate('resources.firms.fields.billing_address')}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.billing_address_city',
                    )}
                    disabled={disabled}
                  />
                  <Field
                    name={FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE}
                    className={classes.input}
                    component={RenderTextField}
                    label={translate(
                      'resources.firms.fields.billing_address_postal_code',
                    )}
                    disabled={disabled}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      </FormControl>
    )
  }
}

FirmLegalInformations.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    inputForcedUppercase: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  forceValidation: PropTypes.func.isRequired,
  isFirmValidatedForPackage: PropTypes.bool.isRequired,
  registrationNumber: PropTypes.string.isRequired,
}

const formConfig = {
  form: EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch) => {
    dispatch(
      showNotification({
        payload: {
          message: translate('resources.firms.validation.onSubmitFail'),
          messageType: ERROR,
        },
      }),
    )
  },
  validate,
}

export default compose(
  i18n,
  reduxForm(formConfig),
  withStyles(styles),
)(FirmLegalInformations)
