import React from 'react'
import PropTypes from 'prop-types'
import { dateFormatter } from 'helpers/date'
import BorderLinearProgress from 'components/shared/LinearProgress/BorderLinearProgress'

const ReceiptProgressBar = ({ translate, classes, createdAt, value }) => (
  <div className={classes.progressBarContainer}>
    <p className={classes.dateFormat}>
      {translate('resources.jobs.validateBills.receiptSignatureCreateAt')}
      &nbsp;{dateFormatter(createdAt, translate('app.date_format.long'))}
    </p>
    <BorderLinearProgress variant="determinate" value={value} />
    <p className={classes.valuePercentage}>{value}%</p>
  </div>
)

ReceiptProgressBar.propTypes = {
  classes: PropTypes.shape({
    valuePercentage: PropTypes.string,
    progressBarContainer: PropTypes.string,
    dateFormat: PropTypes.string,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  createdAt: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}
export default ReceiptProgressBar
