import { createSelector } from 'reselect'
import { get } from 'lodash'

export const initialState = {
  conversationList: [],
  currentConversation: {
    id: null,
    messages: [],
    messagesLoading: false,
  },
  conversationsLoading: false,
}

export const conversationSelector = state => get(state, 'conversations')

export const conversationListSelector = createSelector(
  conversationSelector,
  state => get(state, 'conversationList'),
)

export const conversationsLoadingSelector = createSelector(
  conversationSelector,
  state => get(state, 'conversationsLoading'),
)

export const currentConversationSelector = createSelector(
  conversationSelector,
  state => get(state, 'currentConversation'),
)

export const currentConversationMessagesSelector = createSelector(
  currentConversationSelector,
  state => get(state, 'messages'),
)

export const currentConversationMessagesLoadingSelector = createSelector(
  currentConversationSelector,
  state => get(state, 'messagesLoading'),
)
