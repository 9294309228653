import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles, Typography } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import Grid from '@material-ui/core/Grid'
import TableIncidents from 'components/TableIncidents/TableIncidents'
import classNames from 'classnames'
import DialogsContainer from 'components/Jobs/JobDetails/JobDetailsIncidents/Dialogs/DialogsContainer'
import GenerateSupportingDocumentsContainer from 'components/Jobs/JobDetails/JobDetailsIncidents/GenerateSupportingDocuments/GenerateSupportingDocumentsContainer'
import GenerateDisputeDocumentsContainer from 'components/Jobs/JobDetails/JobDetailsIncidents/GenerateDisputeDocuments/GenerateDisputeDocumentsContainer'
import GenerateReinterventionAfterSaleContainer from './GenerateReinterventionAfterSale/GenerateReinterventionAfterSaleContainer'
import styles from './JobDetailsIncidentsStyles'
import IncidentFormContainer from './IncidentFormContainer'

const JobDetailsIncidents = ({
  classes,
  translate,
  incidents,
  incidentWaitingForList,
  updateWaitingFor,
  incidentStatuses,
  isReinterventionAfterSaleEnabled,
}) => (
  <div className={classes.incidents}>
    <div className={classes.buttons}>
      <GenerateSupportingDocumentsContainer />
      <GenerateDisputeDocumentsContainer />
      {isReinterventionAfterSaleEnabled && (
        <GenerateReinterventionAfterSaleContainer />
      )}
    </div>
    <Typography
      className={classNames({
        [classes.redText]: incidents.totalItems > 0,
      })}
    >
      {incidents.totalItems > 0
        ? translate('app.message.nb_result_incidents', {
            smart_count: incidents.totalItems,
          })
        : translate('app.message.no_result_incidents')}
    </Typography>
    <DialogsContainer translate={translate} />
    {incidents.totalItems > 0 && (
      <Grid className={classes.table}>
        <TableIncidents
          incidents={incidents.list}
          waitingForList={incidentWaitingForList}
          updateWaitingFor={updateWaitingFor}
          statuses={incidentStatuses}
        />
      </Grid>
    )}
    <IncidentFormContainer />
  </div>
)

JobDetailsIncidents.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    table: PropTypes.string,
    redText: PropTypes.string,
    incidents: PropTypes.string,
    buttons: PropTypes.string,
  }).isRequired,
  incidents: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    totalItems: PropTypes.number,
  }).isRequired,
  incidentWaitingForList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateWaitingFor: PropTypes.func.isRequired,
  incidentStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isReinterventionAfterSaleEnabled: PropTypes.bool.isRequired,
}

export default compose(i18n, withStyles(styles))(JobDetailsIncidents)
