import React, { useState } from 'react'
import { Button, Link, Typography, withStyles } from '@material-ui/core'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import { isJobCancelable } from 'helpers/utils/job/job'
import styles from './ToPendingCancelationLinkStyles'

const ToPendingCancelationLink = ({
  jobIri,
  translate,
  classes,
  showStatus,
  status,
  updateJobStatusAction,
  isLoading,
}) => {
  const [confirmDialogOpened, setDialogOpened] = useState(false)

  const openConfirmDialog = () => {
    setDialogOpened(true)
  }

  const handleResponse = ({ currentTarget: { value } }) => {
    if (value === 'true') {
      updateJobStatusAction(jobIri)
    }
    setDialogOpened(!confirmDialogOpened)
  }

  if (!showStatus || !isJobCancelable(status) || isLoading) {
    return null
  }

  return (
    <>
      <Button
        name="toPendingCancelation"
        className={classes.root}
        color="primary"
        component={Link}
        onClick={openConfirmDialog}
      >
        {translate('resources.jobs.navbar.link.to_pending_cancelation')}
      </Button>
      {confirmDialogOpened && (
        <ConfirmDialog
          title={translate('job.to_pending_cancelation.confirm_popin.title')}
          onClose={handleResponse}
        >
          <Typography component="p">
            {translate('job.to_pending_cancelation.confirm_popin.content')}
          </Typography>
        </ConfirmDialog>
      )}
    </>
  )
}

ToPendingCancelationLink.propTypes = {
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  jobIri: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  showStatus: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateJobStatusAction: PropTypes.func.isRequired,
}

export default compose(i18n, withStyles(styles))(ToPendingCancelationLink)
