import {
  BLUE_QUOTATIS,
  WHITE_QUOTATIS,
  BLACK_WEIGHT,
  BLUE_SAV_QUOTATIS,
} from '../../../theme/constants'

const styles = () => ({
  root: {
    position: 'fixed',
    width: '100%',
    background: '#dae1e6',
    zIndex: '11',
  },
  tabs: {
    marginTop: 0,
    marginLeft: '3rem',
    padding: '1rem 0 0',
    boxSizing: 'border-box',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    background: '#dae1e6',
  },
  tab: {
    display: 'inline-block',
    flex: '3',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    background: WHITE_QUOTATIS,
    borderRight: '2px solid #dae1e6',

    '&:first-child': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderRight: '2px solid #dae1e6',
    },

    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      borderRight: 'none',
    },
    color: BLUE_QUOTATIS,
  },
  link: {
    display: 'block',
    alignItem: 'center',
    padding: '12px',
    fontSize: '0.875rem',
    fontWeight: 500,
    fontFamily: 'Montserrat',
    textDecoration: 'none',
    color: 'rgb(138, 158, 171)',
    '&:hover': {
      color: BLUE_QUOTATIS,
    },
    width: '100%',
  },
  activeLink: {
    color: BLUE_QUOTATIS,
  },
  acronym: {
    color: BLUE_SAV_QUOTATIS,
    fontWeight: BLACK_WEIGHT,
  },
})

export default styles
