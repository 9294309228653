import { connect } from 'react-redux'
import { getJobPicturesByOriginSelector } from 'store/jobs/jobSelectors'
import { JOB_PICTURE_ORIGINS } from 'constants/Jobs'
import JobDetailsOrderPictures from './JobDetailsOrderPictures'

const mapStateToProps = state => ({
  hoPictures: getJobPicturesByOriginSelector(state, JOB_PICTURE_ORIGINS.HO),
  proPictures: getJobPicturesByOriginSelector(state, JOB_PICTURE_ORIGINS.PRO),
})

export default connect(mapStateToProps)(JobDetailsOrderPictures)
