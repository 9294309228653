import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import {
  BLOCKED_RECEIPT,
  PENDING_MANUAL_ASSIGNMENT_STATUS,
  PENDING_RESCHEDULING_STATUS,
  PENDING_START_STATUS,
  REFUSED_SIGNATURE,
  WITH_CONTESTED_INVOICE,
} from 'constants/Jobs'
import {
  INCIDENT_NEW_STATUS,
  INCIDENT_PENDING_PAYMENT_STATUS,
  INCIDENT_PENDING_VERIFICATION_STATUS,
  INCIDENT_WAITING_FOR_CRM_MANAGER_STATUS,
  INCIDENT_WAITING_FOR_CRM_MANAGER,
  INCIDENT_NEW_EXCLUDING_WAITING_FOR_PARTNER_STATUS,
} from 'constants/incidents'
import { JOBS_SEARCH_PATH, PRO_LIST_STATUS_TEST_PATH } from 'constants/routes'
import {
  CANDIDATE_DOCUMENTS_NONE,
  CANDIDATE_DOCUMENTS_PARTIAL,
  CANDIDATE_DOCUMENTS_COMPLETE,
  CANDIDATE_VERIFIED_DOCUMENTS_YES,
  CANDIDATE_VERIFIED_DOCUMENTS_NA,
  PACKAGE_STATUS_CANDIDATE,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
} from 'constants/firms'
import {
  DASHBOARD_CARD_TYPE_CUSTOM,
  DASHBOARD_CARD_TYPE_URGENT,
  DASHBOARD_CARD_TYPE_OTHER,
  GEOZONES_DEPARTMENTS,
} from 'constants/dashboard'
import { selectedGeozoneSelector } from 'store/Application/ApplicationSelectors'
import Icon from 'components/shared/Icon/Icon'
import DashboardCardFirmContainer from './DashboardCard/DashboardCardFirmContainer'
import DashboardCardJobContainer from './DashboardCard/DashboardCardJobContainer'
import DashboardCardIncidentContainer from './DashboardCard/DashboardCardIncidentContainer'
import styles from './DashboardStyles'

const Dashboard = ({ classes, translate, selectedGeozone }) => (
  <Grid className={classes.container} container>
    <Grid item xs={12}>
      <Typography
        component="h1"
        variant="h6"
        className={classes.titleDashboardPart}
      >
        {translate('dashboard.jobs.title')}
      </Typography>
      <Grid container>
        <Grid item xs={3}>
          <DashboardCardJobContainer
            title="dashboard.jobs.pending_manual_assignment"
            prefixCategoryTranslation="dashboard.types"
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [`urgent_${PENDING_MANUAL_ASSIGNMENT_STATUS}`],
                type: DASHBOARD_CARD_TYPE_URGENT,
              },
              {
                statuses: [PENDING_MANUAL_ASSIGNMENT_STATUS],
                type: DASHBOARD_CARD_TYPE_OTHER,
              },
            ]}
            filters={[
              {
                field: 'jobStatus',
                values: PENDING_MANUAL_ASSIGNMENT_STATUS,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardJobContainer
            title="dashboard.jobs.pending_rescheduling"
            prefixCategoryTranslation="dashboard.types"
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [`urgent_${PENDING_RESCHEDULING_STATUS}`],
                type: DASHBOARD_CARD_TYPE_URGENT,
              },
            ]}
            filters={[
              {
                field: 'jobStatus',
                values: PENDING_RESCHEDULING_STATUS,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardJobContainer
            title="dashboard.jobs.pending_start"
            prefixCategoryTranslation="dashboard.types"
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [`urgent_${PENDING_START_STATUS}`],
                type: DASHBOARD_CARD_TYPE_URGENT,
              },
            ]}
            filters={[
              {
                field: 'jobStatus',
                values: PENDING_START_STATUS,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardJobContainer
            title="dashboard.jobs.blocked_receipt"
            prefixCategoryTranslation="dashboard.types"
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [BLOCKED_RECEIPT],
                type: DASHBOARD_CARD_TYPE_URGENT,
              },
            ]}
            filters={[
              {
                field: 'jobStatus',
                values: BLOCKED_RECEIPT,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>

        <Grid item xs={3}>
          <DashboardCardJobContainer
            title="dashboard.jobs.refused_signature"
            customTranslationKey={REFUSED_SIGNATURE}
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [REFUSED_SIGNATURE],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'jobStatus',
                values: REFUSED_SIGNATURE,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardJobContainer
            title="dashboard.jobs.with_contested_invoice"
            customTranslationKey={WITH_CONTESTED_INVOICE}
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [WITH_CONTESTED_INVOICE],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'jobStatus',
                values: WITH_CONTESTED_INVOICE,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Typography
        component="h1"
        variant="h6"
        className={classes.titleDashboardPart}
      >
        {translate('dashboard.incidents.title')}
      </Typography>
      <Grid container>
        <Grid item xs={3}>
          <DashboardCardIncidentContainer
            title="dashboard.incidents.new_excluding_waiting_for_partner"
            customTranslationKey="incidents"
            prefixCategoryTranslation="dashboard.types"
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [INCIDENT_NEW_EXCLUDING_WAITING_FOR_PARTNER_STATUS],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'incidentStatus',
                values: INCIDENT_NEW_STATUS,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardIncidentContainer
            title="dashboard.incidents.pending_verification"
            customTranslationKey="incidents"
            prefixCategoryTranslation="dashboard.types"
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [INCIDENT_PENDING_VERIFICATION_STATUS],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'incidentStatus',
                values: INCIDENT_PENDING_VERIFICATION_STATUS,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardIncidentContainer
            title="dashboard.incidents.waiting_for_crm_manager"
            customTranslationKey="incidents"
            prefixCategoryTranslation="dashboard.types"
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [INCIDENT_WAITING_FOR_CRM_MANAGER_STATUS],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'incidentWaitingFor',
                values: INCIDENT_WAITING_FOR_CRM_MANAGER,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardIncidentContainer
            title="dashboard.incidents.pending_payment"
            customTranslationKey="incidents"
            prefixCategoryTranslation="dashboard.types"
            path={JOBS_SEARCH_PATH}
            categories={[
              {
                statuses: [INCIDENT_PENDING_PAYMENT_STATUS],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'incidentStatus',
                values: INCIDENT_PENDING_PAYMENT_STATUS,
              },
              {
                field: 'postCodes',
                values: GEOZONES_DEPARTMENTS[selectedGeozone],
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Typography
        component="h1"
        variant="h6"
        className={classes.titleDashboardPart}
      >
        {translate('dashboard.pros.title')}
      </Typography>
      <Grid container>
        <Grid item xs={3}>
          <DashboardCardFirmContainer
            title="dashboard.candidates.signed_contract"
            customTranslationKey="pros_in_progress"
            path={PRO_LIST_STATUS_TEST_PATH}
            categories={[
              {
                statuses: [CANDIDATE_DOCUMENTS_NONE.code],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'mandatoryDocumentsStatus',
                values: [CANDIDATE_DOCUMENTS_NONE],
              },
              {
                field: 'packageStatusV2',
                values: [PACKAGE_STATUS_CANDIDATE],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardFirmContainer
            title="dashboard.candidates.uploading"
            customTranslationKey="pros_in_progress"
            path={PRO_LIST_STATUS_TEST_PATH}
            categories={[
              {
                statuses: [CANDIDATE_DOCUMENTS_PARTIAL.code],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'mandatoryDocumentsStatus',
                values: [CANDIDATE_DOCUMENTS_PARTIAL],
              },
              {
                field: 'packageStatusV2',
                values: [PACKAGE_STATUS_CANDIDATE],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardFirmContainer
            title="dashboard.candidates.to_validate"
            customTranslationKey="pros_in_progress"
            path={PRO_LIST_STATUS_TEST_PATH}
            categories={[
              {
                statuses: [CANDIDATE_DOCUMENTS_COMPLETE.code],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'mandatoryDocumentsStatus',
                values: [CANDIDATE_DOCUMENTS_COMPLETE],
              },
              {
                field: 'packageStatusV2',
                values: [PACKAGE_STATUS_CANDIDATE],
              },
              {
                field: 'verifiedDocuments',
                values: [CANDIDATE_VERIFIED_DOCUMENTS_NA],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardFirmContainer
            title="dashboard.pros.documents_validated"
            customTranslationKey="pros_in_progress"
            path={PRO_LIST_STATUS_TEST_PATH}
            categories={[
              {
                statuses: [CANDIDATE_VERIFIED_DOCUMENTS_YES.code],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'verifiedDocuments',
                values: [CANDIDATE_VERIFIED_DOCUMENTS_YES],
              },
              {
                field: 'packageStatusV2',
                values: [PACKAGE_STATUS_CANDIDATE],
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <DashboardCardFirmContainer
            title="dashboard.pros.in_progress"
            customTranslationKey="pros_in_progress"
            path={PRO_LIST_STATUS_TEST_PATH}
            categories={[
              {
                statuses: [
                  PACKAGE_STATUS_PENDING_TEST_JOB.code,
                  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB.code,
                ],
                type: DASHBOARD_CARD_TYPE_CUSTOM,
              },
            ]}
            filters={[
              {
                field: 'packageStatusV2',
                values: [
                  PACKAGE_STATUS_PENDING_TEST_JOB,
                  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
                ],
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Icon className={classes.background} icon="bg_dashboard.png" type="image" />
  </Grid>
)

Dashboard.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    titleDashboardPart: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  selectedGeozone: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])
    .isRequired,
}

const mapStateToProps = state => ({
  selectedGeozone: selectedGeozoneSelector(state),
})

export default compose(
  i18n,
  connect(mapStateToProps),
  withStyles(styles),
)(Dashboard)
