import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, touch } from 'redux-form'
import uuidv1 from 'uuid/v1'
import { updateFirmDetailsLegalInformations } from 'store/firms/firmActions'
import { selectAccessTokenDecoded } from 'store/Application/ApplicationSelectors'
import {
  legalStatusSelector,
  capitalAmountSelector,
  registrationNumberSelector,
  businessDirectorySelector,
  headquartersStreetSelector,
  headquartersCitySelector,
  headquartersPostcodeSelector,
  registrationCitySelector,
  postalCodeSelector,
  vatNumberSelector,
  swiftCodeSelector,
  internationalBankAccountNumberSelector,
  streetSelector,
  citySelector,
  postcodeSelector,
  firstNameSelector,
  lastNameSelector,
  isFirmValidatedForPackageSelector,
  firmSubjectToVatSelector,
} from 'store/firms/firmSelectors'
import {
  FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS,
  FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER,
  FIELD_LEGAL_INFORMATIONS_CAPITAL_AMOUNT,
  FIELD_LEGAL_INFORMATIONS_VAT_NUMBER,
  FIELD_LEGAL_INFORMATIONS_SWIFT_CODE,
  FIELD_LEGAL_INFORMATIONS_IBAN,
  FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS,
  FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY,
  FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE,
  FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS,
  FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY,
  FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE,
  FIELD_LEGAL_INFORMATIONS_REGISTRATION_CITY,
  FIELD_LEGAL_INFORMATIONS_POSTAL_CODE,
  FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY,
  FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_FIRST_NAME,
  FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_LAST_NAME,
  FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT,
} from 'constants/firms'
import { EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM } from 'constants/forms'
import { PERM_FIRM_READ_LEGAL } from 'constants/permissions'
import { hasPermission } from 'services/permissions'
import FirmLegalInformations from './FirmLegalInformations'

const FirmDetailsProContainer = ({
  updateCurrentFirm,
  initialValues,
  forceValidation,
  isFirmValidatedForPackage,
  registrationNumber,
}) =>
  hasPermission(PERM_FIRM_READ_LEGAL) && (
    <FirmLegalInformations
      onSubmit={values => updateCurrentFirm(values)}
      initialValues={initialValues}
      forceValidation={forceValidation}
      isFirmValidatedForPackage={isFirmValidatedForPackage}
      registrationNumber={registrationNumber}
    />
  )

FirmDetailsProContainer.propTypes = {
  updateCurrentFirm: PropTypes.func.isRequired,
  forceValidation: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  isFirmValidatedForPackage: PropTypes.bool.isRequired,
  registrationNumber: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
  initialValues: {
    [FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS]: legalStatusSelector(state),
    [FIELD_LEGAL_INFORMATIONS_CAPITAL_AMOUNT]: capitalAmountSelector(state),
    [FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER]: registrationNumberSelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY]: businessDirectorySelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS]: headquartersStreetSelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY]: headquartersCitySelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE]: headquartersPostcodeSelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS]: streetSelector(state),
    [FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY]: citySelector(state),
    [FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE]: postcodeSelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_REGISTRATION_CITY]: registrationCitySelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_POSTAL_CODE]: postalCodeSelector(state),
    [FIELD_LEGAL_INFORMATIONS_VAT_NUMBER]: vatNumberSelector(state),
    [FIELD_LEGAL_INFORMATIONS_SWIFT_CODE]: swiftCodeSelector(state),
    [FIELD_LEGAL_INFORMATIONS_IBAN]: internationalBankAccountNumberSelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_FIRST_NAME]: firstNameSelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_LAST_NAME]: lastNameSelector(
      state,
    ),
    [FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT]: firmSubjectToVatSelector(state),
  },
  isFirmValidatedForPackage: isFirmValidatedForPackageSelector(state),
  registrationNumber: registrationNumberSelector(state),
  token: selectAccessTokenDecoded(state),
})

const mapDispatchToProps = dispatch => ({
  updateCurrentFirm: values =>
    dispatch(updateFirmDetailsLegalInformations({ payload: values })),
  forceValidation: () => {
    // usefull to force the redux-form validation & the error message displaying
    dispatch(
      touch(
        EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM,
        FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS,
        FIELD_LEGAL_INFORMATIONS_CAPITAL_AMOUNT,
        FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER,
        FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY,
        FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS,
        FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY,
        FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE,
        FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS,
        FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY,
        FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE,
        FIELD_LEGAL_INFORMATIONS_REGISTRATION_CITY,
        FIELD_LEGAL_INFORMATIONS_POSTAL_CODE,
        FIELD_LEGAL_INFORMATIONS_VAT_NUMBER,
        FIELD_LEGAL_INFORMATIONS_SWIFT_CODE,
        FIELD_LEGAL_INFORMATIONS_IBAN,
        FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_FIRST_NAME,
        FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_LAST_NAME,
        FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT,
      ),
    )

    // Force change action on a field to trigger validation
    dispatch(
      change(EDIT_FIRM_DETAILS_LEGAL_INFORMATIONS_FORM, 'checkBox', uuidv1()),
    )
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FirmDetailsProContainer)
