import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Card,
  CardActions,
  Fab,
  Typography,
  withStyles,
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import {
  FieldArray,
  Field,
  reduxForm,
  destroy,
  change,
  hasSubmitSucceeded,
} from 'redux-form'
import { currentJobSelector, jobIdSelector } from 'store/jobs/jobSelectors'
import { updateJobHoDate } from 'store/jobs/jobActions'
import { NEW_TIME_SLOT_DATE_FORM } from 'constants/forms'
import {
  PENDING_RESCHEDULING_STATUS,
  PENDING_REALIZATION_STATUS,
  AFTER_SALE_ALL_TYPES,
} from 'constants/Jobs'
import {
  required,
  requiredNumber,
  dateAfterToday,
} from 'helpers/form/validationHelpers'
import i18n from 'providers/i18n/I18nProvider'
import DateInput from 'components/shared/DateInput/DateInput'
import JobNewTimeslotDialog from './JobNewTimeslotDialog'
import JobTimeslotSelect from './JobTimeslotSelect'
import styles, { CustomButton } from './JobTimeslotStyles'

const JobNewTimeslot = ({
  handleSubmit,
  translate,
  dispatch,
  formSubmitted,
  status,
  type,
}) => {
  const [addButtonClicked, setAddButtonClicked] = useState(null)

  useEffect(() => {
    dispatch(destroy(NEW_TIME_SLOT_DATE_FORM))
    setAddButtonClicked(null)
  }, [dispatch, formSubmitted])

  const handleButton = action => {
    setAddButtonClicked(action === 'add')
    dispatch(
      change(NEW_TIME_SLOT_DATE_FORM, 'addButtonClicked', action === 'add'),
    )
  }

  const isAfterSale = AFTER_SALE_ALL_TYPES.includes(type)

  const renderMembers = ({ fields }) => (
    <>
      <Card>
        <CardActions>
          {status !== PENDING_RESCHEDULING_STATUS &&
            status !== PENDING_REALIZATION_STATUS &&
            !isAfterSale && (
              <>
                <CustomButton
                  type="submit"
                  variant="contained"
                  color={addButtonClicked === null ? 'secondary' : 'info'}
                  onClick={() => {
                    handleButton('add')
                    if (fields.length === 0) {
                      fields.push({})
                    }
                  }}
                  disabled={
                    addButtonClicked !== null ? addButtonClicked : false
                  }
                >
                  {translate('job.replace_ho_date.add_date_ho_button')}
                </CustomButton>

                <Typography variant="body2">
                  {translate('job.replace_ho_date.or')}
                </Typography>
              </>
            )}

          <CustomButton
            type="submit"
            variant="contained"
            color={addButtonClicked === null ? 'secondary' : 'info'}
            onClick={() => {
              handleButton('replace')
              if (fields.length === 0) {
                fields.push({})
              }
            }}
            disabled={addButtonClicked !== null ? !addButtonClicked : false}
          >
            {translate('job.replace_ho_date.replace_date_ho_button')}
          </CustomButton>
        </CardActions>
      </Card>
      {fields.map((member, index) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <div key={index}>
          <Field
            validate={[requiredNumber, dateAfterToday]}
            name={`${member}timeslotDate`}
            type="date"
            props={{
              min: new Date(),
              id: 'timeslotDate',
              label: translate('job.replace_ho_date.date_input'),
              fullWidth: false,
            }}
            component={DateInput}
          />
          <Field
            name={`${member}timeslot`}
            validate={[required]}
            component={JobTimeslotSelect}
          />
          {index !== 0 && (
            <Fab
              color="primary"
              aria-label="delete"
              style={{
                width: '40px',
                height: '40px',
                left: '45px',
                verticalAlign: 'bottom',
              }}
              onClick={() => fields.remove(index)}
            >
              <Delete />
            </Fab>
          )}
        </div>
      ))}

      {fields.length > 0 && (
        <Card>
          <CardActions>
            <Fab
              color="primary"
              aria-label="add"
              style={{ width: '40px', height: '40px' }}
              onClick={() => fields.push({})}
            >
              <Add />
            </Fab>
            <Typography variant="body2">
              {translate('job.replace_ho_date.add_date_ho')}
            </Typography>
          </CardActions>
        </Card>
      )}

      <br />
      <JobNewTimeslotDialog />
    </>
  )

  return (
    <form onSubmit={handleSubmit}>
      <FieldArray name="members" component={renderMembers} />
      <br />
    </form>
  )
}

const mapStateToProps = state => ({
  jobId: jobIdSelector(state),
  formSubmitted: hasSubmitSucceeded(NEW_TIME_SLOT_DATE_FORM)(state),
  type: currentJobSelector(state).type,
})

JobNewTimeslot.propTypes = {
  status: PropTypes.string,
  translate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  input: PropTypes.shape({}),
  fields: PropTypes.shape({
    remove: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    map: PropTypes.func.isRequired,
  }),
  type: PropTypes.string,
}

JobNewTimeslot.defaultProps = {
  input: undefined,
  fields: undefined,
  status: null,
  type: undefined,
}

const formConfig = {
  form: NEW_TIME_SLOT_DATE_FORM,
  destroyOnUnmount: false,
  enableReinitialize: true,
  onSubmit: (values, dispatch, { jobId }) => {
    dispatch(
      updateJobHoDate.request({
        values,
        jobId,
      }),
    )
  },
}

export default compose(
  i18n,
  connect(mapStateToProps, null),
  reduxForm(formConfig),
  withStyles(styles),
)(JobNewTimeslot)
