import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import {
  selectedGeozoneSelector,
  showGeozoneSelectorSelector,
} from 'store/Application/ApplicationSelectors'
import { GEOZONES_SELECTOR_FORM } from 'constants/dashboard'
import { reduxForm } from 'redux-form'
import GeozoneSelector from './GeozoneSelector'

const mapStateToProps = state => ({
  showSelector: showGeozoneSelectorSelector(state) || false,
  initialValues: {
    geozones_list: selectedGeozoneSelector(state),
  },
})

const formConfig = {
  form: GEOZONES_SELECTOR_FORM,
}

export default compose(
  connect(mapStateToProps),
  reduxForm(formConfig),
  withRouter,
)(GeozoneSelector)
