import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { resetDisputeInvoice } from 'store/invoices/invoiceActions'
import { invoiceIdSelector } from 'store/jobs/jobSelectors'
import {
  invoiceContestTransition,
  invoiceCancelContestTransition,
} from 'store/invoices/transitions/invoiceTransitionActions'
import i18n from 'providers/i18n/I18nProvider'
import JobDetailsInvoicesContestation from './JobDetailsInvoicesContestation'

const JobDetailsInvoicesContestationContainer = ({
  translate,
  invoice,
  dispatchInvoiceContestTransition,
  dispatchInvoiceCancelContestTransition,
  dispatchResetDispute,
}) => (
  <JobDetailsInvoicesContestation
    translate={translate}
    invoice={invoice}
    dispatchInvoiceContestTransition={dispatchInvoiceContestTransition}
    dispatchInvoiceCancelContestTransition={
      dispatchInvoiceCancelContestTransition
    }
    dispatchResetDispute={dispatchResetDispute}
  />
)

JobDetailsInvoicesContestationContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  invoice: PropTypes.shape({}).isRequired,
  dispatchInvoiceContestTransition: PropTypes.func.isRequired,
  dispatchInvoiceCancelContestTransition: PropTypes.func.isRequired,
  dispatchResetDispute: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  invoiceId: invoiceIdSelector(state),
})

const mapDispatchToProps = dispatch => ({
  dispatchInvoiceContestTransition: (invoiceId, contestation) =>
    dispatch(invoiceContestTransition.request({ invoiceId, contestation })),
  dispatchInvoiceCancelContestTransition: invoiceId =>
    dispatch(invoiceCancelContestTransition.request({ invoiceId })),
  dispatchResetDispute: () => dispatch(resetDisputeInvoice()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
)(JobDetailsInvoicesContestationContainer)
