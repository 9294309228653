import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import { setBarTitle } from 'store/Application/ApplicationActions'
import { resetJobsList } from 'store/jobs/jobActions'
import DashboardContainer from 'components/Dashboard/DashboardContainer'
import { withRouter } from 'react-router-dom'

const Dashboard = ({ setTitle, resetList, translate }) => {
  useEffect(() => {
    setTitle(translate(`resources.jobs.navbar.title.dashboard`))
    resetList()
  }, [resetList, setTitle, translate])

  return <DashboardContainer />
}

Dashboard.propTypes = {
  translate: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  resetList: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
  resetList: () => dispatch(resetJobsList()),
})

export default compose(
  i18n,
  connect(null, mapDispatchToProps),
  withRouter,
)(Dashboard)
