const styles = theme => ({
  buttonSecondary: { ...theme.buttonSecondary, maxWidth: '280px' },
  multiselect: {
    minWidth: 200,
  },
  selectContainer: {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    justifyContent: 'space-between',
  },
})

export default styles
