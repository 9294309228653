import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { firmsCountersSelector } from 'store/firms/firmSelectors'
import { setFirmListFilters } from 'store/firms/firmActions'
import GenericDashboardCard from './GenericDashboardCard'

const DashboardCardFirmContainer = ({
  title,
  path,
  customTranslationKey,
  setFilters,
  filters,
  counters,
}) => (
  <GenericDashboardCard
    cardTitle={title}
    path={path}
    counter={counters}
    setFilters={setFilters}
    filters={filters}
    customTranslationKey={customTranslationKey}
  />
)

DashboardCardFirmContainer.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string,
  counters: PropTypes.shape({}),
  setFilters: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      values: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.array,
      ]).isRequired,
    }),
  ).isRequired,
  customTranslationKey: PropTypes.string,
}

DashboardCardFirmContainer.defaultProps = {
  counters: [],
  path: '#',
  customTranslationKey: 'to_be_defined',
}

const mapStateToProps = (state, { categories }) => ({
  counters: firmsCountersSelector(state, categories),
})

const mapDispatchToProps = dispatch => ({
  setFilters: filters => dispatch(setFirmListFilters({ filters })),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DashboardCardFirmContainer,
)
