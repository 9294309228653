const styles = theme => ({
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  info: {
    backgroundColor: theme.palette.info.main,
    color: 'white',
  },
  warning: {
    backgroundColor: theme.palette.grey.main,
    color: 'white',
  },
  smallSuccess: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.7125rem',
    height: '24px',
  },
})

export default styles
