import React from 'react'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

const DateInput = ({
  input,
  type,
  id,
  label,
  disabled,
  min,
  fullWidth,
  meta: { touched, error },
}) => (
  <TextField
    error={touched && !!error}
    helperText={error}
    fullWidth={fullWidth}
    required
    {...{
      ...input,
      ...{ id },
      ...{ type },
      ...{ label },
      ...{ disabled },
    }}
    inputProps={{ min }}
    InputLabelProps={{
      shrink: true,
    }}
  />
)

DateInput.propTypes = {
  input: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  min: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    touch: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
}

DateInput.defaultProps = {
  type: undefined,
  id: undefined,
  label: undefined,
  disabled: false,
  fullWidth: true,
  min: undefined,
  meta: {},
}

export default DateInput
