export const styles = () => ({
  root: {
    marginTop: '5px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  document: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  page: {
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.5)',
    '& > canvas': {
      maxWidth: '100%',
      height: 'auto !important',
    },
  },
  menu: {
    textAlign: 'center',
    position: 'fixed',
    zIndex: 1,
    background: '#CCC',
    opacity: '0.6',
    marginTop: '-45px',
  },
})
