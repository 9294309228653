/* eslint-disable react/no-did-update-set-state */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from '@material-ui/core'
import {
  HO_STATUS,
  FIELD_SELECTED_PRO_IRI_NAME,
  MANUAL_MATCHING_FORM_NAME,
  TAB_MATCHING_AUTO_RANKING,
} from 'constants/Jobs'
import { dateFormatter } from 'helpers/date'
import i18n from 'providers/i18n/I18nProvider'
import RenderTimeslotSelect from 'components/shared/Form/RenderTimeslotSelect'
import { jobAcceptTransition } from 'store/jobs/jobActions'
import { searchParamsSelector } from 'store/jobs/jobSelectors'
import { requiredNumber, dateAfterToday } from 'helpers/form/validationHelpers'
import DateInput from 'components/shared/DateInput/DateInput'
import styles from '../ManualMatchingStyles'
import MatchingTableHead from './MatchingTableHead'
import MatchingTableItems from './MatchingTableItems'

class ManualMatchingForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      rowsPerPage: 25,
    }
  }

  handleChangePage = (event, page) => {
    const { loadProList, jobId, matchingProsAlgolia } = this.props
    const { rowsPerPage } = this.state
    this.setState({ page })
    if (!matchingProsAlgolia) {
      loadProList({ page: page + 1, rowsPerPage, jobId })
    }
  }

  handleChangePerPage = event => {
    const { loadProList, jobId, matchingProsAlgolia } = this.props
    const { page } = this.state
    this.setState({ rowsPerPage: event.target.value })
    if (!matchingProsAlgolia) {
      loadProList({ page: page + 1, rowsPerPage: event.target.value, jobId })
    }
  }

  setLabelDisplayedRows = ({ from, to, count }) => {
    const { translate } = this.props
    return translate('navigation.page_range_info', {
      offsetBegin: from,
      offsetEnd: to,
      total: count,
    })
  }

  renderColumnsName = columnName => {
    const { translate, classes } = this.props

    return (
      <TableCell className={classes.cell} key={columnName}>
        {translate(columnName)}
      </TableCell>
    )
  }

  formatId = matchingProsAlgolia =>
    matchingProsAlgolia.map(item => {
      item.pro['@id'] = `/engine/pros/${item.pro.id}`
      return item
    })

  rowsInPage = () => {
    const { matchingProsAlgolia } = this.props
    const { page, rowsPerPage } = this.state

    return this.formatId(
      matchingProsAlgolia.slice(rowsPerPage * page, rowsPerPage * (page + 1)),
    )
  }

  render() {
    const {
      handleSubmit,
      total,
      totalAlgolia,
      isJobPendingAssign,
      timeslotDate,
      selectedProIri,
      invalid,
      translate,
      classes,
      tabValue,
      isAcceptedProInTest,
      matchingPros,
      matchingProsAlgolia,
      status,
      acceptedPro,
    } = this.props
    const { rowsPerPage, page } = this.state
    const isTimeslotDateDisabled = !selectedProIri || !isJobPendingAssign
    const submitBtnDisabled = isTimeslotDateDisabled || !timeslotDate || invalid
    const totalCount = isAcceptedProInTest ? total + 1 : total
    const tableItems =
      tabValue === TAB_MATCHING_AUTO_RANKING
        ? this.rowsInPage()
        : (matchingProsAlgolia ? this.rowsInPage() : matchingPros).sort(a =>
            !HO_STATUS.includes(status) && selectedProIri === a.pro['@id']
              ? -1
              : 1,
          )

    return (
      <form onSubmit={handleSubmit}>
        <div className={classes.manualMatchingForm}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell />
                <MatchingTableHead
                  tabValue={tabValue}
                  renderColumnsName={this.renderColumnsName}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              <MatchingTableItems
                matchingPros={tableItems}
                isJobPendingAssign={isJobPendingAssign}
                selectedProIri={selectedProIri}
                tabValue={tabValue}
                acceptedPro={acceptedPro}
                isAcceptedProInTest={isAcceptedProInTest}
              />
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount + totalAlgolia}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangePerPage}
            labelRowsPerPage={translate('navigation.page_rows_per_page')}
            labelDisplayedRows={this.setLabelDisplayedRows}
          />
        </div>
        {tabValue !== TAB_MATCHING_AUTO_RANKING && (
          <>
            <Field
              validate={[requiredNumber, dateAfterToday]}
              name="timeslotDate"
              type="date"
              props={{
                disabled: isTimeslotDateDisabled,
                min: dateFormatter(new Date()),
                id: 'timeslotDate',
                fullWidth: false,
                label: translate('job.manual_matching.date_input.label'),
              }}
              component={DateInput}
            />
            <Field
              name="timeslot"
              isTimeslotDateDisabled={isTimeslotDateDisabled}
              component={RenderTimeslotSelect}
              translate={translate}
            />
            <Button
              type="submit"
              disabled={submitBtnDisabled}
              variant="contained"
              className={classes.buttonBigger}
            >
              {translate('job.manual_matching.submit_btn.label')}
            </Button>
          </>
        )}
      </form>
    )
  }
}

ManualMatchingForm.propTypes = {
  jobId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loadProList: PropTypes.func,
  classes: PropTypes.shape({
    cell: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    manualMatchingForm: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
  }).isRequired,
  matchingPros: PropTypes.arrayOf(PropTypes.shape({})),
  matchingProsAlgolia: PropTypes.arrayOf(PropTypes.shape({})),
  isJobPendingAssign: PropTypes.bool,
  total: PropTypes.number,
  totalAlgolia: PropTypes.number,
  timeslotDate: PropTypes.string,
  selectedProIri: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  tabValue: PropTypes.number.isRequired,
  searchParams: PropTypes.shape({
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
  }),
  acceptedPro: PropTypes.shape({
    packageStatus: PropTypes.string,
  }),
  isAcceptedProInTest: PropTypes.bool,
}

ManualMatchingForm.defaultProps = {
  matchingPros: [],
  loadProList: () => {},
  matchingProsAlgolia: undefined,
  isJobPendingAssign: false,
  timeslotDate: undefined,
  selectedProIri: undefined,
  totalAlgolia: 0,
  total: undefined,
  searchParams: {
    page: 1,
    rowsPerPage: 25,
  },
  acceptedPro: {
    packageStatus: null,
  },
  isAcceptedProInTest: false,
}

const mapStateToProps = state => ({
  searchParams: searchParamsSelector(state),
})

export default compose(
  reduxForm({
    form: MANUAL_MATCHING_FORM_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmit: (
      { timeslotDate, selectedProIri, timeslot },
      dispatch,
      { jobId },
    ) => {
      const timeSlotRange = timeslot.split('-')

      const date = new Date(timeslotDate)
      const timezone = `+0${(date.getTimezoneOffset() / 60) * -1}:00`

      dispatch(
        jobAcceptTransition.request({
          jobId,
          data: {
            matchingPro: {
              pro: selectedProIri,
              timeslot: {
                startTime: `${timeslotDate}T${timeSlotRange[0]}${timezone}`,
                stopTime: `${timeslotDate}T${timeSlotRange[1]}${timezone}`,
              },
            },
          },
        }),
      )
    },
  }),
  connect((state, { form }) => ({
    mapStateToProps,
    selectedProIri: formValueSelector(form)(state, FIELD_SELECTED_PRO_IRI_NAME),
    timeslotDate: formValueSelector(form)(state, 'timeslotDate'),
    timeslot: formValueSelector(form)(state, 'timeSlot'),
  })),
  i18n,
  withStyles(styles),
)(ManualMatchingForm)
