export const BLUE_QUOTATIS = '#143C58'
export const ORANGE_QUOTATIS = '#FFC258'
export const BLACK_QUOTATIS = '#232323'
export const GREY_QUOTATIS = '#999999'
export const BLUE_LIGHT_QUOTATIS = '#f3f7f9'
export const GREY_LIGHT_QUOTATIS = '#F6FAFD'
export const BG_QUOTATIS = '#DAE1E7'
export const WHITE_QUOTATIS = '#FFFFFF'
export const BLUE_SAV_QUOTATIS = '#3333FE'
export const BACKGROUND_SAV_QUOTATIS = '#F2F8FF'

export const XL_SIZE = '1.4rem'
export const L_SIZE = '1.2rem'
export const M_SIZE = '0.8rem'

export const BLACK_WEIGHT = '700'
export const BOLD_WEIGHT = '500'
export const NORMAL_WEIGHT = '300'
