import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import RenderMultiSelect from 'components/shared/Form/RenderMultiSelect'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import RenderTextField from 'components/shared/Form/RenderTextField'
import DateInput from 'components/shared/DateInput/DateInput'
import i18n from 'providers/i18n/I18nProvider'
import { CERFA } from 'constants/firms'
import { FIRM_CERTIFICATE_FORM } from 'constants/forms'
import styles from './FirmCertificateSelectStyle'
import { validate } from './FirmCertificateValidation'

const FirmCertificateSelect = ({
  classes,
  translate,
  firmCertificateLoading,
  firmCertificateTypes,
  firmCertificateTypeCategories,
  setFormIsValid,
  valid,
  dirty,
  certificateType,
  addAdditionalInfo,
  readOnly,
  resetCategories,
  formOption: { document, initialValues },
}) => {
  const { typeCertificat: initialCertificateType } = initialValues
  useEffect(() => {
    if (document) {
      addAdditionalInfo({
        documentName: document.name,
        typeCertificat: initialCertificateType,
      })
    }
    setFormIsValid(valid && dirty)
  }, [
    valid,
    dirty,
    setFormIsValid,
    addAdditionalInfo,
    initialCertificateType,
    document,
  ])

  if (firmCertificateLoading) {
    return <LinearProgress />
  }

  return (
    <form>
      <div className={classes.selectContainer}>
        <Field
          id="certificate-type"
          name={FIRM_CERTIFICATE_FORM.FIELDS.TYPE}
          label={translate('resources.firms.fields.certificate.type')}
          choices={firmCertificateTypes}
          component={RenderSelectField}
          onChange={(event, newValue, previousValue, name) => {
            addAdditionalInfo({
              documentName: firmCertificateTypes.find(
                certificate => certificate.valueOption === newValue,
              ).labelOption,
              [name]: newValue,
            })
            resetCategories()
          }}
          optionValue="valueOption"
          optionText="labelOption"
          disabled={readOnly}
          allowEmpty
        />

        {firmCertificateTypeCategories.length > 0 && (
          <Field
            id="certificate-category"
            name={FIRM_CERTIFICATE_FORM.FIELDS.CATEGORIES}
            label={translate('resources.firms.fields.certificate.category')}
            options={firmCertificateTypeCategories}
            component={RenderMultiSelect}
            onChange={(event, newValue, previousValue, name) =>
              addAdditionalInfo({ [name]: newValue })
            }
            className={classes.multiselect}
            allowEmpty
          />
        )}

        <Field
          id="certificate-expiration-date"
          name={FIRM_CERTIFICATE_FORM.FIELDS.EXPIRATION_DATE}
          type="date"
          component={DateInput}
          onChange={(event, newValue, previousValue, name) =>
            addAdditionalInfo({ [name]: newValue })
          }
          label={translate('firm.contracts.expirationDate')}
          allowEmpty
        />

        {certificateType === CERFA && (
          <Field
            id="certificate-reference"
            name={FIRM_CERTIFICATE_FORM.FIELDS.REFERENCE}
            type="text"
            component={RenderTextField}
            onChange={(event, newValue, previousValue, name) =>
              addAdditionalInfo({ [name]: newValue })
            }
            label={translate('resources.firms.fields.certificate.reference')}
          />
        )}
      </div>
    </form>
  )
}

FirmCertificateSelect.propTypes = {
  addAdditionalInfo: PropTypes.func.isRequired,
  resetCategories: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    selectContainer: PropTypes.string,
    multiselect: PropTypes.string,
    input: PropTypes.string,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  firmCertificateLoading: PropTypes.bool,
  firmCertificateTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  firmCertificateTypeCategories: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  formOption: PropTypes.shape({
    document: PropTypes.shape({ name: PropTypes.string.isRequired }).isRequired,
    initialValues: PropTypes.shape({
      typeCertificat: PropTypes.string.isRequired,
    }),
  }),
  setFormIsValid: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  certificateType: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
}
FirmCertificateSelect.defaultProps = {
  firmCertificateLoading: null,
  formOption: { initialValues: { typeCertificat: undefined }, readOnly: false },
}

export default compose(
  i18n,
  withStyles(styles),
  reduxForm({
    form: FIRM_CERTIFICATE_FORM.NAME,
    validate,
  }),
)(FirmCertificateSelect)
