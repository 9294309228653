import { createSelector } from 'reselect'
import get from 'lodash/get'
import { FIRM_COMMENT_PAGE, FIRM_COMMENT_PER_PAGE } from 'constants/firms'

export const initialState = {
  status: null,
  searchResult: {
    list: [],
    totalItems: 0,
  },
  searchParams: {
    perPage: FIRM_COMMENT_PER_PAGE,
    page: FIRM_COMMENT_PAGE,
  },
}

const firmCommentSelector = state => get(state, 'firmComments')

export const firmCommentsSearchResultSelector = createSelector(
  firmCommentSelector,
  state => get(state, 'searchResult'),
)

export const firmCommentsListSelector = createSelector(
  firmCommentsSearchResultSelector,
  state => get(state, 'list'),
)

export const hasFirmCommentsSelector = createSelector(
  firmCommentsSearchResultSelector,
  state => get(state, 'list', []).length > 0,
)

export const firmCommentsSearchParamsSelector = createSelector(
  firmCommentSelector,
  state => get(state, 'searchParams'),
)
