import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { requiredNumber } from 'helpers/form/validationHelpers'
import i18n from 'providers/i18n/I18nProvider'
import { TAB_MATCHING_AUTO_RANKING } from 'constants/Jobs'
import ManualMatchingFormItem from './ManualMatchingFormItem'
import SimulatedMatchingFormItem from './SimulatedMatchingFormItem'

const MatchingTableItems = props => {
  const {
    matchingPros,
    isJobPendingAssign,
    selectedProIri,
    tabValue,
    acceptedPro,
    isAcceptedProInTest,
  } = props

  return tabValue !== TAB_MATCHING_AUTO_RANKING ? (
    <>
      {isAcceptedProInTest && (
        <ManualMatchingFormItem
          isJobPendingAssign={isJobPendingAssign}
          selectedProIri={selectedProIri}
          isTabValueZero={tabValue === 0}
          pro={acceptedPro}
          requiredNumber={requiredNumber}
        />
      )}
      {matchingPros.map(matchingPro => (
        <ManualMatchingFormItem
          key={matchingPro.pro['@id']}
          isJobPendingAssign={isJobPendingAssign}
          selectedProIri={selectedProIri}
          isTabValueZero={tabValue === 0}
          matchingPro={matchingPro}
          requiredNumber={requiredNumber}
          pro={matchingPro.pro}
          status={matchingPro.status}
        />
      ))}
    </>
  ) : (
    matchingPros.map(matchingPro => (
      <SimulatedMatchingFormItem
        key={matchingPro.pro.id}
        matchingPro={matchingPro}
        pro={matchingPro.pro}
      />
    ))
  )
}

MatchingTableItems.propTypes = {
  matchingPros: PropTypes.arrayOf(PropTypes.shape({})),
  isJobPendingAssign: PropTypes.bool,
  selectedProIri: PropTypes.string,
  tabValue: PropTypes.number.isRequired,
  acceptedPro: PropTypes.shape({
    packageStatus: PropTypes.string,
  }),
  isAcceptedProInTest: PropTypes.bool,
}

MatchingTableItems.defaultProps = {
  matchingPros: [],
  isJobPendingAssign: false,
  selectedProIri: undefined,
  acceptedPro: {
    packageStatus: null,
  },
  isAcceptedProInTest: false,
}

export default compose(i18n)(MatchingTableItems)
