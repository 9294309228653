import { connect } from 'react-redux'
import { getJobConversations } from 'store/conversations/conversationsActions'
import {
  conversationListSelector,
  conversationsLoadingSelector,
} from 'store/conversations/conversationsSelectors'
import { jobIdSelector } from 'store/jobs/jobSelectors'
import JobMessaging from './JobMessaging'

const mapStateToProps = state => ({
  jobId: jobIdSelector(state),
  conversationList: conversationListSelector(state),
  conversationsLoading: conversationsLoadingSelector(state),
})

const mapDispatchToProps = (dispatch, { jobId }) => ({
  getConversations: () => {
    dispatch(getJobConversations.request({ jobId }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(JobMessaging)
