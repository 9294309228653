import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { dateFormatter } from 'helpers/date'
import { DATE_FORMAT } from 'constants/date'
import { withStyles, Link, Typography } from '@material-ui/core'
import { formatIriToId } from 'helpers/utils/common'
import { styles } from './PackageContractStyle'

const PackageContract = ({
  contractType,
  translate,
  getFirmContractData,
  firm,
  contractSignedAt,
  classes,
}) => (
  <>
    <Link
      className={classes.contractLink}
      onClick={() =>
        getFirmContractData({
          firmId: formatIriToId(firm['@id']),
          contractType,
        })
      }
      to="/empty"
    >
      <div className={classes.packageContractTitle}>
        {`${translate(`resources.firms.fields.contract_${contractType}`)}`}
      </div>
    </Link>
    <Typography>
      {`${translate('resources.firms.fields.signature_date')}: ${dateFormatter(
        contractSignedAt,
        DATE_FORMAT,
      )}`}
    </Typography>
  </>
)

PackageContract.propTypes = {
  firm: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  classes: PropTypes.shape({
    contractLink: PropTypes.string.isRequired,
    packageContractTitle: PropTypes.string.isRequired,
  }).isRequired,
  getFirmContractData: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  contractSignedAt: PropTypes.string.isRequired,
  contractType: PropTypes.string,
}

PackageContract.defaultProps = {
  contractType: 'package',
}

export default compose(i18n, withStyles(styles))(PackageContract)
