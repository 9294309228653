const styles = () => ({
  blockStatus: {
    padding: '0.7rem 0',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginLeft: '1rem',
  },
})

export default styles
