import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Chip from 'components/shared/Chip/Chip'
import { withStyles, Typography, Button, Grid } from '@material-ui/core'
import {
  JOB_IN_PROGRESS_STATUS,
  BLOCKED_RECEIPT,
  AFTER_SALE_ALL_TYPES,
} from 'constants/Jobs'
import UploaderBtn from 'components/shared/Uploader/UploaderBtn'

import CustomPopper from '../../ValidateBills/Popper/Popper'
import ReceiptInformations from './ReceiptInformations'

import styles from './ReceiptItemStyle'

const ReceiptItem = ({
  translate,
  chipTypeReceipt,
  receiptSignatureStatus,
  isInProgressOrFinishedReceipt,
  receiptSignature,
  anchorElReceipt,
  dispatchReceiptSignature,
  onClickPopOver,
  onValidationUploaderPopIn,
  classes,
  memberWhoSigned,
  totalMember,
  isRefusedReceipt,
  isActivedReceipt,
  isExpiredReceipt,
  isDraftReceipt,
  receiptSignatureRefusalMember,
  receiptSignatureRefusalReason,
  onSuccessFileLoad,
  onClickDialogReceipt,
  jobStatus,
  currentJob,
  jobs,
  unblockReceipt,
  isSignedReceipt,
  isCreatedReceipt,
  isDownloadableReceipt,
}) => {
  useEffect(() => {
    if (!receiptSignature && isInProgressOrFinishedReceipt) {
      // Checking if the receiptSignature is generated (means that we have initiate YouSign procedure)
      dispatchReceiptSignature()
    }
  }, [
    receiptSignature,
    dispatchReceiptSignature,
    isInProgressOrFinishedReceipt,
  ])

  const handleJobUpdate = () => {
    unblockReceipt(currentJob.jobId, currentJob.reserves)
  }

  const text = () => {
    if (!jobs) {
      return false
    }

    const jobType = jobs.map(job => AFTER_SALE_ALL_TYPES.includes(job.type))
    const jobReceiptValidated = jobs.map(job => job.receiptValidated === false)

    if (jobType && jobReceiptValidated) {
      return translate(
        'resources.jobs.validateBills.receipt.removeReservesOnInitialIntervention',
      )
    }

    return translate('resources.jobs.validateBills.receipt.removeReserves')
  }

  const waitingSignature =
    isRefusedReceipt || isActivedReceipt || isExpiredReceipt || isDraftReceipt

  return (
    <Grid container spacing={1} direction="row">
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {translate('resources.jobs.fields.receipt')}
        </Typography>
        {isCreatedReceipt && (
          <Chip
            type={chipTypeReceipt}
            label={translate(
              `resources.jobs.validateBills.receipt.${receiptSignatureStatus}`,
            )}
          />
        )}
      </Grid>
      {isCreatedReceipt && (
        <Grid item xs={12}>
          {isInProgressOrFinishedReceipt && (
            <>
              {/* TODO: refacto: move ReceiptInformations => components/Document/Informations */}
              <ReceiptInformations
                totalMember={totalMember}
                memberWhoSigned={memberWhoSigned}
                translate={translate}
                classes={classes}
                receiptSignature={receiptSignature}
              />
            </>
          )}
          {isRefusedReceipt && (
            <>
              <Chip
                type={chipTypeReceipt}
                label={translate(
                  `resources.jobs.validateBills.receipt.${receiptSignatureStatus}`,
                )}
                onClick={event => onClickPopOver(event, 'anchorElReceipt')}
              />
              <CustomPopper open={!!anchorElReceipt} anchorEl={anchorElReceipt}>
                {translate(
                  `resources.jobs.validateBills.receiptRefused.${receiptSignatureRefusalMember}`,
                )}
                {receiptSignatureRefusalReason
                  ? ` "${receiptSignatureRefusalReason}"`
                  : translate(
                      'resources.jobs.validateBills.receiptRefused.noReason',
                    )}
              </CustomPopper>
            </>
          )}
        </Grid>
      )}
      <Grid item xs={12} className={classes.btnContainer}>
        {/* Bouton ajout BFT */}
        {!isSignedReceipt && (
          <UploaderBtn
            className={classes.buttonBigger}
            label={translate('resources.jobs.validateBills.receipt.upload')}
            title={translate('resources.receipt.title')}
            onSuccess={onSuccessFileLoad}
            onValidation={onValidationUploaderPopIn}
            dropzoneText={translate('resources.receipt.dropZoneText')}
            reserveLabel={translate('resources.receipt.reserveLabel')}
            checkboxNeeded
          />
        )}
        {isSignedReceipt && (
          <>
            {/* Bouton Voir BFT */}
            <Button
              className={
                waitingSignature
                  ? classes.buttonSecondary
                  : classes.buttonBigger
              }
              onClick={onClickDialogReceipt}
            >
              {waitingSignature
                ? translate('invoice.state.waiting.signature.see_receipt')
                : translate('invoice.state.see_receipt')}
            </Button>
            {jobStatus === JOB_IN_PROGRESS_STATUS && (
              <UploaderBtn
                className={classes.buttonBigger}
                title={translate('resources.receipt.title')}
                label={translate(
                  `resources.jobs.validateBills.receipt.${
                    isDownloadableReceipt ? 'reupload' : 'upload'
                  }`,
                )}
                onSuccess={onSuccessFileLoad}
                onValidation={onValidationUploaderPopIn}
                dropzoneText={translate('resources.receipt.dropZoneText')}
                reserveLabel={translate('resources.receipt.reserveLabel')}
                checkboxNeeded
              />
            )}

            {currentJob.substatus === BLOCKED_RECEIPT && (
              <Button
                className={classes.buttonBigger}
                onClick={handleJobUpdate}
              >
                {text()}
              </Button>
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

ReceiptItem.propTypes = {
  classes: PropTypes.shape({
    signatureDetail: PropTypes.string,
    textCenter: PropTypes.string,
    primary: PropTypes.string,
    error: PropTypes.string,
    buttonSecondary: PropTypes.string,
    title: PropTypes.string,
    uploadBtn: PropTypes.string,
    btnContainer: PropTypes.string,
    buttonBigger: PropTypes.string,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  chipTypeReceipt: PropTypes.string.isRequired,
  receiptSignatureStatus: PropTypes.string,
  isInProgressOrFinishedReceipt: PropTypes.bool.isRequired,
  receiptSignature: PropTypes.shape({
    createdAt: PropTypes.string,
    members: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  anchorElReceipt: PropTypes.shape({}),
  dispatchReceiptSignature: PropTypes.func.isRequired,
  onClickPopOver: PropTypes.func.isRequired,
  onValidationUploaderPopIn: PropTypes.func.isRequired,
  onSuccessFileLoad: PropTypes.func.isRequired,
  memberWhoSigned: PropTypes.number.isRequired,
  totalMember: PropTypes.number.isRequired,
  isRefusedReceipt: PropTypes.bool.isRequired,
  isActivedReceipt: PropTypes.bool.isRequired,
  isExpiredReceipt: PropTypes.bool.isRequired,
  isDraftReceipt: PropTypes.bool.isRequired,
  receiptSignatureRefusalMember: PropTypes.string,
  receiptSignatureRefusalReason: PropTypes.string,
  isSignedReceipt: PropTypes.bool.isRequired,
  isCreatedReceipt: PropTypes.bool.isRequired,
  isDownloadableReceipt: PropTypes.bool.isRequired,
  onClickDialogReceipt: PropTypes.func.isRequired,
  jobStatus: PropTypes.string.isRequired,
  currentJob: PropTypes.shape({
    receiptSignatureStatus: PropTypes.string,
    jobId: PropTypes.string,
    reserves: PropTypes.string,
    substatus: PropTypes.string,
  }).isRequired,
  unblockReceipt: PropTypes.func.isRequired,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func,
    }),
  ),
}

ReceiptItem.defaultProps = {
  receiptSignatureStatus: null,
  receiptSignatureRefusalMember: null,
  receiptSignatureRefusalReason: null,
  receiptSignature: undefined,
  anchorElReceipt: null,
  jobs: undefined,
}
export default withStyles(styles)(ReceiptItem)
