import { head } from 'lodash'

/**
 * Apply a list of rules on form's fields and returns fields errors
 *
 * @param fieldsWithRules : object like {fieldName1: [rule1, rule2, ...], fieldName2: [rule2, rule4, ...]}
 * @param values          : values of the redux form
 * @param props           : parent's component props
 * @returns {{}|{[p: string]: *}}
 *
 */
export const applyValidationRules = (fieldsWithRules, values, props) =>
  Object.keys(fieldsWithRules).reduce((acc, field) => {
    const rules = fieldsWithRules[field]

    return {
      ...acc,
      [field]: head(
        rules
          .map(rule => rule(values[field], values, props))
          .filter(element => element !== undefined),
      ),
    }
  }, {})
