import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { initializeFeatureFlags } from 'feature-flag-consumer-js-lib'
import * as Sentry from '@sentry/react'

import { loadApplication } from 'store/Application/ApplicationActions'
import client from 'services/httpClient/frontApiClient'
import { CONSUMER_BACKOFFICE } from 'constants/features'

import configureStore from './store/store'
import sagas from './store/sagas'
import App from './app'
import * as serviceWorker from './serviceWorker'
import styleTheme from './theme/default'
import { env, sentry } from './config'

const theme = createTheme(styleTheme)

const store = configureStore()
store.runSaga(sagas)

Sentry.init({
  dsn: sentry.url,
  environment: process.env.NODE_ENV === 'development' ? 'development' : env,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: sentry.tracesSampleRate,
  replaysSessionSampleRate: sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
})

const initializeApplication = async () => {
  try {
    await initializeFeatureFlags(store, CONSUMER_BACKOFFICE, client)
  } catch (e) {
    console.error(e)
  }
  store.dispatch(loadApplication())
  const root = createRoot(document.getElementById('root'))

  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>,
  )
}

initializeApplication()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
