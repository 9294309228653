import { connect } from 'react-redux'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import CerfaForm from './CerfaForm'

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
)(CerfaForm)
