import { createSelector } from 'reselect'
import get from 'lodash/get'

export const initialState = {
  status: null,
  list: [],
}

const reserveTypesSelector = state => get(state, 'reserves')

export const reserveTypesListSelector = createSelector(
  reserveTypesSelector,
  state => get(state, 'list', []),
)

export const hasReserveTypesSelector = createSelector(
  reserveTypesSelector,
  state => get(state, 'list', []).length > 0,
)
