import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  CircularProgress,
  withStyles,
} from '@material-ui/core'
import { Field } from 'redux-form'
import config from 'config'

import RenderTextField from 'components/shared/Form/RenderTextField'
import { email, required } from 'helpers/form/validationHelpers'

import styles from './LoginStyles'

const Login = ({
  handleSubmit,
  invalid,
  authLinkEmail,
  isAuthLoginLoading,
  classes,
  translate,
}) => {
  const imageSrc = authLinkEmail
    ? `${config.cloudinary.url}/vitrine/illustration/partner_email_sent`
    : `${config.cloudinary.url}/vitrine/illustration/partner_login`

  const title = authLinkEmail
    ? translate('login.verif_email')
    : translate('login.welcome')

  const text = authLinkEmail
    ? translate('login.send_email', { authLinkEmail })
    : translate('login.description')

  if (isAuthLoginLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress size={60} />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
        <img src={imageSrc} alt="Illustration" className={classes.image} />
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        {!authLinkEmail && (
          <>
            <Field
              className={classes.textField}
              id="email"
              name="userEmail"
              type="text"
              label={translate('login.textfield_label')}
              placeholder={translate('login.textfield_placeholder')}
              variant="outlined"
              validate={[required, email]}
              autoFocus
              component={RenderTextField}
            />
            <Button
              type="submit"
              className={classes.button}
              variant="contained"
              color="primary"
              size="large"
              disabled={invalid}
            >
              {translate('login.button_label')}
            </Button>
          </>
        )}
      </div>
    </form>
  )
}

Login.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  authLinkEmail: PropTypes.string,
  isAuthLoginLoading: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
    root: PropTypes.string,
    textField: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string,
    loaderContainer: PropTypes.string,
  }).isRequired,
}

Login.defaultProps = {
  authLinkEmail: null,
  isAuthLoginLoading: false,
}

export default withStyles(styles)(Login)
