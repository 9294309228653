export default theme => ({
  container: {
    paddingTop: 'O',
    paddingBottom: '4rem',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    margin: '1rem',
    justifyContent: 'end',
    width: '80vw',
  },
  table: {
    margin: '1rem',
  },
  icon: {
    height: '12px',
    width: '12px',
    color: theme.palette.primary.main,
    backgroundSize: 'contain',
    justifyContent: 'end',
  },
  iconPlus: {
    padding: '5px 0 0 6px',
    display: 'flex',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
  },
  grid: {
    margin: 'auto',
    marginBottom: '12rem',
    padding: '1rem',
  },
  gridButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    width: '80vw',
  },
  validationButton: {
    justifyContent: 'flex-end',
    '&:hover': {
      color: theme.palette.white.main,
    },
  },
})
