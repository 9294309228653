import { connect } from 'react-redux'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { withStyles } from '@material-ui/core'
import {
  receiptSignatureStatusSelector,
  receiptSignatureRefusalMemberSelector,
  receiptSignatureRefusalReasonSelector,
  receiptSignatureSelector,
  chipTypeReceiptSelector,
  isInProgressOrFinishedReceiptSelector,
  isRefusedReceiptSelector,
  isManualReceiptSelector,
  isActivedReceiptSelector,
  isExpiredReceiptSelector,
  isDraftReceiptSelector,
  membersWhoSignedSelector,
  totalMemberSelector,
  statusSelector,
  currentJobSelector,
  isSignedOrWaitingSignatureSelector,
  isCreatedReceiptSelector,
  isDownloadableReceiptSelector,
} from 'store/jobs/jobSelectors'
import { unblockReceipt } from 'store/jobs/jobActions'
import { fileLoadSuccess } from 'store/upload/uploadActions'
import { worksiteJobsSelector } from 'store/worksites/worksiteSelectors'
import ReceiptItem from './ReceiptItem'
import styles from './ReceiptItemStyle'

const mapStateToProps = (state, ownProps) => ({
  isReceiptManual: isManualReceiptSelector(state),
  chipTypeReceipt: chipTypeReceiptSelector(state),
  receiptSignatureStatus: receiptSignatureStatusSelector(state),
  isInProgressOrFinishedReceipt: isInProgressOrFinishedReceiptSelector(state),
  receiptSignature: receiptSignatureSelector(state),
  receiptSignatureRefusalMember: receiptSignatureRefusalMemberSelector(state),
  receiptSignatureRefusalReason: receiptSignatureRefusalReasonSelector(state),
  isRefusedReceipt: isRefusedReceiptSelector(state),
  isActivedReceipt: isActivedReceiptSelector(state),
  isExpiredReceipt: isExpiredReceiptSelector(state),
  isDraftReceipt: isDraftReceiptSelector(state),
  isSignedReceipt: isSignedOrWaitingSignatureSelector(state),
  isCreatedReceipt: isCreatedReceiptSelector(state),
  isDownloadableReceipt: isDownloadableReceiptSelector(state),
  memberWhoSigned: membersWhoSignedSelector(state),
  totalMember: totalMemberSelector(state),
  jobStatus: statusSelector(state),
  currentJob: currentJobSelector(state),
  jobs: worksiteJobsSelector(state),
  ...ownProps,
})

const mapDispatchToProps = dispatch => ({
  onSuccessFileLoad: fileName => dispatch(fileLoadSuccess(fileName)),
  unblockReceipt: (jobId, reserves) =>
    dispatch(unblockReceipt(jobId, reserves)),
})
export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ReceiptItem)
