import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core'
import { compose } from 'redux'

import styles from './BulleStyles'
/**
 * Component displaying an info bulle
 * @param {bool} isVisible
 * @param {string} content
 * @param {object} classes
 */
const Bulle = ({ isVisible, content, classes }) =>
  isVisible && (
    <div className={classes.container}>
      <div
        className={classnames(classes.content, classes.round, classes.arrow)}
      >
        <div className={classes.text}>{content}</div>
      </div>
    </div>
  )

Bulle.propTypes = {
  isVisible: PropTypes.bool,
  content: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    talkBubble: PropTypes.string,
    arrow: PropTypes.string,
    container: PropTypes.string,
    text: PropTypes.string,
    content: PropTypes.string,
    triRight: PropTypes.string,
    round: PropTypes.string,
  }).isRequired,
}

Bulle.defaultProps = {
  isVisible: false,
}

export default compose(withStyles(styles))(Bulle)
