import connect from 'react-redux/es/connect/connect'
import {
  createInvoiceAfterSale,
  doDisplayPurchaseInvoiceCreationDialog,
  generateInvoiceAfterSale,
} from 'store/invoices/invoiceActions'
import { compose } from 'redux'
import {
  additionalAmountOptionsSelector,
  additionalInvoiceNaturesInLineSelector,
  additionalInvoiceTypesInLineTypesSelector,
  additionalProvidersInLineSelector,
  additionalRebillingCustomersInLineSelector,
} from 'store/invoices/invoiceSelectors'
import { selectIdTokenDecoded } from 'store/Application/ApplicationSelectors'
import CreatePurchaseInvoiceForm from './CreatePurchaseInvoiceForm'

const mapStateToProps = state => ({
  invoiceNaturesOptions: additionalInvoiceNaturesInLineSelector(state),
  invoiceLineTypesOptions: additionalInvoiceTypesInLineTypesSelector(state),
  providersOptions: additionalProvidersInLineSelector(state),
  amountsOptions: additionalAmountOptionsSelector(state),
  rebillingCustomersOptions: additionalRebillingCustomersInLineSelector(state),
  idToken: selectIdTokenDecoded(state),
})

const mapDispatchToProps = dispatch => ({
  hidePurchaseInvoiceCreationDialog: () =>
    dispatch(
      doDisplayPurchaseInvoiceCreationDialog({
        payload: { displayed: false },
      }),
    ),
  dispatchCreateInvoiceRequest: data =>
    dispatch(createInvoiceAfterSale.request({ data })),
  dispatchGenerateInvoiceAfterSale: () =>
    dispatch(generateInvoiceAfterSale.request()),
})
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreatePurchaseInvoiceForm,
)
