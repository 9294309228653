import React from 'react'
import PropTypes from 'prop-types'
import uuidv1 from 'uuid/v1'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'redux'
import classNames from 'classnames'
import Icon from 'components/shared/Icon/Icon'
import styles from './SelectFieldWithIconStyles'

const SelectFieldWithIcon = ({
  id,
  inputLabel,
  input,
  choices,
  meta: { touched, error },
  classes,
}) => (
  <FormControl error={touched && error !== undefined} fullWidth>
    {inputLabel && (
      <InputLabel htmlFor={`select-${uuidv1()}`}>{inputLabel}</InputLabel>
    )}
    <Select
      {...input}
      inputProps={{ name: input.name, id }}
      autoWidth
      classes={{ selectMenu: classes.selectMenu }}
    >
      {choices.map(({ value, label, icon }) => (
        <MenuItem
          key={uuidv1()}
          value={value}
          classes={{ root: classes.menuItem }}
        >
          <span className={classNames({ [classes.label]: !!icon })}>
            {label}
          </span>
          {icon && (
            <Icon classes={{ root: classes.icon }} icon={`${icon}.svg`} />
          )}
        </MenuItem>
      ))}
    </Select>
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)
SelectFieldWithIcon.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  id: PropTypes.string,
  classes: PropTypes.shape({
    selectMenu: PropTypes.string,
    menuItem: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.string,
    }),
  ).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    touch: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
  inputLabel: PropTypes.string,
}

SelectFieldWithIcon.defaultProps = {
  inputLabel: null,
  meta: {},
  id: `select-${uuidv1()}`,
}

export default compose(withStyles(styles))(SelectFieldWithIcon)
