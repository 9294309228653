import {
  GET_CONVERSATIONS_REQ,
  GET_CONVERSATION_MESSAGES_REQ,
} from './conversationsActions'
import { initialState } from './conversationsSelectors'

export default (state = initialState, action) => {
  let [id, conversation] = [null, null]
  if (action.type === GET_CONVERSATION_MESSAGES_REQ.SUCCESS) {
    ;[, id] = action.payload['@id'].split('/').reverse()
    ;[conversation] = state.conversationList.filter(conv => id === conv.id)
  }
  switch (action.type) {
    case GET_CONVERSATIONS_REQ.REQUEST:
      return {
        ...state,
        conversationsLoading: true,
      }
    case GET_CONVERSATIONS_REQ.SUCCESS:
      return {
        ...state,
        conversationList: action.payload['hydra:member'],
        conversationsLoading: false,
      }
    case GET_CONVERSATIONS_REQ.FAILURE:
      return {
        ...state,
        conversationsLoading: false,
      }
    case GET_CONVERSATION_MESSAGES_REQ.REQUEST:
      return {
        ...state,
        currentConversation: {
          ...initialState.currentConversation,
          messagesLoading: true,
        },
      }
    case GET_CONVERSATION_MESSAGES_REQ.SUCCESS:
      return {
        ...state,
        currentConversation: {
          ...state.currentConversation,
          id,
          proName: `${conversation.proLastName.toUpperCase()} ${
            conversation.proFirstName
          }`,
          messages: action.payload['hydra:member'],
          messagesLoading: false,
        },
      }
    case GET_CONVERSATION_MESSAGES_REQ.FAILURE:
      return {
        ...state,
        currentConversation: {
          ...initialState.currentConversation,
          messagesLoading: false,
        },
      }
    default: {
      return state
    }
  }
}
