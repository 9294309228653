import { createSelector } from 'reselect'
import { get } from 'lodash'

export const initialState = {
  file: null,
  isLoading: false,
}

export const certificateFileSelector = state =>
  get(state, 'firmCertificateFile')

export const firmCertificateFileSelector = createSelector(
  certificateFileSelector,
  state => get(state, 'file'),
)

export const firmCertificateFileLoadingSelector = createSelector(
  certificateFileSelector,
  state => get(state, 'isLoading'),
)
