import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { compose } from 'redux'
import { departmentsListByZoneSelector } from 'store/departments/departmentSelectors'
import { PackageActions } from 'store/products/packageActions'
import { PackageSelector } from 'store/products/packageSelector'
import { tradesListSelector } from 'store/trades/tradeSelectors'
import { getFormValuesFromFilterFirmsListForm } from 'store/firms/firmSelectors'
import { getDepartmentsList } from 'store/departments/departmentActions'
import { getTradesList } from 'store/trades/tradeActions'
import { resetFilterFirm } from 'store/firms/firmActions'
import {
  CANDIDATE_VERIFIED_DOCUMENTS_STATUS,
  FIRM_CONTRACT_OPTIONS,
  FIRM_MANDATORY_DOC_STATUS,
  FIRM_ONBOARDINGSCORE,
  FIRM_ORIGINS,
  PACKAGES_SUBSCRIPTION_WORDING,
  FIRM_USER_STATUS_SUSPENDED_REASONS_FILTER,
} from 'constants/firms'
import { FILTER_FIRMS_LIST_FORM } from 'constants/forms'
import FirmsFilters from './FirmsFilters'

class FirmsFiltersContainer extends PureComponent {
  componentDidMount() {
    const { getDepartments, getPackages, getTrades } = this.props

    getDepartments()
    getPackages()
    getTrades()
  }

  render() {
    const {
      contractOptions,
      trades,
      departments,
      packages,
      origins,
      onboardingScores,
      verifiedDocuments,
      mandatoryDocumentsStatus,
      initialValues,
      handleSearchChange,
      handleFiltersChange,
      handleReset,
      showAllFilters,
    } = this.props

    return (
      <FirmsFilters
        contractOptions={contractOptions}
        trades={trades}
        departments={departments}
        packages={packages}
        origins={origins}
        onboardingScores={onboardingScores}
        verifiedDocuments={verifiedDocuments}
        mandatoryDocumentsStatus={mandatoryDocumentsStatus}
        initialValues={initialValues}
        packagesSubscription={PACKAGES_SUBSCRIPTION_WORDING}
        packagesSubscriptionName="packageStatusV2"
        handleSearchChange={handleSearchChange}
        handleFiltersChange={handleFiltersChange}
        handleReset={handleReset}
        showAllFilters={showAllFilters}
        firmUserStatusSuspendedReasons={
          FIRM_USER_STATUS_SUSPENDED_REASONS_FILTER
        }
      />
    )
  }
}

FirmsFiltersContainer.propTypes = {
  contractOptions: PropTypes.arrayOf(PropTypes.shape({})),
  departments: PropTypes.arrayOf({}).isRequired,
  origins: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onboardingScores: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  verifiedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  mandatoryDocumentsStatus: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  packages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  trades: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  getDepartments: PropTypes.func.isRequired,
  getPackages: PropTypes.func.isRequired,
  getTrades: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  showAllFilters: PropTypes.bool,
}

FirmsFiltersContainer.defaultProps = {
  contractOptions: FIRM_CONTRACT_OPTIONS,
  origins: FIRM_ORIGINS,
  onboardingScores: FIRM_ONBOARDINGSCORE,
  verifiedDocuments: CANDIDATE_VERIFIED_DOCUMENTS_STATUS,
  mandatoryDocumentsStatus: FIRM_MANDATORY_DOC_STATUS,
  showAllFilters: true,
}

const mapStateToProps = state => ({
  initialValues: getFormValuesFromFilterFirmsListForm(state),
  departments: departmentsListByZoneSelector(state),
  packages: PackageSelector.typeListSelector()(state),
  trades: tradesListSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getDepartments: () => dispatch(getDepartmentsList.request()),
  getPackages: () => dispatch(PackageActions.getTypeList().request()),
  getTrades: () => dispatch(getTradesList.request()),
  handleReset: () => dispatch(resetFilterFirm()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FILTER_FIRMS_LIST_FORM,
    destroyOnUnmount: false,
  }),
)(FirmsFiltersContainer)
