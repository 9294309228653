import { GET_RESERVE_TYPES_REQ } from './reservesActions'
import { initialState } from './reservesSelectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RESERVE_TYPES_REQ.SUCCESS:
      return {
        ...state,
        list: action.payload,
      }
    default: {
      return state
    }
  }
}
