import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  withStyles,
} from '@material-ui/core'
import GenerateAfterSaleInvoicesContainer from 'components/Jobs/JobDetails/JobDetailsInvoices/GenerateAfterSaleInvoices/GenerateAfterSaleInvoicesContainer'
import Icon from 'components/shared/Icon/Icon'
import styles from './CreatePurchaseInvoiceFormStyles'

const CreatePurchaseInvoiceForm = ({
  classes,
  translate,
  hidePurchaseInvoiceCreationDialog,
  invoiceNaturesOptions,
  invoiceLineTypesOptions,
  providersOptions,
  rebillingCustomersOptions,
  dispatchCreateInvoiceRequest,
  dispatchGenerateInvoiceAfterSale,
}) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const [lines, setLines] = useState([])
  const [providerInData, setProviderInData] = useState('')
  const [invoiceTypesInData, setInvoiceTypesInData] = useState('')
  const [natureOptionsInData, setNatureOptionsInData] = useState('')
  const [
    rebillingCustomerOptionsInData,
    setRebillingCustomerOptionsInData,
  ] = useState('')

  const addLine = line => {
    setLines([...lines, line])
  }

  const removeLine = index => {
    setLines(lines.filter((_, lineIndex) => lineIndex !== index))
  }

  useEffect(() => {
    dispatchGenerateInvoiceAfterSale()
  }, [dispatchGenerateInvoiceAfterSale])

  useEffect(() => {
    lines.map(line => {
      setProviderInData(providersOptions[line.provider])
      setInvoiceTypesInData(invoiceLineTypesOptions[line.invoiceLineTypes])
      setNatureOptionsInData(invoiceNaturesOptions[line.invoiceNatures])
      setRebillingCustomerOptionsInData(
        rebillingCustomersOptions?.[line?.rebillingCustomer] ||
          translate('afterSale.invoices.select.label_rebilling_na'),
      )
      return { providerInData, invoiceTypesInData, natureOptionsInData }
    })
  }, [
    lines,
    providerInData,
    invoiceTypesInData,
    natureOptionsInData,
    providersOptions,
    invoiceLineTypesOptions,
    invoiceNaturesOptions,
    rebillingCustomersOptions,
    translate,
  ])

  return (
    <Dialog open onClose={hidePurchaseInvoiceCreationDialog} maxWidth="xl">
      <Grid container className={classes.container}>
        <Button onClick={hidePurchaseInvoiceCreationDialog}>
          <Icon icon="close.svg" className={classes.icon} />
        </Button>

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                {translate('afterSale.invoices.select.label_reasons')}
              </TableCell>
              <TableCell>
                {translate(
                  'afterSale.invoices.select.label_benefit_to_be_paid',
                )}
              </TableCell>
              <TableCell>
                {translate('afterSale.invoices.select.label_paid_at')}
              </TableCell>
              <TableCell>
                {translate('afterSale.invoices.select.label_amount')}
              </TableCell>
              <TableCell>
                {translate('afterSale.invoices.select.label_rebilling_at')}
              </TableCell>
              <TableCell>
                {translate('afterSale.invoices.select.label_delete')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lines.map(({ invoiceId, amount }, index) => (
              <TableRow key={invoiceId}>
                <TableCell>{natureOptionsInData}</TableCell>
                <TableCell>{invoiceTypesInData}</TableCell>
                <TableCell>{providerInData}</TableCell>
                <TableCell>{amount ? `${amount} €` : ''}</TableCell>
                <TableCell>{rebillingCustomerOptionsInData}</TableCell>
                <TableCell>
                  <IconButton onClick={() => removeLine(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Grid item className={classes.grid}>
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={() => setDialogOpened(true)}
            className={classes.button}
            disabled={lines.length > 0}
          >
            {translate('afterSale.invoices.add.line')}
            <Icon icon="plus-circle-fill.svg" className={classes.iconPlus} />
            {dialogOpened && (
              <GenerateAfterSaleInvoicesContainer
                setDialogOpened={setDialogOpened}
                addLine={addLine}
              />
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.gridButton}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            dispatchCreateInvoiceRequest(lines)
            hidePurchaseInvoiceCreationDialog()
          }}
          className={classes.validationButton}
          disabled={lines.length === 0}
        >
          {translate('afterSale.invoices.button.label')}
        </Button>
      </Grid>
    </Dialog>
  )
}

CreatePurchaseInvoiceForm.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconPlus: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    grid: PropTypes.string.isRequired,
    gridButton: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
    validationButton: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  hidePurchaseInvoiceCreationDialog: PropTypes.func.isRequired,
  dispatchCreateInvoiceRequest: PropTypes.func.isRequired,
  invoiceNaturesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  invoiceLineTypesOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  providersOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rebillingCustomersOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatchGenerateInvoiceAfterSale: PropTypes.func.isRequired,
}

export default compose(withStyles(styles), i18n)(CreatePurchaseInvoiceForm)
