import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { FILTER_INVOICES_LIST_FORM } from 'constants/forms'
import { reduxForm, Field } from 'redux-form'
import { Button, FormControl, Grid, withStyles } from '@material-ui/core'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectMultipleAutocompleteField from 'components/shared/Form/RenderSelectMultipleAutocompleteField'
import Icon from 'components/shared/Icon/Icon'
import styles from './InvoicesFiltersStyles'

const InvoicesFilters = ({
  classes,
  translate,
  handleSearchChange,
  handleFiltersChange,
  handleExportClick,
  invoiceStates,
}) => (
  <Grid container className={classes.searchBar}>
    <Grid className={classes.fullWidth}>
      <FormControl className={classes.formControl}>
        <Field
          name="query"
          type="search"
          component={RenderTextField}
          label={translate('invoice.filter.search.label')}
          onChange={handleSearchChange}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Field
          className={classes.selectEmpty}
          name="state"
          id="stateAutocompleteSelect"
          component={RenderSelectMultipleAutocompleteField}
          onChange={values => handleFiltersChange('state', values)}
          label={translate('invoice.status.label')}
          choices={invoiceStates}
          optionValue="code"
        />
      </FormControl>
      <Grid item className={classes.blocButtonExport}>
        <Button
          onClick={handleExportClick}
          variant="contained"
          color="default"
          className={classes.buttonExport}
        >
          <Icon className={classes.imgDownload} icon="download.svg" />
          {translate('invoice.buttons.export')}
        </Button>
      </Grid>
    </Grid>
  </Grid>
)

InvoicesFilters.propTypes = {
  classes: PropTypes.shape({
    searchBar: PropTypes.string.isRequired,
    fullWidth: PropTypes.string.isRequired,
    formControl: PropTypes.string.isRequired,
    selectEmpty: PropTypes.string.isRequired,
    blocButtonExport: PropTypes.string.isRequired,
    buttonExport: PropTypes.string.isRequired,
    imgDownload: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
  handleExportClick: PropTypes.func.isRequired,
  invoiceStates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default compose(
  i18n,
  reduxForm({
    form: FILTER_INVOICES_LIST_FORM,
  }),
  withStyles(styles),
)(InvoicesFilters)
