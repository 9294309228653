import algoliasearch from 'algoliasearch'
import config from 'config'
import { getCurrentLocale } from 'constants/locales'
import AlgoliaMock from './algolia/mocks/algoliaMock'

const client = config.mocking
  ? new AlgoliaMock(`${config.algolia.url}`)
  : algoliasearch(config.algolia.applicationId, config.algolia.apiKey)

/**
 * @param  {string} indexName
 * @param  {Object} [searchParams={}]
 * @return {Object}
 */
const search = (indexName, searchParams = {}) => {
  const index = client.initIndex(indexName)

  return new Promise((resolve, reject) => {
    index.search(searchParams, (err, content) => {
      if (err) return reject(err)

      return resolve(content)
    })
  })
}

/**
 * @param  {string} indexName
 * @param  {string} facetName
 * @param  {string} facetQuery
 * @param  {Object} [searchParams={}]
 * @return {Object}
 */
const searchForFacetValues = (
  indexName,
  facetName,
  facetQuery,
  searchParams = {},
) => {
  const index = client.initIndex(indexName)

  return new Promise((resolve, reject) => {
    index.searchForFacetValues(
      {
        facetName,
        facetQuery,
        ...searchParams,
      },
      (err, content) => {
        if (err) return reject(err)

        return resolve(content)
      },
    )
  })
}

export const searchFirms = async options => {
  const { orderBy, sort, ...searchParams } = options
  let indexName = `${config.algolia.indexPrefix}_firms`
  if (orderBy && sort) {
    indexName += `_${orderBy}_${sort}`
  }
  return search(indexName, searchParams)
}

export const searchFirmsByPackageStatusFacets = () =>
  searchForFacetValues(
    `${config.algolia.indexPrefix}_firms`,
    'packageStatusV2',
    '*',
    true,
  )

export const searchFirmsByMandatoryDocumentsStatusStatusFacets = () =>
  searchForFacetValues(
    `${config.algolia.indexPrefix}_firms`,
    'mandatoryDocumentsStatus',
    '*',
    {
      filters: 'packageStatusV2:candidate AND verifiedDocuments:n_a',
    },
  )

export const searchFirmsByDocumentsVerifiedStatusStatusFacets = () =>
  searchForFacetValues(
    `${config.algolia.indexPrefix}_firms`,
    'verifiedDocuments',
    '*',
    {
      filters: 'packageStatusV2:candidate',
    },
  )

const commonSearchProductFacets = type =>
  searchForFacetValues(
    `${config.algolia.indexPrefix}_${type}`,
    'categories',
    '*',
  )

export const searchPackageFacets = () =>
  commonSearchProductFacets(`products_${getCurrentLocale()}`)

export const searchProduct = (type = 'packages', options = {}) =>
  search(`${config.algolia.indexPrefix}_products_${getCurrentLocale()}`, {
    ...options,
    facetFilters: [[`product_type:service_${type}`]],
  })

export const searchPackages = options => searchProduct('packages', options)
