import documentsApiClient from 'services/httpClient/documentsApiClient'
import proApiClient from 'services/httpClient/proApiClient'
import { handleRequest } from 'helpers/store/sagasHelpers'
import translate from 'providers/i18n/translateService'
import { ERROR, INFO, SUCCESS } from 'constants/variant'
import { EMPTY_PATH } from 'constants/routes'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { change, getFormValues } from 'redux-form'

import { DOCUMENT_FORM } from 'constants/forms'
import {
  uploadTmpFile,
  UPLOAD_TMP_FILE,
  SAVE_UPLOAD_FILE,
  saveUploadFile,
  OPEN_FILE,
  getFile,
  GET_FILE,
  DELETE_DOCUMENT,
  deleteDocument,
} from 'store/proDocuments/proDocumentActions'
import { showNotification } from 'store/Application/ApplicationActions'
import {
  currentFirmIdSelector,
  currentFirmTypeSelector,
} from 'store/firms/firmSelectors'
import { firmGetSingleFile, getSingleFirm } from 'store/firms/firmActions'

const uploadTmpFileRequest = function*({ data }) {
  if (Array.from(data.files).length === 0) {
    return
  }

  data.files.append('documentsType', JSON.stringify(data.documentTypes))

  yield* handleRequest({
    requestActions: uploadTmpFile,
    promise: call(documentsApiClient.post, '/upload', data.files),
    actionParams: {
      formPart: data.formPart,
    },
  })
}

const uploadTmpFileFailure = function*() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(
          'resources.firms.fields.documents_uploadTmpFile_Failure',
        ),
      },
    }),
  )
}

const saveFileIdToReduxForm = function*({ payload, actionParams }) {
  const formData = yield select(
    getFormValues(`${DOCUMENT_FORM}${actionParams.formPart}`),
  )

  const data = payload.reduce((accumulator, single) => {
    const name = Object.keys(single)[0]
    const id = Object.values(single)[0]

    return [...accumulator, { name, id }]
  }, [])

  const newValue =
    formData && formData[`${actionParams.formPart}Files`]
      ? [...data, ...formData[`${actionParams.formPart}Files`]]
      : [...data]

  yield put(
    change(
      `${DOCUMENT_FORM}${actionParams.formPart}`,
      `${actionParams.formPart}Files`,
      newValue,
    ),
  )
}

const saveUploadFileRequest = function*({ payload }) {
  const firmId = yield select(currentFirmIdSelector)
  let savedFiles = yield select(currentFirmTypeSelector, payload.formPart)

  if (!payload.expirationDate) {
    return yield put(
      showNotification({
        payload: {
          message: translate(
            'resources.firms.fields.documents_saveUploadFile_Error_NoDate',
          ),
          messageType: ERROR,
        },
      }),
    )
  }

  if (!payload.values && !savedFiles) {
    return false
  }

  savedFiles = (savedFiles || []).map(savedFile => ({
    fileId: savedFile.id,
    expirationDate: payload.expirationDate,
  }))

  const filesToSave = (payload.values || []).map(file => ({
    fileId: file.id,
    fileName: file.name,
    expirationDate: payload.expirationDate,
    fileType: payload.formPart,
  }))

  const files = [...savedFiles, ...filesToSave]

  return yield* handleRequest({
    requestActions: saveUploadFile,
    promise: call(proApiClient.put, `/api/firms/${firmId}/files`, {
      files,
    }),
    actionParams: { firmId, files },
  })
}

const saveUploadFileSuccess = function*({ actionParams: { firmId, files } }) {
  yield put(
    showNotification({
      payload: {
        message: translate(
          'resources.firms.fields.documents_saveUploadFile_success',
        ),
        messageType: INFO,
      },
    }),
  )

  yield all(
    files.map(file =>
      handleRequest({
        requestActions: firmGetSingleFile,
        promise: call(proApiClient.get, `/api/firm_files/${file.fileId}`),
        actionParams: { savedFile: file },
      }),
    ),
  )

  yield* handleRequest({
    requestActions: getSingleFirm,
    promise: call(proApiClient.get, `/api/firms/${firmId}`),
  })
}

const saveUploadFileFailure = function*() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(
          'resources.firms.fields.documents_saveUploadFile_failure',
        ),
      },
    }),
  )
}

const openFileRequest = function*({ fileId }) {
  const newWindow = window.open(EMPTY_PATH)

  yield* handleRequest({
    requestActions: getFile,
    promise: call(documentsApiClient.get, `${fileId}/download`),
    actionParams: {
      newWindow,
    },
  })
}

const deleteDocumentRequest = function*({ payload: documentIri }) {
  yield* handleRequest({
    requestActions: deleteDocument,
    promise: call(proApiClient.delete, documentIri),
  })
}

const deleteDocumentSuccess = function*() {
  const firmId = yield select(currentFirmIdSelector)

  yield put(
    showNotification({
      payload: {
        message: translate(
          'resources.firms.messages.documents_deleteDocument_success',
        ),
        messageType: SUCCESS,
      },
    }),
  )

  yield* handleRequest({
    requestActions: getSingleFirm,
    promise: call(proApiClient.get, `/api/firms/${firmId}`),
  })
}

const deleteDocumentFailure = function*() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(
          'resources.firms.messages.documents_deleteDocument_failure',
        ),
      },
    }),
  )
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

function getFileSuccess({ payload, actionParams: { newWindow } }) {
  const extention = payload.name
    .split('.')
    .pop()
    .toLowerCase()
  const type = extention === 'pdf' ? 'application/pdf' : `image/${extention}`
  const style = extention === 'pdf' ? 'width="100%" height="100%"' : ''
  const blob = new Blob([b64toBlob(payload.content)], {
    type: 'application/pdf',
  })
  let fileURL = ''

  if (extention === 'pdf') {
    fileURL = URL.createObjectURL(blob)
  } else {
    fileURL = `data:${type};base64,${payload.content}`
  }

  newWindow.document.write(`<object data="${fileURL}" ${style}></object>`)
}

export default function*() {
  yield all([
    takeLatest(UPLOAD_TMP_FILE.REQUEST, uploadTmpFileRequest),
    takeLatest(UPLOAD_TMP_FILE.SUCCESS, saveFileIdToReduxForm),
    takeLatest(UPLOAD_TMP_FILE.FAILURE, uploadTmpFileFailure),
    takeLatest(SAVE_UPLOAD_FILE.REQUEST, saveUploadFileRequest),
    takeLatest(SAVE_UPLOAD_FILE.SUCCESS, saveUploadFileSuccess),
    takeLatest(SAVE_UPLOAD_FILE.FAILURE, saveUploadFileFailure),
    takeLatest(OPEN_FILE, openFileRequest),
    takeLatest(DELETE_DOCUMENT.REQUEST, deleteDocumentRequest),
    takeLatest(DELETE_DOCUMENT.SUCCESS, deleteDocumentSuccess),
    takeLatest(DELETE_DOCUMENT.FAILURE, deleteDocumentFailure),
    takeLatest(GET_FILE.SUCCESS, getFileSuccess),
  ])
}
