import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { setBarTitle } from 'store/Application/ApplicationActions'
import { featureFlagSelector as featureFlagsBackSelector } from 'store/Application/ApplicationSelectors'
import frontApiClient from 'services/httpClient/frontApiClient'
import {
  CONSUMER_BACKOFFICE,
  CONSUMER_HO_DESKTOP,
  CONSUMER_ORDER_DESKTOP,
  CONSUMER_PRO_APP,
  CONSUMER_PRO_DESKTOP,
} from 'constants/features'
import { Col, Row } from 'react-bootstrap'
import Feature from './Feature'

const FeaturesContainer = ({ setTitle, translate }) => {
  useEffect(() => {
    setTitle(translate('app.page.features'))
  }, [setTitle, translate])

  const [frontFeatureFlags, setFrontFeatureFlags] = useState({})
  const [appFeatureFlags, setAppFeatureFlags] = useState({})
  const [boFeatureFlags, setBoFeatureFlags] = useState({})
  const [hoWebFF, setHoWebFF] = useState({})
  const [orderWebFF, setOrderWebFF] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const fetchFeatureFlags = consumer =>
        frontApiClient.get(`feature-flags/${consumer}`)
      const boFFResponse = await fetchFeatureFlags(CONSUMER_BACKOFFICE)
      const frontFFResponse = await fetchFeatureFlags(CONSUMER_PRO_DESKTOP)
      const appFFResponse = await fetchFeatureFlags(CONSUMER_PRO_APP)
      const hoWebFFResp = await fetchFeatureFlags(CONSUMER_HO_DESKTOP)
      const orderWebFFResp = await fetchFeatureFlags(CONSUMER_ORDER_DESKTOP)

      setFrontFeatureFlags(frontFFResponse.data)
      setAppFeatureFlags(appFFResponse.data)
      setBoFeatureFlags(boFFResponse.data)
      setHoWebFF(hoWebFFResp.data)
      setOrderWebFF(orderWebFFResp.data)
    }

    fetchData()
  }, [
    setFrontFeatureFlags,
    setAppFeatureFlags,
    setBoFeatureFlags,
    setHoWebFF,
    setOrderWebFF,
  ])

  return (
    <div>
      <Row>
        <Col>
          <h3>Feature Flag BO</h3>
          {Object.keys(boFeatureFlags).length > 0 &&
            Object.entries(boFeatureFlags).map(([key, value]) => (
              <Feature name={key} key={key} enabled={value} />
            ))}
        </Col>
        <Col>
          <h3>Feature Flag Carol</h3>
          {Object.keys(frontFeatureFlags).length > 0 &&
            Object.entries(frontFeatureFlags).map(([key, value]) => (
              <Feature name={key} key={key} enabled={value} />
            ))}
        </Col>
        <Col>
          <h3>Feature Flag App</h3>
          {Object.keys(appFeatureFlags).length > 0 &&
            Object.entries(appFeatureFlags).map(([key, value]) => (
              <Feature name={key} key={key} enabled={value} />
            ))}
        </Col>
        <Col>
          <h3>hoWebFF</h3>
          {Object.keys(hoWebFF).length > 0 &&
            Object.entries(hoWebFF).map(([key, value]) => (
              <Feature name={key} key={key} enabled={value} />
            ))}
        </Col>
        <Col>
          <h3>orderWebFF</h3>
          {Object.keys(orderWebFF).length > 0 &&
            Object.entries(orderWebFF).map(([key, value]) => (
              <Feature name={key} key={key} enabled={value} />
            ))}
        </Col>
      </Row>
    </div>
  )
}

FeaturesContainer.propTypes = {
  translate: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
  featureFlagsBackOfficeList: PropTypes.shape({}),
}

FeaturesContainer.defaultProps = {
  featureFlagsBackOfficeList: {},
}

const mapDispatchToProps = dispatch => ({
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
})

const mapStateToProps = state => ({
  featureFlagBackOfficeList: featureFlagsBackSelector(state),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
)(FeaturesContainer)
