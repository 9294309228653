const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  p: {
    fontSize: 'small',
  },
  tdDate: {
    minWidth: '150px',
  },
  multiLine: {
    whiteSpace: 'pre-line',
  },
  icon: {
    height: '20px',
    width: '20px',
    display: 'inline-block',
    cursor: 'pointer',
  },
})

export default styles
