import { all, takeLatest, call } from 'redux-saga/effects'
import engineClient from 'services/httpClient/engineClient'
import { handleRequest } from 'helpers/store/sagasHelpers'
import { formatIriToId } from 'helpers/utils/common'
import {
  GET_CONVERSATIONS_REQ,
  GET_CONVERSATION_MESSAGES_REQ,
  getJobConversations,
  getConversationMessages,
} from './conversationsActions'

/**
 * Get job conversations
 */
export function* handleGetJobConversations({ jobId }) {
  yield* handleRequest({
    requestActions: getJobConversations,
    promise: call(
      engineClient.get,
      `jobs/${formatIriToId(jobId)}/conversations?startedAt[exists]=true`,
    ),
  })
}

/**
 * Get job conversation messages
 */
export function* handleGetConversationMessages({ conversationId }) {
  yield* handleRequest({
    requestActions: getConversationMessages,
    promise: call(engineClient.get, `conversations/${conversationId}/messages`),
    actionParams: { triggerModalLoader: true },
  })
}

/**
 * Conversation Sagas
 */
export default function* root() {
  yield all([
    takeLatest(GET_CONVERSATIONS_REQ.REQUEST, handleGetJobConversations),
    takeLatest(
      GET_CONVERSATION_MESSAGES_REQ.REQUEST,
      handleGetConversationMessages,
    ),
  ])
}
