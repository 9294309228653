import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import lodashUnion from 'lodash/union'
import lodashDifference from 'lodash/difference'
import lodashIntersection from 'lodash/intersection'

import SelectDepartmentsStyles from './SelectDepartmentsStyles'

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
}

const SELECT_ALL_VAL_PREFIX = 'Select-All-'

const RenderDepartmentsMultiSelect = ({
  classes,
  label,
  translate,
  departments,
  input,
  initialValues,
}) => {
  const [selectedZones, setSelectedZones] = React.useState([])

  useEffect(() => {
    if (Object.keys(departments).length) {
      const zones = []
      const depsByZone = Object.keys(departments).reduce(
        (acc, zone) => ({
          ...acc,
          [zone]: departments[zone].map(dep => dep.code),
        }),
        {},
      )

      Object.keys(depsByZone).forEach(zone => {
        if (
          lodashIntersection(initialValues, depsByZone[zone]).length ===
          depsByZone[zone].length
        ) {
          zones.push(`${SELECT_ALL_VAL_PREFIX}-${zone}`)
        }
      })

      setSelectedZones(zones)
    }
  }, [departments, initialValues])

  const handleChange = event => {
    const evtZones = [...selectedZones]
    let evtDeps = []
    const depsZonesToSelect = []
    const depsZonesToUnselect = []
    const depsByZone = Object.keys(departments).reduce(
      (acc, zone) => ({
        ...acc,
        [zone]: departments[zone].map(dep => dep.code),
      }),
      {},
    )

    event.target.value.forEach(choice => {
      if (choice.startsWith(SELECT_ALL_VAL_PREFIX)) {
        const idx = selectedZones.indexOf(choice)
        const zone = choice.split('-').pop()

        if (idx === -1) {
          depsZonesToSelect.push(zone)
          evtZones.push(choice)
        } else {
          depsZonesToUnselect.push(zone)
          evtZones.splice(idx, 1)
        }
      } else {
        evtDeps.push(choice)
      }
    })

    depsZonesToUnselect.forEach(depsZone => {
      evtDeps = lodashDifference(evtDeps, depsByZone[depsZone])
    })

    depsZonesToSelect.forEach(depsZone => {
      evtDeps = lodashUnion(evtDeps, depsByZone[depsZone])
    })

    Object.keys(depsByZone).forEach(zone => {
      const idx = evtZones.indexOf(`${SELECT_ALL_VAL_PREFIX}-${zone}`)

      if (
        lodashIntersection(evtDeps, depsByZone[zone]).length ===
          depsByZone[zone].length &&
        idx === -1
      ) {
        evtZones.push(`${SELECT_ALL_VAL_PREFIX}-${zone}`)
      } else if (
        lodashIntersection(evtDeps, depsByZone[zone]).length !==
          depsByZone[zone].length &&
        idx !== -1
      ) {
        evtZones.splice(idx, 1)
      }
    })

    input.onChange(evtDeps)
    setSelectedZones(evtZones)
  }

  return (
    <>
      <InputLabel id="departments-multiple-checkbox-label">{label}</InputLabel>
      {Object.keys(departments).length > 0 && typeof input.value === 'object' && (
        <Select
          labelId="departments-multiple-checkbox-label"
          id="departments-multiple-checkbox"
          multiple
          value={input.value}
          onChange={handleChange}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {Object.keys(departments).map(key => {
            const list = departments[key].map(dep => (
              <MenuItem
                className={classes.option}
                key={dep.name}
                value={dep.code}
              >
                <Checkbox checked={input.value.indexOf(dep.code) !== -1} />
                <ListItemText
                  className={classes.optionText}
                  primary={`${dep.code} - ${dep.name}`}
                />
              </MenuItem>
            ))

            const selectAllValue = `${SELECT_ALL_VAL_PREFIX}-${key}`

            list.unshift(
              <MenuItem
                className={classes.selectAll}
                key={key}
                value={selectAllValue}
              >
                <Checkbox
                  checked={selectedZones.indexOf(selectAllValue) !== -1}
                />
                <ListItemText
                  className={classes.optionText}
                  primary={translate(`geozones.${key}`)}
                />
              </MenuItem>,
            )

            return list
          })}
        </Select>
      )}
    </>
  )
}
RenderDepartmentsMultiSelect.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    chips: PropTypes.string.isRequired,
    chip: PropTypes.string.isRequired,
    noLabel: PropTypes.string.isRequired,
    optionText: PropTypes.string.isRequired,
    option: PropTypes.string.isRequired,
    selectAll: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  departments: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.shape({}),
  }).isRequired,
  inputValues: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
}

RenderDepartmentsMultiSelect.defaultProps = {
  inputValues: undefined,
  initialValues: undefined,
}

export default compose(withStyles(SelectDepartmentsStyles))(
  RenderDepartmentsMultiSelect,
)
