import Polyglot from 'node-polyglot'
import phrases from 'providers/i18n/lang/fr.json'

// Get traductions
const polyglot = new Polyglot({
  locale: 'fr',
  phrases,
})

export default polyglot
