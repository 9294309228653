import translate from 'providers/i18n/translateService'

export const HO = 'ho'
export const PARTNER = 'partner'
export const PRO = 'pro'
export const INTERNAL = 'internal'
export const INCIDENT_TRANSITIONS = {
  new: 'new',
  analyzing: 'start',
  processing: 'analyze',
  pending_resolution: 'process',
  pending_verification: 'decide',
  pending_payment: 'verify',
  payment_planned: 'plan_payment',
  solved: 'solve',
  canceled: 'cancel',
}

export const INCIDENT_NEW_STATUS = 'new'
export const INCIDENT_PENDING_VERIFICATION_STATUS = 'pending_verification'
export const INCIDENT_PENDING_PAYMENT_STATUS = 'pending_payment'
export const INCIDENT_PAYMENT_PLANNED_STATUS = 'payment_planned'
export const INCIDENT_ANALYZING_STATUS = 'analyzing'
export const INCIDENT_PROCESSING_STATUS = 'processing'
export const INCIDENT_PENDING_RESOLUTION_STATUS = 'pending_resolution'
export const INCIDENT_SOLVED_STATUS = 'solved'
export const INCIDENT_CANCELED_STATUS = 'canceled'
export const INCIDENT_WAITING_FOR_CRM_MANAGER_STATUS = 'waiting_for_crm_manager'
export const INCIDENT_NEW_EXCLUDING_WAITING_FOR_PARTNER_STATUS =
  'new_excluding_waiting_for_partner'

export const INCIDENT_CLOSED_STATUSES = [
  INCIDENT_SOLVED_STATUS,
  INCIDENT_CANCELED_STATUS,
]

export const INCIDENT_WAITING_FOR_CRM_MANAGER = 'crm_manager'

export const INCIDENT_ISSUED_BY_HO = {
  code: HO,
  name: translate('resources.incidents.issued_by.ho'),
}

export const INCIDENT_ISSUED_BY_PRO = {
  code: PRO,
  name: translate('resources.incidents.issued_by.pro'),
}

export const INCIDENT_ISSUED_BY_PARTNER = {
  code: PARTNER,
  name: translate('resources.incidents.issued_by.partner'),
}

export const INCIDENT_ISSUED_BY_INTERNAL = {
  code: INTERNAL,
  name: translate('resources.incidents.issued_by.internal'),
}

export const INCIDENT_ISSUED_BY = [
  INCIDENT_ISSUED_BY_PRO,
  INCIDENT_ISSUED_BY_HO,
  INCIDENT_ISSUED_BY_PARTNER,
  INCIDENT_ISSUED_BY_INTERNAL,
]

export const INCIDENT_FORM = 'INCIDENT_FORM'

export const INCIDENT_ORIGIN_HO = {
  code: HO,
  label: translate('resources.incidents.origin.ho'),
}

export const INCIDENT_ORIGIN_PARTNER = {
  code: PARTNER,
  label: translate('resources.incidents.origin.partner'),
}

export const INCIDENT_ORIGIN_PRO = {
  code: PRO,
  label: translate('resources.incidents.origin.pro'),
}

export const INCIDENT_ORIGIN_INTERNAL = {
  code: INTERNAL,
  label: translate('resources.incidents.origin.internal'),
}

export const INCIDENT_ORIGINS = [
  INCIDENT_ORIGIN_HO,
  INCIDENT_ORIGIN_PARTNER,
  INCIDENT_ORIGIN_PRO,
  INCIDENT_ORIGIN_INTERNAL,
]

export const INCIDENT_TYPE_CODES_PROPOSING_RESCHEDULING_ON_SOLVE = [
  'MALFUNCTIONING_PRODUCT',
  'UNADAPTED_PRODUCT',
  'WRONG_PRODUCT',
  'PRODUCT_NOT_AVAILABLE',
  'MISSING_PRODUCT',
]

export const NB_DAYS_BEFORE_JOB_SHOULD_RESCHEDULING_ON_SOLVE_MALFUNCTIONING_PRODUCT_LIKE = 2
