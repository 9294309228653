import React from 'react'
import get from 'lodash/get'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import RadioButton from 'components/shared/RadioButton/RadioButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { reduxForm, Field } from 'redux-form'
import { SET_INCIDENT_RESPONSIBLE_FORM } from 'constants/forms'
import { INCIDENT_ISSUED_BY, INCIDENT_ISSUED_BY_PRO } from 'constants/incidents'
import {
  selectCurrentUpdatedIncident,
  selectRespSelectDialog,
} from 'store/incidents/incidentSelectors'
import { setIncidentResponsible } from 'store/incidents/incidentActions'
import {
  detailAcceptedJobProSelector,
  selectIncident,
} from 'store/jobs/jobSelectors'
import styles from './JobDetailsIncidentsPopinStyles'
import IncidentSelectProResponsibleContainer from './IncidentSelectProResponsibleContainer'

const JobDetailsIncidentsPopin = ({
  displayed,
  handleClose,
  translate,
  classes,
  handleSubmit,
  dirty,
  currentUpdatedIncidentId,
  proList,
  doDisplayStatusChangeConfirmDialogAction,
}) => {
  if (currentUpdatedIncidentId === null) {
    return null
  }

  return (
    <Dialog
      open={displayed}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="flex-start">
          <DialogTitle id="alert-dialog-title">
            {translate('resources.incidents.popin.title', {
              incidentId: currentUpdatedIncidentId,
            })}
          </DialogTitle>
          <DialogContent className={classes.textContainer}>
            <DialogContentText>
              {doDisplayStatusChangeConfirmDialogAction ? (
                <>
                  <strong>
                    {translate('resources.incidents.popin.label2.main')}
                  </strong>
                  <br />
                  {translate('resources.incidents.popin.label2.second')}
                </>
              ) : (
                translate('resources.incidents.popin.label')
              )}
            </DialogContentText>
            {INCIDENT_ISSUED_BY.map(issued_by => (
              <Grid key={issued_by.code} container>
                <Typography component="label" className={classes.label}>
                  <Field
                    type="radio"
                    name="responsible"
                    value={issued_by.code}
                    component={RadioButton}
                    disabled={
                      issued_by.code === INCIDENT_ISSUED_BY_PRO.code &&
                      !proList.length
                    }
                  />
                  {issued_by.name}
                  {issued_by.code === INCIDENT_ISSUED_BY_PRO.code && (
                    <div className={classes.inlineSelect}>
                      <IncidentSelectProResponsibleContainer />
                    </div>
                  )}
                </Typography>
              </Grid>
            ))}
          </DialogContent>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <DialogActions align="right">
            <Button
              type="submit"
              autoFocus
              className={classes.buttonBigger}
              disabled={!dirty}
            >
              {translate('app.action.validate')}
            </Button>
            <Button onClick={handleClose} className={classes.buttonReload}>
              {translate('app.action.cancel')}
            </Button>
          </DialogActions>
        </Grid>
      </form>
    </Dialog>
  )
}

JobDetailsIncidentsPopin.defaultProps = {
  doDisplayStatusChangeConfirmDialogAction: null,
  currentUpdatedIncidentId: null,
}

JobDetailsIncidentsPopin.propTypes = {
  classes: PropTypes.shape({
    counter: PropTypes.string.isRequired,
    textContainer: PropTypes.string.isRequired,
    buttonReload: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    inlineSelect: PropTypes.string.isRequired,
  }).isRequired,
  displayed: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  currentUpdatedIncidentId: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  dirty: PropTypes.bool.isRequired,
  proList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  doDisplayStatusChangeConfirmDialogAction: PropTypes.shape({}),
}

const mapStateToProps = state => {
  const currentUpdatedIncidentId = selectCurrentUpdatedIncident(state).id
  const { doDisplayStatusChangeConfirmDialogAction } = selectRespSelectDialog(
    state,
  )
  const incident = selectIncident(state, currentUpdatedIncidentId)
  const initialValues = {}
  const responsible = get(incident, 'responsible')
  if (responsible !== undefined) {
    initialValues.responsible = responsible
  }
  const pro = get(incident, 'pro[@id]', null)
  if (pro !== undefined) {
    initialValues.pro = pro
  }

  return {
    proList: detailAcceptedJobProSelector(state),
    currentUpdatedIncidentId,
    initialValues,
    doDisplayStatusChangeConfirmDialogAction,
  }
}

export default compose(
  i18n,
  withStyles(styles),
  connect(mapStateToProps),
  reduxForm({
    form: SET_INCIDENT_RESPONSIBLE_FORM,
    fields: ['responsible'],
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmit: (
      { responsible, pro },
      dispatch,
      { currentUpdatedIncidentId, doDisplayStatusChangeConfirmDialogAction },
    ) => {
      const params = {
        incidentId: currentUpdatedIncidentId,
        responsible,
        pro,
        doDisplayStatusChangeConfirmDialogAction,
      }

      dispatch(setIncidentResponsible.request({ params }))
    },
    onSubmitSuccess: (result, dispatch, { handleClose }) => {
      handleClose()
    },
  }),
)(JobDetailsIncidentsPopin)
