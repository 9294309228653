import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import { setBarTitle } from 'store/Application/ApplicationActions'
import SaleInvoicesListContainer from 'components/SaleInvoices/SaleInvoicesList/SaleInvoicesListContainer'
import PropTypes from 'prop-types'
import { compose } from 'redux'

const Invoices = ({ setTitle, translate }) => {
  useEffect(() => {
    setTitle(translate('resources.saleInvoices.navbar.title.all'))
  }, [setTitle, translate])

  return <SaleInvoicesListContainer />
}

const mapDispatchToProps = dispatch => ({
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
})

Invoices.propTypes = {
  translate: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
}

export default compose(i18n, connect(null, mapDispatchToProps))(Invoices)
