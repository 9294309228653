export default () => ({
  card: {
    backgroundImage: 'linear-gradient(90deg, #3F5F85, #234868, #143C58)',
    padding: '10px',
    margin: '1rem',
    flex: 'auto',
    minWidth: '150px',
    cursor: 'pointer',
  },
  cardContent: {
    width: '100%',
    textAlign: 'left',
    '&:last-child': {
      paddingBottom: 'inherit',
    },
  },
  firstNumber: {
    fontSize: 25,
    fontWeight: '600',
    display: 'inline-block',
    marginRight: '5px',
    color: '#FFFFFF',
  },
  urgentNumber: {
    fontSize: 25,
    color: '#EDA936',
    fontWeight: '600',
    display: 'inline-block',
    marginRight: '5px',
  },
  urgentWord: {
    fontSize: 14,
    color: '#EDA936',
    fontWeight: '600',
    display: 'inline-block',
    marginRight: '15px',
  },
  other: {
    fontSize: 14,
    color: '#FFFFFF',
    fontWeight: '600',
    display: 'inline-block',
    marginRight: '5px',
  },
  cardAction: {
    display: 'block',
    width: '100%',
  },
  title: {
    color: '#FFFFFF',
    textAlign: 'left',
  },
  gridContent: {
    textAlign: 'left',
  },
  gridContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  link: {
    display: 'inline-table',
    cursor: 'pointer',
    marginRight: '20px',
  },
})
