import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { incidentsSelector } from 'store/jobs/jobSelectors'
import {
  incidentStatusesForSelectSelector,
  incidentWaitingForListForSelectSelector,
} from 'store/incidents/incidentSelectors'
import {
  fetchIncident,
  fetchIncidentStatuses,
  fetchIncidentWaitingForList,
  updateIncidentWaitingFor,
} from 'store/incidents/incidentActions'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { REINTERVENTION_AFTER_SALE } from 'constants/features'
import { isFeatureFlagEnabledSelector } from 'store/Application/ApplicationSelectors'
import JobDetailsIncidents from './JobDetailsIncidents'

const JobDetailsIncidentsContainer = ({
  incidents,
  fetchIncidentAction,
  dispatchFetchIncidentStatuses,
  incidentStatuses,
  fetchIncidentWaitingForListAction,
  incidentWaitingForList,
  updateIncidentWaitingForAction,
  isReinterventionAfterSaleEnabled,
}) => {
  useEffect(() => {
    if (!incidents.incidentListRequested && !incidents.incidentListRequesting) {
      fetchIncidentAction()
    }
  }, [
    fetchIncidentAction,
    incidents.incidentListRequested,
    incidents.incidentListRequesting,
  ])

  useEffect(() => {
    dispatchFetchIncidentStatuses()
  }, [dispatchFetchIncidentStatuses])

  useEffect(() => {
    fetchIncidentWaitingForListAction()
  }, [fetchIncidentWaitingForListAction])

  if (incidents.incidentListRequesting) {
    return <CircularProgress size={20} />
  }

  const updateWaitingFor = (incidentId, waitingForValue) => {
    updateIncidentWaitingForAction(incidentId, waitingForValue)
  }

  return (
    <JobDetailsIncidents
      incidents={incidents}
      incidentWaitingForList={incidentWaitingForList}
      updateWaitingFor={updateWaitingFor}
      incidentStatuses={incidentStatuses}
      isReinterventionAfterSaleEnabled={isReinterventionAfterSaleEnabled}
    />
  )
}

JobDetailsIncidentsContainer.propTypes = {
  fetchIncidentAction: PropTypes.func.isRequired,
  dispatchFetchIncidentStatuses: PropTypes.func.isRequired,
  incidentStatuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  incidents: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    totalItems: PropTypes.number,
    loading: PropTypes.bool,
    incidentListRequesting: PropTypes.bool,
    incidentListRequested: PropTypes.bool,
  }),
  fetchIncidentWaitingForListAction: PropTypes.func.isRequired,
  incidentWaitingForList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateIncidentWaitingForAction: PropTypes.func.isRequired,
  isReinterventionAfterSaleEnabled: PropTypes.bool.isRequired,
}

JobDetailsIncidentsContainer.defaultProps = {
  incidents: {
    list: [],
    totalItems: 0,
    loading: false,
    incidentListRequested: false,
    incidentListRequesting: false,
  },
}

const mapStateToProps = state => ({
  incidents: incidentsSelector(state),
  incidentStatuses: incidentStatusesForSelectSelector(state),
  incidentWaitingForList: incidentWaitingForListForSelectSelector(state),
  isReinterventionAfterSaleEnabled: isFeatureFlagEnabledSelector(
    state,
    REINTERVENTION_AFTER_SALE,
  ),
})

const mapDispatchToProps = dispatch => ({
  fetchIncidentAction: () => dispatch(fetchIncident.request()),
  fetchIncidentWaitingForListAction: () =>
    dispatch(fetchIncidentWaitingForList.request()),
  updateIncidentWaitingForAction: (id, waitingFor) =>
    dispatch(updateIncidentWaitingFor.request({ id, waitingFor })),
  dispatchFetchIncidentStatuses: () =>
    dispatch(fetchIncidentStatuses.request()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobDetailsIncidentsContainer)
