import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { reduxForm, Field } from 'redux-form'
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  FormControl,
  Grid,
  withStyles,
} from '@material-ui/core'
import { EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM } from 'constants/forms'
import { FIRM_ORIGINS } from 'constants/firms'
import i18n from 'providers/i18n/I18nProvider'
import RenderSwitch from 'components/shared/Form/RenderSwitch'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import RenderTextField from 'components/shared/Form/RenderTextField'
import RenderSelectMultipleAutocompleteField from 'components/shared/Form/RenderSelectMultipleAutocompleteField'
import Icon from 'components/shared/Icon/Icon'
import { validateManualRating } from 'helpers/utils/firm/firm'
import styles from './FirmInformationsPackageStyles'

const FirmInformationsPackage = ({
  classes,
  translate,
  initialValues: { stores },
  handleSubmit,
}) => (
  <FormControl className={classes.formControl}>
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="flex-start" direction="row">
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar>
                  <Icon className={classes.icon} icon="market.svg" />
                </Avatar>
              }
              classes={{
                title: classes.titles,
              }}
              title={translate('resources.firms.cards.quotatis')}
            />
            <CardContent>
              <Field
                name="origin"
                id="originSelect"
                className={classes.input}
                component={RenderSelectField}
                choices={FIRM_ORIGINS}
                label={translate('resources.firms.fields.origin')}
                optionValue="code"
                optionText="name"
                allowEmpty
              />
              <Field
                name="storeCodes"
                className={classes.fullWidth}
                choices={stores}
                component={RenderSelectMultipleAutocompleteField}
                label={translate('resources.firms.fields.stores')}
                optionValue="code"
                optionText="name"
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar>
                  <Icon className={classes.icon} icon="config.svg" />
                </Avatar>
              }
              classes={{
                title: classes.titles,
              }}
              title={translate('resources.firms.cards.configuration')}
            />
            <CardContent>
              <Field
                name="isRemoval"
                className={classes.input}
                component={RenderSwitch}
                label={translate('resources.firms.fields.removal')}
              />
              <Field
                name="isSaturday"
                className={classes.input}
                component={RenderSwitch}
                label={translate('resources.firms.fields.saturday')}
              />
              <Field
                name="isSunday"
                className={classes.input}
                component={RenderSwitch}
                label={translate('resources.firms.fields.sunday')}
              />
              <Field
                name="isWeekend"
                className={classes.input}
                component={RenderSwitch}
                label={translate('resources.firms.fields.weekend')}
              />
              <Field
                name="reliable"
                className={classes.input}
                component={RenderSwitch}
                label={translate('resources.firms.fields.reliable')}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardHeader
              avatar={
                <Avatar>
                  <Icon className={classes.icon} icon="notification.svg" />
                </Avatar>
              }
              classes={{
                title: classes.titles,
              }}
              title={translate('resources.firms.cards.rating')}
            />
            <CardContent>
              <Field
                name="nbJobsDone"
                className={classes.input}
                component={RenderTextField}
                label={translate('resources.firms.fields.nb_jobs_done')}
                disabled
              />
              <Field
                name="manualRating"
                className={classes.input}
                component={RenderTextField}
                label={translate('resources.firms.fields.manual_rating')}
                validate={validateManualRating}
              />
              <Field
                name="rankingRating"
                className={classes.input}
                component={RenderTextField}
                label={translate('resources.firms.fields.ranking_rating')}
                disabled
              />
              <Field
                name="packageRating"
                className={classes.input}
                component={RenderTextField}
                label={translate('resources.firms.fields.package_rating')}
                disabled
              />
              <Field
                name="leadRating"
                className={classes.input}
                component={RenderTextField}
                label={translate('resources.firms.fields.lead_rating')}
                disabled
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  </FormControl>
)

FirmInformationsPackage.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    card: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    fullWidth: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    titles: PropTypes.string.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    stores: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
}

export default compose(
  i18n,
  reduxForm({
    form: EDIT_FIRM_DETAILS_INFO_PACKAGE_FORM,
    enableReinitialize: true,
  }),
  withStyles(styles),
)(FirmInformationsPackage)
