import translate from 'providers/i18n/translateService'

export const VALIDATE = 'validate'
export const RETRY_TEST = 'retry_test'
export const SUSPEND_DEFINITIVELY = 'suspend_definitively'

export const FIRM_ORIGINS = [
  { code: 'QUOTATIS', name: 'Quotatis' },
  { code: 'BRICOMAN', name: 'Bricoman' },
  { code: 'WELDOM', name: 'Weldom' },
  { code: 'LEROY_MERLIN', name: 'Leroy Merlin' },
  { code: 'SITE_LM', name: 'Site Leroy Merlin' },
  { code: 'SEA', name: 'SEA' },
  { code: 'EMAIL', name: 'Email' },
  { code: 'SMS', name: 'SMS' },
  { code: 'FACEBOOK', name: 'Facebook' },
  { code: 'OTHER', name: 'Other' },
]

export const PACKAGES_SUBSCRIPTION = [
  { code: 0, key: 'noValue', name: '------' },
  {
    code: 1,
    key: 'candidate',
    name: translate('firm.packageStatus.candidate'),
  },
  { code: 2, key: 'validate', name: translate('firm.packageStatus.validate') },
  {
    code: 3,
    key: 'suspended',
    name: translate('firm.packageStatus.suspended'),
  },
]

export const SUSPENDED_BECAUSE_FIRM_CLOSED = {
  code: 'firm_closed',
  key: 'firm_closed',
  name: translate('firm.suspendedReason.firmClosed'),
}

export const SUSPENDED_BECAUSE_QUALITY_FEEDBACK = {
  code: 'quality_feedback',
  key: 'quality_feedback',
  name: translate('firm.suspendedReason.qualityFeedback'),
}

export const SUSPENDED_BECAUSE_DOCS_NOT_UP_TO_DATE = {
  code: 'docs_not_up_to_date',
  key: 'docs_not_up_to_date',
  name: translate('firm.suspendedReason.docsNotUpToDate'),
}

export const SUSPENDED_BECAUSE_QUALITY_LITIGATION = {
  code: 'quality_litigation',
  key: 'quality_litigation',
  name: translate('firm.suspendedReason.qualityLitigation'),
}

export const SUSPENDED_BECAUSE_SICK_LEAVE = {
  code: 'sick_leave',
  key: 'sick_leave',
  name: translate('firm.suspendedReason.sickLeave'),
}

export const SUSPENDED_BECAUSE_NO_AVAILABILITY = {
  code: 'no_availability',
  key: 'no_availability',
  name: translate('firm.suspendedReason.noAvailability'),
}

export const SUSPENDED_BECAUSE_QTT_SERVICE_LITIGATION = {
  code: 'qtt_service_litigation',
  key: 'qtt_service_litigation',
  name: translate('firm.suspendedReason.qttServiceLitigation'),
}

export const SUSPENDED_BECAUSE_UNATTRACTIVE_REMUNERATION = {
  code: 'unattractive_remuneration',
  key: 'unattractive_remuneration',
  name: translate('firm.suspendedReason.unattractiveRemuneration'),
}

export const SUSPENDED_BECAUSE_NOT_INTERESTED_IN_PACKAGES = {
  code: 'not_interested_in_packages',
  key: 'not_interested_in_packages',
  name: translate('firm.suspendedReason.notInterestedInPackages'),
}

export const SUSPENDED_BECAUSE_APP_NOT_WORKING_PROPERLY = {
  code: 'app_not_working_properly',
  key: 'app_not_working_properly',
  name: translate('firm.suspendedReason.appNotWorking'),
}

export const SUSPENDED_BECAUSE_OTHER = {
  code: 'other',
  key: 'other',
  name: translate('firm.suspendedReason.other'),
}

export const SUSPENDED_LABEL = {
  code: '',
  key: 'label',
  name: translate('firm.suspendedPro.popin.label'),
}

export const FIRM_USER_STATUS_SUSPENDED_REASONS = [
  SUSPENDED_LABEL,
  SUSPENDED_BECAUSE_FIRM_CLOSED,
  SUSPENDED_BECAUSE_QUALITY_FEEDBACK,
  SUSPENDED_BECAUSE_DOCS_NOT_UP_TO_DATE,
  SUSPENDED_BECAUSE_QUALITY_LITIGATION,
  SUSPENDED_BECAUSE_SICK_LEAVE,
  SUSPENDED_BECAUSE_NO_AVAILABILITY,
  SUSPENDED_BECAUSE_QTT_SERVICE_LITIGATION,
  SUSPENDED_BECAUSE_UNATTRACTIVE_REMUNERATION,
  SUSPENDED_BECAUSE_NOT_INTERESTED_IN_PACKAGES,
  SUSPENDED_BECAUSE_APP_NOT_WORKING_PROPERLY,
  SUSPENDED_BECAUSE_OTHER,
]

export const FIRM_USER_STATUS_SUSPENDED_REASONS_FILTER = [
  SUSPENDED_BECAUSE_FIRM_CLOSED,
  SUSPENDED_BECAUSE_QUALITY_FEEDBACK,
  SUSPENDED_BECAUSE_DOCS_NOT_UP_TO_DATE,
  SUSPENDED_BECAUSE_QUALITY_LITIGATION,
  SUSPENDED_BECAUSE_SICK_LEAVE,
  SUSPENDED_BECAUSE_NO_AVAILABILITY,
  SUSPENDED_BECAUSE_QTT_SERVICE_LITIGATION,
  SUSPENDED_BECAUSE_UNATTRACTIVE_REMUNERATION,
  SUSPENDED_BECAUSE_NOT_INTERESTED_IN_PACKAGES,
  SUSPENDED_BECAUSE_APP_NOT_WORKING_PROPERLY,
  SUSPENDED_BECAUSE_OTHER,
]

export const PACKAGE_STATUS_NOT_CANDIDATE = {
  code: 'not_candidate',
  key: 'not_candidate',
  name: translate('firm.packageStatus.not_candidate'),
}

export const PACKAGE_STATUS_CANDIDATE = {
  code: 'candidate',
  key: 'candidate',
  name: translate('firm.packageStatus.candidate'),
}

export const PACKAGE_STATUS_VALIDATED = {
  code: 'validated',
  key: 'validated',
  name: translate('firm.packageStatus.validated'),
}

export const PACKAGE_STATUS_PENDING_TEST_JOB = {
  code: 'pending_test_job',
  key: 'pending_test_job',
  name: translate('firm.packageStatus.pending_test_job'),
}

export const PACKAGE_STATUS_WAITING_FOR_PARTNER_VALIDATION = {
  code: 'waiting_for_partner_validation',
  key: 'waiting_for_partner_validation',
  name: translate('firm.packageStatus.waiting_for_partner_validation'),
}

export const PACKAGE_STATUS_IN_PROGRESS_TEST_JOB = {
  code: 'in_progress_test_job',
  key: 'in_progress_test_job',
  name: translate('firm.packageStatus.in_progress_test_job'),
}

export const PACKAGE_STATUS_SUSPENDED_TEMPORARY = {
  code: 'suspended_temporary',
  key: 'suspended_temporary',
  name: translate('firm.packageStatus.suspended_temporary'),
}

export const PACKAGE_STATUS_SUSPENDED_DEFINITIVELY = {
  code: 'suspended_definitively',
  key: 'suspended_definitively',
  name: translate('firm.packageStatus.suspended_definitively'),
}

export const PRO_PK_DISABLED_STATUSES = [
  PACKAGE_STATUS_SUSPENDED_DEFINITIVELY.code,
  PACKAGE_STATUS_SUSPENDED_TEMPORARY.code,
]

export const PACKAGES_SUBSCRIPTION_WORDING = [
  PACKAGE_STATUS_NOT_CANDIDATE,
  PACKAGE_STATUS_CANDIDATE,
  PACKAGE_STATUS_VALIDATED,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
  PACKAGE_STATUS_SUSPENDED_TEMPORARY,
  PACKAGE_STATUS_SUSPENDED_DEFINITIVELY,
]

export const PACKAGES_SUBSCRIPTION_WORDING_CANDIDATE = [
  PACKAGE_STATUS_NOT_CANDIDATE,
  PACKAGE_STATUS_CANDIDATE,
  PACKAGE_STATUS_PENDING_TEST_JOB,
]

export const PACKAGES_SUBSCRIPTION_WORDING_VALIDATION = [
  PACKAGE_STATUS_VALIDATED,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
]

export const VALIDATE_CANDIDATURE_TRANSITION = 'validate_candidature'
export const PREPARE_APPLICATION_TRANSITION = 'prepare_application'
export const FORCE_VALIDATE_TRANSITION = 'force_validate'
export const VALIDATE_TEST_TRANSITION = 'validate_test'
export const RETRY_TEST_TRANSITION = 'retry_test'
export const BLACKLIST_TRANSITION = 'blacklist'

export const PACKAGES_SUBSCRIPTION_WORDING__TRANSITIONS = {
  validate_candidature: {
    from: ['candidate'],
    to: 'pending_test_job',
  },
  prepare_application: {
    from: ['candidate'],
    to: 'waiting_for_partner_validation',
  },
  force_validate: {
    from: ['pending_test_job'],
    to: 'validated',
  },
  validate_test: {
    from: ['in_progress_test_job'],
    to: 'validated',
  },
  retry_test: {
    from: ['in_progress_test_job'],
    to: 'pending_test_job',
  },
  blacklist: {
    from: [
      'pending_test_job',
      'in_progress_test_job',
      'validated',
      'suspended_temporary',
    ],
    to: 'suspended_definitively',
  },
}

export const PACKAGE_STATUS_CANDIDATE_ORIGINS = [
  {
    code: null,
    name: translate('firm.package_status_candidate_origin.none'),
  },
  {
    code: 'signup',
    name: translate('firm.package_status_candidate_origin.signup'),
  },
  { code: 'bo', name: translate('firm.package_status_candidate_origin.bo') },
]

export const SUBJECT_TO_VAT = 'SUBJECT_TO_VAT'
export const NOT_SUBJECT_TO_VAT = 'NOT_SUBJECT_TO_VAT'
export const FIRM_VATS = [
  {
    name: translate('firm_details.tva.subject_to'),
    key: [SUBJECT_TO_VAT],
    code: true,
  },
  {
    name: translate('firm_details.tva.not_subject_to'),
    key: [NOT_SUBJECT_TO_VAT],
    code: false,
  },
]
export const FIRM_GENDER = [{ name: 'Mr' }, { name: 'Mrs' }, { name: 'Miss' }]
export const LEROY_MERLIN = 'LEROY_MERLIN'

export const FIRM_CONTRACT_OPTIONS = [
  { code: 'PACKAGE', name: 'Package' },
  { code: 'PICK', name: 'Select' },
  { code: 'PUSH', name: 'Connect' },
  { code: 'DIRECTORY', name: 'Directory' },
  { code: 'NO_CONTRACT', name: 'Sans contrat' },
]

export const FIRM_LEGAL_STATUS = [
  { code: 'EI', name: 'Entrepreneur Individuel' },
  {
    code: 'EURL',
    name: 'EURL - Entreprise unipersonnelle à responsabilité limitée',
  },
  { code: 'SARL', name: 'SARL - Société à Responsabilité limitée' },
  { code: 'SAS', name: 'SAS - Société par actions simplifiée' },
  {
    code: 'SASU',
    name: 'SASU - Société par actions simplifiée unipersonnelle',
  },
  { code: 'SA', name: 'SA - Société anonyme' },
  { code: 'SCA', name: 'SCA - Société en commandite par actions' },
  { code: 'SCCV', name: 'SCCV - Société civile de construction-vente' },
  { code: 'SCI', name: 'SCI - Société civile immobilière' },
  { code: 'SCM', name: 'SCM - Société civile de moyens' },
  { code: 'SCP', name: 'SCP - Société civile professionnelle' },
  { code: 'SCS', name: 'SCS - Société en commandite simple' },
  { code: 'SEP', name: 'SEP - Société en participation' },
  { code: 'SNC', name: 'SNC - Société en nom collectif' },
  { code: 'COOP_ART', name: 'Coopérative artisanale' },
  { code: 'SC_ETRANGERE', name: 'Société étrangère immatriculée au RCS' },
]

export const ALL_PROS_LIST = 'all_pros_list'
export const PROS_WITH_PACKAGES_LIST = 'pros_with_pk_list'
export const DIRECTORY = 'DIRECTORY'

export const VALIDATION = 'VALIDATION'
export const DETAILS = 'DETAILS'
export const INFORMATION_PACKAGE = 'INFORMATION_PACKAGE'
export const LEGAL_INFORMATIONS = 'LEGAL_INFORMATIONS'
export const ZONE_INTERVENTION = 'ZONE_INTERVENTION'
export const PACKAGE = 'PACKAGE'
export const DOCUMENTATION = 'DOCUMENTATION'
export const DOCUMENTATION_PRO = 'DOCUMENTATION_PRO'
export const HISTORY = 'HISTORY'
export const COLLABORATORS = 'COLLABORATORS'
export const CONTRACT_SIGNATURES_STATUS_FINISHED = 'finished'

export const FIELD_LEGAL_INFORMATIONS_LEGAL_STATUS = 'legalStatus'
export const FIELD_LEGAL_INFORMATIONS_REGISTRATION_NUMBER = 'registrationNumber'
export const FIELD_LEGAL_INFORMATIONS_CAPITAL_AMOUNT = 'capitalAmount'
export const FIELD_LEGAL_INFORMATIONS_VAT_NUMBER = 'vatNumber'
export const FIELD_LEGAL_INFORMATIONS_SWIFT_CODE = 'swiftCode'
export const FIELD_LEGAL_INFORMATIONS_IBAN = 'internationalBankAccountNumber'
export const FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_ADDRESS =
  'headQuartersAddress'
export const FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_CITY = 'headQuartersCity'
export const FIELD_LEGAL_INFORMATIONS_HEAD_QUARTERS_POSTAL_CODE =
  'headQuartersPostalCode'
export const FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS = 'billingAddress'
export const FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_CITY =
  'billingAddressCity'
export const FIELD_LEGAL_INFORMATIONS_BILLING_ADDRESS_POSTAL_CODE =
  'billingAddressPostalCode'
export const FIELD_LEGAL_INFORMATIONS_REGISTRATION_CITY = 'registrationCity'
export const FIELD_LEGAL_INFORMATIONS_POSTAL_CODE = 'postalCode'
export const FIELD_LEGAL_INFORMATIONS_BUSINESS_DIRECTORY = 'businessDirectory'
export const FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_FIRST_NAME =
  'legalRepresentativeFirstName'
export const FIELD_LEGAL_INFORMATIONS_LEGAL_REPRESENTATIVE_LAST_NAME =
  'legalRepresentativeLastName'
export const FIELD_LEGAL_INFORMATIONS_SUBJECT_TO_VAT = 'subjectToVat'
export const FIRM_FILE = 'FirmFile'

export const FIRM_COMMENT_TYPE = {
  COMMENT: 'COMMENT',
  STATUS_CHANGE: 'STATUS_CHANGE',
}

export const FIRM_COMMENT_PER_PAGE = 25
export const FIRM_COMMENT_PAGE = 0

export const FIRM_MATCHING_OPTION_DEPARTMENT = 'MATCHING_OPTION_DEPARTMENT'
export const FIRM_MATCHING_OPTION_RADIUS = 'MATCHING_OPTION_RADIUS'
export const FIRM_MATCHING_OPTIONS = [
  FIRM_MATCHING_OPTION_DEPARTMENT,
  FIRM_MATCHING_OPTION_RADIUS,
]

export const FIRM_MATCHING_RADIUS_SHORT = 'MATCHING_RADIUS_SHORT'
export const FIRM_MATCHING_RADIUS_STANDARD = 'MATCHING_RADIUS_STANDARD'
export const FIRM_MATCHING_RADIUS_LARGE = 'MATCHING_RADIUS_LARGE'
export const FIRM_MATCHING_RADIUSES = [
  FIRM_MATCHING_RADIUS_SHORT,
  FIRM_MATCHING_RADIUS_STANDARD,
  FIRM_MATCHING_RADIUS_LARGE,
]
export const FIRM_MATCHING_RADIUS_VALUES = {
  MATCHING_RADIUS_SHORT: 20000,
  MATCHING_RADIUS_STANDARD: 50000,
  MATCHING_RADIUS_LARGE: 100000,
}

export const RGE = 'rge'
export const CERFA = 'cerfa'

export const NO_SCORE = 'no_score'
export const SCORE_KO = 'score_ko'
export const SCORE_TEST_PROJECT = 'score_test_project'
export const SCORE_OK = 'score_ok'

export const FIRM_ONBOARDINGSCORE = [
  {
    code: NO_SCORE,
    name: translate(`firm.onboardingscore.${NO_SCORE}`),
  },
  {
    code: SCORE_KO,
    name: translate(`firm.onboardingscore.${SCORE_KO}`),
  },
  {
    code: SCORE_TEST_PROJECT,
    name: translate(`firm.onboardingscore.${SCORE_TEST_PROJECT}`),
  },
  {
    code: SCORE_OK,
    name: translate(`firm.onboardingscore.${SCORE_OK}`),
  },
]

export const DOCUMENTS_NONE = 'none'
export const DOCUMENTS_PARTIAL = 'partial'
export const DOCUMENTS_COMPLETE = 'complete'

export const CANDIDATE_DOCUMENTS_NONE = {
  code: DOCUMENTS_NONE,
  key: DOCUMENTS_NONE,
  name: translate(`firm.mandatory_document.${DOCUMENTS_NONE}`),
}

export const CANDIDATE_DOCUMENTS_PARTIAL = {
  code: DOCUMENTS_PARTIAL,
  key: DOCUMENTS_PARTIAL,
  name: translate(`firm.mandatory_document.${DOCUMENTS_PARTIAL}`),
}

export const CANDIDATE_DOCUMENTS_COMPLETE = {
  code: DOCUMENTS_COMPLETE,
  key: DOCUMENTS_COMPLETE,
  name: translate(`firm.mandatory_document.${DOCUMENTS_COMPLETE}`),
}

export const FIRM_MANDATORY_DOC_STATUS = [
  CANDIDATE_DOCUMENTS_NONE,
  CANDIDATE_DOCUMENTS_PARTIAL,
  CANDIDATE_DOCUMENTS_COMPLETE,
]

export const VERIFIED_DOCUMENTS_NA = 'n_a'
export const VERIFIED_DOCUMENTS_YES = 'yes'
export const VERIFIED_DOCUMENTS_NO = 'no'

export const CANDIDATE_VERIFIED_DOCUMENTS_NA = {
  code: VERIFIED_DOCUMENTS_NA,
  key: VERIFIED_DOCUMENTS_NA,
  name: translate(`firm.verified_documents.${VERIFIED_DOCUMENTS_NA}`),
}

export const CANDIDATE_VERIFIED_DOCUMENTS_YES = {
  code: VERIFIED_DOCUMENTS_YES,
  key: VERIFIED_DOCUMENTS_YES,
  name: translate(`firm.verified_documents.${VERIFIED_DOCUMENTS_YES}`),
}

export const CANDIDATE_VERIFIED_DOCUMENTS_NO = {
  code: VERIFIED_DOCUMENTS_NO,
  key: VERIFIED_DOCUMENTS_NO,
  name: translate(`firm.verified_documents.${VERIFIED_DOCUMENTS_NO}`),
}

export const CANDIDATE_VERIFIED_DOCUMENTS_STATUS = [
  CANDIDATE_VERIFIED_DOCUMENTS_NA,
  CANDIDATE_VERIFIED_DOCUMENTS_YES,
  CANDIDATE_VERIFIED_DOCUMENTS_NO,
]
