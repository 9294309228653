import { connect } from 'react-redux'
import { setDisplayedInvoice } from 'store/invoices/invoiceActions'
import AllInvoicesListTableBody from './AllInvoicesListTableBody'

const mapDispatchToProps = dispatch => ({
  dispatchSetDisplayedInvoice: invoiceId =>
    dispatch(setDisplayedInvoice(invoiceId)),
})

export default connect(null, mapDispatchToProps)(AllInvoicesListTableBody)
