import React from 'react'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import { DialogContent } from '@material-ui/core'

const ConfirmDialog = ({ children, ...rest }) => (
  <SimpleDialog {...{ ...rest }} type="confirm">
    <DialogContent>{children}</DialogContent>
  </SimpleDialog>
)

ConfirmDialog.propTypes = SimpleDialog.propTypes

export default ConfirmDialog
