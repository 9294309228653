import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { toPendingCancelationTransition } from 'store/jobs/jobActions'
import {
  toPendingCancelationTransitionIsLoadingSelector,
  jobIdSelector,
  statusOrSubstatusSelector,
} from 'store/jobs/jobSelectors'
import { showStatusJobDetailsSelector } from 'store/Application/ApplicationSelectors'
import PropTypes from 'prop-types'
import ToPendingCancelationLink from './ToPendingCancelationLink'

const ToPendingCancelationLinkContainer = ({
  jobIri,
  status,
  showStatus,
  isLoading,
  updateJobStatusAction,
}) =>
  jobIri && status && showStatus ? (
    <ToPendingCancelationLink
      jobIri={jobIri}
      status={status}
      showStatus={showStatus}
      isLoading={isLoading}
      updateJobStatusAction={updateJobStatusAction}
    />
  ) : (
    ''
  )

ToPendingCancelationLinkContainer.propTypes = {
  jobIri: PropTypes.string,
  status: PropTypes.string,
  showStatus: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  updateJobStatusAction: PropTypes.func.isRequired,
}

ToPendingCancelationLinkContainer.defaultProps = {
  jobIri: undefined,
  status: undefined,
  isLoading: undefined,
}

const mapStateToProps = state => ({
  jobIri: jobIdSelector(state),
  status: statusOrSubstatusSelector(state),
  showStatus: showStatusJobDetailsSelector(state),
  isLoading: toPendingCancelationTransitionIsLoadingSelector(state),
})

const mapDispatchToProps = dispatch => ({
  updateJobStatusAction: jobIri =>
    dispatch(toPendingCancelationTransition.request({ jobIri })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ToPendingCancelationLinkContainer)
