import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import i18n from 'providers/i18n/I18nProvider'
import PropTypes from 'prop-types'
import { setBarTitle } from 'store/Application/ApplicationActions'
import JobsSearchContainer from 'components/Jobs/JobsSearch/JobsSearchContainer'
import { withRouter } from 'react-router-dom'

const JobsSearch = ({ setTitle, translate }) => {
  useEffect(() => {
    setTitle(translate(`resources.jobs.navbar.title.search`))
  }, [setTitle, translate])

  return <JobsSearchContainer />
}

JobsSearch.propTypes = {
  translate: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
})

export default compose(
  i18n,
  connect(null, mapDispatchToProps),
  withRouter,
)(JobsSearch)
