import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { dateFormatter } from 'helpers/date'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  FormControl,
} from '@material-ui/core'
import Icon from 'components/shared/Icon/Icon'
import { incidentInitialStatusAndWaitingForSelector } from 'store/jobs/jobSelectors'
import {
  doChangeStatus,
  doDisplayRespSelectDialog,
} from 'store/incidents/incidentActions'
import uuidv1 from 'uuid/v1'
import {
  INCIDENT_NEW_STATUS,
  INCIDENT_SOLVED_STATUS,
  INCIDENT_CANCELED_STATUS,
} from 'constants/incidents'
import { compose } from 'redux'
import classNames from 'classnames'
import LinkCustom from 'components/shared/Link/LinkCustom'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { LIST_INCIDENT_STATUSES_FORM } from 'constants/forms'
import { getBoldOptions } from 'helpers/utils/incident/incident'
import RenderSelectField from '../shared/Form/RenderSelectField'
import styles from '../Jobs/JobDetails/JobDetailsIncidents/JobDetailsIncidentsStyles'

const TableIncidents = ({
  incidents,
  translate,
  classes,
  statuses,
  updateWaitingFor,
  waitingForList,
  displayRespSelectDialog,
  changeStatus,
}) => {
  const dateFormat = translate('app.date_format.long')

  const canceledIncident = incidents.filter(
    inc => inc.status === INCIDENT_CANCELED_STATUS,
  )
  const newIncident = incidents.filter(
    inc => inc.status === INCIDENT_NEW_STATUS,
  )
  const solvedIncident = incidents.filter(
    inc => inc.status === INCIDENT_SOLVED_STATUS,
  )

  const restOfIncident = incidents.filter(
    inc =>
      ![
        INCIDENT_NEW_STATUS,
        INCIDENT_SOLVED_STATUS,
        INCIDENT_CANCELED_STATUS,
      ].includes(inc.status),
  )

  const renderResponsibleLabel = responsible =>
    responsible
      ? translate(`resources.incidents.responsible.${responsible}`)
      : '—'

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row" key="incidentIdLabel">
            {translate('resources.jobs.fields.incident.incident_id')}
          </TableCell>
          <TableCell component="th" scope="row" key="statusLabel">
            {translate('resources.jobs.fields.incident.status')}
          </TableCell>
          <TableCell component="th" scope="row" key="waiting_for">
            {translate('resources.jobs.fields.incident.waiting_for')}
          </TableCell>
          <TableCell component="th" scope="row" key="typeCode">
            {translate('resources.jobs.fields.incident.type_code')}
          </TableCell>
          <TableCell component="th" scope="row" key="typeLabel">
            {translate('resources.jobs.fields.incident.type_label')}
          </TableCell>
          <TableCell component="th" scope="row" key="descriptionLabel">
            {translate('resources.jobs.fields.incident.description')}
          </TableCell>
          <TableCell component="th" scope="row" key="createdAtLabel">
            {translate('resources.jobs.fields.incident.created_at')}
          </TableCell>
          <TableCell component="th" scope="row" key="responsability">
            {translate('resources.jobs.fields.incident.responsability')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[
          ...newIncident,
          ...restOfIncident,
          ...solvedIncident,
          ...canceledIncident,
        ].map(
          ({
            type,
            description,
            status,
            createdAt,
            incidentId,
            responsible,
            statusLabel,
          }) => (
            <TableRow
              key={uuidv1()}
              className={classNames({
                [classes.newIncident]: status === INCIDENT_NEW_STATUS,
                [classes.solved]: status === INCIDENT_SOLVED_STATUS,
              })}
            >
              <TableCell component="td" scope="row">
                {incidentId}
              </TableCell>
              <TableCell component="td" scope="row">
                {status !== INCIDENT_CANCELED_STATUS &&
                status !== INCIDENT_SOLVED_STATUS ? (
                  <FormControl className={classes.formControl}>
                    <Field
                      id={incidentId}
                      choices={statuses}
                      component={RenderSelectField}
                      optionValue="key"
                      optionText="value"
                      name={incidentId}
                      onChange={(e, newStatus) =>
                        changeStatus({ incidentId, newStatus })
                      }
                      boldOptions={getBoldOptions(status)}
                    />
                  </FormControl>
                ) : (
                  statusLabel
                )}
              </TableCell>
              <TableCell component="td" scope="row">
                {status !== INCIDENT_CANCELED_STATUS &&
                status !== INCIDENT_SOLVED_STATUS ? (
                  <FormControl className={classes.formControl}>
                    <Field
                      allowEmpty
                      id={`waitingFor-${incidentId}`}
                      name={`waitingFor-${incidentId}`}
                      choices={waitingForList}
                      component={RenderSelectField}
                      optionText="value"
                      optionValue="key"
                      onChange={({ target }) =>
                        updateWaitingFor(incidentId, target.value)
                      }
                    />
                  </FormControl>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell component="td" scope="row">
                {type.code}
              </TableCell>
              <TableCell component="td" scope="row">
                {type.label}
              </TableCell>
              <TableCell component="td" scope="row">
                {description || ''}
              </TableCell>
              <TableCell component="td" scope="row">
                {createdAt ? dateFormatter(createdAt, dateFormat, true) : '—'}
              </TableCell>
              <TableCell component="td" scope="row">
                {responsible ? (
                  <LinkCustom
                    onClick={() =>
                      displayRespSelectDialog({ incidentId, type })
                    }
                    className={classes.link}
                    disabled={
                      status === INCIDENT_CANCELED_STATUS ||
                      status === INCIDENT_SOLVED_STATUS
                    }
                  >
                    {renderResponsibleLabel(responsible)}
                  </LinkCustom>
                ) : (
                  status === INCIDENT_CANCELED_STATUS ||
                  status === INCIDENT_SOLVED_STATUS || (
                    <Icon
                      className={classes.icon}
                      icon="edit.svg"
                      onClick={() =>
                        displayRespSelectDialog({ incidentId, type })
                      }
                    />
                  )
                )}
              </TableCell>
            </TableRow>
          ),
        )}
      </TableBody>
    </Table>
  )
}

TableIncidents.propTypes = {
  classes: PropTypes.shape({
    newIncident: PropTypes.string,
    solved: PropTypes.string,
    link: PropTypes.string,
    icon: PropTypes.string,
    formControl: PropTypes.string,
  }).isRequired,
  incidents: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.shape({
        label: PropTypes.string.isRequired,
      }).isRequired,
      status: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
    }),
  ),
  translate: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateWaitingFor: PropTypes.func.isRequired,
  waitingForList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  displayRespSelectDialog: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
}

TableIncidents.defaultProps = {
  incidents: [],
}

const mapStateToProps = state => ({
  initialValues: incidentInitialStatusAndWaitingForSelector(state),
})

const mapDispatchToProps = dispatch => ({
  displayRespSelectDialog: ({ incidentId, type }) =>
    dispatch(
      doDisplayRespSelectDialog({
        payload: { incidentId, type, displayed: true },
      }),
    ),
  changeStatus: ({ incidentId, newStatus }) =>
    dispatch(doChangeStatus({ payload: { incidentId, newStatus } })),
})

export default compose(
  i18n,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: LIST_INCIDENT_STATUSES_FORM, enableReinitialize: true }),
)(TableIncidents)
