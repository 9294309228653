const styles = () => ({
  expansionPanelBloc: {
    margin: '3rem !important',
    display: 'block',
    padding: '1rem',
    background: 'white',
    borderRadius: '4px',
  },
  heading: {
    fontSize: '1.4rem',
    fontWeight: '500',
    color: '#143C58',
  },
  expansionPanelDetailsBlock: {
    width: '100%',
    display: 'block',
  },
  iconTargetPanel: {
    position: 'absolute',
    top: '35px',
    right: '60px',
    zIndex: 1,
  },
  icon: {
    width: '20px',
    height: '20px',
    backgroundSize: 'contain',
  },
  panelWithIcon: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default styles
