import { connect } from 'react-redux'
import { compose } from 'redux'
import { formValueSelector, reduxForm } from 'redux-form'
import { formatIriToId } from 'helpers/utils/common'
import i18n from 'providers/i18n/I18nProvider'
import {
  createTravelCompensationPurchaseInvoice,
  doDisplayTravelCompensationPurchaseInvoiceCreationDialog,
} from 'store/invoices/invoiceActions'
import {
  detailAcceptedJobProSelector,
  formattedJobIdSelector,
} from 'store/jobs/jobSelectors'
import { PRO_FIELD_NAME } from 'constants/Jobs'

import CreateTravelCompensationPurchaseInvoiceForm from './CreateTravelCompensationPurchaseInvoiceForm'

export const TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION_FORM_NAME =
  'TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION'

const selectTravelCompensationPurchaseInvoiceCreationFormValue = formValueSelector(
  TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION_FORM_NAME,
)

const mapStateToProps = state => ({
  prosHavingBeenPlannedOnJob: detailAcceptedJobProSelector(state),
  jobId: formattedJobIdSelector(state),
  proIri: selectTravelCompensationPurchaseInvoiceCreationFormValue(
    state,
    PRO_FIELD_NAME,
  ),
})

const mapDispatchToProps = dispatch => ({
  hideTravelCompensationPurchaseInvoiceCreationDialog: () =>
    dispatch(
      doDisplayTravelCompensationPurchaseInvoiceCreationDialog({
        payload: { displayed: false },
      }),
    ),
  doCreateTravelCompensationPurchaseInvoice: (jobId, proId) =>
    dispatch(createTravelCompensationPurchaseInvoice.request({ jobId, proId })),
})

const mergeProps = (
  { jobId, proIri, ...otherStateProp },
  { doCreateTravelCompensationPurchaseInvoice, ...otherDispatchProps },
  ownProps,
) => ({
  ...ownProps,
  ...otherStateProp,
  ...otherDispatchProps,
  handleTravelCompensationPurchaseInvoiceCreationFormSubmit: () => {
    doCreateTravelCompensationPurchaseInvoice(jobId, formatIriToId(proIri))
    otherDispatchProps.hideTravelCompensationPurchaseInvoiceCreationDialog()
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({ form: TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION_FORM_NAME }),
  i18n,
)(CreateTravelCompensationPurchaseInvoiceForm)
