import { connect } from 'react-redux'
import { compose } from 'redux'
import i18n from 'providers/i18n/I18nProvider'
import { getCerfaSignature } from 'store/jobs/jobActions'
import { uploadJobCerfa } from 'store/upload/uploadActions'
import {
  jobIdSelector,
  isInProgressOrFinishedCerfaSelector,
  cerfaSignatureSelector,
  isSignedCerfaSelector,
  cerfaSignatureStatusSelector,
  isDocumentCreatedSelector,
  documentChipTypeSelector,
  documentTotalMemberSelector,
  documentMembersWhoSignedSelector,
  cerfaManualSignStatusSelector,
} from 'store/jobs/jobSelectors'
import Cerfa from './Cerfa'

const mapStateToProps = state => ({
  jobIri: jobIdSelector(state),
  cerfaSignature: cerfaSignatureSelector(state),
  cerfaSignatureStatus: cerfaSignatureStatusSelector(state),
  isInProgressOrFinishedCerfa: isInProgressOrFinishedCerfaSelector(state),
  isDocumentCreated: isDocumentCreatedSelector(cerfaSignatureStatusSelector)(
    state,
  ),
  chipType: documentChipTypeSelector(cerfaSignatureStatusSelector)(state),
  totalMember: documentTotalMemberSelector(cerfaSignatureSelector)(state),
  membersWhoSigned: documentMembersWhoSignedSelector(cerfaSignatureSelector)(
    state,
  ),
  isSignedCerfa: isSignedCerfaSelector(state),
  cerfaManualSignStatus: cerfaManualSignStatusSelector(state),
})

const mapDispatchToProps = (dispatch, { jobIri }) => ({
  dispatchCerfaSignature: () => {
    dispatch(getCerfaSignature.request({ jobIri }))
  },
  dispatchUploadJobCerfa: (file, additionalInfo) =>
    dispatch(uploadJobCerfa(file, additionalInfo)),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
)(Cerfa)
