import React from 'react'
import PropTypes from 'prop-types'
import Invoice from 'components/Jobs/JobDetails/JobDetailsWorkflow/ValidateBills/Invoice/Invoice'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import { DATE_FORMAT } from 'constants/date'
import {
  INVOICE_PRO,
  INVOICE_STATE_AWAITING_VALIDATION,
  INVOICE_STATE_BLOCKED,
  INVOICE_STATE_CONTESTED,
} from 'constants/invoices'
import Chip from 'components/shared/Chip/Chip'
import { dateFormatter } from 'helpers/date'
import {
  getInvoiceIdFromIri,
  getChipType,
  getDisplayedState,
} from 'helpers/utils/invoice/invoice'
import { convertCentsToEuros } from 'helpers/utils/common'
import {
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Grid,
} from '@material-ui/core'
import UiLink from '@material-ui/core/Link'
import CreateTravelCompensationPurchaseInvoiceFormContainer from 'components/Jobs/JobDetails/JobDetailsInvoices/CreateTravelCompensationPurchaseInvoiceForm/CreateTravelCompensationPurchaseInvoiceFormContainer'
import Icon from 'components/shared/Icon/Icon'

import JobDetailsInvoicesContestationContainer from './JobDetailsInvoicesContestationContainer'
import styles from './JobDetailsInvoicesStyles'
import CreatePurchaseInvoiceFormContainer from './CreatePurchaseInvoiceForm/CreatePurchaseInvoiceFormContainer'

const JobDetailsInvoices = ({
  classes,
  translate,
  invoices,
  displayedInvoice,
  dispatchSetDisplayedInvoice,
  dispatchResetDisplayedInvoice,
  displayTravelCompensationPurchaseInvoiceCreationDialog,
  displayPurchaseInvoiceCreationDialog,
  createTravelCompensationPurchaseInvoiceDialogDisplayed,
  createPurchaseInvoiceDialogDisplayed,
  travelCompensationPurchaseInvoiceFeatureEnabled,
  isAdditionalInvoiceFormEnabled,
}) => {
  const showContestationColumn =
    invoices.totalItems > 0 &&
    !!invoices.list.find(
      invoice =>
        invoice.state === INVOICE_STATE_AWAITING_VALIDATION ||
        invoice.state === INVOICE_STATE_CONTESTED ||
        invoice.state === INVOICE_STATE_BLOCKED,
    )

  return (
    <>
      <>
        <Grid item>
          <div className={classes.buttons}>
            {travelCompensationPurchaseInvoiceFeatureEnabled && (
              <Button
                className={classes.buttonBigger}
                variant="contained"
                color="secondary"
                onClick={displayTravelCompensationPurchaseInvoiceCreationDialog}
              >
                {translate('resources.jobs.button.create_displacement_invoice')}
              </Button>
            )}
            {isAdditionalInvoiceFormEnabled && (
              <>
                <Button
                  className={classes.buttonBigger}
                  variant="contained"
                  color="secondary"
                  onClick={displayPurchaseInvoiceCreationDialog}
                >
                  {translate('resources.jobs.button.create_invoice')}
                </Button>
                {createPurchaseInvoiceDialogDisplayed && (
                  <CreatePurchaseInvoiceFormContainer />
                )}
              </>
            )}
          </div>
        </Grid>
        {createTravelCompensationPurchaseInvoiceDialogDisplayed && (
          <CreateTravelCompensationPurchaseInvoiceFormContainer />
        )}
      </>
      {displayedInvoice && (
        <SimpleDialog title="" onClose={dispatchResetDisplayedInvoice}>
          <Invoice invoiceId={displayedInvoice} />
        </SimpleDialog>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{translate('invoice.invoiceNumber.label')}</TableCell>
            <TableCell>{translate('invoice.reference.label2')}</TableCell>
            <TableCell>{translate('invoice.store.code')}</TableCell>
            <TableCell>{translate('invoice.company.label')}</TableCell>
            <TableCell>
              {translate('invoice.amountExcludingTaxes.label')}
            </TableCell>
            <TableCell>{translate('invoice.issued_at.label')}</TableCell>
            <TableCell>{translate('invoice.status.label')}</TableCell>
            {showContestationColumn && (
              <TableCell>{translate('invoice.contestation.label')}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.totalItems > 0 &&
            invoices.list.map(invoice => {
              const {
                number,
                partnerOrderNumber,
                state,
                stateUpdatedAt,
                store,
                issuedAt,
                customerBillingData,
                providerBillingData,
                subtotal,
              } = invoice

              const displayedState = getDisplayedState(state, stateUpdatedAt)

              const invoiceId = parseInt(
                getInvoiceIdFromIri(invoice['@id']),
                10,
              )

              return (
                <TableRow key={invoiceId}>
                  <TableCell>
                    <UiLink
                      href="#"
                      onClick={event => {
                        event.preventDefault()
                        dispatchSetDisplayedInvoice(invoiceId)
                      }}
                      className={classes.menuLink}
                    >
                      {number || translate('invoice.proforma.label')}
                      <Icon className={classes.icon} icon="eye.svg" />
                    </UiLink>
                  </TableCell>
                  <TableCell>{partnerOrderNumber}</TableCell>
                  <TableCell>{`${store.name} (${store.codeAccount})`}</TableCell>
                  <TableCell>
                    {invoice['@type'] === INVOICE_PRO
                      ? providerBillingData.company
                      : customerBillingData.company}
                  </TableCell>
                  <TableCell>{convertCentsToEuros(subtotal)} €</TableCell>
                  <TableCell>{dateFormatter(issuedAt, DATE_FORMAT)}</TableCell>
                  <TableCell>
                    <span className={classes.dateStatus}>
                      {dateFormatter(stateUpdatedAt, DATE_FORMAT)}
                    </span>
                    <Chip
                      label={translate(`invoice.state.${displayedState}`)}
                      type={getChipType(displayedState)}
                    />
                  </TableCell>
                  {showContestationColumn && (
                    <TableCell>
                      <JobDetailsInvoicesContestationContainer
                        invoice={invoice}
                      />
                    </TableCell>
                  )}
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </>
  )
}

JobDetailsInvoices.propTypes = {
  classes: PropTypes.shape({
    menuLink: PropTypes.string.isRequired,
    dateStatus: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    buttons: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  invoices: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    totalItems: PropTypes.number,
  }).isRequired,
  dispatchSetDisplayedInvoice: PropTypes.func.isRequired,
  dispatchResetDisplayedInvoice: PropTypes.func.isRequired,
  displayedInvoice: PropTypes.number,
  displayTravelCompensationPurchaseInvoiceCreationDialog:
    PropTypes.func.isRequired,
  displayPurchaseInvoiceCreationDialog: PropTypes.func.isRequired,
  createTravelCompensationPurchaseInvoiceDialogDisplayed:
    PropTypes.bool.isRequired,
  createPurchaseInvoiceDialogDisplayed: PropTypes.bool.isRequired,
  travelCompensationPurchaseInvoiceFeatureEnabled: PropTypes.bool.isRequired,
  isAdditionalInvoiceFormEnabled: PropTypes.bool.isRequired,
}

JobDetailsInvoices.defaultProps = {
  displayedInvoice: null,
}

export default withStyles(styles)(JobDetailsInvoices)
