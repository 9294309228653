import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { withStyles, Dialog, Button } from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DropzoneArea } from 'material-ui-dropzone'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { fileLoadFailure } from 'store/upload/uploadActions'

import { connect } from 'react-redux'
import { PRO_DOCUMENT_UPLOAD_SIZE_LIMIT } from 'constants/documents'
import Styles from './UploaderStyles'
import UploaderRequirements from './UploaderRequirements'

class UploaderPopIn extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      additionalInfo: { isContested: false },
      formIsValidated: false,
    }
  }

  getFiles = () => {
    const { filesLimit } = this.props
    const { file, files } = this.state

    if (filesLimit > 1) {
      return files
    }

    return file
  }

  handleClosing = () => {
    const { onClose } = this.props

    // Reset the state of the component on close
    this.setState({
      file: undefined,
      files: undefined,
      formIsValidated: false,
      additionalInfo: { isContested: false },
    })

    return onClose()
  }

  handleValidation = () => {
    const { onValidation, editionMode } = this.props
    const { additionalInfo } = this.state
    const files = this.getFiles()

    // If file is uploaded
    if (files || editionMode) {
      onValidation(files, additionalInfo)
    }

    // Close the popin
    return this.handleClosing()
  }

  handleFileLoad = files => {
    const [file] = files

    if (!file) {
      return
    }

    const { onSuccessFileLoad, filesLimit } = this.props
    onSuccessFileLoad(file.name)
    this.setState({ file })

    if (filesLimit > 1) {
      this.setState({ files })
    }
  }

  toggleCheckbox = ({ target }) => {
    this.setState(prevState => ({
      additionalInfo: {
        ...prevState.additionalInfo,
        isContested: target.checked,
      },
    }))
  }

  setFormIsValid = formIsValidated => {
    this.setState({ formIsValidated })
  }

  setAdditionalInfo = additionalInfo => {
    this.setState({ additionalInfo })
  }

  addAdditionalInfo = additionalInfo => {
    this.setState(prevState => ({
      additionalInfo: { ...prevState.additionalInfo, ...additionalInfo },
    }))
  }

  isDisabled = () => {
    const { FormComponent, editionMode = false } = this.props
    const { file, formIsValidated } = this.state
    if (!FormComponent) {
      return !file
    }

    // Check if the form is valid when it is present only
    return editionMode ? !formIsValidated : !file || !formIsValidated
  }

  render() {
    const {
      acceptedFiles,
      open,
      onClose,
      translate,
      filesLimit,
      dropzoneText,
      reserveLabel,
      checkboxNeeded,
      title,
      FormComponent,
      formOption,
      classes,
      onFailureFileLoad,
    } = this.props
    const {
      additionalInfo: { isContested },
    } = this.state
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <UploaderRequirements acceptedFiles={acceptedFiles} />
          <DropzoneArea
            acceptedFiles={acceptedFiles}
            showFileNames
            filesLimit={filesLimit}
            onChange={this.handleFileLoad}
            onDropRejected={files => onFailureFileLoad(files[0].name)}
            dropzoneText={
              dropzoneText || translate('resources.form.dropZoneText')
            }
            showAlerts={false}
            maxFileSize={PRO_DOCUMENT_UPLOAD_SIZE_LIMIT}
          />
          <DialogContentText id="alert-dialog-description" />

          {checkboxNeeded && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isContested}
                  value="contestationCheckBox"
                  onChange={this.toggleCheckbox}
                />
              }
              label={reserveLabel}
            />
          )}
          {FormComponent && (
            <FormComponent
              setFormIsValid={this.setFormIsValid}
              setAdditionalInfo={this.setAdditionalInfo}
              addAdditionalInfo={this.addAdditionalInfo}
              formOption={formOption}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClosing} className={classes.buttonCancel}>
            {translate('app.action.cancel')}
          </Button>
          <Button
            onClick={this.handleValidation}
            className={classes.buttonBigger}
            disabled={this.isDisabled()}
            autoFocus
          >
            {translate('app.action.validate')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

UploaderPopIn.propTypes = {
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidation: PropTypes.func.isRequired,
  onSuccessFileLoad: PropTypes.func.isRequired,
  onFailureFileLoad: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  filesLimit: PropTypes.number,
  checkboxNeeded: PropTypes.bool,
  editionMode: PropTypes.bool,
  dropzoneText: PropTypes.string,
  reserveLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  formOption: PropTypes.shape({}),
  FormComponent: PropTypes.func,
  classes: PropTypes.shape({
    buttonBigger: PropTypes.string,
    buttonCancel: PropTypes.string,
  }).isRequired,
}

UploaderPopIn.defaultProps = {
  acceptedFiles: ['application/pdf'],
  filesLimit: 1,
  checkboxNeeded: false,
  editionMode: false,
  formOption: { initialValues: {}, readOnly: false },
  dropzoneText: '',
  reserveLabel: null,
  FormComponent: null,
}

const mapDispatchToProps = dispatch => ({
  onFailureFileLoad: fileName => dispatch(fileLoadFailure(fileName)),
})

export default compose(
  connect(null, mapDispatchToProps),
  i18n,
  withStyles(Styles),
)(UploaderPopIn)
