import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { jobIdSelector } from 'store/jobs/jobSelectors'
import { worksiteJobsSelector } from 'store/worksites/worksiteSelectors'
import { showStatusJobDetailsSelector } from 'store/Application/ApplicationSelectors'
import JobDetailsWorksiteJobsNavigation from './JobDetailsWorksiteJobsNavigation'

const JobDetailsWorksiteJobsNavigationContainer = ({
  jobId,
  jobs,
  showStatus,
}) =>
  jobs ? (
    <JobDetailsWorksiteJobsNavigation
      jobId={jobId}
      jobs={jobs}
      showStatus={showStatus}
    />
  ) : null

JobDetailsWorksiteJobsNavigationContainer.propTypes = {
  jobId: PropTypes.string.isRequired,
  jobs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showStatus: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  jobId: jobIdSelector(state),
  jobs: worksiteJobsSelector(state),
  showStatus: showStatusJobDetailsSelector(state),
})

export default compose(
  i18n,
  connect(mapStateToProps),
  withRouter,
)(JobDetailsWorksiteJobsNavigationContainer)
