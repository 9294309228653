import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { TableCell, TableRow } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import SortableTableCell from 'components/shared/Tables/SortableTableCell'

const FirmsListTableHead = ({
  translate,
  additionalProps: { hasPackages },
  orderBy,
  sort,
  handleChangeSort,
}) => (
  <TableRow>
    <TableCell />
    <TableCell>{translate('firm.id.label')}</TableCell>
    <TableCell>{translate('firm.company.label')}</TableCell>
    <TableCell>{translate('firm.username.label')}</TableCell>
    {hasPackages && <TableCell>{translate('firm.packages.label')}</TableCell>}
    <TableCell>{translate('firm.trades.label')}</TableCell>
    <TableCell>{translate('firm.packages_subscription.label')}</TableCell>
    <SortableTableCell
      sortField="contractSignedAt"
      label={translate('firm.contract_signedAt.label')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <TableCell>{translate('firm.origin.label')}</TableCell>
    <TableCell>{translate('firm.cp.label')}</TableCell>
    <TableCell>{translate('firm.intervention_area.label')}</TableCell>
    <SortableTableCell
      sortField="onboardingScore"
      label={translate('firm.onboardingscore.label')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <SortableTableCell
      sortField="lastFirmCommentDate"
      label={translate('firm.lastCommentDate.label')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
  </TableRow>
)

FirmsListTableHead.propTypes = {
  translate: PropTypes.func.isRequired,
  additionalProps: PropTypes.shape({
    hasPackages: PropTypes.bool.isRequired,
  }).isRequired,
  handleChangeSort: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  orderBy: PropTypes.string,
}

FirmsListTableHead.defaultProps = {
  orderBy: null,
}

export default compose(i18n)(FirmsListTableHead)
