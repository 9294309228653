import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import uuidv1 from 'uuid/v1'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

const renderOptionText = (element, optionText) => {
  if (!element) return ''

  return typeof optionText === 'function'
    ? optionText(element)
    : get(element, optionText)
}

/**
 * Usage :
 *
 *    <Field
        name="timestols"
        component={RenderSelectTimeslotsField}
        choices={[
          {
              "startTime":"2019-09-24T06:00:00Z",
              "stopTime":"2019-09-24T11:30:00Z"
          },
          {
              "startTime":"2019-09-25T06:00:00Z",
              "stopTime":"2019-09-25T11:30:00Z"
          }
        ]}
        allowEmpty
      />
 */

const RenderSelectTimeslotsField = ({
  input,
  label,
  meta: { touched, error },
  choices,
  optionText,
  optionValue,
  id,
  allowEmpty,
  ...custom
}) => (
  <FormControl error={touched && error !== undefined} fullWidth>
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: input.name,
        id,
      }}
    >
      {allowEmpty && <option value="" />}
      {choices.map(choice => (
        <option key={uuidv1()} value={[choice.startTime, choice.stopTime]}>
          {renderOptionText(choice, optionText)}
        </option>
      ))}
    </Select>
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)

RenderSelectTimeslotsField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    touch: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
  id: PropTypes.string,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  optionValue: PropTypes.string,
  allowEmpty: PropTypes.bool,
}

RenderSelectTimeslotsField.defaultProps = {
  label: '',
  id: `select-${uuidv1()}`,
  optionText: 'name',
  optionValue: 'value',
  meta: {},
  allowEmpty: false,
}

export default RenderSelectTimeslotsField
