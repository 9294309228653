import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import { history } from 'store/store'

import NotFound from 'pages/NotFound/NotFound'
import Login from 'pages/Login/LoginContainer'

import PrivateRoute from 'components/PrivateRoute/PrivateRouteContainer'
import AdminRoutes from 'components/AdminRoutes/AdminRoutes'

import {
  HOME_PATH,
  LOGIN_PATH,
  EMPTY_PATH,
  ADMIN_PATH_PREFIX,
  ERROR_PATH,
} from 'constants/routes'
import Error from './pages/Error/Error'

const App = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Route exact path={EMPTY_PATH} render={() => <div />} />
      <Route exact path={ERROR_PATH} component={Error} />
      <Route exact path={HOME_PATH} render={() => <div />} />
      <Route exact path={LOGIN_PATH} render={() => <Login />} />
      <PrivateRoute path={ADMIN_PATH_PREFIX} component={AdminRoutes} />
      <PrivateRoute component={NotFound} />
    </Switch>
  </ConnectedRouter>
)

export default App
