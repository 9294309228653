import { all, put, select, takeLatest } from 'redux-saga/effects'
import ls from 'local-storage'
import { firmLegacyIdSelector } from 'store/firms/firmSelectors'
import { updateSearchJobParams } from 'store/jobs/jobActions'
import { SET_LS_LEGACYID, GET_LS_LEGACYID } from './localStorageActions'

export function* handleSetLSProLegacyId() {
  try {
    const proLegacyId = yield select(firmLegacyIdSelector)
    ls.set('proLegacyId', proLegacyId)
  } catch (e) {
    console.error(e)
  }
}

export function* handleGetLSProLegacyId() {
  try {
    const legacyId = ls.get('proLegacyId')

    if (!legacyId) {
      return
    }

    yield put(
      updateSearchJobParams({
        filters: [{ field: 'pro', values: legacyId }],
      }),
    )
    ls.remove('proLegacyId')
  } catch (e) {
    console.error(e)
  }
}

export default function*() {
  yield all([
    takeLatest(SET_LS_LEGACYID, handleSetLSProLegacyId),
    takeLatest(GET_LS_LEGACYID, handleGetLSProLegacyId),
  ])
}
