import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  CardContent,
  Card,
} from '@material-ui/core'
import { dateFormatter } from 'helpers/date'
import {
  JOB_EVENT_TYPE,
  JOB_EVENT_HISTORY_PAGE,
  JOB_EVENT_HISTORY_PER_PAGE,
} from 'constants/Jobs'
import classNames from 'classnames'
import CommentForm from './CommentForm'
import styles from './JobHistoryStyles'

const JobHistory = ({
  classes,
  searchParams,
  translate,
  jobId,
  handleChangePage,
  handleChangeRowsPerPage,
  setLabelDisplayedRows,
  currentJobHistory,
}) => (
  <Card>
    <CardContent>
      <Typography component="p">
        {translate('resources.jobs.comment.rgpd_condition')}
      </Typography>
      <CommentForm jobId={jobId} searchParams={searchParams} />
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              {translate('job.job_events_for_job.col_headers.date')}
            </TableCell>
            <TableCell>
              {translate('job.job_events_for_job.col_headers.who')}
            </TableCell>
            <TableCell>
              {translate('job.job_events_for_job.col_headers.comment')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentJobHistory.events.map(jobEvent => {
            const { type, date, actor, comment } = jobEvent
            return (
              <TableRow
                key={jobEvent['@id']}
                className={classNames({
                  [classes.statusChange]:
                    type === JOB_EVENT_TYPE.STATUS_CHANGE ||
                    type === JOB_EVENT_TYPE.REQUIREMENTS_STATUS_CHANGE,
                  [classes.reMatching]: type === JOB_EVENT_TYPE.RE_MATCHING,
                  [classes.incident]: type === JOB_EVENT_TYPE.INCIDENT,
                  [classes.geolocation]:
                    type === JOB_EVENT_TYPE.START_GEOLOCATION ||
                    type === JOB_EVENT_TYPE.STOP_GEOLOCATION_CHECK_IN ||
                    type === JOB_EVENT_TYPE.STOP_GEOLOCATION_PRO_BUTTON ||
                    type === JOB_EVENT_TYPE.STOP_GEOLOCATION_UNEXPECTED,
                  [classes.checkinHo]: type === JOB_EVENT_TYPE.HO_CHECK_IN,
                })}
              >
                <TableCell component="th" scope="row">
                  {dateFormatter(date, translate('app.date_format.long'))}
                </TableCell>
                <TableCell>{actor}</TableCell>
                <TableCell align="left">
                  <div dangerouslySetInnerHTML={{ __html: comment }} />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={currentJobHistory.totalItems}
        rowsPerPage={searchParams.perPage}
        page={searchParams.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={translate('navigation.page_rows_per_page')}
        labelDisplayedRows={setLabelDisplayedRows}
      />
    </CardContent>
  </Card>
)

JobHistory.defaultProps = {
  currentJobHistory: {
    totalItems: 0,
    events: [],
  },
  searchParams: {
    perPage: JOB_EVENT_HISTORY_PER_PAGE,
    page: JOB_EVENT_HISTORY_PAGE,
  },
}

JobHistory.propTypes = {
  classes: PropTypes.shape({
    table: PropTypes.string.isRequired,
    statusChange: PropTypes.string.isRequired,
    incident: PropTypes.string.isRequired,
    checkinHo: PropTypes.string.isRequired,
    reMatching: PropTypes.string.isRequired,
    geolocation: PropTypes.string.isRequired,
  }).isRequired,
  jobId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    perPage: PropTypes.number,
    page: PropTypes.number,
  }),
  currentJobHistory: PropTypes.shape({
    totalItems: PropTypes.number,
    events: PropTypes.arrayOf(
      PropTypes.shape({
        '@id': PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        actor: PropTypes.string.isRequired,
        comment: PropTypes.string.isRequired,
      }),
    ),
  }),
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  setLabelDisplayedRows: PropTypes.func.isRequired,
}

export default withStyles(styles)(JobHistory)
