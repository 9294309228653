export const styles = theme => ({
  buttonBigger: theme.buttonBigger,
  buttonSecondary: theme.buttonSecondary,

  button: {
    padding: '0.7rem 3rem',
    borderRadius: '3px',
    textTransform: 'none',
    marginLeft: '2rem',
  },
})
