import {
  generateRequestActions,
  createRequestTypes,
  generateSimpleAction,
} from 'helpers/store/actionsHelpers'

export const GET_INVOICES_LIST = createRequestTypes('GET_INVOICES_LIST')
export const GET_JOB_INVOICES_LIST = createRequestTypes('GET_JOB_INVOICES_LIST')
export const GET_INVOICES_EXPORT = createRequestTypes('GET_INVOICES_EXPORT')
export const GET_SINGLE_INVOICE = createRequestTypes('GET_SINGLE_INVOICE')
export const RESET_FILTER_INVOICE = 'RESET_FILTER_INVOICE'
export const RESET_DISPUTE_INVOICE = 'RESET_DISPUTE_INVOICE'
export const INVOICE_CART_ADD_ITEM = 'INVOICE_CART_ADD_ITEM'
export const INVOICE_CART_UPDATE_ITEM = 'INVOICE_CART_UPDATE_ITEM'
export const INVOICE_CART_DELETE_ITEM = 'INVOICE_CART_DELETE_ITEM'
export const INVOICE_CART_PROCESS_TOTALS = 'INVOICE_CART_PROCESS_TOTALS'
export const SET_SUBJECT_TO_VAT = 'SET_SUBJECT_TO_VAT'
export const UPDATE_INVOICE = createRequestTypes('UPDATE_INVOICE')
export const RESET_INVOICES_LIST = 'RESET_INVOICES_LIST'
export const EXPORT_INVOICES_LIST = 'EXPORT_INVOICES_LIST'
export const SET_DISPLAYED_INVOICE = 'SET_DISPLAYED_INVOICE'

export const getInvoicesList = generateRequestActions(GET_INVOICES_LIST)
export const getJobInvoicesList = generateRequestActions(GET_JOB_INVOICES_LIST)
export const getInvoicesExport = generateRequestActions(GET_INVOICES_EXPORT)
export const getInvoiceDetails = generateRequestActions(GET_SINGLE_INVOICE)
export const resetDisputeInvoice = generateSimpleAction(RESET_DISPUTE_INVOICE)
export const resetFilterInvoice = generateSimpleAction(RESET_FILTER_INVOICE)
export const invoiceCartAddItem = generateSimpleAction(INVOICE_CART_ADD_ITEM)
export const invoiceCartUpdateItem = generateSimpleAction(
  INVOICE_CART_UPDATE_ITEM,
)
export const invoiceCartDeleteItem = generateSimpleAction(
  INVOICE_CART_DELETE_ITEM,
)
export const invoiceCartProcessTotals = generateSimpleAction(
  INVOICE_CART_PROCESS_TOTALS,
)
export const setSubjectToVat = generateSimpleAction(SET_SUBJECT_TO_VAT)
export const updateInvoice = generateRequestActions(UPDATE_INVOICE)
export const resetInvoicesList = generateSimpleAction(RESET_INVOICES_LIST)
export const exportInvoicesList = generateSimpleAction(EXPORT_INVOICES_LIST)
export const setDisplayedInvoice = generateSimpleAction(SET_DISPLAYED_INVOICE)

export const TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED =
  'TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED'
export const doDisplayTravelCompensationPurchaseInvoiceCreationDialog = generateSimpleAction(
  TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED,
)
export const CREATE_TRAVEL_COMPENSATION_PURCHASE_INVOICE = createRequestTypes(
  'CREATE_TRAVEL_COMPENSATION_PURCHASE_INVOICE',
)
export const createTravelCompensationPurchaseInvoice = generateRequestActions(
  CREATE_TRAVEL_COMPENSATION_PURCHASE_INVOICE,
)

export const PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED =
  'PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED'
export const doDisplayPurchaseInvoiceCreationDialog = generateSimpleAction(
  PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED,
)
export const CREATE_PURCHASE_INVOICE = createRequestTypes(
  'CREATE_PURCHASE_INVOICE',
)
export const createPurchaseInvoice = generateRequestActions(
  CREATE_PURCHASE_INVOICE,
)
export const GENERATE_INVOICE_AFTERSALE_REQ = createRequestTypes(
  'GENERATE_INVOICE_AFTERSALE',
)
export const generateInvoiceAfterSale = generateRequestActions(
  GENERATE_INVOICE_AFTERSALE_REQ,
)

export const CREATE_INVOICE_AFTERSALE_REQ = createRequestTypes(
  'CREATE_INVOICE_AFTERSALE',
)

export const createInvoiceAfterSale = generateRequestActions(
  CREATE_INVOICE_AFTERSALE_REQ,
)
