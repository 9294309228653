const styles = theme => ({
  content: {
    padding: theme.spacing(2),
  },
  boxShadow: {
    boxShadow: '0 3px 5px #00000055',
  },
})

export default styles
