import { createSelector } from 'reselect'
import { get } from 'lodash'
import { getFormValues } from 'redux-form'
import { POST_INCIDENT_FORM } from 'constants/forms'
import { mapCounterByCategories } from 'helpers/dashboard/card'

export const initialState = {
  incidentTypes: [],
  incidentWaitingForList: [],
  incidentStatuses: [],
  incidentCounts: [],
  dialogs: {
    respSelect: {
      displayed: false,
      doDisplayStatusChangeConfirmDialogAction: null,
    },
    statusChangeConfirm: {
      displayed: false,
    },
  },
  currentUpdated: {
    id: null,
    newStatus: null,
    type: null,
  },
}

export const incidentSelector = state => get(state, 'incidents')

export const selectCurrentUpdatedIncident = createSelector(
  incidentSelector,
  state => get(state, 'currentUpdated'),
)

const formValuesSelector = state => getFormValues(POST_INCIDENT_FORM)(state)

export const selectedNatureFromFormSelector = createSelector(
  formValuesSelector,
  formValues => get(formValues, 'incidentNature'),
)

export const selectedTypeFromFormSelector = createSelector(
  formValuesSelector,
  formValues => get(formValues, 'incidentType'),
)

export const incidentTypesSelector = createSelector(incidentSelector, state =>
  get(state, 'incidentTypes'),
)

const incidentWaitingForListSelector = createSelector(incidentSelector, state =>
  get(state, 'incidentWaitingForList'),
)

export const incidentWaitingForListForSelectSelector = createSelector(
  incidentWaitingForListSelector,
  waitingForList =>
    Object.entries(waitingForList).map(([key, value]) => ({ key, value })),
)

const incidentStatusesSelector = createSelector(incidentSelector, state =>
  get(state, 'incidentStatuses'),
)

export const incidentStatusesForSelectSelector = createSelector(
  incidentStatusesSelector,
  incidentStatuses =>
    Object.entries(incidentStatuses).map(([key, value]) => ({ key, value })),
)

export const incidentTypesByNatureSelector = state => {
  const incidentTypes = incidentTypesSelector(state)

  const selectedNature = selectedNatureFromFormSelector(state)

  return incidentTypes.filter(
    incidentType => incidentType.nature === selectedNature,
  )
}

export const incidentNaturesSelector = createSelector(
  incidentTypesSelector,
  incidentTypes =>
    Object.keys(
      incidentTypes.reduce(
        (natures, incidentType) => ({
          ...natures,
          [incidentType.nature]: incidentType,
        }),
        {},
      ),
    ).map(nature => ({ code: nature, label: nature })),
)

export const postedIncidentIdSelector = createSelector(
  incidentSelector,
  state => get(state, 'postedIncidentId'),
)

const incidentCountByStatusesSelector = createSelector(
  incidentSelector,
  state => get(state, 'incidentCounts'),
)

export const incidentsCountersSelector = (state, categories) =>
  mapCounterByCategories(categories, incidentCountByStatusesSelector(state))

export const selectRespSelectDialog = state =>
  incidentSelector(state).dialogs.respSelect
export const selectRespSelectDialogDisplayed = state =>
  incidentSelector(state).dialogs.respSelect.displayed
const selectStatusChangeConfirmDisplayed = state =>
  incidentSelector(state).dialogs.statusChangeConfirm.displayed

export const selectDisplayedDialogs = createSelector(
  selectRespSelectDialogDisplayed,
  selectStatusChangeConfirmDisplayed,
  (respSelectDisplayed, statusChangeConfirmDisplayed) => ({
    respSelect: respSelectDisplayed,
    statusChangeConfirm: statusChangeConfirmDisplayed,
  }),
)
