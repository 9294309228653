import {
  generateRequestActions,
  createRequestTypes,
} from 'helpers/store/actionsHelpers'

export const GET_FIRM_CONTRACT_OPTIONS = createRequestTypes(
  'GET_FIRM_CONTRACT_OPTIONS',
)

export const getFirmContractOptions = generateRequestActions(
  GET_FIRM_CONTRACT_OPTIONS,
)
