const styles = theme => ({
  buttonSecondary: { ...theme.buttonSecondary, maxWidth: '280px' },
  multiselect: {
    marginLeft: '20px',
    minWidth: 200,
    maxWidth: 300,
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  buttonBigger: {
    ...theme.buttonBigger,
    textTransform: 'uppercase',
    maxHeight: '40px',
  },
  buttonCancel: {
    ...theme.buttonBigger,
    color: 'grey',
    backgroundColor: 'none',
    textTransform: 'uppercase',
    maxHeight: '40px',
  },
  editIcon: {
    width: '20px',
    height: '20px',
    backgroundSize: 'contain',
    float: 'right',
    cursor: 'pointer',
  },
})

export default styles
