const styles = theme => ({
  root: {
    ...theme.root,
  },
  selectMenu: {
    ...theme.selectMenu,
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  label: {
    width: '70%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  icon: {
    width: '40px',
    height: '40px',
    backgroundSize: 'contain',
  },
})

export default styles
