export default theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  card: {
    minHeight: '327px',
  },
  input: {
    width: '80%',
    marginBottom: theme.spacing(1),
  },
  inputForcedUppercase: {
    width: '80%',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase !important',
  },
})
