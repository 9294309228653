const styles = () => ({
  root: {
    padding: '11rem 2rem 0',
    marginBottom: '-1.5rem',
    background: 'none',
  },
  JobsDetails: {
    backgroundColor: '#dae1e6',
  },
  ExpansionPanelBloc: {
    margin: '3rem',
    display: 'block',
    padding: '1rem',
    background: 'white',
    borderRadius: '4px',
  },
  timeslots: {
    display: 'block',
    marginTop: '1rem',
  },
  blockStatus: {
    position: 'fixed',
    top: '5px',
    right: '4rem',
    padding: '0.7rem 0',
    fontWeight: '600',
    textTransform: 'uppercase',
    zIndex: '10000',
  },
  fieldStatus: {
    fontWeight: 500,
  },
  client: {
    border: 0,
    margin: 0,
  },
  fields: {
    fontSize: '0.8rem',
  },
  address: {
    lineHeight: '0.2rem',
  },
  expansionPanelSummary: {
    display: 'inline-table',
    width: '50%',
  },
  expansionPanelDetails: {
    display: 'flex',
    width: '50%',
  },
  firstCard: {
    marginTop: '0',
    borderRadius: '0',
  },
  workflow: {
    display: 'block',
    padding: 0,
  },
  heading: {
    fontSize: '1.4rem',
    fontWeight: '500',
    color: '#143C58',
  },
  CardHeader: {
    fontSize: '0.7rem',
  },
  subtitlesOrder: {
    fontSize: '1.1rem',
    color: '#143C58',
    fontWeight: '500',
  },
  subtitlesPackages: {
    fontSize: '1.1rem',
    color: '#143C58',
    fontWeight: '500',
  },
  subtitlesClient: {
    fontSize: '1.1rem',
    color: '#143C58',
    fontWeight: '500',
  },
  iconOrder: {
    width: '40px',
    height: '40px',
    backgroundSize: 'contain',
  },
  iconProfile: {
    width: '40px',
    height: '30px',
    backgroundSize: 'contain',
  },
  imgPartner: {
    width: '64px',
    height: '64px',
  },
  orderTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
})

export default styles
