import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { dateFormatter } from 'helpers/date'
import { DATE_FORMAT } from 'constants/date'
import uuidv1 from 'uuid/v1'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import CardContent from '@material-ui/core/CardContent'
import { PRO_DOCUMENTS, FILE_UPLOAD_LIMIT } from 'constants/documents'
import { Typography, withStyles, Link, Grid, Box } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import {
  firmDocumentsSelector,
  firmCertificateSelector,
  firmCertificateTypesSelector,
} from 'store/firms/firmSelectors'
import { openFile, deleteDocument } from 'store/proDocuments/proDocumentActions'
import { getFirmCertificates } from 'store/firms/firmActions'
import Icon from 'components/shared/Icon/Icon'
import UploaderBtn from 'components/shared/Uploader/UploaderBtn'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import FirmCertificateSelectContainer from 'components/Firms/FirmDetails/FirmCertificate/FirmCertificateSelectContainer'

import { FIRM_CERTIFICATE_FORM } from 'constants/forms'
import { uploadDocument, fileLoadSuccess } from 'store/upload/uploadActions'
import { styles } from './FirmFilesStyles'
import FileUploader from './FileUploader'

const FirmFiles = ({
  translate,
  mandatory,
  firmDocuments,
  dispatchGetFirmCertificates,
  firmCertificateTypes,
  firmCertificates,
  dispatchOpenFile,
  dispatchDocumentDeletion,
  onValidationDocumentUploaded,
  onSuccessFileLoad,
  classes,
}) => {
  const [deletionDialogOpened, setDeletionDialogOpened] = useState(false)
  const [documentToDelete, setDocumentToDelete] = useState(null)

  useEffect(() => {
    dispatchGetFirmCertificates()
  }, [dispatchGetFirmCertificates])

  const openDeletionDialog = documentIri => {
    setDocumentToDelete(documentIri)
    setDeletionDialogOpened(true)
  }

  const handleDeletionDialogResponse = event => {
    const { value } = event.currentTarget

    if (value === 'true') {
      dispatchDocumentDeletion(documentToDelete)
    }

    setDeletionDialogOpened(false)
  }

  return (
    <>
      {deletionDialogOpened && (
        <ConfirmDialog
          title={translate('resources.firms.document_deletion.dialog_title')}
          onClose={handleDeletionDialogResponse}
        >
          <Typography component="p">
            {translate('resources.firms.document_deletion.dialog_message')}
          </Typography>
        </ConfirmDialog>
      )}
      <Grid container spacing={3}>
        {PRO_DOCUMENTS.filter(
          proDocument =>
            mandatory === null ||
            (mandatory === true && proDocument.mandatory) ||
            (mandatory === false && !proDocument.mandatory),
        ).map(proDocument => {
          const { name: documentType, meta, certificateType } = proDocument
          const documentTypeAlias = certificateType || documentType
          const { isCertificate } = meta

          let documentsByType = firmDocuments.filter(
            doc => doc.type.toLowerCase() === documentType.toLowerCase(),
          )

          if (isCertificate) {
            const certificatesByType = firmCertificates
              .filter(
                firmCertificate =>
                  firmCertificate.certificate.type === certificateType,
              )
              .map(file => file.firmFile && file.firmFile['@id'])

            documentsByType = firmDocuments.filter(doc =>
              doc.files.some(f => certificatesByType.includes(f['@id'])),
            )
          }

          const documentIri =
            documentsByType.length > 0 ? documentsByType[0]['@id'] : null

          const groupedFiles = documentsByType.sort((fileA, fileB) => {
            const dateA = fileA.expirationDate || fileA.issueDate
            const dateB = fileB.expirationDate || fileB.issueDate

            return new Date(dateB) - new Date(dateA)
          })

          const hasDocumentFiles = documentsByType.length > 0

          let initialValues = {}

          if (isCertificate) {
            const currentCertificate = firmCertificateTypes.find(
              certificate => certificate.type === documentTypeAlias,
            )

            initialValues = {
              ...initialValues,
              [FIRM_CERTIFICATE_FORM.FIELDS.TYPE]: currentCertificate
                ? currentCertificate.id
                : null,
            }
          }

          const formOption = {
            formName: `FIRM_DOCUMENT_FORM.${documentTypeAlias}`,
            documentIri,
            documentType: documentTypeAlias,
            initialValues,
            readOnly: isCertificate,
            document: PRO_DOCUMENTS.find(document =>
              isCertificate
                ? document.certificateType === certificateType
                : document.name === documentType,
            ),
          }

          return (
            <Grid item xs={6} key={uuidv1()}>
              <Card>
                <CardContent
                  className={
                    hasDocumentFiles ? classes.cardOk : classes.cardPending
                  }
                >
                  <Box display="flex" p={1}>
                    <Box flexGrow={1}>
                      <Typography
                        variant="h3"
                        className={classes.titleTypeDocument}
                      >
                        {translate(
                          `resources.firms.fields.document_title.${documentTypeAlias}`,
                        )}
                      </Typography>
                      {hasDocumentFiles && (
                        <Icon className={classes.icon} icon="clock.svg" />
                      )}
                    </Box>
                    <Box className={classes.uploadButton}>
                      <UploaderBtn
                        acceptedFiles={[
                          'application/pdf',
                          'image/png',
                          'image/jpeg',
                        ]}
                        className={classes.uploadButtonInner}
                        title={translate(
                          `resources.firms.fields.document_title.${documentTypeAlias}`,
                        )}
                        label={translate(
                          'resources.firms.document.add_document',
                        )}
                        onSuccess={onSuccessFileLoad}
                        onValidation={onValidationDocumentUploaded}
                        dropzoneText={translate(
                          'resources.firms.certificate.dropzoneText',
                        )}
                        FormComponent={
                          isCertificate
                            ? FirmCertificateSelectContainer
                            : FileUploader
                        }
                        formOption={formOption}
                        filesLimit={FILE_UPLOAD_LIMIT}
                      />
                    </Box>
                  </Box>
                  {hasDocumentFiles && (
                    <>
                      <Divider
                        variant="fullWidth"
                        className={classes.divider}
                      />
                      <>
                        {(groupedFiles || []).map(groupedFile => {
                          const {
                            reference,
                            expiringDate,
                            issueDate,
                          } = groupedFile

                          initialValues[
                            FIRM_CERTIFICATE_FORM.FIELDS.REFERENCE
                          ] = reference
                          initialValues[
                            FIRM_CERTIFICATE_FORM.FIELDS.EXPIRATION_DATE
                          ] = expiringDate
                          initialValues[
                            FIRM_CERTIFICATE_FORM.FIELDS.ISSUE_DATE
                          ] = issueDate

                          return (
                            <Fragment key={uuidv1()}>
                              {groupedFile.files.length > 0 && (
                                <>
                                  {groupedFile.expiringDate && (
                                    <Typography className={classes.titleMeta}>
                                      {translate(
                                        `resources.firms.fields.expiration_date`,
                                      )}
                                      {': '}
                                      {dateFormatter(
                                        groupedFile.expiringDate,
                                        DATE_FORMAT,
                                      )}
                                    </Typography>
                                  )}
                                  {groupedFile.issueDate && (
                                    <Typography className={classes.titleMeta}>
                                      {translate(
                                        `resources.firms.fields.issue_date`,
                                      )}
                                      {': '}
                                      {dateFormatter(
                                        groupedFile.issueDate,
                                        DATE_FORMAT,
                                      )}
                                    </Typography>
                                  )}
                                  <Typography className={classes.titleMeta}>
                                    <Icon
                                      className={classes.iconRight}
                                      icon="trash.svg"
                                      onClick={() =>
                                        openDeletionDialog(groupedFile['@id'])
                                      }
                                    />
                                    {!isCertificate && (
                                      <UploaderBtn
                                        acceptedFiles={[
                                          'application/pdf',
                                          'image/png',
                                          'image/jpeg',
                                        ]}
                                        title={translate(
                                          `resources.firms.fields.document_title.${documentType}`,
                                        )}
                                        label={translate(
                                          'resources.firms.document.add_document',
                                        )}
                                        onSuccess={onSuccessFileLoad}
                                        onValidation={
                                          onValidationDocumentUploaded
                                        }
                                        dropzoneText={translate(
                                          'resources.firms.certificate.dropzoneText',
                                        )}
                                        FormComponent={
                                          isCertificate
                                            ? FirmCertificateSelectContainer
                                            : FileUploader
                                        }
                                        formOption={{
                                          ...formOption,
                                          documentIri: groupedFile['@id'],
                                          initialValues: {
                                            ...initialValues,
                                            ...groupedFile.files,
                                          },
                                          formId: uuidv1(),
                                        }}
                                        filesLimit={FILE_UPLOAD_LIMIT}
                                        editIcon
                                        groupedFiles={groupedFiles}
                                      />
                                    )}
                                  </Typography>
                                  {groupedFile.files.map(file => (
                                    <div key={uuidv1()}>
                                      <Typography className={classes.titleFile}>
                                        <Link
                                          className={classes.fileLink}
                                          onClick={() =>
                                            dispatchOpenFile({
                                              fileId: file.id,
                                              fileType: file.type,
                                            })
                                          }
                                          to="/empty"
                                        >
                                          {file.name}
                                        </Link>
                                      </Typography>
                                    </div>
                                  ))}
                                </>
                              )}
                            </Fragment>
                          )
                        })}
                      </>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

FirmFiles.propTypes = {
  classes: PropTypes.shape({
    cardKo: PropTypes.string.isRequired,
    cardOk: PropTypes.string.isRequired,
    cardPending: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
    fileLink: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconRight: PropTypes.string.isRequired,
    titleFile: PropTypes.string.isRequired,
    titleMeta: PropTypes.string.isRequired,
    titleTypeDocument: PropTypes.string.isRequired,
    uploadButton: PropTypes.string.isRequired,
    uploadButtonInner: PropTypes.string.isRequired,
  }).isRequired,
  dispatchGetFirmCertificates: PropTypes.func.isRequired,
  dispatchOpenFile: PropTypes.func.isRequired,
  dispatchDocumentDeletion: PropTypes.func.isRequired,
  firmCertificateTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  firmCertificates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  firmDocuments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mandatory: PropTypes.bool,
  onSuccessFileLoad: PropTypes.func.isRequired,
  onValidationDocumentUploaded: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
}

FirmFiles.defaultProps = {
  mandatory: null,
}

const mapStateToProps = state => ({
  firmDocuments: firmDocumentsSelector(state),
  firmCertificates: firmCertificateSelector(state),
  firmCertificateTypes: firmCertificateTypesSelector(state),
})

const mapDispatchToProps = dispatch => ({
  dispatchGetFirmCertificates: () => dispatch(getFirmCertificates.request()),
  dispatchOpenFile: ({ fileId, fileType }) =>
    dispatch(openFile({ fileId, fileType })),
  onValidationDocumentUploaded: (files, additionalInfo) =>
    dispatch(uploadDocument(files, additionalInfo)),
  dispatchDocumentDeletion: documentIri =>
    dispatch(deleteDocument.request(documentIri)),
  onSuccessFileLoad: fileName => dispatch(fileLoadSuccess(fileName)),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
  withStyles(styles),
)(FirmFiles)
