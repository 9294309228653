import React from 'react'
import PropTypes from 'prop-types'
import InvoiceContestationPopIn from 'components/Jobs/JobDetails/JobDetailsWorkflow/InvoiceContestation/InvoiceContestationPopIn'
import DialogList from 'components/Jobs/JobDetails/JobDetailsWorkflow/Dialog/DialogList'
import {
  INVOICE_PRO,
  INVOICE_STATE_AWAITING_VALIDATION,
  INVOICE_STATE_BLOCKED,
  INVOICE_STATE_CONTESTED,
} from 'constants/invoices'
import { getInvoiceIdFromIri } from 'helpers/utils/invoice/invoice'
import { withStyles, Button, Typography, Container } from '@material-ui/core'
import styles from './JobDetailsInvoicesContestationStyles'

class JobDetailsInvoicesContestation extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      confirmDialogCancelDisputeInvoice: false,
      dialogUpdateInvoiceOpened: false,
      openContestationPopIn: false,
    }

    this.handleDialogUpdateInvoice = this.handleDialogUpdateInvoice.bind(this)
  }

  handleClickOpenContestationPopIn = () => {
    this.setState({ openContestationPopIn: true })
  }

  handleClickCloseContestationPopIn = () => {
    this.setState({ openContestationPopIn: false })
  }

  handleClickConfirmDialogCancelDisputeInvoice = () => {
    this.setState({
      confirmDialogCancelDisputeInvoice: true,
    })
  }

  handleDialogUpdateInvoice = () => {
    const { dialogUpdateInvoiceOpened } = this.state
    this.setState({
      dialogUpdateInvoiceOpened: !dialogUpdateInvoiceOpened,
    })
  }

  handleCloseDialog = (event, invoiceId) => {
    const { confirmDialogCancelDisputeInvoice } = this.state
    if (confirmDialogCancelDisputeInvoice === true) {
      const { value } = event.currentTarget
      if (value === 'true') {
        const { dispatchInvoiceCancelContestTransition } = this.props
        dispatchInvoiceCancelContestTransition(invoiceId)
      }
    }

    this.setState({
      confirmDialogCancelDisputeInvoice: false,
      dialogUpdateInvoiceOpened: false,
      openContestationPopIn: false,
    })
  }

  render() {
    const {
      classes,
      translate,
      invoice,
      dispatchInvoiceContestTransition,
      dispatchResetDispute,
    } = this.props

    const {
      confirmDialogCancelDisputeInvoice,
      dialogUpdateInvoiceOpened,
      openContestationPopIn,
    } = this.state

    const invoiceId = parseInt(getInvoiceIdFromIri(invoice['@id']), 10)

    if (invoice['@type'] !== INVOICE_PRO) {
      return null
    }

    return (
      <>
        {(invoice.state === INVOICE_STATE_AWAITING_VALIDATION ||
          invoice.state === INVOICE_STATE_BLOCKED) && (
          <>
            <Button
              className={classes.button}
              onClick={this.handleClickOpenContestationPopIn}
            >
              {translate('resources.jobs.invoiceContestation.title')}
            </Button>
            <InvoiceContestationPopIn
              onValidation={contestation =>
                dispatchInvoiceContestTransition(invoiceId, contestation)
              }
              dispatchResetDispute={() => dispatchResetDispute()}
              open={openContestationPopIn}
              onClose={this.handleClickCloseContestationPopIn}
            />
          </>
        )}

        {invoice.state === INVOICE_STATE_CONTESTED &&
          !!invoice.reasonDispute && (
            <Typography component="p">{invoice.reasonDispute}</Typography>
          )}

        {invoice.state === INVOICE_STATE_CONTESTED && (
          <Container className={classes.container}>
            <Button
              className={classes.button}
              onClick={this.handleClickConfirmDialogCancelDisputeInvoice}
            >
              {translate('resources.jobs.validateBills.invoice.cancelDispute')}
            </Button>
            <Button
              className={classes.button}
              onClick={this.handleDialogUpdateInvoice}
            >
              {translate('resources.jobs.validateBills.invoice.update')}
            </Button>
          </Container>
        )}

        <DialogList
          invoiceId={invoiceId}
          onCloseDialog={event => this.handleCloseDialog(event, invoiceId)}
          updateInvoiceOpened={dialogUpdateInvoiceOpened}
          translate={translate}
          displayDialogCancelDisputeInvoice={confirmDialogCancelDisputeInvoice}
        />
      </>
    )
  }
}

JobDetailsInvoicesContestation.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    '@id': PropTypes.string,
    '@type': PropTypes.string,
    state: PropTypes.string,
    reasonDispute: PropTypes.string,
  }).isRequired,
  dispatchInvoiceContestTransition: PropTypes.func.isRequired,
  dispatchInvoiceCancelContestTransition: PropTypes.func.isRequired,
  dispatchResetDispute: PropTypes.func.isRequired,
}

export default withStyles(styles)(JobDetailsInvoicesContestation)
