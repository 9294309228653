import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, CircularProgress } from '@material-ui/core'

const Loader = ({ loading }) => (
  <Dialog open={loading}>
    <DialogContent>
      <div>
        <CircularProgress />
      </div>
    </DialogContent>
  </Dialog>
)

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
}

export default Loader
