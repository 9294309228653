export default theme => ({
  root: {
    background: 'none',
    paddingRight: '34px',
  },
  head: {
    backgroundColor: theme.palette.background.primary,
  },
  table: {
    minWidth: 700,
  },
  firmPackageCell: {
    minWidth: '180px',
  },
  nameCell: {
    minWidth: '130px',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
})
