import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import classNames from 'classnames'
import i18n from 'providers/i18n/I18nProvider'
import { ListItem, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Icon from 'components/shared/Icon/Icon'

import styles from './MenuItemStyles'

const MenuItem = ({
  classes,
  itemClassName,
  translate,
  translateId,
  path,
  onClick,
  isCollapse,
  isOpen,
  jobCount,
  imageType,
  active,
}) => {
  const [toggleUpdate, updatePath] = useState(false)

  return (
    <Link
      to={path}
      className={classes.menuLink}
      onClick={() => updatePath(!toggleUpdate)}
    >
      <ListItem
        button
        onClick={onClick}
        className={classNames(itemClassName, active ? classes.isActive : '')}
      >
        <Icon className={classes.imgIconLink} icon={`${imageType}.svg`} />
        <ListItemText
          primary={
            jobCount
              ? `${translate(translateId)} (${jobCount})`
              : translate(translateId)
          }
        />
        {isCollapse && (isOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
    </Link>
  )
}

MenuItem.propTypes = {
  classes: PropTypes.shape({
    isActive: PropTypes.string.isRequired,
    menuLink: PropTypes.string.isRequired,
    imgIconLink: PropTypes.string.isRequired,
  }).isRequired,
  itemClassName: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateId: PropTypes.string.isRequired,
  path: PropTypes.string,
  isCollapse: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  jobCount: PropTypes.number,
  imageType: PropTypes.string,
  active: PropTypes.bool.isRequired,
}

MenuItem.defaultProps = {
  imageType: null,
  isCollapse: false,
  isOpen: false,
  path: '#',
  itemClassName: null,
  jobCount: null,
  onClick: () => {},
}

export default compose(i18n, withStyles(styles))(MenuItem)
