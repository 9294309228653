import React from 'react'

const Version = () => (
  <>
    <h1>v{process.env.REACT_APP_VERSION}</h1>
    <p>Git SHA1: {process.env.REACT_APP_GIT_SHA1}</p>
    <p>Target: {process.env.REACT_APP_TARGET}</p>
  </>
)

export default Version
