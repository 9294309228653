import { all, call, takeLatest } from 'redux-saga/effects'

import { handleRequest } from 'helpers/store/sagasHelpers'
import partnersClient from 'services/httpClient/partnersClient'

import { GET_PARTNERS, getPartners } from './partnersActions'

function* handleGetPartners() {
  yield* handleRequest({
    requestActions: getPartners,
    promise: call(partnersClient.get, `/partners`),
    actionParams: { triggerModalLoader: true },
  })
}

export default function*() {
  yield all([takeLatest(GET_PARTNERS.REQUEST, handleGetPartners)])
}
