import {
  INCIDENT_ANALYZING_STATUS,
  INCIDENT_CANCELED_STATUS,
  INCIDENT_NEW_STATUS,
  INCIDENT_PAYMENT_PLANNED_STATUS,
  INCIDENT_PENDING_PAYMENT_STATUS,
  INCIDENT_PENDING_RESOLUTION_STATUS,
  INCIDENT_PENDING_VERIFICATION_STATUS,
  INCIDENT_PROCESSING_STATUS,
  INCIDENT_SOLVED_STATUS,
} from 'constants/incidents'

export const getBoldOptions = status => {
  switch (status) {
    case INCIDENT_NEW_STATUS:
      return [INCIDENT_ANALYZING_STATUS]
    case INCIDENT_ANALYZING_STATUS:
      return [INCIDENT_PROCESSING_STATUS, INCIDENT_CANCELED_STATUS]
    case INCIDENT_PROCESSING_STATUS:
      return [INCIDENT_PENDING_RESOLUTION_STATUS, INCIDENT_CANCELED_STATUS]
    case INCIDENT_PENDING_RESOLUTION_STATUS:
      return [
        INCIDENT_PENDING_PAYMENT_STATUS,
        INCIDENT_PENDING_VERIFICATION_STATUS,
      ]
    case INCIDENT_PENDING_VERIFICATION_STATUS:
      return [
        INCIDENT_PENDING_PAYMENT_STATUS,
        INCIDENT_PROCESSING_STATUS,
        INCIDENT_SOLVED_STATUS,
      ]
    case INCIDENT_PENDING_PAYMENT_STATUS:
      return [INCIDENT_PAYMENT_PLANNED_STATUS]
    case INCIDENT_PAYMENT_PLANNED_STATUS:
      return [INCIDENT_SOLVED_STATUS]
    default:
      return []
  }
}
