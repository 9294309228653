import { addDays, format } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo } from 'react'
import GenericConfirmDialog from 'components/Jobs/JobDetails/JobDetailsIncidents/StatusChangeConfirmDialog/GenericConfirmDialog/GenericConfirmDialog'
import { Field } from 'redux-form'
import DateInput from 'components/shared/DateInput/DateInput'
import RenderCheckbox from 'components/shared/Form/RenderCheckbox'
import RenderTimeslotSelect from 'components/shared/Form/RenderTimeslotSelect'
import { ISO_SHORT } from 'constants/date'
import { createDateGteMinDate, required } from 'helpers/form/validationHelpers'
import {
  CHECK_BOX_FIELD_NAME,
  TIMESLOT_DATE_FIELD_NAME,
  TIMESLOT_FIELD_NAME,
} from 'constants/Jobs'

const RescheduleForm = ({
  translate,
  hideStatusChangeConfirmDialog,
  confirmDialogAction,
  disableValidate,
  deadlineReached,
  checkBoxValue,
  triggerValidation,
  proPlannedDtStr,
}) => {
  useEffect(() => {
    triggerValidation()
  }, [triggerValidation])

  const dialogContentText = deadlineReached
    ? 'resources.incidents.broken_product_type.update.status.confirm_popin.deadline_reached.text'
    : 'resources.incidents.broken_product_type.update.status.confirm_popin.deadline_not_reached.text'

  const todayDate = new Date()
  const tomorrowDate = addDays(todayDate, 1)
  const minDate = useMemo(
    () => (proPlannedDtStr ? new Date(proPlannedDtStr) : tomorrowDate),
    [proPlannedDtStr, tomorrowDate],
  )

  const dateGteMinDate = useCallback(() => {
    createDateGteMinDate(minDate)
  }, [minDate])

  return (
    <GenericConfirmDialog
      translate={translate}
      title="resources.incidents.broken_product_type.update.status.confirm_popin.title"
      hideStatusChangeConfirmDialog={hideStatusChangeConfirmDialog}
      confirmDialogAction={confirmDialogAction}
      validateLabel="resources.incidents.broken_product_type.update.status.confirm_popin.validate_btn"
      justifyContainerBtns="flex-end"
      disableValidate={disableValidate}
      dialogContentText={dialogContentText}
      formContent={
        <>
          {!deadlineReached && (
            <Field
              fullWidth
              label={translate(
                'resources.incidents.broken_product_type.update.status.confirm_popin.deadline_reached.checkbox_text',
              )}
              name={CHECK_BOX_FIELD_NAME}
              component={RenderCheckbox}
            />
          )}
          <Field
            disabled={!deadlineReached && !checkBoxValue}
            validate={[required, dateGteMinDate]}
            name={TIMESLOT_DATE_FIELD_NAME}
            type="date"
            props={{
              min: format(minDate, ISO_SHORT),
              fullWidth: false,
              label: translate(
                'resources.incidents.broken_product_type.update.status.confirm_popin.wished_date',
              ),
            }}
            component={DateInput}
          />
          <Field
            isTimeslotDateDisabled={!deadlineReached && !checkBoxValue}
            validate={[required]}
            name={TIMESLOT_FIELD_NAME}
            component={RenderTimeslotSelect}
            translate={translate}
          />
        </>
      }
    />
  )
}

RescheduleForm.defaultProps = {
  disableValidate: undefined,
  checkBoxValue: false,
  proPlannedDtStr: null,
}

RescheduleForm.propTypes = {
  triggerValidation: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  hideStatusChangeConfirmDialog: PropTypes.func.isRequired,
  confirmDialogAction: PropTypes.func.isRequired,
  disableValidate: PropTypes.bool,
  deadlineReached: PropTypes.bool.isRequired,
  checkBoxValue: PropTypes.bool,
  proPlannedDtStr: PropTypes.string,
}
export default RescheduleForm
