import {
  FIELD_INVOICE_ITEM_QUANTITY,
  FIELD_INVOICE_ITEM_SUBTOTAL,
  FIELD_INVOICE_ITEM_TOTAL,
  FIELD_INVOICE_ITEM_UNIT_PRICE,
  FIELD_INVOICE_ITEM_VAT_RATE,
  FIELD_INVOICE_ITEMS,
  FIELD_INVOICE_SUBTOTAL,
  FIELD_INVOICE_TAX_TOTAL,
  FIELD_INVOICE_TOTAL,
  FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT,
  INVOICE_NEW_ITEM_TEMPLATE,
} from 'constants/invoices'
import { GET_INVOICE_JOB } from 'store/jobs/jobActions'
import { initialState } from './invoiceSelectors'
import {
  GET_INVOICES_LIST,
  RESET_FILTER_INVOICE,
  GET_SINGLE_INVOICE,
  INVOICE_CART_ADD_ITEM,
  INVOICE_CART_DELETE_ITEM,
  INVOICE_CART_UPDATE_ITEM,
  INVOICE_CART_PROCESS_TOTALS,
  SET_SUBJECT_TO_VAT,
  UPDATE_INVOICE,
  RESET_DISPUTE_INVOICE,
  RESET_INVOICES_LIST,
  SET_DISPLAYED_INVOICE,
  TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED,
  PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED,
  GENERATE_INVOICE_AFTERSALE_REQ,
} from './invoiceActions'

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_INVOICES_LIST:
      return {
        ...state,
        list: [],
      }
    case GET_INVOICES_LIST.REQUEST:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          jobId: action.jobId || initialState.searchParams.jobId,
          number: action.number || initialState.searchParams.number,
          orderNumber:
            action.orderNumber || initialState.searchParams.orderNumber,
          proName: action.proName || initialState.searchParams.proName,
          homeOwnerName:
            action.homeOwnerName || initialState.searchParams.homeOwnerName,
          states: action.states || initialState.searchParams.states,
          issuedAt: action.issuedAt || initialState.searchParams.issuedAt,
          types: action.types || initialState.searchParams.types,
          page: action.page || initialState.searchParams.page,
          rowsPerPage:
            action.rowsPerPage || initialState.searchParams.rowsPerPage,
          orderBy: action.orderBy || initialState.searchParams.orderBy,
          sort: action.sort || initialState.searchParams.sort,
        },
      }
    case GET_INVOICES_LIST.SUCCESS:
      return {
        ...state,
        list: action.payload['hydra:member'],
        searchParams: {
          ...state.searchParams,
          total: action.payload['hydra:totalItems'],
        },
      }
    case GET_SINGLE_INVOICE.SUCCESS:
    case UPDATE_INVOICE.SUCCESS:
      return {
        ...state,
        currentInvoice: action.payload,
        currentInvoiceCart: {
          [FIELD_INVOICE_ITEMS]: [...action.payload[FIELD_INVOICE_ITEMS]],
          [FIELD_INVOICE_SUBTOTAL]: action.payload[FIELD_INVOICE_SUBTOTAL],
          [FIELD_INVOICE_TAX_TOTAL]: action.payload[FIELD_INVOICE_TAX_TOTAL],
          [FIELD_INVOICE_TOTAL]: action.payload[FIELD_INVOICE_TOTAL],
          [FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT]:
            action.payload.providerBillingData[
              FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT
            ],
        },
      }
    case RESET_DISPUTE_INVOICE:
      return {
        ...state,
        currentInvoice: {
          ...state.searchParams,
          reasonDispute: null,
        },
      }
    case RESET_FILTER_INVOICE:
      return {
        ...state,
        searchParams: initialState.searchParams,
      }
    case INVOICE_CART_ADD_ITEM:
      return {
        ...state,
        currentInvoiceCart: {
          ...state.currentInvoiceCart,
          [FIELD_INVOICE_ITEMS]: state.currentInvoiceCart[
            FIELD_INVOICE_ITEMS
          ].concat([INVOICE_NEW_ITEM_TEMPLATE]),
        },
      }
    case INVOICE_CART_DELETE_ITEM:
      return {
        ...state,
        currentInvoiceCart: {
          ...state.currentInvoiceCart,
          [FIELD_INVOICE_ITEMS]: [
            ...state.currentInvoiceCart[FIELD_INVOICE_ITEMS].filter(
              (item, index) => index !== action.index,
            ),
          ],
        },
      }
    case INVOICE_CART_UPDATE_ITEM:
      return {
        ...state,
        currentInvoiceCart: {
          ...state.currentInvoiceCart,
          [FIELD_INVOICE_ITEMS]: [
            ...state.currentInvoiceCart[FIELD_INVOICE_ITEMS].map(
              (item, index) => {
                if (index === action.index) {
                  // Apply the updated value
                  const newItem = {
                    ...item,
                    ...action.item,
                  }

                  // Process subtotal for the updated item
                  const newSubtotal =
                    newItem[FIELD_INVOICE_ITEM_QUANTITY] *
                    newItem[FIELD_INVOICE_ITEM_UNIT_PRICE]

                  // Update tax total for the updated item if quantity or unitPrice has been updated
                  const newTaxTotal = state.currentInvoiceCart[
                    FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT
                  ]
                    ? parseInt(newSubtotal * 0.2, 10)
                    : 0

                  return {
                    ...newItem,
                    [FIELD_INVOICE_ITEM_SUBTOTAL]: newSubtotal,
                    [FIELD_INVOICE_ITEM_VAT_RATE]: newTaxTotal,
                    [FIELD_INVOICE_ITEM_TOTAL]: newSubtotal + newTaxTotal,
                  }
                }

                return item
              },
            ),
          ],
        },
      }
    case INVOICE_CART_PROCESS_TOTALS:
      // eslint-disable-next-line no-case-declarations
      const [subtotal, taxTotal, total] = state.currentInvoiceCart[
        FIELD_INVOICE_ITEMS
      ].map(item => [
        item[FIELD_INVOICE_ITEM_SUBTOTAL],
        item[FIELD_INVOICE_ITEM_VAT_RATE],
        item[FIELD_INVOICE_ITEM_TOTAL],
      ]).reduce(
        (acc, curr) => {
          const [accSubtotal, accTax, accTotal] = acc
          const [currSubtotal, currTax, currTotal] = curr

          return [
            accSubtotal + currSubtotal,
            accTax + currTax,
            accTotal + currTotal,
          ]
        },
        [0, 0, 0],
      )

      return {
        ...state,
        currentInvoiceCart: {
          ...state.currentInvoiceCart,
          [FIELD_INVOICE_SUBTOTAL]: subtotal,
          [FIELD_INVOICE_TAX_TOTAL]: state.currentInvoiceCart.subjectToVat
            ? taxTotal
            : 0,
          [FIELD_INVOICE_TOTAL]: state.currentInvoiceCart.subjectToVat
            ? total
            : subtotal,
        },
      }
    case SET_SUBJECT_TO_VAT:
      return {
        ...state,
        currentInvoiceCart: {
          ...state.currentInvoiceCart,
          [FIELD_INVOICE_PROVIDER_BILLING_DATA_SUBJECT_TO_VAT]:
            action.isSubjectToVat,
        },
      }
    case SET_DISPLAYED_INVOICE:
      return {
        ...state,
        displayedInvoice: action.payload,
      }
    case GET_INVOICE_JOB.FAILURE:
      return {
        ...state,
        displayedInvoice: initialState.displayedInvoice,
      }
    case TRAVEL_COMPENSATION_PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          createTravelCompensationPurchaseInvoice: {
            ...state.dialogs.createTravelCompensationPurchaseInvoice,
            displayed: action.payload.displayed,
          },
        },
      }
    case PURCHASE_INVOICE_CREATION_DIALOG_DISPLAYED:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          createPurchaseInvoice: {
            ...state.dialogs.createPurchaseInvoice,
            displayed: action.payload.displayed,
          },
        },
      }
    case GENERATE_INVOICE_AFTERSALE_REQ.SUCCESS: {
      const invoiceNatures = {}
      const invoiceLineTypes = {}
      const providers = {}
      const rebillingCustomers = {}

      action.payload?.invoiceNatures?.forEach(item => {
        invoiceNatures[item?.code] = item?.label
      })
      action.payload?.invoiceLineTypes?.forEach(item => {
        invoiceLineTypes[item?.value] = item?.label
      })
      action.payload?.providers?.forEach(item => {
        item?.values?.forEach(providerValue => {
          providers[providerValue?.id] = providerValue?.name
        })
      })
      action.payload?.rebilling_customers?.forEach(item => {
        item?.values?.forEach(rebillingCustomerValue => {
          rebillingCustomers[rebillingCustomerValue?.id] =
            rebillingCustomerValue?.name
        })
      })

      return {
        ...state,
        additionalInvoices: {
          ...action.payload,
          rebilling: action.payload?.rebilling?.[0] || {},
          additionalInvoicesInLine: {
            invoiceNatures,
            invoiceLineTypes,
            providers,
            rebillingCustomers,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}
