import translate from 'providers/i18n/translateService'
import { isBeforeFormattedDate } from 'helpers/date'
import { FIRM_CERTIFICATE_FORM } from 'constants/forms'

export const validate = values => {
  // Translate errors
  const errors = {}
  if (!values[FIRM_CERTIFICATE_FORM.FIELDS.TYPE]) {
    errors[FIRM_CERTIFICATE_FORM.FIELDS.TYPE] = translate(
      'resources.firms.fields.certificate.typeError',
    )
  }

  if (
    !values[FIRM_CERTIFICATE_FORM.FIELDS.CATEGORIES] ||
    !values[FIRM_CERTIFICATE_FORM.FIELDS.CATEGORIES].length
  ) {
    errors[FIRM_CERTIFICATE_FORM.FIELDS.CATEGORIES] = translate(
      'resources.firms.fields.certificate.categoryError',
    )
  }

  if (!values[FIRM_CERTIFICATE_FORM.FIELDS.EXPIRATION_DATE]) {
    errors[FIRM_CERTIFICATE_FORM.FIELDS.EXPIRATION_DATE] = translate(
      'resources.firms.fields.certificate.expirationDateError',
    )
  }

  if (
    isBeforeFormattedDate(values[FIRM_CERTIFICATE_FORM.FIELDS.EXPIRATION_DATE])
  ) {
    errors[FIRM_CERTIFICATE_FORM.FIELDS.EXPIRATION_DATE] = translate(
      'resources.firms.fields.certificate.expirationDateBeforeError',
    )
  }

  if (!values[FIRM_CERTIFICATE_FORM.FIELDS.REFERENCE]) {
    errors[FIRM_CERTIFICATE_FORM.FIELDS.REFERENCE] = translate(
      'resources.firms.fields.certificate.referenceError',
    )
  }
  return errors
}
