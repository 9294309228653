import React, { PureComponent } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import Pdf from 'components/shared/Pdf/Pdf'
import { getInvoiceJob } from 'store/jobs/jobActions'
import { invoiceJobSelector } from 'store/jobs/jobSelectors'
import { isLoadingSelector } from 'store/Application/ApplicationSelectors'

class Invoice extends PureComponent {
  componentDidMount() {
    const { getInvoice } = this.props
    getInvoice()
  }

  render() {
    const { invoice, isLoading, translate } = this.props

    return (
      invoice.content &&
      !isLoading && (
        <Pdf
          file={`data:application/pdf;base64,${invoice.content}`}
          downloadFilename={`${translate('resources.jobs.fields.invoice')}.pdf`}
        />
      )
    )
  }
}

Invoice.defaultProps = {
  invoice: {
    name: null,
    content: null,
  },
}

Invoice.propTypes = {
  invoice: PropTypes.shape({
    name: PropTypes.string,
    content: PropTypes.string,
  }),
  getInvoice: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  invoice: invoiceJobSelector(state),
  isLoading: isLoadingSelector(state),
})

const mapDispatchToProps = (dispatch, { invoiceId }) => ({
  getInvoice: () => dispatch(getInvoiceJob.request({ invoiceId })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  i18n,
)(Invoice)
