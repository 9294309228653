import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import { reducer as featureFlagsReducers } from 'feature-flag-consumer-js-lib'

import application from './Application/ApplicationReducer'
import departments from './departments/departmentReducer'
import firmComments from './firmComments/firmCommentReducer'
import firms from './firms/firmReducer'
import stores from './stores/storeReducer'
import packages from './products/packageReducer'
import trades from './trades/tradeReducer'
import jobs from './jobs/jobReducer'
import worksites from './worksites/worksiteReducer'
import invoices from './invoices/invoiceReducer'
import saleInvoices from './saleInvoices/saleInvoiceReducer'
import proDocuments from './proDocuments/proDocumentReducer'
import incidents from './incidents/incidentReducer'
import firmCertificateFile from './firmCertificateFile/firmCertificateFileReducer'
import conversations from './conversations/conversationsReducer'
import firmContractOptions from './firmContractOptions/firmContractOptionsReducer'
import partners from './partners/partnersReducer'
import reserves from './reserves/reservesReducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    featureFlags: featureFlagsReducers,
    application,
    firms,
    jobs,
    worksites,
    form: formReducer.plugin(proDocuments),
    departments,
    firmComments,
    stores,
    packages,
    trades,
    invoices,
    saleInvoices,
    incidents,
    firmCertificateFile,
    conversations,
    firmContractOptions,
    partners,
    reserves,
  })
