import { useEffect, useState } from 'react'

/**
 *
 * @param task the task to call, this function must be declared outside a component,
 *  or be memoized (with useMemo or useCallback), if not it will be called endlessly (since it's a dependency of the effect).
 * @param defaultData the default data while the task has not returned.
 * ready: boolean that indicates if task has been executed, it is true if an error occured,
 * data : the returned data,
 * error: error thrown during the task, null otherwise,
 */
export const useTaskCaller = (task, defaultData = null) => {
  const [ready, setReady] = useState(false)
  const [data, setData] = useState(defaultData)
  const [error, setError] = useState(null)

  useEffect(() => {
    const executeTask = () => {
      try {
        const result = task()
        setData(result !== undefined ? result : defaultData)
        setReady(true)
        setError(null)
      } catch (e) {
        setData(defaultData)
        setReady(true)
        setError(e)
      }
    }
    executeTask()
  }, [task, defaultData])

  return [ready, data, error]
}
