export default theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
    padding: '3rem 2rem 0',
    background: 'none',
  },
  manualMatchingForm: {
    padding: '2rem 0',
  },
  tabs: {
    margin: '2rem 1rem 0 1rem',
    background: '#f3f7f9',
    width: 'max-content',
    borderRadius: '5px',
  },
  tab: {
    padding: '12px 30px',
    maxWidth: 'fit-content',

    '&:first-child': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderRight: '2px solid #fff',
    },

    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      borderLeft: '2px solid #fff',
    },
  },
  matchingDetails: {
    backgroundColor: '#dae1e6',
  },
  wrapper: {
    margin: '3rem',
    display: 'block',
    padding: '2rem',
    background: 'white',
  },
  alertWrapper: {
    padding: '1rem 1rem 0 1rem',
  },
  head: {
    backgroundColor: theme.palette.background.primary,
  },
  table: {
    padding: 0,
    minWidth: 700,
  },
  bgPink: {
    background: '#ffebeb',
    padding: '20px',
  },
  card: {
    minWidth: '150px',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  cell: {
    maxWidth: '100px',
  },
  listening: {
    margin: 0,
  },
  conditionsLabel: {
    textAlign: 'center',
  },
  conditions: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  buttonConditions: {
    display: 'block',
    fontSize: '0.9rem',
    padding: '10px 20px',
    margin: '5px auto 15px',
    borderRadius: 0,
    fontFamily: ['montserrat'].join(','),
    fontWeight: 700,
    backgroundColor: '#FDA20A',
  },
  ErrorOutlineIcon: {
    display: 'flex',
    margin: '0 auto',
    width: '40px',
    height: '90%',
    alignItems: 'center',
    color: '#a78484',
  },
  warning: {
    display: 'table',
    maxWidth: 'inherit',
    padding: '1rem',
    margin: '1rem 0',
    background: 'rgb(239, 213, 213)',
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonBigger: theme.buttonBigger,
  customTop: {
    marginTop: '1.5rem',
  },
})
