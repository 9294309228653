import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Typography } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import { DELIVERY_MODE_BY_CRAFTSMAN } from 'constants/Jobs'
import TableProducts from 'components/TableProducts/TableProducts'
import WrapperInline from 'components/shared/WrapperInline/WrapperInline'
import Label from 'components/shared/Label/Label'

const JobDetailsProduct = ({ deliveryMode, products, translate }) => (
  <>
    <Typography variant="body2" gutterBottom>
      {translate('resources.jobs.fields.bought_product')}
    </Typography>
    <TableProducts products={products} />
    <WrapperInline>
      <Label label={translate('resources.jobs.fields.delivery')} />
      <Typography variant="body2" gutterBottom>
        {deliveryMode === DELIVERY_MODE_BY_CRAFTSMAN
          ? translate('resources.jobs.fields.deliveredByCraftsman')
          : '-'}
      </Typography>
    </WrapperInline>
  </>
)

JobDetailsProduct.propTypes = {
  deliveryMode: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  translate: PropTypes.func.isRequired,
}

JobDetailsProduct.defaultProps = {
  deliveryMode: undefined,
  products: [],
}

export default compose(i18n)(JobDetailsProduct)
