import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { change, reduxForm, reset, getFormSyncErrors } from 'redux-form'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'

import { maxLength, required } from 'helpers/form/validationHelpers'
import { applyValidationRules } from 'helpers/utils/validate'

import {
  incidentTypesByNatureSelector,
  incidentNaturesSelector,
  selectedNatureFromFormSelector,
  selectedTypeFromFormSelector,
} from 'store/incidents/incidentSelectors'
import {
  fetchIncidentTypes,
  postIncident,
} from 'store/incidents/incidentActions'
import IncidentForm from 'components/Jobs/JobDetails/JobDetailsIncidents/IncidentForm'
import { POST_INCIDENT_FORM } from 'constants/forms'
import {
  DESCRIPTION,
  INCIDENT_NATURE,
  INCIDENT_TYPE,
  INCIDENT_ORIGIN,
} from 'constants/invoices'

const IncidentFormContainer = ({
  fetchIncidentTypesAction,
  handleSubmit,
  incidentTypes,
  incidentNatures,
  selectedNature,
  selectedType,
  resetIncidentType,
  description,
  translate,
  formErrors,
}) => {
  const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(
    false,
  )

  useEffect(() => {
    if (incidentTypes.length === 0) {
      fetchIncidentTypesAction()
    }
  }, [fetchIncidentTypesAction, incidentTypes])

  return (
    <IncidentForm
      confirmationDialogOpened={confirmationDialogOpened}
      openConfirmationDialog={() => setConfirmationDialogOpened(true)}
      incidentTypes={incidentTypes}
      incidentNatures={incidentNatures}
      handleClose={() => setConfirmationDialogOpened(false)}
      handleSubmit={handleSubmit}
      handleChangeNature={resetIncidentType}
      selectedNature={selectedNature}
      selectedType={selectedType}
      description={description}
      translate={translate}
      formErrors={formErrors}
    />
  )
}

IncidentFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  resetIncidentType: PropTypes.func.isRequired,
  fetchIncidentTypesAction: PropTypes.func.isRequired,
  incidentTypes: PropTypes.arrayOf(PropTypes.shape({})),
  incidentNatures: PropTypes.arrayOf(PropTypes.shape({})),
  formErrors: PropTypes.arrayOf(PropTypes.string),
  selectedNature: PropTypes.string,
  selectedType: PropTypes.string,
  description: PropTypes.string,
}

IncidentFormContainer.defaultProps = {
  incidentTypes: [],
  formErrors: [],
  incidentNatures: [],
  selectedNature: null,
  selectedType: null,
  description: null,
}

const mapStateToProps = state => ({
  incidentTypes: incidentTypesByNatureSelector(state),
  incidentNatures: incidentNaturesSelector(state),
  selectedNature: selectedNatureFromFormSelector(state),
  selectedType: selectedTypeFromFormSelector(state),
  formErrors: getFormSyncErrors(POST_INCIDENT_FORM)(state),
})

const mapDispatchToProps = dispatch => ({
  fetchIncidentTypesAction: () => dispatch(fetchIncidentTypes.request()),
  resetIncidentType: () => {
    dispatch(change(POST_INCIDENT_FORM, 'incidentType', null))
  },
})

export const validate = (values, props) => {
  const fieldsWithRules = {
    [DESCRIPTION]: [maxLength(1000)],
    [INCIDENT_NATURE]: [required],
    [INCIDENT_TYPE]: [required],
    [INCIDENT_ORIGIN]: [required],
  }

  const errors = applyValidationRules(fieldsWithRules, values, props)
  return errors
}

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: POST_INCIDENT_FORM,
    destroyOnUnmount: false,
    enableReinitialize: true,
    fields: ['description', 'incidentType', 'incidentOrigin'],
    validate,
    onSubmit: ({ description, incidentType, incidentOrigin }, dispatch) => {
      dispatch(
        postIncident.request({
          data: {
            description,
            type: incidentType,
            origin: incidentOrigin,
          },
        }),
      )
    },
    onSubmitSuccess: (result, dispatch) => {
      dispatch(reset(POST_INCIDENT_FORM))
    },
  }),
)(IncidentFormContainer)
