const merge = require('lodash/merge')
const getEnvironmentFromUrl = require('./helpers/utils/url')

const MOCK_HOST = process.env.REACT_APP_MOCK_HOST
const MOCK_PORT = process.env.REACT_APP_MOCK_PORT
const DEVSRV_PORT = process.env.REACT_APP_DEVSRV_PORT

const hostname = typeof window !== 'undefined' && window.location.hostname
const currentEnv = getEnvironmentFromUrl(
  hostname !== MOCK_HOST ? hostname : '',
  'backoffice',
  'quotatis.com',
)

const configs = {
  all: {
    env: currentEnv,
    api: {
      kongApiKey: 'UmMQGKmb4kGT0YFTk54UxYHVNIF4Oect',
      url: 'https://back-gw.qa.quotatis.com/',
    },
    auth0: {
      domain: 'auth0.backoffice.qa.quotatis.com',
      clientID: 'GwIC0wESctUMgJUKtfduqwn2M1cMp5WE',
      audience: 'https://auth0.backoffice.qa.quotatis.com/logical-api',
      redirectUri: 'https://backoffice.qa.quotatis.com',
    },
    engine: {
      url: 'https://back-gw.qa.quotatis.com/engine',
    },
    partners: {
      url: 'https://back-gw.qa.quotatis.com/partners',
    },
    proApi: {
      url: 'https://back-gw.qa.quotatis.com/pro',
    },
    documentsApi: {
      url: 'https://back-gw.qa.quotatis.com/documents',
    },
    billing: {
      url: 'https://back-gw.qa.quotatis.com/billing',
    },
    sylius: {
      url: 'https://back-gw.qa.quotatis.com',
    },
    frontApi: {
      url: 'https://back-gw.qa.quotatis.com/front-api',
    },
    n8n: {
      url: 'https://back-gw.qa.quotatis.com/n8n',
    },
    algolia: {
      applicationId: 'TIM81UW1UV',
      apiKey: '7411f9e2b69ad135d5484ce27ae7bc39',
      indexPrefix: 'qa_k8s',
    },
    legacy: {
      docLink:
        'https://admin-fr.legacy.quotatis.net/admin_contractor.html?go2=document_management&id_firm=',
      proLink:
        'https://admin-fr.legacy.quotatis.net/admin_contractor.html?go2=general_information&id_firm=',
    },
    palette: {
      color: '#444',
    },
    google: {
      mapsKey: 'AIzaSyCYrFIvVAYqnWCNjjAfGIanmwxVvj1FPj8',
    },
    proWebSiteDirectory: {
      url: 'https://annuaire.qa.quotatis.fr/artisan',
    },
    cloudinary: {
      url: 'https://res.cloudinary.com/quotatis/image/upload/FrontApp',
    },
    sentry: {
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.5,
      url:
        'https://114272140c6c98a2e86aec2cd212459c@o88364.ingest.us.sentry.io/4507175821049856',
    },
  },
  development: {
    // Uncomment in order to use services in local
    // api: {
    //   url: 'https://engine.local.quotatis.com:8205/engine',
    // },
    // engine: {
    //   url: 'https://engine.local.quotatis.com:8205/engine',
    // },
    // billing: {
    //   url: 'https://billing.local.quotatis.com:8207',
    // },
    // sylius: {
    //   url: 'https://sylius-dev.qarx.io:8090',
    // },
    // proApi: {
    //   url: 'https://pro-api.local.quotatis.com:8204',
    // },
    // documentsApi: {
    //   url: 'https://documents-api.local.quotatis.com:8202',
    // },
    // frontApi: {
    //   url: 'https://front-api.local.quotatis.com:8210',
    // },
    // algolia: {
    //   apiKey: 'e6195f3306b70efdf782020f9fe66261',
    //   indexPrefix: 'dev',
    // },
  },
  local: {
    auth0: {
      domain: 'auth0.backoffice.qa.quotatis.com',
      clientID: 'GwIC0wESctUMgJUKtfduqwn2M1cMp5WE',
      audience: 'https://auth0.backoffice.qa.quotatis.com/logical-api',
      redirectUri: 'https://backoffice.local.quotatis.com:3000',
    },
  },
  qa1: {
    api: {
      url: 'https://back-gw.qa1.quotatis.com/',
    },
    engine: {
      url: 'https://back-gw.qa1.quotatis.com/engine',
    },
    proApi: {
      url: 'https://back-gw.qa1.quotatis.com/pro',
    },
    billing: {
      url: 'https://back-gw.qa1.quotatis.com/billing',
    },
    sylius: {
      url: 'https://back-gw.qa1.quotatis.com',
    },
    documentsApi: {
      url: 'https://back-gw.qa1.quotatis.com/documents',
    },
    partners: {
      url: 'https://back-gw.qa1.quotatis.com/partners',
    },
    frontApi: {
      url: 'https://back-gw.qa1.quotatis.com/front-api',
    },
    n8n: {
      url: 'https://back-gw.qa1.quotatis.com/n8n',
    },
    algolia: {
      apiKey: 'e2b042ec4e82a621bdebef875cb0867c',
      indexPrefix: 'qa1_k8s',
    },
    auth0: {
      domain: 'auth0.backoffice.qa.quotatis.com',
      clientID: 'GwIC0wESctUMgJUKtfduqwn2M1cMp5WE',
      audience: 'https://auth0.backoffice.qa.quotatis.com/logical-api',
      redirectUri: 'https://backoffice.qa1.quotatis.com',
    },
    proWebSiteDirectory: {
      url: 'https://annuaire.qa1.quotatis.fr/artisan',
    },
  },
  qa: {
    auth0: {
      domain: 'auth0.backoffice.qa.quotatis.com',
      clientID: 'GwIC0wESctUMgJUKtfduqwn2M1cMp5WE',
      audience: 'https://auth0.backoffice.qa.quotatis.com/logical-api',
      redirectUri: 'https://backoffice.qa.quotatis.com',
    },
  },
  preprod: {
    api: {
      kongApiKey: 'TWvkwum4k4spr4LJwRXpyksaTSaKb1xn',
      url: 'https://back-gw.preprod.quotatis.com/',
    },
    engine: {
      url: 'https://back-gw.preprod.quotatis.com/engine',
    },
    partners: {
      url: 'https://back-gw.preprod.quotatis.com/partners',
    },
    proApi: {
      url: 'https://back-gw.preprod.quotatis.com/pro',
    },
    billing: {
      url: 'https://back-gw.preprod.quotatis.com/billing',
    },
    sylius: {
      url: 'https://back-gw.preprod.quotatis.com',
    },
    documentsApi: {
      url: 'https://back-gw.preprod.quotatis.com/documents',
    },
    frontApi: {
      url: 'https://back-gw.preprod.quotatis.com/front-api',
    },
    n8n: {
      url: 'https://back-gw.preprod.quotatis.com/n8n',
    },
    algolia: {
      apiKey: 'e7f8b4f2211e13a48d45828e971924ce',
      indexPrefix: 'preprod_k8s',
    },
    auth0: {
      domain: 'auth0.backoffice.preprod.quotatis.com',
      clientID: 'FNXwe9OebXXAfdgzoZjooQUwRmKHpMMY',
      audience: 'https://auth0.backoffice.preprod.quotatis.com/logical-api',
      redirectUri: 'https://backoffice.preprod.quotatis.com',
    },
    legacy: {
      docLink:
        'https://admin-fr.legacy.quotatis.net/admin_contractor.html?go2=document_management&id_firm=',
      proLink:
        'https://admin-fr.legacy.quotatis.net/admin_contractor.html?go2=general_information&id_firm=',
    },
    proWebSiteDirectory: {
      url: 'https://annuaire.preprod.quotatis.fr/artisan',
    },
    palette: {
      color: '#400101',
    },
  },
  prod: {
    api: {
      kongApiKey: 'g9CvfCp6jSMlDQvDjEobO647hCRgJxkr',
      url: 'https://back-gw.prod.quotatis.com/',
    },
    engine: {
      url: 'https://back-gw.prod.quotatis.com/engine',
    },
    proApi: {
      url: 'https://back-gw.prod.quotatis.com/pro',
    },
    partners: {
      url: 'https://back-gw.prod.quotatis.com/partners',
    },
    billing: {
      url: 'https://back-gw.prod.quotatis.com/billing',
    },
    sylius: {
      url: 'https://back-gw.prod.quotatis.com',
    },
    documentsApi: {
      url: 'https://back-gw.prod.quotatis.com/documents',
    },
    frontApi: {
      url: 'https://back-gw.prod.quotatis.com/front-api',
    },
    n8n: {
      url: 'https://back-gw.prod.quotatis.com/n8n',
    },
    algolia: {
      apiKey: 'c132604c110720dba428697d2a08b061',
      indexPrefix: 'prod_k8s',
    },
    auth0: {
      domain: 'auth0.backoffice.prod.quotatis.com',
      clientID: '1GdpTHjDliEOxSWH5rM8uvzeuyNdIaPp',
      audience: 'https://auth0.backoffice.prod.quotatis.com/logical-api',
      redirectUri: 'https://backoffice.quotatis.com',
    },
    legacy: {
      docLink:
        'https://admin.quotatis.fr/admin_contractor.html?go2=document_management&id_firm=',
      proLink:
        'https://admin.quotatis.fr/admin_contractor.html?go2=general_information&id_firm=',
    },
    google: {
      mapsKey: 'AIzaSyAsFOnh8HufqRe55p_7rCOQG0nxz22px4Q',
    },
    proWebSiteDirectory: {
      url: 'https://annuaire.quotatis.fr/artisan',
    },
    palette: {
      color: '#143C58',
    },
    sentry: {
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
    },
  },
  algolia: {
    applicationId: 'TIM81UW1UV',
    apiKey: '7411f9e2b69ad135d5484ce27ae7bc39',
    indexPrefix: 'qa_k8s',
  },
  api: {
    kongApiKey: 'UmMQGKmb4kGT0YFTk54UxYHVNIF4Oect',
    url: 'https://back-gw-qa.quotatis.com/engine',
  },
  mocks: {
    api: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/`,
    },
    documentsApi: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/documents-api`,
    },
    sylius: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/sylius`,
    },
    proApi: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/pro-api`,
    },
    hoApi: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/ho-api`,
    },
    engine: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/engine`,
    },
    billing: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/billing`,
    },
    frontApi: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/front-api`,
    },
    algolia: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/algolia`,
    },
    devSrv: {
      url: `http://${MOCK_HOST}:${DEVSRV_PORT}`,
    },
    mocking: true,
    auth0: {
      domain: 'carol-fr-dev.qarx.io:7002/auth0',
      clientID: 'ciGnXMY01zOPrxDWlYXob5vDojGpwZUG',
      audience: 'carol-fr-dev.qarx.io:7002/auth0/userinfo',
    },
    partners: {
      url: `http://${MOCK_HOST}:${MOCK_PORT}/partners`,
    },
  },
}

const config = merge(configs.all, configs[configs.all.env])

module.exports = config
