import { createSelector } from 'reselect'
import get from 'lodash/get'

export const initialState = {
  status: null,
  list: [],
}

const storeSelector = state => get(state, 'stores')

export const storesListSelector = createSelector(storeSelector, state => {
  const list = get(state, 'list', [])

  return list.reduce(
    (acc, currentValue) => [
      ...acc,
      {
        ...currentValue,
        name: currentValue.postalCode
          ? `${currentValue.name} - ${currentValue.postalCode.slice(0, 2)}`
          : `${currentValue.name}`,
      },
    ],
    [],
  )
})
