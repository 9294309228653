/* eslint-disable no-unused-expressions */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { withStyles, Grid, Typography, Button } from '@material-ui/core'
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog'
import { JOBS_SEARCH_PATH } from 'constants/routes'
import {
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
  PACKAGE_STATUS_VALIDATED,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  PACKAGE_STATUS_SUSPENDED_DEFINITIVELY,
} from 'constants/firms'
import styles from './FirmValidationProStyles'

const FirmValidationPro = ({
  classes,
  translate,
  handleSubmit,
  status,
  changePackageStatus,
  isFormValid,
  setLegacyId,
}) => {
  const [validateDialogOpened, setValidateDialogOpened] = useState(false)
  const [retestDialogOpened, setRetestDialogOpened] = useState(false)
  const [suspendedDialog, setSuspendedDialog] = useState(false)

  const handleValidateDialogResponse = event => {
    const { value } = event.currentTarget
    if (value === 'true') {
      handleSubmit()
      !isFormValid && changePackageStatus(status)
    } else {
      changePackageStatus(status)
    }
    setValidateDialogOpened(!validateDialogOpened)
  }

  const handleRetestDialogResponse = event => {
    const { value } = event.currentTarget
    if (value === 'true') {
      handleSubmit()
      !isFormValid && changePackageStatus(status)
    } else {
      changePackageStatus(status)
    }
    setRetestDialogOpened(!retestDialogOpened)
  }

  const handleSuspendedDialogResponse = event => {
    const { value } = event.currentTarget
    if (value === 'true') {
      handleSubmit()
      !isFormValid && changePackageStatus(status)
    } else {
      changePackageStatus(status)
    }
    setSuspendedDialog(!suspendedDialog)
  }

  return (
    <Grid container className={classes.root}>
      {validateDialogOpened && (
        <ConfirmDialog
          title={translate('resources.firms.validation_pro.dialog_title')}
          onClose={handleValidateDialogResponse}
        >
          <Typography component="p">
            {translate('resources.firms.validation_pro.dialog_message')}
          </Typography>
          <Typography className={classes.margin} component="p">
            {translate('resources.firms.validation_pro.warning')}
          </Typography>
        </ConfirmDialog>
      )}
      {retestDialogOpened && (
        <ConfirmDialog
          title={translate(
            'resources.firms.validation_pro.retest_dialog_title',
          )}
          onClose={handleRetestDialogResponse}
        >
          <Typography component="p">
            {translate('resources.firms.validation_pro.retest_dialog_message')}
          </Typography>
          <Typography className={classes.margin} component="p">
            {translate('resources.firms.validation_pro.warning')}
          </Typography>
        </ConfirmDialog>
      )}
      {suspendedDialog && (
        <ConfirmDialog
          title={translate(
            'resources.firms.validation_pro.dialog_suspend_title',
          )}
          onClose={handleSuspendedDialogResponse}
        >
          <Typography component="p">
            {translate('resources.firms.validation_pro.dialog_suspend_message')}
          </Typography>
          <Typography className={classes.margin} component="p">
            {translate('resources.firms.validation_pro.warning')}
          </Typography>
        </ConfirmDialog>
      )}
      <Grid item xs={12}>
        <Typography component="p">
          {translate('resources.firms.accordions.validate_pro.text')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {PACKAGE_STATUS_IN_PROGRESS_TEST_JOB.code === status && (
          <Link
            onClick={() => setLegacyId()}
            to={JOBS_SEARCH_PATH}
            target="_blank"
          >
            {translate('resources.firms.validation_pro.link_message')}
          </Link>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={0} justifyContent="flex-start">
          <Grid item className={classes.margin}>
            <Button
              onClick={() => {
                changePackageStatus(PACKAGE_STATUS_VALIDATED.code)
                setValidateDialogOpened(!validateDialogOpened)
              }}
              className={classes.buttonBigger}
            >
              {translate('resources.firms.buttons.pro_validate')}
            </Button>
          </Grid>

          <Grid item className={classes.margin}>
            <Button
              onClick={() => {
                changePackageStatus(PACKAGE_STATUS_PENDING_TEST_JOB.code)
                setRetestDialogOpened(!retestDialogOpened)
              }}
              className={classes.buttonSecondary}
              disabled={PACKAGE_STATUS_IN_PROGRESS_TEST_JOB.code !== status}
            >
              {translate('resources.firms.buttons.retest_pro')}
            </Button>
          </Grid>
          <Grid item className={classes.margin}>
            <Button
              onClick={() => {
                changePackageStatus(PACKAGE_STATUS_SUSPENDED_DEFINITIVELY.code)
                setSuspendedDialog(!suspendedDialog)
              }}
              variant="contained"
              className={classes.buttonDelete}
            >
              {translate('resources.firms.buttons.refuse_pro')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

FirmValidationPro.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    buttonBigger: PropTypes.string.isRequired,
    buttonSecondary: PropTypes.string.isRequired,
    buttonDelete: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  changePackageStatus: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  setLegacyId: PropTypes.func.isRequired,
}

export default compose(withStyles(styles), i18n)(FirmValidationPro)
