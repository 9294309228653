import React from 'react'
import { compose } from 'redux'
import { reduxForm, Field, reset } from 'redux-form'
import RenderSelectField from 'components/shared/Form/RenderSelectField'
import PropTypes from 'prop-types'
import i18n from 'providers/i18n/I18nProvider'
import { Grid, withStyles, Button } from '@material-ui/core'
import SimpleDialog from 'components/shared/SimpleDialog/SimpleDialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContentText from '@material-ui/core/DialogContentText'
import { FIRM_USER_STATUS_SUSPENDED_REASONS } from 'constants/firms'
import { UPDATE_USER_SUSPENSION_REASON_FORM } from 'constants/forms'
import { updateUserSuspensionReasons } from 'store/firms/firmActions'
import { required } from 'helpers/form/validationHelpers'
import styles from './FirmDetailsProSuspendedReasonsModalStyles'

const FirmDetailsProSuspendedReasonModal = ({
  translate,
  classes,
  handleClose,
  handleSubmit,
  open,
}) => (
  <SimpleDialog
    open={open}
    title={translate('firm.suspendedPro.popin.title')}
    onClose={handleClose}
    type="custom"
  >
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <DialogContentText>
          {translate('firm.suspendedPro.popin.text')}
        </DialogContentText>
        <Grid>
          <Field
            name="suspendedReason"
            id="suspendedReason"
            className={classes.input}
            component={RenderSelectField}
            choices={FIRM_USER_STATUS_SUSPENDED_REASONS}
            optionValue="code"
            validate={[required]}
          />
        </Grid>
      </DialogContent>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <DialogActions align="right">
          <Button type="submit" variant="contained" color="secondary">
            {translate('app.action.validate')}
          </Button>
          <Button onClick={handleClose} variant="outlined" color="primary">
            {translate('app.action.cancel')}
          </Button>
        </DialogActions>
      </Grid>
    </form>
  </SimpleDialog>
)

FirmDetailsProSuspendedReasonModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    input: PropTypes.string,
  }).isRequired,
  initialValues: PropTypes.shape({
    suspensionReason: PropTypes.string.isRequired,
  }).isRequired,
}

export default compose(
  i18n,
  withStyles(styles),
  reduxForm({
    form: UPDATE_USER_SUSPENSION_REASON_FORM,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmit: ({ suspendedReason }, dispatch, { currentStatus }) => {
      dispatch(
        updateUserSuspensionReasons.request({
          data: suspendedReason,
          packageStatusV2: currentStatus,
        }),
      )
    },
    onSubmitSuccess: (result, dispatch, { handleClose }) => {
      dispatch(reset(UPDATE_USER_SUSPENSION_REASON_FORM))
      handleClose()
    },
  }),
)(FirmDetailsProSuspendedReasonModal)
