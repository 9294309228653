import { all, call, put, takeLatest } from 'redux-saga/effects'
import { handleRequest } from 'helpers/store/sagasHelpers'
import { showNotification } from 'store/Application/ApplicationActions'
import { ERROR } from 'constants/variant'
import translate from 'providers/i18n/translateService'
import proApiClient from 'services/httpClient/proApiClient'

import {
  GET_FIRM_CONTRACT_OPTIONS,
  getFirmContractOptions,
} from './firmContractOptionsActions'

function* handleGetFirmContractOptions() {
  yield* handleRequest({
    requestActions: getFirmContractOptions,
    promise: call(proApiClient.get, '/api/contract_options'),
  })
}

function* handleGetFirmContractOptionsFailure() {
  yield put(
    showNotification({
      payload: {
        messageType: ERROR,
        message: translate(
          'resources.firms.fields.documents_deleteUploadFile_failure',
        ),
      },
    }),
  )
}

export default function*() {
  yield all([
    takeLatest(GET_FIRM_CONTRACT_OPTIONS.REQUEST, handleGetFirmContractOptions),
    takeLatest(
      GET_FIRM_CONTRACT_OPTIONS.FAILURE,
      handleGetFirmContractOptionsFailure,
    ),
  ])
}
