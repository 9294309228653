import React from 'react'
import PropTypes from 'prop-types'
import Label from 'components/shared/Label/Label'
import WrapperInline from 'components/shared/WrapperInline/WrapperInline'
import { withStyles } from '@material-ui/core'
import classnames from 'classnames'
import Icon from 'components/shared/Icon/Icon'
import styles from './InlineTextFieldStyle'
/**
 * InlineTextField
 *
 * render a text field on inline
 *
 * @param {string} label label to display
 * @param {string} field input field to display
 * @param {string} classes input class
 * @param {boolean} isBold input field to display
 * @param {boolean} isStriked input field to display
 * @param {boolean} isPreWrap input field to display
 * @param {string} certificate input field to display
 * @param {string} Element input field to display
 */
const InlineTextField = ({
  label,
  field,
  classes,
  isBold,
  isPreWrap,
  isStriked,
  certificate,
  Element,
}) => (
  <WrapperInline
    classes={{
      wrapper: isBold ? classes.bold : null,
    }}
    withIcon={!!certificate}
  >
    {label && <Label label={label} />}
    <Element
      className={classnames(
        isPreWrap ? classes.preWrap : '',
        isBold ? classes.bold : '',
        isStriked ? classes.strike : '',
        label && Element === 'span' ? classes.marginSpan : '',
        label && Element === 'p' ? classes.marginP : '',
      )}
    >
      {field}
    </Element>
    {certificate && (
      <Icon classes={{ root: classes.icon }} icon={`${certificate}.svg`} />
    )}
  </WrapperInline>
)

InlineTextField.propTypes = {
  classes: PropTypes.shape({
    marginSpan: PropTypes.string.isRequired,
    marginP: PropTypes.string.isRequired,
    bold: PropTypes.string.isRequired,
    strike: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    preWrap: PropTypes.string.isRequired,
  }).isRequired,
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  label: PropTypes.string,
  certificate: PropTypes.string,
  isBold: PropTypes.bool,
  isStriked: PropTypes.bool,
  isPreWrap: PropTypes.bool,
  Element: PropTypes.string,
}

InlineTextField.defaultProps = {
  field: '-',
  label: undefined,
  isBold: false,
  isStriked: false,
  isPreWrap: false,
  certificate: null,
  Element: 'span',
}

export default withStyles(styles)(InlineTextField)
