export default () => ({
  root: {
    paddingLeft: 0,
    display: 'inline-flex',
    width: '177px',
  },
  icon: {
    width: '20px',
    height: '20px',
    backgroundSize: 'contain',
  },
  label: {
    fontSize: '0.755rem',
    color: 'rgb(181, 181, 181)',
    flex: '0 0 100%',
  },
  title: {
    color: 'green',
  },
  proDirectoryisActivate: {
    color: '#6eab71',
    maxWidth: '150px',
    flex: '1 0 50%',
  },
  proDirectoryisDesactivate: {
    color: '#de616f',
    flex: '1 0 50%',
  },
})
