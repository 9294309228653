import { FIRM_SET_FIRM_FILES } from 'store/firms/firmActions'
import { REMOVE_TMP_FILE } from 'store/proDocuments/proDocumentActions'
import { DOCUMENT_TYPES } from 'constants/documents'

// eslint-disable-next-line import/no-mutable-exports
let documentPlugin = {}

DOCUMENT_TYPES.forEach(type => {
  documentPlugin = {
    ...documentPlugin,
    [`DocumentForm${type}`]: (state = {}, action) => {
      const formValues =
        state && state.values ? state.values[action.documentType] : []
      const index = (formValues || []).findIndex(
        formValue => formValue.id === action.idFile,
      )

      switch (action.type) {
        case REMOVE_TMP_FILE:
          if (index > -1) {
            formValues.splice(index, 1)
          }
          return {
            ...state,
            values: {
              ...state.values,
              [action.documentType]: formValues,
            },
          }
        case FIRM_SET_FIRM_FILES:
          return {
            ...state,
            values: {
              ...state.values,
              [`${action.payload.type}Files`]: [],
            },
          }

        default: {
          return state
        }
      }
    },
  }
})

export default documentPlugin
