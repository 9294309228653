import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { TableCell, TableRow } from '@material-ui/core'
import i18n from 'providers/i18n/I18nProvider'
import SortableTableCell from 'components/shared/Tables/SortableTableCell'

const InvoicesListTableHead = ({
  handleChangeSort,
  translate,
  orderBy,
  sort,
}) => (
  <TableRow>
    <TableCell>{translate('invoice.reference.label')}</TableCell>
    <TableCell>{translate('invoice.store.code')}</TableCell>
    <TableCell>{translate('invoice.company.label')}</TableCell>
    <TableCell>{translate('invoice.pro.label')}</TableCell>
    <TableCell>{translate('invoice.client.label')}</TableCell>
    <TableCell>{translate('invoice.time_slot.label')}</TableCell>
    <TableCell>{translate('invoice.status.label')}</TableCell>
    <SortableTableCell
      sortField="stateUpdatedAt"
      label={translate('invoice.status_date.label')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <TableCell>{translate('invoice.total.label')}</TableCell>
    <SortableTableCell
      sortField="issuedAt"
      label={translate('invoice.issued_at.label')}
      orderBy={orderBy}
      sort={sort}
      handleChangeSort={handleChangeSort}
    />
    <TableCell>{translate('invoice.total_sale_price.label')}</TableCell>
  </TableRow>
)

InvoicesListTableHead.propTypes = {
  handleChangeSort: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
}

export default compose(i18n)(InvoicesListTableHead)
