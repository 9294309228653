import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import i18n from 'providers/i18n/I18nProvider'
import { setBarTitle } from 'store/Application/ApplicationActions'
import { getSingleJob } from 'store/jobs/jobActions'
import {
  statusOrSubstatusSelector,
  invoiceStatusSelector,
} from 'store/jobs/jobSelectors'
import { showStatusJobDetailsSelector } from 'store/Application/ApplicationSelectors'
import ChipStatus from './ChipStatus'

const ChipStatusContainer = ({ showStatus, status, invoiceStatus }) =>
  showStatus && (
    <ChipStatus
      showStatus={showStatus}
      status={status}
      invoiceStatus={invoiceStatus}
    />
  )

ChipStatusContainer.propTypes = {
  status: PropTypes.string,
  invoiceStatus: PropTypes.string,
  showStatus: PropTypes.bool.isRequired,
}

ChipStatusContainer.defaultProps = {
  status: undefined,
  invoiceStatus: undefined,
}

const mapStateToProps = state => ({
  invoiceStatus: invoiceStatusSelector(state),
  status: statusOrSubstatusSelector(state),
  showStatus: showStatusJobDetailsSelector(state),
})

const mapDispatchToProps = dispatch => ({
  getJob: searchParams => dispatch(getSingleJob.request(searchParams)),
  setTitle: barTitle => dispatch(setBarTitle({ barTitle })),
})

export default compose(
  i18n,
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(ChipStatusContainer)
