import { all, takeLatest, delay, select, put, call } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import translate from 'providers/i18n/translateService'
import { handleRequest } from 'helpers/store/sagasHelpers'
import { change } from 'redux-form'
import engineClient from 'services/httpClient/engineClient'
import { JOB_PATH_PREFIX } from 'constants/routes'
import {
  JOB_TRANSITION_TO_PENDING_PAYMENT,
  JOB_TRANSITION_RE_MATCHING,
  JOB_TRANSITION_ASSIGNMENT,
  JOB_TRANSITION_CANCEL_ASSIGNMENT,
  JOB_TRANSITION_CANCEL_RESCHEDULE,
  MANUAL_MATCHING_FORM_NAME,
  FIELD_SELECTED_PRO_IRI_NAME,
  JOB_TRANSITION_PROCESS_JOB_START,
  JOB_TRANSITION_TO_PENDING_CANCELATION,
  JOB_TRANSITION_TO_PENDING_NEW_DATE,
  JOB_TRANSITION_PROCESS_CANCELED,
  JOB_TRANSITION_PROCESS_TO_DONE,
} from 'constants/Jobs'
import {
  toPendingPaymentTransition,
  getJobEventsForJob,
  jobRematchingTransition,
  jobAcceptTransition,
  jobDeclineTransition,
  jobReschedulingTransition,
  processJobStartTransition,
  toPendingCancelationTransition,
  JOB_DECLINE_TRANSITION_REQ,
  JOB_ACCEPT_TRANSITION_REQ,
  JOB_REMATCHING_TRANSITION_REQ,
  TO_PENDING_PAYMENT_TRANSITION_REQ,
  JOB_RESCHEDULING_TRANSITION_REQ,
  PROCESS_JOB_START_TRANSITION_REQ,
  TO_PENDING_CANCELATION_TRANSITION_REQ,
  JOB_PENDING_NEW_DATE_TRANSITION_REQ,
  jobPendingNewDateTransition,
  toCancelTransition,
  TO_CANCEL_TRANSITION_REQ,
  toDoneTransition,
  TO_DONE_TRANSITION_REQ,
} from 'store/jobs/jobActions'
import { showNotification } from 'store/Application/ApplicationActions'
import { ERROR, INFO } from 'constants/variant'
import { formatIriForTransition } from 'helpers/utils/job/job'
import { currentJobHistoryParametersSelector } from 'store/jobs/jobSelectors'
import { handleGetMatchingPros } from 'store/jobs/jobSagas'

import {
  HTTP_CONFLICT,
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_INTERNAL_SERVER_ERROR,
} from 'constants/httpCodes'

const getActionFromTransition = transition => {
  switch (transition) {
    case JOB_TRANSITION_TO_PENDING_PAYMENT:
      return toPendingPaymentTransition
    case JOB_TRANSITION_RE_MATCHING:
      return jobRematchingTransition
    case JOB_TRANSITION_ASSIGNMENT:
      return jobAcceptTransition
    case JOB_TRANSITION_CANCEL_ASSIGNMENT:
      return jobDeclineTransition
    case JOB_TRANSITION_CANCEL_RESCHEDULE:
      return jobReschedulingTransition
    case JOB_TRANSITION_PROCESS_JOB_START:
      return processJobStartTransition
    case JOB_TRANSITION_TO_PENDING_CANCELATION:
      return toPendingCancelationTransition
    case JOB_TRANSITION_TO_PENDING_NEW_DATE:
      return jobPendingNewDateTransition
    case JOB_TRANSITION_PROCESS_CANCELED:
      return toCancelTransition
    case JOB_TRANSITION_PROCESS_TO_DONE:
      return toDoneTransition
    default:
      return ''
  }
}

const getTypeError = (errorCode = HTTP_INTERNAL_SERVER_ERROR) => {
  switch (errorCode) {
    case HTTP_CONFLICT:
      return 'conflict_error'
    case HTTP_UNPROCESSABLE_ENTITY:
      return 'unprocessable_error'
    default:
      return 'error'
  }
}

function* applyTransitionForJob(
  jobIri,
  transition,
  data,
  pushToLocation = false,
) {
  try {
    const action = getActionFromTransition(transition)
    const url = `${formatIriForTransition(jobIri)}/transition/${transition}`
    const options = { triggerModalLoader: true }
    yield* handleRequest({
      requestActions: action,
      promise: call(engineClient.post, url, data),
      actionParams: options,
    })

    const jobEventSearchParams = yield select(
      currentJobHistoryParametersSelector,
    )

    const searchParams = {
      ...jobEventSearchParams,
      job: jobIri,
    }

    yield put(getJobEventsForJob.request({ searchParams }))

    yield put(
      showNotification({
        payload: {
          message: translate(`job.${transition}.success`),
          messageType: INFO,
        },
      }),
    )

    if (pushToLocation) {
      yield delay(1000)
      yield put(push(`${JOB_PATH_PREFIX}/${encodeURIComponent(jobIri)}/show`))
    }
  } catch (e) {
    const typeError = getTypeError(e.response.status)
    console.error(e)
    yield put(
      showNotification({
        payload: {
          message: translate(`job.${transition}.${typeError}`),
          messageType: ERROR,
        },
      }),
    )
  }
}

// The keyword transtion is deprecated for transition routes
function* applyTransitionForJobByUrl(
  jobIri,
  transition,
  prefixurl,
  data,
  pushToLocation = false,
) {
  try {
    const action = getActionFromTransition(transition)
    const url = `${formatIriForTransition(jobIri)}/${prefixurl}/${transition}`
    const options = { triggerModalLoader: true }
    yield* handleRequest({
      requestActions: action,
      promise: call(engineClient.post, url, data),
      actionParams: options,
    })

    const jobEventSearchParams = yield select(
      currentJobHistoryParametersSelector,
    )

    const searchParams = {
      ...jobEventSearchParams,
      job: jobIri,
    }

    yield put(getJobEventsForJob.request({ searchParams }))

    yield put(
      showNotification({
        payload: {
          message: translate(`job.${transition}.success`),
          messageType: INFO,
        },
      }),
    )

    if (pushToLocation) {
      yield delay(1000)
      yield put(push(`${JOB_PATH_PREFIX}/${encodeURIComponent(jobIri)}/show`))
    }
  } catch (e) {
    const typeError = getTypeError(e.response.status)
    console.error(e)
    yield put(
      showNotification({
        payload: {
          message: translate(`job.${transition}.${typeError}`),
          messageType: ERROR,
        },
      }),
    )
  }
}

function* handleToPendingPaymentTransitionRequest({ jobIri }) {
  yield applyTransitionForJob(jobIri, JOB_TRANSITION_TO_PENDING_PAYMENT)
}

function* handleRematchingJobTransitionRequest({ jobIri }) {
  yield applyTransitionForJob(jobIri, JOB_TRANSITION_RE_MATCHING)
  yield delay(1000)
  const searchParams = {
    page: 1,
    rowsPerPage: 25,
    jobId: jobIri,
  }

  yield handleGetMatchingPros(searchParams)
}

function* handleAcceptJobTransitionRequest({ jobId, data }) {
  yield applyTransitionForJob(jobId, JOB_TRANSITION_ASSIGNMENT, data, true)
}

function* handleDeclineJobTransitionRequest({ jobIri, proIri }) {
  const data = { matchingPro: { pro: proIri } }
  yield applyTransitionForJob(jobIri, JOB_TRANSITION_CANCEL_ASSIGNMENT, data)
  yield put(
    change(MANUAL_MATCHING_FORM_NAME, FIELD_SELECTED_PRO_IRI_NAME, null),
  )
}
function* handleReschedulingJobTransitionRequest({
  jobIri,
  newTimeSlot,
  selectedProIri,
}) {
  const data = {
    matchingPro: {
      pro: selectedProIri,
      timeslot: { startTime: newTimeSlot[0], stopTime: newTimeSlot[1] },
    },
  }

  yield applyTransitionForJob(
    jobIri,
    JOB_TRANSITION_CANCEL_RESCHEDULE,
    data,
    true,
  )
}

function* handleProcessJobStartTransitionRequest({ jobIri }) {
  yield applyTransitionForJob(jobIri, JOB_TRANSITION_PROCESS_JOB_START)
}

function* handleToPendingCancelationTransitionRequest({ jobIri }) {
  yield applyTransitionForJob(jobIri, JOB_TRANSITION_TO_PENDING_CANCELATION)
}

function* handletoCancelTransitionRequest({ jobIri }) {
  yield applyTransitionForJob(jobIri, JOB_TRANSITION_PROCESS_CANCELED)
}

function* handletoDoneTransitionRequest({ jobIri }) {
  yield applyTransitionForJobByUrl(
    jobIri,
    JOB_TRANSITION_PROCESS_TO_DONE,
    'after_sale/resolution',
  )
}

function* handlePendingNewDateTransitionRequest({ jobIri }) {
  yield applyTransitionForJob(jobIri, JOB_TRANSITION_TO_PENDING_NEW_DATE)
}

export default function*() {
  yield all([
    takeLatest(
      TO_PENDING_PAYMENT_TRANSITION_REQ.REQUEST,
      handleToPendingPaymentTransitionRequest,
    ),
    takeLatest(
      JOB_REMATCHING_TRANSITION_REQ.REQUEST,
      handleRematchingJobTransitionRequest,
    ),
    takeLatest(
      JOB_PENDING_NEW_DATE_TRANSITION_REQ.REQUEST,
      handlePendingNewDateTransitionRequest,
    ),
    takeLatest(
      JOB_ACCEPT_TRANSITION_REQ.REQUEST,
      handleAcceptJobTransitionRequest,
    ),
    takeLatest(
      JOB_DECLINE_TRANSITION_REQ.REQUEST,
      handleDeclineJobTransitionRequest,
    ),
    takeLatest(
      JOB_RESCHEDULING_TRANSITION_REQ.REQUEST,
      handleReschedulingJobTransitionRequest,
    ),
    takeLatest(
      PROCESS_JOB_START_TRANSITION_REQ.REQUEST,
      handleProcessJobStartTransitionRequest,
    ),
    takeLatest(
      TO_PENDING_CANCELATION_TRANSITION_REQ.REQUEST,
      handleToPendingCancelationTransitionRequest,
    ),
    takeLatest(
      TO_CANCEL_TRANSITION_REQ.REQUEST,
      handletoCancelTransitionRequest,
    ),
    takeLatest(TO_DONE_TRANSITION_REQ.REQUEST, handletoDoneTransitionRequest),
  ])
}
