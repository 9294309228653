import React from 'react'
import PropTypes from 'prop-types'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormHelperText from '@material-ui/core/FormHelperText'

/**
 * Usage :
 *
 *    <Field
        name="sex"
        component={RenderRadio}
        choices={[
          { name: 'Male', value: 'male' },
          { name: 'Female', value: 'female' },
        ]}
      />
 */

const RenderRadio = ({
  input,
  label,
  choices,
  radioText,
  radioValue,
  radioKey,
  meta: { touched, error },
  ...rest
}) => (
  <FormControl error={touched && error !== undefined}>
    {label && <FormLabel component="legend">{label}</FormLabel>}
    <RadioGroup {...input} {...rest}>
      {choices.map(choice => (
        <FormControlLabel
          key={choice[radioKey]}
          value={choice[radioValue]}
          control={<Radio />}
          label={choice[radioText]}
        />
      ))}
    </RadioGroup>
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)

RenderRadio.propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  radioText: PropTypes.string,
  radioValue: PropTypes.string,
  radioKey: PropTypes.string,
  meta: PropTypes.shape({
    touch: PropTypes.bool,
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
}

RenderRadio.defaultProps = {
  label: '',
  radioText: 'name',
  radioValue: 'value',
  radioKey: 'key',
  meta: {},
}

export default RenderRadio
