import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withStyles, Tooltip } from '@material-ui/core'
import WithHover from 'components/shared/WithHover/WithHover'
import Icon from 'components/shared/Icon/Icon'
import styles from './AvailableTableStyles'

const AvailableTable = ({ bubbleContent, classes, icon }) => (
  <div>
    {bubbleContent ? (
      <Tooltip
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
        }}
        title={bubbleContent}
        placement="top"
        arrow
      >
        <div>
          <Icon className={classes.icon} icon={`${icon}.svg`} />
        </div>
      </Tooltip>
    ) : (
      <div>
        <Icon className={classes.icon} icon={`${icon}.svg`} />
      </div>
    )}
  </div>
)

AvailableTable.propTypes = {
  bubbleContent: PropTypes.string,
  icon: PropTypes.string,
  classes: PropTypes.shape({
    icon: PropTypes.string,
    customTooltip: PropTypes.string,
    customArrow: PropTypes.string,
  }),
}

AvailableTable.defaultProps = {
  bubbleContent: '',
  icon: '',
  classes: undefined,
}

export default compose(withStyles(styles), WithHover)(AvailableTable)
