export default theme => ({
  preWrap: {
    ...theme.preWrap,
  },
  text: {
    fontWeight: theme.typography.normal.fontWeight,
  },
  bold: {
    ...theme.typography.body2,
    fontWeight: theme.typography.bold.fontWeight,
    lineHeight: '2 rem',
  },
  strike: {
    textDecorationLine: 'line-through',
  },
  icon: {
    display: 'inline-block',
    width: '40px',
    height: '16px',
    margin: '3px 0 -3px 5px',
    backgroundSize: 'contain',
  },
  marginSpan: {
    marginLeft: '5px',
  },
  marginP: {
    margin: '0',
  },
})
